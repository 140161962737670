import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import Button from "react-bootstrap/Button";

const LoginForm = ({
  validate,
  onSubmit,
  passwordType,
  showHidePassword,
  valMsgs,
  applicationEndpoint,
  freemiumUser,
  scrollToError,
  setValMsgs
}) => {
  return (
    <Fragment>
      <Formik
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
        initialValues={{
          username: "",
          password: "",
          rememberChecked: true
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          errors,
          isSubmitting,
          isValidating,
          isValid
        }) => (
          <Form onSubmit={handleSubmit} className="ml-2 mr-2">
            <Form.Group>
              <Form.Label className="loginInput appFontSubHeading">Username or email address</Form.Label>
              <Form.Control
                data-testid="username"
                id="username"
                name="username"
                className={"form-control form-custom-control shadow-none" + (valMsgs == "" ? "" : " loginBorder")}
                type="username"
                value={values.username}
                onChange={e=> {handleChange(e);setValMsgs("");}}
                onBlur={handleBlur}
                isInvalid={touched.username && errors.username}
                tabIndex="1" // tabIndex is used to set the order of tabbing through the form fields
              />
              <Form.Control.Feedback data-testid="userNameError" type="invalid" className="error-message">
                {errors.username}
              </Form.Control.Feedback>
              {!errors.username && valMsgs && (
                <div type="invalid" className="error-message">
                  {valMsgs}
                </div>
              )}
            </Form.Group>
            <Form.Group>
              <div className="row-label">
                <div className="col-md-8">
                  <Form.Label className="loginInput appFontSubHeading">Password</Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="loginInputForgotPassword appFont">
                    <a
                      href={applicationEndpoint + "ForgotPassword/Index"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Forgot password?
                    </a>
                  </Form.Label>
                </div>
              </div>
              <Button
                className={
                  passwordType === "password" ? "password-icon hide-password-icon" : "password-icon"
                }
                onClick={e => {
                  showHidePassword(e, "password");
                }}
              />
              <Form.Control
                data-testid="password"
                id="password"
                name="password"
                type={passwordType}
                className="form-control form-custom-control shadow-none"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.password && errors.password}
                tabIndex="2"
              />
              <Form.Control.Feedback data-testid="passwordError" type="invalid" className="error-message">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  data-testid="rememberChecked"
                  id="rememberChecked"
                  name="rememberChecked"
                  value={values.rememberChecked}
                  onChange={e => setFieldValue("rememberChecked", e.target.checked)}
                  onBlur={handleBlur}
                  defaultChecked={true}
                />
                <Form.Check.Label className="rememberCheckboxInput">Remember me</Form.Check.Label>
              </Form.Check>
            </Form.Group>
            {/* Visible login button fixed to bottom of page and can cover password field on occasion,
                    hidden button matches the size of visible login button to prevent this. */}
            <div className="hidden-button ml-3 mr-3 pb-3 pt-3 whiteBackground">
              <button className="loginButton btn-primary btn-block shadow-none blueButton">Log in</button>
            </div>
            <Form.Group>
              <div className="fixed-bottom ml-3 mr-3 pb-3 pt-3 whiteBackground">
                <button data-testid="LoginFormSubmitButton" type="submit" className="loginButton btn-primary btn-block shadow-none blueButton" tabIndex="3">
                  Log in
                </button>
                {freemiumUser && (
                  <div className="appFontSubHeading signupText">
                    Don’t have access to TRIYO?{" "}
                    <a href="https://triyo.io/self-serve-sign-up/" target="blank">
                      Click here to sign up
                    </a>
                  </div>
                )}
              </div>
            </Form.Group>
            {/* Formik validation occurs automatically both on form change and on submit. Formik form events used to ensure the scroll event
                  is called only once on submit; during Formik validation when the form is registered as invalid.*/}
            {isValidating && isSubmitting && !isValid ? scrollToError(touched, errors) : null}
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default LoginForm;