import React, { Fragment } from "react";
import { OverlayTrigger, Popover, Dropdown, Tooltip } from "react-bootstrap";
import Moment from "react-moment";
import ReassignMemberIcon from "../../resources/Icons/Icon-Reassign-Members-Green-36px.svg";
import Arrow from "../../resources/Icons/Icon-Right-Arrow-Black-32px.svg";
import downloadIcon from "../../resources/Icons/Download-20px-Blue-60.svg";
import arrowIconLightBlue from "../../resources/Icons/Icon-Arrow-Down-LightBlue-12x13px.svg";
import attachmentIconLightBlue from "../../resources/Icons/Icon-Attachment-LightBlue-12x13px.svg";
import "./TaskAuditSingleComment.scss";
import "./TaskAuditReassignMembers.scss";
import TaskReassignMemberPopover from "./TaskReassignMemberPopover";
import PropTypes from 'prop-types';

const TaskAuditReassignMembers = ({
  comment,
  projectMembers,
  userName,
  userEmail,
  taskRole,
  actionType,
  commentText,
  downloadFiles,
  documentList,
  index,
  emailTask
}) => {

  const reassignedMembers = (m) => {
    if (m == "") {
      return [];
    }
    else {
      let members = m.split(",");
      return projectMembers.filter((member) => members.includes(member.Id.toString()));
    }
  }

  const filesToDownload = (emailTask, documentList, index) => {
    try {
      if (!emailTask) {
        return documentList;
      }
      else if (emailTask && index === 0) {
        return documentList.filter((d, i) => i !== (documentList.length - 1));
      }
      else if (emailTask && index !== 0) {
        return documentList.filter((d, i) => i !== 0);
      }
    }
    catch (err) {
      console.log("ERROR : filesToDownload", err)
      return [];
    }
  }

  return (
    <div className="TaskAuditReassignSection flex-grow-1">
      <div className="taskAuditDateTimeText align-items-start">
        <Moment format="MM/DD/YYYY">{comment?.Created}</Moment> <Moment format="h:mm a">{comment?.Created}</Moment> · Notification
      </div>
      <div className="user">
        <div>
          <img src={ReassignMemberIcon}></img>
        </div>
        <div className="userDetails">
          <div className="reassign-heading">
            Member Permission(s) Re-assigned
          </div>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{userEmail}</Tooltip>}
          >{
              <span className="reassign-by">by <b>{userName?.length > 30 ? `${userName?.slice(0, 30)}...` : userName} </b>({taskRole})</span>
            }
          </OverlayTrigger>
        </div>
      </div>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{actionType === "Reassigned" ? "Re-Assigned" : actionType}
        </Tooltip>}
      >
        <div className="taskAuditStatusContainer ">
          {actionType === "Reassigned" ? "Re-Assigned" : actionType}
        </div>
      </OverlayTrigger>
      <div>
      {
          comment.ReassignTaskMembers?.OwnerChanged &&
            <div>
              <div className="memberRole">
                Owner
              </div>
              <div className="memberChange">
                <div className="oldPermission">
                  {comment.ReassignTaskMembers.OwnerOld == "" ? <span><b>Unassigned</b></span>
                    : <TaskReassignMemberPopover
                      reassignMembers={reassignedMembers(comment.ReassignTaskMembers.OwnerOld)}
                    ></TaskReassignMemberPopover>}
                </div>
                <img src={Arrow} alt="Transfer Arrow"></img>
                <div className="newPermission">
                  {comment.ReassignTaskMembers.OwnerNew == "" ? <span><b>Unassigned</b></span>
                    : <TaskReassignMemberPopover
                      reassignMembers={reassignedMembers(comment.ReassignTaskMembers.OwnerNew)}
                    ></TaskReassignMemberPopover>}
                </div>
              </div>
            </div>           
        }
        {
          comment.ReassignTaskMembers?.ApproverChanged &&
            <div>
              <div className="memberRole">
                Approver
              </div>
              <div className="memberChange">
                <div className="oldPermission">
                  {comment.ReassignTaskMembers.ApproverOld == "" ? <span><b>Unassigned</b></span>
                    : <TaskReassignMemberPopover
                      reassignMembers={reassignedMembers(comment.ReassignTaskMembers.ApproverOld)}
                    ></TaskReassignMemberPopover>}
                </div>
                <img src={Arrow} alt="Transfer Arrow"></img>
                <div className="newPermission">
                  {comment.ReassignTaskMembers.ApproverNew == "" ? <span><b>Unassigned</b></span>
                    : <TaskReassignMemberPopover
                      reassignMembers={reassignedMembers(comment.ReassignTaskMembers.ApproverNew)}
                    ></TaskReassignMemberPopover>}
                </div>
              </div>
            </div>
        }
        {
          comment.ReassignTaskMembers?.EditorChanged &&
            <div>
              <div className="memberRole">
                Editor
              </div>
              <div className="memberChange">
                <div className="oldPermission">
                  {comment.ReassignTaskMembers.EditorOld == "" ? <span><b>Unassigned</b></span>
                    : <TaskReassignMemberPopover
                      reassignMembers={reassignedMembers(comment.ReassignTaskMembers.EditorOld)}
                    ></TaskReassignMemberPopover>}
                </div>
                <img src={Arrow} alt="Transfer Arrow"></img>
                <div className="newPermission">
                  {comment.ReassignTaskMembers.EditorNew == "" ? <span><b>Unassigned</b></span>
                    : <TaskReassignMemberPopover
                      reassignMembers={reassignedMembers(comment.ReassignTaskMembers.EditorNew)}
                    ></TaskReassignMemberPopover>}
                </div>
              </div>
            </div>
        }
        {
          comment.ReassignTaskMembers?.ViewerChanged &&
            <div>
              <div className="memberRole">
                Viewer
              </div>
              <div className="memberChange">
                <div className="oldPermission">
                  {comment.ReassignTaskMembers.ViewerOld == "" ? <span><b>Unassigned</b></span>
                    : <TaskReassignMemberPopover
                      reassignMembers={reassignedMembers(comment.ReassignTaskMembers.ViewerOld)}
                    ></TaskReassignMemberPopover>}
                </div>
                <img src={Arrow} alt="Transfer Arrow"></img>
                <div className="newPermission">
                  {comment.ReassignTaskMembers.ViewerNew == "" ? <span><b>Unassigned</b></span>
                    : <TaskReassignMemberPopover
                      reassignMembers={reassignedMembers(comment.ReassignTaskMembers.ViewerNew)}
                    ></TaskReassignMemberPopover>}
                </div>
              </div>
            </div>          
        }
      </div>
      <div className={"taskAuditComment taskAuditNormalComment text-break"}>
        {commentText === "<No comments entered>" ? "" : commentText}
      </div>
      <div className="taskAuditDownloads mb-2 mt-2">
        {filesToDownload(emailTask, documentList, index)?.length > 0 && (
          <Fragment>
            <OverlayTrigger
              rootClose={true}
              placement="top"
              trigger="click"
              overlay={
                <Popover id="popover-contained">
                  <Popover.Content>
                    {
                      filesToDownload(emailTask, documentList, index)
                        .map((file, idx) => {
                          return (
                            <div key={file?.Name} className="download-file" onClick={() => downloadFiles(file)}>
                              <img
                                src={downloadIcon}
                                className="taskEditorDownloadIcon"
                                alt="Download File"
                                width="12px"
                                height="12px"
                              />
                              <span className="download-file-name download-audit">
                                {file.Name.length > 15
                                  ? `${file.Name.slice(0, 15)}...${file.Name.slice(
                                    file.Name.length - 5,
                                    file.Name.length
                                  )}`
                                  : file.Name}
                              </span>
                              {(emailTask ? (idx !== documentList.length - 2) : (idx !== documentList.length - 1)) && (
                                <Dropdown.Divider className="dropdown-row-divider" />
                              )}
                            </div>
                          );
                        })}
                  </Popover.Content>
                </Popover>
              }
            >
              <div className="AuditTrailAttachmentContainer">
                <span className="mt-2" style={{ color: "#1592E6", fontSize: "12px" }}>
                  <img src={attachmentIconLightBlue} className="taskEditorAttachmentIcon" alt="Attachment" />
                  {`${filesToDownload(emailTask, documentList, index).length} ${filesToDownload(emailTask, documentList, index).length > 1
                    ? "Attachments" : "Attachment"
                    }`}
                  <img src={arrowIconLightBlue} className="taskEditorArrowIcon" alt="Attachment" />
                </span>
              </div>
            </OverlayTrigger>
            {(filesToDownload(emailTask, documentList, index)).length > 1 && (
              <div
                className="downloadAll"
                onClick={() => downloadFiles(
                  (filesToDownload(emailTask, documentList, index)))}
              >
                <img
                  src={downloadIcon}
                  className="taskEditorDownloadIcon"
                  alt="Download File"
                  width="12px"
                  height="12px"
                />
                <a style={{ color: "#1592E6", fontSize: "12px" }}>Download All</a>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  )

};

TaskAuditReassignMembers.propTypes = {
  comment: PropTypes.shape({
      ReassignTaskMembers: PropTypes.shape({
      OwnerChanged: PropTypes.bool,
      OwnerOld: PropTypes.string,
      OwnerNew: PropTypes.string,
      ApproverChanged: PropTypes.bool,
      ApproverOld: PropTypes.string,
      ApproverNew: PropTypes.string,
      EditorChanged: PropTypes.bool,
      EditorOld: PropTypes.string,
      EditorNew: PropTypes.string,
      ViewerChanged: PropTypes.bool,
      ViewerOld: PropTypes.string,
      ViewerNew: PropTypes.string,
    }),
  }),  
};

export default TaskAuditReassignMembers;
