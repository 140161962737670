// Same function in Email Parser - MVC
export const GetBetween = (strSource, strStart, strEnd) => {
  let Start;
  let End;
  if (strSource.includes(strStart) && strSource.includes(strEnd)) {
    Start = strSource.indexOf(strStart, 0) + strStart.length;
    End = strSource.indexOf(strEnd, Start);
    return strSource.substring(Start, End);
  } else {
    return "";
  }
};

export const parseEmailHeaders= (rawEmail) => {
  const lines = rawEmail.split(/\r?\n/);
  const headers = {};

  for (const line of lines) {
    if (line === "") {
      break;
    }

    if (line.startsWith(" ") || line.startsWith("\t")) {
      const lastHeader = headers[Object.keys(headers).pop()];
      headers[Object.keys(headers).pop()] = lastHeader + line.trim();
    } else {
      const match = line.match(/^([\w-]+):\s*(.*)$/);
      if (match) {
        const headerName = match[1].toLowerCase();
        const headerValue = match[2];
        headers[headerName] = headerValue;
      }
    }
  }

  return headers;
}
