import React, { useContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ApiService from "../../services/ApiService";
import TaskListDetails from "./TasksListDetails";
import AppContext from "../../app/AppContext";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import { calendarIconBlue, disabledIcon, plusIcon } from "../../resources/Icons/icons";
import TaskCreateButton from "./TaskCreateButton.js";
import ManualRefreshButton from "../manual-refresh-button/manual-refresh-button";
import noTasksPlaceHolder from "../../resources/Images/No-task-placeholder-186px.svg";
import "bootstrap/dist/css/bootstrap.min.css";

const TasksList = (props) => {
  const { localizeTaskDates, setPage, setAppPage, isRegularTask } = props;
  const context = useContext(AppContext);
  const appType = context.officeAppType;
  const [updatedKey, setUpdatedKey] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [mainTasks, setMainTasks] = useState([]);
  const [otherTasks, setOtherTasks] = useState([]);
  const [filterTasks, setFilterTasks] = useState("");
  const [mainDocType, setMainDocType] = useState("");
  const [docTypeMain, setDocTypeMain] = useState("");
  const [docTypeOther, setDocTypeOther] = useState("");
  const [hide, setHide] = useState(false);
  const [loading, setLoading] = useState(true);
  const [canCreateTask, setCanCreateTask] = useState(true);
  const [taskAlreadyExists, setTaskAlreadyExists] = useState(false);
  const [existingTask, setExistingTask] = useState();
  const [onClickCreateTask, setOnClickCreateTask] = useState(false);

  const checkRolePrivilege = async (user, privilege, menuItem) => {
    let status=false;
    if (user) {
      for (const currPrivilege of user['RolePrivilege']) {
        if (currPrivilege['MenuItem'] == menuItem && currPrivilege[privilege]) {
          status=true;
          return setCanCreateTask(status);
        }
      }
      status = false;
      return setCanCreateTask(status);
    }
  };

  const getDataTasks = async () => {
    console.log("Waiting for [GetUserTasks]...");
    await ApiService.getUserTasks(context)
      .then((result) => {
        if (result.status === 200) {
          let tasksList = result.data.Tasks;
          tasksList.forEach(task => {
            task = localizeTaskDates(task);
          });
          setTasks(tasksList);
          if (tasksList) {
            if (appType === "Outlook") {
              // For Outlook
              let otherTasksListT =  tasksList;
              setMainTasks(tasksList.filter(task => task.TaskType === "Regular"));
              // For Outlook - Document bottom
              setOtherTasks(otherTasksListT.filter(task => task.TaskType === "Document"));
            }

            if ((appType === "PowerPoint") || (appType === "Word") || (appType === "Excel")) {
              // For PPT/Word - Top
              setMainTasks(tasksList.filter(task => task.DocumentType === appType));

              // For Std/PPT/Word - Bottom
              let otherTaskItems = tasksList.filter(task => task.DocumentType !== appType);
              let orderItems = ["Regular", "Document"];
              let mapper = new Map(orderItems.map((v, i) => [v, i + 1]))

              let finalTaskList = otherTaskItems.sort((a, b) => {
                return (mapper.get(a.TaskType) || Infinity) - (mapper.get(b.TaskType) || Infinity)
              })
              setOtherTasks(finalTaskList);
            }
          }
          checkRolePrivilege(context.currUser, "Add", "Task Management");
          setLoading(false);
          setUpdatedKey(updatedKey + 1);
        } else {
          console.log("API [GetUserTasks] error status: " + result.status);
          checkRolePrivilege(context.currUser, "Add", "Task Management");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("API [GetUserTasks] error ->");
        console.log(error);
        checkRolePrivilege(context.currUser, "Add", "Task Management");
        setLoading(false);
        setAppPage("500 error");
      })
  };

  useEffect(() => {
    const waitingForDocType = () => {
      if (appType.length) {
        getDataTasks();
        setDocTypeMain(appType === "Outlook"? "Regular" : appType);
        setDocTypeOther(appType === "Outlook" ? "Document" : "Other");
      } else {
        setTimeout(waitingForDocType, 300);
      }
    };
    waitingForDocType();
  }, [appType]);

  useEffect(() => {
    if (appType === "Outlook" && onClickCreateTask === true && !taskAlreadyExists) {
      if (context.currEmail) {
        if (context.prevEmail) {
          // User selected different email
          if (context.prevEmail?.item?.itemId !== context.currEmail?.item?.itemId) {
            if (context.currEmail?.item?.conversationId) {
              ApiService.getEmailTask(context.currEmail?.item?.conversationId, context)
                .then(result => {
                  const emailThreadExists = result.data;
                  if (context.currEmail?.item && !emailThreadExists && !isRegularTask) {
                    createTask();
                  } else if (emailThreadExists && !existingTask) {
                    setTaskAlreadyExists(true);
                    setExistingTask(emailThreadExists);
                  }
                });
            }
          }
        }
      }
    }
  }, [mainTasks, otherTasks, existingTask, taskAlreadyExists]);

  const setFilter = (value) => setFilterTasks(value);

  const createTask = (emailTask) => {
    context.previousPage = "tasksList";
    if(emailTask){
      setAppPage("task");
    } else {
      setAppPage("task : regularTask")
    }
  };

  const openWebPage = () => window.open(context.applicationEndpoint);

  const hideContent = (hidden) => { if (hide != hidden) setHide(hidden); };

  const onCreateTask = (emailTask) => {
    if (context.currEmail?.item?.conversationId && emailTask) {
      ApiService.getEmailTask(context.currEmail?.item?.conversationId, context)
        .then(result => {
          onCreateTaskCallback(result.data, emailTask);
        }).catch(error => console.log(error));
    } else {
      onCreateTaskCallback(null, emailTask);
    }
  };

  const onCreateTaskCallback = (emailThreadExists, emailTask) => {
    setOnClickCreateTask(true);
    if (emailThreadExists) {
      setTaskAlreadyExists(true);
      setExistingTask(emailThreadExists);
    } else {
      setOnClickCreateTask(false);
      createTask(emailTask);
    }
  };

  const isMemberAssignedToTask = (currentUserId, task) => {
    if (!task) return false;
    if (!task.Members?.length) return false;
    return task.Members.filter(m => m.UserId === currentUserId)?.length > 0;
  };

  const onTaskSelect = (task) => {
    if (task.Id == context.currentTaskId) {
      setAppPage("taskEditor");
    } else {
      context.currentTask = task;
      setAppPage("taskEditor");
    }
  };

  let activeView = null;

  if (appType === "Outlook" && onClickCreateTask && taskAlreadyExists) {
    activeView = (
      <div className="mt-7 mr-3">
        <div className="text-center icon-warning" style={{ width: '100px', height: '100px', marginTop: '60%' }}>
        </div>
        <div className="text-center mt-4">
          <span className="projectCreatedTitle">
            The task failed to create.
          </span>
          <p className="mt-3 px-3 mb-0">
            A new task could not be created because a user has already created a task on this email thread.
          </p>
          {!isMemberAssignedToTask(context.currUser.Id, existingTask) && <p>
            You do not have permission to view the existing task.
          </p>}
        </div>

        <div className="fixed-bottom pl-4 pr-4 pb-2 whiteBackground">
          <button
            className={`loginButton btn-primary blueButtonOutline ${isMemberAssignedToTask(context.currUser.Id, existingTask) ? 'button50' : 'button100'} ml-1 shadow-none`}
            onClick={() => setTaskAlreadyExists(false)}
          >
            Go to Task List
          </button>
          {isMemberAssignedToTask(context.currUser.Id, existingTask) && (
            <button
              className="loginButton btn-primary blueButton button50 mr-1 shadow-none float-right"
              onClick={() => onTaskSelect(existingTask)}
            >
            View Task
            </button>
          )}
          <div className="text-center mb-4 mt-3">
            <a href="#" onClick={() => openWebPage()}>
              <span className="appFont underline projectCreatedWebLink buttonLink">Switch to web application</span>
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (appType !== "Outlook" || (!onClickCreateTask || !taskAlreadyExists)) {
    activeView = (
      <div>
        <LoadingOverlay loading={loading} hideContent={hideContent} minDuration={100}/>
        { !hide &&
          <div className="mt-7" key={updatedKey}>
            <div className="row fixed-top whiteBackground taskSearchMargin">
              <div className="d-flex justify-content-between col-12 mt-3">
                <span className="projectPageTitle appFont">Tasks</span>
                <ManualRefreshButton refreshService={getDataTasks} />
              </div>
              { //No tasks
                appType === "Outlook" && (tasks?.length < 1) &&
                <div className="task-list-placeholder-container">
                  <div className="task-list-placeholder">
                    <img src={noTasksPlaceHolder} className="mt-3 mb-3" />
                    <h4 className="appFont font-weight-semi-bold pb-1">No tasks yet</h4>
                    <p className="appFontLightTwo pl-3 pr-3 pb-3">
                      Tasks you create will appear here. To
                      create a task click on the button below.
                    </p>
                    <TaskCreateButton
                      childButton = {
                        <button style={{ minWidth: "200px" }}
                          className="loginButton btn-primary blueButton button50 shadow-none"
                        >
                          Create Task
                        </button>
                      }
                      isWide={true}
                      className="dropdown"
                      onClickEvent={onCreateTask}
                      isDropup={true}
                    />
                  </div>
                </div>
              }
              { //At least 1 task
                ((appType !== "Outlook") || (appType === "Outlook" && tasks?.length > 0)) &&
                <div className="col-12 mw-100 mt-2" style={{ paddingRight: "30px" }}>
                  <div className="input-group mb-3">
                    <input
                      id="filterTasks"
                      name="filterTasks"
                      type="text"
                      value={filterTasks}
                      className="form-control form-custom-control searchInput textIconInput appFont task-list-search"
                      placeholder="Search Tasks"
                      onChange={(event) => setFilter(event.target.value)}
                    />
                    { canCreateTask && appType === "Outlook" &&
                      <TaskCreateButton
                        childButton = {
                          <div className="input-group-append" style={{ height: "36px" }}>
                            <span className="btn btn-outline-secondary shadow-none blueButtonInput">
                              <img src={plusIcon} alt="Tasks List"/>
                            </span>
                          </div>
                        }
                        className="dropleft"
                        onClickEvent={onCreateTask}
                      />
                    }
                    { !canCreateTask &&
                      <OverlayTrigger
                        placement="bottom-end"
                        overlay={
                          <Tooltip>
                            You don&apos;t have access to create tasks. Please contact administration for assistance.
                          </Tooltip>
                        }
                      >
                        <div>
                          <img src={disabledIcon} alt="disabled add icon"></img>
                        </div>
                      </OverlayTrigger>
                    }
                  </div>
                </div>
              }
            </div>
            <div className="pt-8"/>
              { ((appType !== "Outlook") || (appType === "Outlook" && tasks?.length > 0)) &&
                <span>
                  <TaskListDetails
                    tasks={mainTasks}
                    docType={docTypeMain}
                    filterTasks={filterTasks}
                    setPage={setPage}
                    main={mainDocType}
                    setMain={(type) => setMainDocType(type)}
                    setAppPage={setAppPage}
                    localizeTaskDates={localizeTaskDates}
                  />
                  <TaskListDetails
                    tasks={otherTasks}
                    docType={docTypeOther}
                    filterTasks={filterTasks}
                    setPage={setPage}
                    main={mainDocType}
                    setMain={(type) => setMainDocType(type)}
                    setAppPage={setAppPage}
                    localizeTaskDates={localizeTaskDates}
                  />
                </span>
              }
            <div className="pb-7"/>
            <div className="fixed-bottom pl-2 pr-2 whiteBackground">
              <div className="text-center switchToWeb">
                <a href="#" onClick={() => openWebPage()}>
                  <img src={calendarIconBlue} className="mr-2 pb-1" />
                  <span className="appFont underline buttonLink">Switch to web application</span>
                </a>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }

  return <div>{activeView}</div>;
}

TasksList.propTypes = {
  setAppPage: PropTypes.func,
  setPage: PropTypes.func,
  localizeTaskDates: PropTypes.func,
  isRegularTask: PropTypes.bool
};

export default TasksList;
