import React, { useState, createRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/TaskMembersPopover.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import GroupMembers from "../../resources/Icons/Icon-People-16px-Blue.svg";

const TaskMembersPopover = ({ projMembers, ownerId, groups, isProject = false, creator }) => {
  const [members, setMembers] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [membersPopover, setMembersPopover] = useState([]);
  const [showPopoverScroll, setShowPopoverScroll] = useState(false);
  const [ownerGroup, setOwnerGroup] = useState([]);
  const [hasGroupAsOwner, setHasGroupAsOwner] = useState(false);

  const membersRef = createRef();

  useEffect(() => {
    if (groups?.length > 0) {
      groups.forEach(element => {
        if (element.IsOwner) {
          setOwnerGroup(element);
          setHasGroupAsOwner(true);
        }
      });
    }
    ownerId && moveOwnerToFront();
    updateMembersPopoverList();
  }, [projMembers]);
  
  useEffect(() => {
    ownerId && moveOwnerToFront();
    updateMembersPopoverList();
  }, [hasGroupAsOwner]);

  const moveOwnerToFront = () => {
    const owner = getOwner();
    if (ownerGroup.length == 0) {
      if (owner && owner != undefined) {
        setMembers([owner, ...projMembers.filter(member => member.UserId !== ownerId || member.isGroup)]);
      } else {
        setMembers([...projMembers.filter(member => member.UserId !== ownerId || member.isGroup)]);
      }
    }
    else {
      if (owner && owner != undefined) {
        setMembers([ownerGroup, owner, ...projMembers.filter(member => member.UserId !== ownerId || member.isGroup)]);
      } else {
        setMembers([ownerGroup, ...projMembers.filter(member => member.UserId !== ownerId || member.isGroup)]);
      }
    }

  };

  const getOwner = () => {    
    let owner = projMembers.find(member => member?.UserId === ownerId && !member?.isGroup);
    if(!owner){
      owner = creator;
      return {User:owner, UserId: owner?.Id};
    }
    return owner;
  };

  const sortByName = currMembers => {
    if (!currMembers || !currMembers.length) return [];
    return currMembers.sort((a, b) => a.User.Name.localeCompare(b.User.Name));
  };

  const updateMembersPopoverList = () => {
    if (isProject) {
      setMembersPopover(projMembers);
      setShowPopoverScroll(projMembers.length > 4);
    } else {
      const owner = getOwner();
      const editors = sortByName(
        projMembers.filter(member => (member.CanEdit || member.IsAssigned) && member.UserId !== ownerId)
      );
      const approvers = sortByName(projMembers.filter(member => member.CanApprove && member.UserId !== ownerId));
      const viewers = sortByName(projMembers.filter(member => member.CanView && member.UserId !== ownerId));
      const publishers = sortByName(projMembers.filter(member => member.CanPublish && member.UserId !== ownerId));

      let newMembersPopover;

      if (ownerGroup.length == 0) {
        if (owner && owner != undefined) {
          newMembersPopover = [owner, ...editors, ...approvers, ...viewers, ...publishers];
        } else {
          newMembersPopover = [...editors, ...approvers, ...viewers, ...publishers];
        }
      }
      else {
        if (owner && owner != undefined) {
          newMembersPopover = [ownerGroup, owner, ...editors, ...approvers, ...viewers, ...publishers];
        } else {
          newMembersPopover = [ownerGroup, ...editors, ...approvers, ...viewers, ...publishers];
        }
      }
      setMembersPopover(newMembersPopover);
      setShowPopoverScroll(newMembersPopover.length > 4);
    }
  };

  const getPermission = (member, isOwner) => {
    let permission = "";

    if (member.CanApprove) permission = "Approve";
    else if (member.CanPublish) permission = "Publish";
    else if (member.CanEdit || member.IsAssigned) permission = "Edit";
    else if (member.CanView) permission = "View";
    else if (isOwner) permission = "Owner";

    return permission;
  };

  const getGroupPermission = (member) => {
    let permission = "";

    if (member.IsOwner) permission = "Owner";
    else if (member.CanApprove) permission = "Approve";
    else if (member.CanEdit || member.IsAssigned) permission = "Edit";
    else if (member.CanView) permission = "View";

    return permission;
  };

  const handlePopoverOnMouseEnter = () => {
    setShowPopover(true);
  };

  const handlePopoverOnMouseLeave = () => {
    setShowPopover(false);
  };

  const showTaskMembersPopover = () => {
    let activeView = null;
    if (members) {
      if (members.length > 0) {
        let extraMembers = members?.length - 4;
        activeView = (
          <div className="task-members-popover" ref={membersRef}>

            <OverlayTrigger
              placement="bottom-end"
              show={showPopover}
              container={membersRef}
              delay={{ show: 250, hide: 250 }}
              overlay={
                <div className="members-popover-container">
                  <div
                    className="members-popover"
                    onMouseEnter={handlePopoverOnMouseEnter}
                    onMouseLeave={handlePopoverOnMouseLeave}
                  >
                    <ul className={`text-right ${showPopoverScroll ? "members-popover-scroll" : ""}`}>
                      {membersPopover.map(member => {
                        const isOwner = ownerId && ownerId === member.UserId;
                        let isGroup = member.GroupId > 0;
                        return (
                          <li key={`li-${isGroup ? member.GroupId : member.User?.Id}`} className={`${hasGroupAsOwner ? "hasGroup" :""}`}>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 250 }}
                              overlay={
                                <Tooltip
                                  id={"member " + isGroup ? member.GroupId : member.User?.Id}
                                  key={isGroup ? member.GroupId : member.User.Id}
                                  style={{ maxWidth: "256px" }}
                                >
                                  {
                                    isGroup ?
                                      <div className="text-left">
                                        <span className="d-block">
                                          <strong>
                                            {member?.Name?.length > 30
                                              ? `${member.Name.slice(0, 15)}...`
                                              : member.Name}{" "}
                                          </strong>
                                        </span>
                                        <span className="d-block">
                                          {member?.Email?.length > 26
                                            ? `${member?.Email.slice(0, 26)}...`
                                            : member?.Email}
                                        </span>

                                      </div>
                                      :
                                      <div className="text-left">
                                        <span className="d-block">
                                          <strong>
                                            {member?.User?.Name?.length > 15
                                              ? `${member.User.Name.slice(0, 15)}...`
                                              : member.User.Name}{" "}
                                            {!isProject ? `(${member.User?.Role?.Name})` : ""}
                                          </strong>
                                        </span>
                                        <span className="d-block">
                                          {member?.User?.Email?.length > 26
                                            ? `${member?.User?.Email.slice(0, 26)}...`
                                            : member?.User?.Email}
                                        </span>
                                        {isProject && <span className="d-block">{member?.User?.Role?.Name}</span>}
                                        {!isProject && (
                                          <span
                                            className="d-block"
                                            dangerouslySetInnerHTML={{
                                              __html: member.User.Company
                                                ? `${member.User.Company.Name?.length > 16
                                                  ? member.User.Company.Name.slice(0, 16) + "..."
                                                  : member.User.Company.Name
                                                } &middot; ${member.User?.Role?.Name}`
                                                : member.User?.Role?.Name
                                            }}
                                          ></span>
                                        )}
                                      </div>
                                  }

                                </Tooltip>
                              }
                              key={isGroup ? member.GroupId : member.User?.Id}
                            >
                              <span
                                className={`userInitialsCompact appFont ${(isOwner && !hasGroupAsOwner) || isGroup  ? "userInitialsCompactOwner" : ""}  ${(isOwner && hasGroupAsOwner) ? "userInitialsCompactCreator" : ""}`}
                                key={isGroup ? member.GroupId : member.User?.Id}
                              >
                                {isGroup ? member.Initials : member.User.Initials}
                              </span>
                            </OverlayTrigger>
                            {
                            (isOwner && hasGroupAsOwner) ?
                              <div className="d-inline-flex w-60">
                                <span className="text-truncate">{member?.User?.Name}</span>
                                <span>(Creator)</span>
                              </div>
                              :
                              <span className="user-name">
                              {isGroup ? member?.Name?.length > 15 ? `${member.Name.slice(0, 15)}...` : member?.Name
                                : member?.User?.Name?.length > 15 ? `${member.User.Name.slice(0, 15)}...` : member?.User?.Name}
                            </span>
                            }
                            {/* GROUP TOOLTIP */}
                            {isGroup &&
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 250 }}
                                overlay={
                                  <div className="members-popover-container">
                                    <div
                                      className="members-popover">
                                      <ul className={`text-right members-popover-scroll`}>
                                        {member.GroupMembers.map(groupMembers => {
                                          let isGroupOwner = ownerId && ownerId === groupMembers.UserId;
                                          return (
                                            <li key={groupMembers.UserId}>
                                              <span
                                                className={`userInitialsCompact appFont ${isGroupOwner ? "userInitialsCompactOwner" : ""}`}
                                                key={groupMembers.User?.Id}
                                              >
                                                {groupMembers.User.Initials}
                                              </span>
                                              <span className="user-name">
                                                {groupMembers.User.Name}
                                              </span>

                                            </li>
                                          )
                                        })}
                                      </ul>

                                    </div>
                                  </div>

                                }
                              >
                                <img src={GroupMembers} />
                              </OverlayTrigger>}
                            {isProject && <span className="user-company">{isGroup ? "" : member.User.Company?.Name}</span>}
                            {!isProject && <span className="user-role">{isGroup ? getGroupPermission(member) : getPermission(member, isOwner && !hasGroupAsOwner)}</span>}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              }
            >
              <div className="d-flex" onMouseEnter={handlePopoverOnMouseEnter} onMouseLeave={handlePopoverOnMouseLeave}>
                {members.slice(0, 4).map(member => {
                  const isOwner = ownerId && ownerId === member.UserId && !member.isGroup;
                  let isGroup = member.GroupId > 0;
                  return (
                    <div className={`${(isOwner && hasGroupAsOwner) ? "userInitialsCompactCreatorContainer" : ""}`} key={isGroup ? member.GroupId : member.User.Id}>      
                      <span
                        className={`userInitialsCompact appFont ${(isOwner && !hasGroupAsOwner) || isGroup ? "userInitialsCompactOwner" : ""} ${(isOwner && hasGroupAsOwner) ? "userInitialsCompactCreator" : ""}`}
                    
                      >
                        {isGroup ? member.Initials : member.User.Initials}
                      </span>
                    </div>
                  );
                })}
                {extraMembers > 0 && (
                  <span className={"userInitialsCompact appFont userInitialsExtra"}>{`+${extraMembers}`}</span>
                )}
              </div>
            </OverlayTrigger>
          </div>
        );
      }
    } else {
      activeView = <div>-</div>;
    }

    return activeView;
  };

  return <div>{showTaskMembersPopover()}</div>;
};

export default TaskMembersPopover;
