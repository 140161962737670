class SecurityMatrixService {
    // if the current user has the given privilege for the given menu item, return true
    checkRolePrivilege(user, privilege, menuItem){
        for (const currPrivilege of user['RolePrivilege']) {
            if (currPrivilege['MenuItem'] == menuItem && currPrivilege[privilege]) {
                return true;
            }
        }
        return false;
    }
}

export default new SecurityMatrixService();