import React from 'react';
import './spinner.scss';
import FluentUiIcon from '../../resources/fluent-ui-icon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Spinner = () => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip>{"Processing"}</Tooltip>}>
            <div data-testid="spinner-icon">
                <div className="spinner">
                    <FluentUiIcon
                        name="SpinnerIosRegular"
                        color="#0078d4"
                        size={20}
                    />
                </div>
            </div>
        </OverlayTrigger>
    );
};

export default Spinner;
