import ApiService from "../services/ApiService";
class OfficeWordService {
  setFilePropertyProject(projectId) {
    let classContext = this;
    console.log("Word -> Property [projectId] to be set to -> " + projectId);
    Word.run(function(context) {
      let property = context.document.properties.customProperties.getItemOrNullObject("projectId");    
      context.load(property);
      return context.sync().then(function() {
        if (property.isNullObject) {
          context.document.properties.customProperties.add("projectId", projectId);
          console.log("Word -> Property [projectId] added -> " + projectId);
          return context.sync();
        } else {
          console.log("Word -> Property [projectId] exists -> " + property.value);
          property.delete();
          console.log("Word -> Property [projectId] deleted");
          return context.sync().then(function() {
            classContext.addFilePropertyProject(projectId);
          });
        }
      });
    }).catch(function(e) {
      console.log("Word -> Property [projectId] error -> ");
      console.log(e.message);
    });
  }

  setFilePropertyProjectCallback(projectId, callbackFunction) {
    let classContext = this;
    console.log("Word -> Property [projectId] to be set to -> " + projectId);
    Word.run(function(context) {
      let property = context.document.properties.customProperties.getItemOrNullObject("projectId");
      context.load(property);
      return context.sync().then(function() {
        if (property.isNullObject) {
          context.document.properties.customProperties.add("projectId", projectId);
          console.log("Word -> Property [projectId] added -> " + projectId);
          return context.sync().then(function() {
            console.log("Uploading...");
            callbackFunction();
          });
        } else {
          console.log("Word -> Property [projectId] exists -> " + property.value);
          property.delete();
          console.log("Word -> Property [projectId] deleted");
          return context.sync().then(function() {
            classContext.addFilePropertyProject(projectId);
          });
        }
      });
    }).catch(function(e) {
      console.log("Word -> Property [projectId] error -> ");
      console.log(e.message);
    });
  }

  addFilePropertyProject(projectId) {
    Word.run(function(context) {
      let property = context.document.properties.customProperties.getItemOrNullObject("projectId");
      context.load(property);
      return context.sync().then(function() {
        if (property.isNullObject) {
          context.document.properties.customProperties.add("projectId", projectId);
          console.log("Word -> Property [projectId] added -> " + projectId);
          return context.sync();
        } else {
          console.log("Word -> Property [projectId] added -> " + projectId);
        }
      });
    }).catch(function(e) {
      console.log("Word -> Property [projectId] error -> ");
      console.log(e.message);
    });
  }

  setFilePropertyDocument(documentId) {
    let classContext = this;
    console.log("Word -> Property [documentId] to be set to -> " + documentId);
    Word.run(function(context) {
      let property = context.document.properties.customProperties.getItemOrNullObject("documentId");
      context.load(property);
      return context.sync().then(function() {
        if (property.isNullObject) {
          context.document.properties.customProperties.add("documentId", documentId);
          console.log("Word -> Property [documentId] added -> " + documentId);
          return context.sync();
        } else {
          console.log("Word -> Property [documentId] exists -> " + property.value);
          property.delete();
          console.log("Word -> Property [documentId] deleted");
          return context.sync().then(function() {
            classContext.addFilePropertyDocument(documentId);
          });
        }
      });
    }).catch(function(e) {
      console.log("Word -> Property [documentId] error -> ");
      console.log(e.message);
    });
  }

  setFilePropertyDocumentCallback(documentId, projectId, callbackFunctionA, callbackFunctionB) {
    let classContext = this;
    console.log("Word -> Property [documentId] to be set to -> " + documentId);
    Word.run(function(context) {
      let property = context.document.properties.customProperties.getItemOrNullObject("documentId");
      context.load(property);
      return context.sync().then(function() {
        if (property.isNullObject) {
          context.document.properties.customProperties.add("documentId", documentId);
          console.log("Word -> Property [documentId] added -> " + documentId);
          return context.sync().then(function() {
            callbackFunctionA(projectId, callbackFunctionB);
          });
        } else {
          console.log("Word -> Property [documentId] exists -> " + property.value);
          property.delete();
          console.log("Word -> Property [documentId] deleted");
          return context.sync().then(function() {
            classContext.addFilePropertyDocument(documentId);
          });
        }
      });
    }).catch(function(e) {
      console.log("Word -> Property [documentId] error -> ");
      console.log(e.message);
    });
  }

  addFilePropertyDocument(documentId) {
    Word.run(function(context) {
      let property = context.document.properties.customProperties.getItemOrNullObject("documentId");
      context.load(property);
      return context.sync().then(function() {
        if (property.isNullObject) {
          context.document.properties.customProperties.add("documentId", documentId);
          console.log("Word -> Property [documentId] added -> " + documentId);
          return context.sync();
        } else {
          console.log("Word -> Property [documentId] added -> " + documentId);
        }
      });
    }).catch(function(e) {
      console.log("Word -> Property [documentId] error -> ");
      console.log(e.message);
    });
  }

  getFilePropertyDocument(callbackFunction) {
    Word.run(function(context) {
      let property = context.document.properties.customProperties.getItemOrNullObject("documentId");
      context.load(property);
      return context.sync().then(function() {
        if (property.isNullObject) {
          callbackFunction(0);
        } else {
          callbackFunction(property.value);
        }
      });
    }).catch(function(e) {
      console.log(e.message);
    });
  }

  getFilePropertyScroll(callbackFunction) {
    Word.run(function(context) {
      let property = context.document.properties.customProperties.getItemOrNullObject("scroll");
      context.load(property);
      return context.sync().then(function() {
        if (property.isNullObject) {
          callbackFunction(0);
        } else {
          callbackFunction(property.value);
        }
      });
    }).catch(function(e) {
      console.log(e.message);
    });
  }

  getFilePropertyProject(callbackFunction) {
    Word.run(function(context) {
      let property = context.document.properties.customProperties.getItemOrNullObject("projectId");
      context.load(property);
      return context.sync().then(function() {
        if (property.isNullObject) {
          callbackFunction(0);
        } else {
          callbackFunction(property.value);
        }
      });
    }).catch(function(e) {
      console.log(e.message);
    });
  }

  getFilePropertyTask(callbackFunction) {
    Word.run(function(context) {
      let property = context.document.properties.customProperties.getItemOrNullObject("taskId");
      context.load(property);
      return context.sync().then(function() {
        if (property.isNullObject) {
          callbackFunction(0);
        } else {
          callbackFunction(property.value);
        }
      });
    }).catch(function(e) {
      console.log(e.message);
    });
  }

  getFilePropertyToken(callbackFunction, AppContext) {
    Word.run(function(context) {
      let property = context.document.properties.customProperties.getItemOrNullObject("token");
      context.load(property);
      return context.sync().then(function() {
        if (property.isNullObject) {
          callbackFunction("");          
        } 
        else {         
          AppContext.userToken = property.value;
          let validateToken = ApiService.getCurrentUser(AppContext)
          .then((result) => {
            if (result.status === 200)
              return true; 
            else          
              return false;             
          },
          (error) => {
            return false;          
          })  
          let result = validateToken;
          if(!result){
            context.document.properties.customProperties.deleteAll(); 
            AppContext.userToken = "";            
            callbackFunction("");
          }
          else{
            callbackFunction(property.value);
          }
        }
      });
    }).catch(function(e) {     
      console.log(e.message);
    });
  }

  createDocument(document) {
    Word.run(function(context) {
      let myNewDoc = context.application.createDocument(document);
      context.load(myNewDoc);
      return context
        .sync()
        .then(function() {
          myNewDoc.open();
          context.sync();
        })
        .catch(function(e) {
          console.log(e.message);
        });
    }).catch(function(e) {
      console.log(e.message);
    });
  }

  getDocumentText(callbackFunction) {
    Word.run(function(context) {
      let bodyRange = context.document.body.getRange("Whole");
      bodyRange.load("text");
      return context.sync().then(function() {
        callbackFunction(JSON.stringify(bodyRange.text));        
      });
    }).catch(function(e) {
      console.log(e.message);
    });
  }

  getDocumentSelectionText(code, callbackFunction) {
    return new Promise(function(resolve, reject){
      Office.context.document.getSelectedDataAsync(Office.CoercionType.Text, function(asyncResult) {
        if (asyncResult.status == Office.AsyncResultStatus.Failed) {
          console.log("Action failed. Error: " + JSON.stringify(asyncResult.error));
          reject("fail");
        } else {
          resolve(callbackFunction(asyncResult.value));
        }
      });
    });
  }

  getDocumentSelectionOoxml(code, replacementCodes, callbackFunction) {
    let activeTextSelection = null;

    Office.context.document.getSelectedDataAsync(Office.CoercionType.Ooxml, async function(asyncResultA) {
      if (asyncResultA.status == Office.AsyncResultStatus.Failed) {
        console.log("Action failed. Error: " + JSON.stringify(asyncResultA.error));
      } else {
        activeTextSelection = asyncResultA.value;
        //console.log(asyncResultA.value);
        activeTextSelection = activeTextSelection.replace(/</g, "-sqbktleft-");
        activeTextSelection = activeTextSelection.replace(/>/g, "-sqbktright-");
        activeTextSelection = activeTextSelection.replace(/&/g, "-andsgn-");
        let startHtml = "#S#" + code + "#";
        let endHtml = "#" + code + "#E#";

        await Word.run(async function(context) {
          let fullBodyRange = context.document.body.getRange("Content");
          let selectionRange = context.document.getSelection();

          for (let codeIdx in replacementCodes){
            console.log(replacementCodes[codeIdx]);
            let codeStartRangeCollection = fullBodyRange.search("#S" + replacementCodes[codeIdx]);
            let codeEndRangeCollection = fullBodyRange.search(replacementCodes[codeIdx] + "E#");
            codeStartRangeCollection.load("items");
            codeEndRangeCollection.load("items");
            
            await context.sync();

            if(codeStartRangeCollection.items.length > 0 && codeEndRangeCollection.items.length > 0){
              let codeStartRange = codeStartRangeCollection.getFirstOrNullObject();
              let codeEndRange = codeEndRangeCollection.getFirstOrNullObject();
              let fullCodeRange = null;

              fullCodeRange = codeStartRange.expandToOrNullObject(codeEndRange);
              let rangeComparison = selectionRange.compareLocationWith(fullCodeRange);

              await context.sync();

              let rangeCompareString = rangeComparison.value;

              if(rangeCompareString.includes("Inside") || rangeCompareString.includes("Overlaps") || rangeCompareString.includes("Contains") || rangeCompareString == "Equal"){
                return context.sync().then(function() {
                  callbackFunction(null);
                });
              }
            }
          }

          let selText = context.document.getSelection();
          selText.font.highlightColor = "gray";

          let currSel = context.document.getSelection();
          let rngBefore = currSel.getRange("Start");
          let rngAfter = currSel.getRange("End");
          let before = rngBefore.insertParagraph(startHtml, "After");
          let after = rngAfter.insertParagraph(endHtml, "Before");

          after.load('styleBuiltIn,font');
          before.load('styleBuiltIn,font');
          
          fullBodyRange = context.document.body.getRange("Content");
          
          await context.sync();

          after.styleBuiltIn = "Heading5";
          before.styleBuiltIn = "Heading5";
          after.font.highlightColor = "lime";
          before.font.highlightColor = "lime";

          /* =========== Content Control logic, DO NOT remove ===========
          let codeStartRange = fullBodyRange.search(startHtml).getFirst();
          let codeEndRange = fullBodyRange.search(endHtml).getFirst();
          let fullCodeRange = null;

          fullCodeRange = codeStartRange.expandTo(codeEndRange);
          let assignedContentControl = fullCodeRange.insertContentControl();
          assignedContentControl.cannotDelete = true;
          assignedContentControl.cannotEdit = true;
          */

          return context.sync().then(function() {
            callbackFunction(activeTextSelection);
          });
        }).catch(function(error) {
          console.log("Error: " + error);
          if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
          }
        });
      }
    });
  }
}

export default new OfficeWordService();
