import React, { useContext, useState } from "react";
import AppContext from "../../app/AppContext";
import wordThumbnail from "../../../assets/onboardingWordDemoThumbnail.png";
import powerpointThumbnail from "../../../assets/onboardingPowerPointDemoThumbnail.png";
import outlookThumbnail from "../../../assets/onboardingOutlookDemoThumbnail_1484px.png";
import playButton from "../../../assets/Play-Icon-48px.svg"
import boardPlus from "../../../assets/BoardPlus-14px-Purple-60.svg";
import boardGear from "../../../assets/BoardGear-15px-Purple-60.svg";
import boardRibbon from "../../../assets/BoardRibbon-15px-Purple-60.svg";
import OfficeSharedService from "../../services/OfficeSharedService";

// localStorage key to check if first log in (true = logged in before)
export const firstLoggedInKey = "triyoFirstLoggedIn";

const OnboardingSplash = ({ setPage, onLoginChange }) => {
  const [playingVideo, setPlayingVideo] = useState(false);
  const context = useContext(AppContext);
  const OfficeSharedServiceResult = OfficeSharedService.getAppType(Office.context); // Result should be Word, PowerPoint, or Outlook.

  if (context.userLogin) {
    onLoginChange(true);
    return <div>user is logged in</div>;
  }

  if (playingVideo && OfficeSharedServiceResult !== null) {
    const domainUrl = `${window.location.origin}/assets/video_popup_${OfficeSharedServiceResult.toLowerCase()}.html`;
    Office.context.ui.displayDialogAsync(domainUrl, { height: 61, width: 55 });
    setPlayingVideo(false);
  }

  const playVideo = () => {
    setPlayingVideo(true);
  }

  const showVideoThumbnail = thumbnail => (
    <div className="video-container">
        <img className="mb-3" width="100%" alt={`${OfficeSharedServiceResult} video`} src={thumbnail} />
        <div className="video-controls-container">
          <div className="video-controls-sub-container">
            <img src={playButton} className="video-controls play-control" onClick={playVideo} alt="play video"/>
          </div>
        </div>
    </div>
  );

  const assets = {
    officeAppType: OfficeSharedServiceResult,
    officeFileType: "",
    videoThumbnailURL: "",
    outlookBullets: [
      { icon: boardPlus, text: "Link emails and their recipients with Tasks to add structure and actionable workflows to your communication." },
      { icon: boardGear, text: "Organize Tasks into appropriate Projects with correct due dates, priority levels, and labels, all within Outlook." },
      { icon: boardRibbon, text: "Never lose track of important conversations and action items with a fully searchable Audit Trail." }
    ],
    bullets: [
      { icon: boardPlus, text: "Create and manage Projects and Tasks" },
      { icon: boardGear, text: "Build workflows by assigning document sections/slides to your team members" },
      { icon: boardRibbon, text: "Approve and merge everyone's work into one finished deck" }
    ]
  };

  const onGetStarted = () => {
    localStorage.setItem(firstLoggedInKey, true);
    setPage("login");
  };

  switch (OfficeSharedServiceResult) {
    case "Word":
      assets.officeFileType = "document";
      assets.videoThumbnailURL = wordThumbnail;
      break;
    case "PowerPoint":
      assets.officeFileType = "slide deck";
      assets.videoThumbnailURL = powerpointThumbnail;
      break;
    case "Outlook":
      assets.officeFileType = "Mailbox";
      assets.videoThumbnailURL = outlookThumbnail;
      break;
    default:
    // Should not happen
  }

  return (
    <div className="container fluid px-0">
      <div className="text-center mb-3">
        <div className="col-12">
          <div className="mb-3 pt-5 bold appFont">
            <h3 style={{ fontWeight: "700", fontSize: "24px" }}>Welcome to TRIYO</h3>
          </div>
          <p className="mx-3">
            TRIYO's Add-in for Microsoft {assets.officeAppType} brings frictionless collaboration and streamlined
            workflows right to your {assets.officeFileType}.
          </p>
        </div>
      </div>
      {showVideoThumbnail(assets.videoThumbnailURL)}
      {(OfficeSharedServiceResult === "Outlook" ? assets.outlookBullets : assets.bullets).map((bullet, idx) => (
        <div className="mb-3 mx-4" key={idx}>
          <div style={{ alignItems: "normal", display: "flex", justifyContent: "left" }} >
            <img className="mr-2" src={bullet.icon} style={{marginTop: "3px", verticalAlign: 'middle', height: "14px", width: "11px"}} alt="Board Icon"/>
            <span style={{ fontStyle: "normal", fontSize: "14px", fontWeight: 400, lineHeight: "21px", color:"#121212", textAlign: 'justify'}}>
              {bullet.text}</span>
          </div>
        </div>
      ))}
      <div style={{ height: "40px" }}></div>
      <div className="ml-3 mr-3 pb-3 pt-3 whiteBackground d-flex justify-content-center">
        <button
          type="button"
          className="loginButton btn-primary btn-block shadow-none blueButton"
          onClick={onGetStarted}
          style={{ width: "176px" }}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default OnboardingSplash;