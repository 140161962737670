import React, { useState, useEffect, useContext } from "react";

const { apiUrl } = window["APP_SETTINGS"];
const { appUrl } = window["APP_SETTINGS"];
const { loginSSO } = window["APP_SETTINGS"];
const { version } = window["APP_SETTINGS"];
const { selfServeUser } = window["APP_SETTINGS"];
const {enableCreateButton} = window["APP_SETTINGS"];

const AppContext = React.createContext({
  // Office Diagnostics => for older versions
  // hostName  => E.g: Outlook, OutlookWebApp, OutlookIOS, or OutlookAndroid
  // hostVersion =>  E.g: 15.0.468.0   or    16.0.15601.20148

  //Office.context.diagnostics => for office
  host: "", //  Access, Excel, OneNote, Outlook, PowerPoint, Project, Visio, Word
  platform: "", // Android, Mac, OfficeOnline, PC, Universal, iOS
  version: "", // E.g: 15.0.468.0   or    16.0.15601.20148

  //Office.Mailbox.diagnostics => for Outlook
  hostName: "", // Outlook, OutlookWebApp, OutlookIOS, or OutlookAndroid
  hostVersion: "",  //E.g: 15.0.468.0   or    16.0.15601.20148    or 20220923004.15 on OutlookWebApp

  currentTask: null,
  previousPage: "",
  currentPage: "login",
  userLogin: null,
  tokenReady: null,
  userToken: "",
  jwtUserToken: "",
  userName: "",
  userNameFull: "",
  userEmail: "",
  loginSSO: loginSSO,
  apiEndpoint: apiUrl,
  emailParser: "",
  emailParserType: "",
  applicationEndpoint: appUrl,
  addinVersion: version,
  selfServeUser: selfServeUser,
  taskDetailsPlaceholder: null,
  composeType: "",
  emailTrackingEncodingPrefix: "sted12x", //same we have MVC-parser
  emailTrackingEncodingSuffix: "xeted13", //same we have MVC-parser
  emailPixelInserted: "", //String containing email pixel inserted in email body
  taskIdInserted: "", // String containing taskId inserted in email body
  prevTaskIdInserted: "",
  taskIdInsertedRedirected: "",
  currentProjectId: 0,
  currentProjectDocumentId: 0,
  currentTaskId: 0,
  appMode: "project",
  fileCheck: false,
  officeAppType: "",
  officeAppTypeDoc: "",
  currentProjectFileName: "",
  currentProjectFileLock: false,
  emptyDoc: false,
  newDoc: false,
  closeInstance: false,
  currUser: null,
  currEmail: null, //Read mode
  prevEmail: null, //Read mode
  currEmailRecipients: null, //Compose mode
  emailRecipientsChanged: false, //Compose mode
  existingTask: null,
  existingLegacyEmailTask: null, //Compose mode
  showExistingLegacyEmailTask: false, //Compose mode
  currentSubject: "",
  currentTo: [],
  currentCC: [],
  currentBCC: [],
  currentBody: "",
  addinFirstRender: true,
  attachmentScroll: false,
  enableCreateButton:enableCreateButton,
  checkTokenCompleted: false,
  firstValidationsCompleted: false,
  secondValidationsCompleted: false,
  redirectComposeWindow: false,
  redirectComposeCompleted: false,
  composeReFwCheckCompleted: false
});

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  const [state, setState] = useState({
    host: "", //  Access, Excel, OneNote, Outlook, PowerPoint, Project, Visio, Word
    platform: "", // Android, Mac, OfficeOnline, PC, Universal, iOS
    version: "", // E.g: 15.0.468.0   or    16.0.15601.20148
    hostName: "Outlook", // Values: Outlook, OutlookWebApp, OutlookIOS, or OutlookAndroid
    hostVersion: "", // E.g: 15.0.468.0   or    16.0.15601.20148
    isInitialized: false,
    outlookComposeMode: null,
    currentTask: null,
    currentPage: "login",
    userLogin: null,
    tokenReady: null,
    userToken: "",
    jwtUserToken: "",
    userName: "",
    userNameFull: "",
    userEmail: "",
    emailParser: "",
    emailParserType: "",
    loginSSO: loginSSO,
    apiEndpoint: apiUrl,
    applicationEndpoint: appUrl,
    addinVersion: version,
    selfServeUser: selfServeUser,
    taskDetailsPlaceholder: false,
    composeType: "",
    emailTrackingEncodingPrefix: "sted12x", //same we have MVC-parser
    emailTrackingEncodingSuffix: "xeted13", //same we have MVC-parser
    emailPixelInserted: "", //String containing email pixel inserted in email body
    taskIdInserted: "", // String containing taskId inserted in email body
    prevTaskIdInserted: "",
    taskIdInsertedRedirected: "",
    currentProjectId: 0,
    currentProjectDocumentId: 0,
    currentTaskId: 0,
    appMode: "project",
    fileCheck: false,
    officeAppType: "",
    officeAppTypeDoc: "",
    currentProjectFileName: "",
    currentProjectFileLock: false,
    emptyDoc: false,
    newDoc: false,
    closeInstance: false,
    currUser: null,
    currEmail: null,
    currEmailRecipients: null,
    emailRecipientsChanged: false,
    existingTask: null,
    existingLegacyEmailTask: null, //Compose mode
    showExistingLegacyEmailTask: false, //Compose mode
    mailbox: null,
    currentSubject: "",
    currentTo: [],
    currentCC: [],
    currentBCC: [],
    currentBody: "",
    currentConversationId: 0,
    enableCreateButton :enableCreateButton,
    checkTokenCompleted: false,
    firstValidationsCompleted: false,
    secondValidationsCompleted: false,
    redirectComposeWindow: false,
    redirectComposeCompleted: false,
    composeReFwCheckCompleted: false
  });

  //Compose Mode - Get item(email) in case of RecipientsChanged
  useEffect(() => {
    const asyncHandler = async () => {
      // We'll call this function to read the current office state
      // from mailbox, item.
      const update = () => {

        if (Office.context.mailbox.item.displayReplyForm != undefined) {
          // Unpack the office context.
          const mailbox = Office.context.mailbox;
          const item = mailbox?.item;
          const getItemState = () => {
            if (!item) return undefined;
            const {
              from,
              to,
              cc,
              bcc,
              internetMessageId,
              conversationId,
              itemType,
              subject,
              body,
            } = item;
            return {
              from,
              to,
              cc,
              bcc,
              internetMessageId,
              conversationId,
              itemType,
              subject,
              body,
            };
          };

          const getMailboxState = () => {
            if (!mailbox) return undefined;
            const { userProfile } = mailbox;
            return {
              userProfile,
              item: getItemState(),
            };
          };

          setState({
            state: {
              mailbox: getMailboxState(),
            },
            isInitialized: true,
          });

        } else {
          //Compose Mode - Get item(email) in case of RecipientsChanged
          // Unpack the office context.
          const mailbox = Office.context.mailbox;
          const item = mailbox?.item;
          const getItemState = () => {
            if (!item) return undefined;
            const {
              from,
              to,
              cc,
              bcc,
              conversationId,
              subject,
              body
            } = item;
            return {
              from,
              to,
              cc,
              bcc,
              conversationId,
              subject,
              body
            };
          };

          const getRecipientsState = () => {
            if (!item) return undefined;
            return {
              item: getItemState()
            };
          };

          setState({
            state: {
              item: getRecipientsState(),
            },
            isInitialized: true,
          });
        }
      };

      // Update our application, now that Office is initialized.
      update();
    };
    Office.onReady(() => {
      asyncHandler();
    });
  }, []);

  return (
    <AppContext.Provider value={state}>{children}</AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;

const INITIAL_OFFICE_CONTEXT = {
  host: "", //  Access, Excel, OneNote, Outlook, PowerPoint, Project, Visio, Word
  platform: "", // Android, Mac, OfficeOnline, PC, Universal, iOS
  version: "", // E.g: 15.0.468.0   or    16.0.15601.20148
  hostName: "Outlook", // Values: Outlook, OutlookWebApp, OutlookIOS, or OutlookAndroid
  hostVersion: "", // E.g: 15.0.468.0   or    16.0.15601.20148
  isInitialized: false,
  currentTask: null,
  currentPage: "login",
  userLogin: null,
  tokenReady: null,
  userToken: "",
  jwtUserToken: "",
  userName: "",
  userNameFull: "",
  userEmail: "",
  emailParser: "",
  emailParserType: "",
  loginSSO: loginSSO,
  apiEndpoint: apiUrl,
  applicationEndpoint: appUrl,
  addinVersion: version,
  selfServeUser: selfServeUser,
  taskDetailsPlaceholder: false,
  composeType: "",
  emailTrackingEncodingPrefix: "sted12x", //same we have MVC-parser
  emailTrackingEncodingSuffix: "xeted13", //same we have MVC-parser
  emailPixelInserted: "", //String containing email pixel inserted in email body
  taskIdInserted: "", // String containing taskId inserted in email body
  prevTaskIdInserted: "",
  taskIdInsertedRedirected: "",
  currentProjectId: 0,
  currentProjectDocumentId: 0,
  currentTaskId: 0,
  appMode: "project",
  fileCheck: false,
  officeAppType: "",
  officeAppTypeDoc: "",
  currentProjectFileName: "",
  currentProjectFileLock: false,
  emptyDoc: false,
  newDoc: false,
  closeInstance: false,
  currUser: null,
  currEmail: null,
  currEmailRecipients: null,
  emailRecipientsChanged: false,
  existingTask: null,
  existingLegacyEmailTask: null, //Compose mode
  showExistingLegacyEmailTask: false, //Compose mode
  mailbox: null,
  currentConversationId: 0,
  enableCreateButton:enableCreateButton,
  firstValidationsCompleted: false,
  secondValidationsCompleted: false,
  redirectComposeWindow: false,
  redirectComposeCompleted: false,
  composeReFwCheckCompleted: false
};

export default AppContext;