import React, { Component } from "react";
import AppContext from "../../app/AppContext";
import ApiService from "../../services/ApiService";
import { trackPromise} from "react-promise-tracker";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import LogoutButton from "./logoutButton";

class Profile extends Component {
  state = {
    currUser: null,
    hide: false
  };

  static contextType = AppContext;

  componentDidMount = () => {
    trackPromise(
      ApiService.getCurrentUser(this.context)
        .then(result => {
          if (result.status === 200) {
            this.setState({ currUser: result.data.User })
          } else {
            console.log("API [GetCurrentUser] error status: " + result.status);
          }
        }).catch(e => {
          console.log("API [GetCurrentUser] error ->");
          console.log(e);
          this.props.setAppPage("500 error");
        }), 'profile-area'
    );
  }

  hideContent = hidden => {
    if(this.state.hide != hidden){
      this.setState({ hide: hidden });
    }
  }

  render() {
    return <div className="mt-7">
              <LoadingOverlay area="profile-area" inline="loading-overlay-inline" hideContent={this.hideContent}/>
              {!this.state.hide && this.state.currUser !== null ?
                <div>
                  <div className="mb-4 pt-4">
                    <span className="userInitialsProfile appFont">
                      {this.state.currUser.Initials}
                    </span>
                    <h4 style={{textAlign: "center", marginTop: "16px"}}>{this.state.currUser.Name}</h4>
                  </div>
                  <div className="row ml-2 mr-1">
                    <div className="col-12 taskDetailsRow appFont">
                      <div className="float-left taskDetailsLabel">ROLE</div>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 250 }}
                        overlay={
                          <Tooltip id="tooltip-1">
                            {this.state.currUser.Role.Name}
                          </Tooltip>  
                        }
                      > 
                        <div className="float-right text-right mr-2 taskDetailsText taskDescription">
                          {this.state.currUser.Role.Name}
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="row ml-2 mr-1">
                    <div className="col-12 taskDetailsRow appFont">
                      <div className="float-left taskDetailsLabel">EMAIL</div>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 250 }}
                        overlay={
                          <Tooltip id="tooltip-1">
                            {this.state.currUser.Email}
                          </Tooltip>  
                        }
                      > 
                        <div className="float-right text-right mr-2 taskDetailsText taskDescription">
                          {this.state.currUser.Email}
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="row ml-2 mr-1">
                    <div className="col-12 taskDetailsRow appFont">
                      <div className="float-left taskDetailsLabel">VERSION</div>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 250 }}
                        overlay={
                          <Tooltip id="tooltip-1">
                            {this.context.addinVersion}
                          </Tooltip>  
                        }
                      > 
                        <div className="float-right text-right mr-2 taskDetailsText taskDescription">
                          {this.context.addinVersion}
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="fixed-bottom ml-3 mr-3 pb-3 pt-3 whiteBackground">
                  <LogoutButton
                    onLogout={this.props.onLogout}
                  />
                </div>
                </div>
                :
                null
              }
          </div>;
  }
}

export default Profile;