import React from 'react';
import { Form } from 'react-bootstrap';
import { isDynamicFieldValid, FIELD_TYPES, getDefaultPlaceholderForFieldType } from '../utils/utils';

import DatePicker from 'react-datepicker';
import TriyoForm from '../../triyoForm/TriyoForm';
import DynamicFieldTable from '../ContentViewer/DynamicFieldTable';

const TaskDynamicFieldsInput = (props) => {
    const { field, handleChange, disableErrors, disabled, isFromCreate, setDynamicFields } = props;
    const { Data, FieldType, LookupValues, Placeholder, touched } = field;
    const isInvalid = !disableErrors && touched && !isDynamicFieldValid(field) && !isFromCreate;

    function disableScroll() {
        const { documentElement: html, body } = document;

        html.style.overflow = 'hidden';
        body.style.overflow = 'hidden';

        for (const modal of document.getElementsByClassName('modal')) {
            modal.style.overflow = 'hidden';
        }
    }

    function enableScroll() {
        const { documentElement: html, body } = document;

        html.style.overflow = '';
        body.style.overflow = '';

        for (const modal of document.getElementsByClassName('modal')) {
            modal.style.overflow = '';
        }
    }

    const fieldInputProps = {
        value: Data,
        onChange: handleChange,
        isInvalid,
        placeholder: Placeholder ?? getDefaultPlaceholderForFieldType(FieldType),
        // disables Enter from submitting forms on fields other than long text entries
        onKeyDown:
            FieldType !== FIELD_TYPES.multiText
                ? (e) => {
                    e.key === 'Enter' && e.preventDefault();
                }
                : null,
        disabled,
    };

    const addRow = (field) => {
        setDynamicFields((oldDynamicFields) =>
            oldDynamicFields.map((oldField) =>
                oldField.Id === field.Id
                    ? {
                        ...oldField,
                        Table: {
                            ...oldField.Table,
                            Rows: []
                        },
                    }
                    : oldField
            )
        );
    }

    // uses isInvalid prop on Form.Control
    // because isInvalid === true creates a red (!) sign whereas
    // isValid === true creates a green checkmark

    switch (FieldType) {
        case FIELD_TYPES.multiText:
            return (
                <Form.Control
                    {...fieldInputProps}
                    className="multitext-input form-custom-control"
                    as="textarea"
                    name="dynamic-fields-textarea"
                />
            );
        case FIELD_TYPES.dropDown:
            return (
                <Form.Control {...fieldInputProps} as="select" className="form-custom-control">
                    <option value="">-Select-</option>
                    {LookupValues.map((lookupValue, idx) => (
                        <option value={lookupValue} key={idx}>
                            {lookupValue}
                        </option>
                    ))}
                </Form.Control>
            );
        case FIELD_TYPES.radioButtons:
            return (
                <TriyoForm.RadioButtons
                    className="options "
                    lookupValues={LookupValues}
                    value={fieldInputProps.value}
                    onChange={(e) => { handleChange({ target: { value: e } }) }}
                />
            )

        case FIELD_TYPES.checkbox:
            return (
                <TriyoForm.Checkboxes
                    className="options"
                    lookupValues={LookupValues}
                    value={fieldInputProps.value}
                    onChange={(e) => { handleChange({ target: { value: e } }) }}
                />
            )
        case FIELD_TYPES.toggle:
            return (
                <div className="toggle">
                    <TriyoForm.Label
                        label={field.Label}
                        required={false}
                    />
                    <TriyoForm.Toggle
                        checked={fieldInputProps.value === "selected"}
                        onChange={() => { handleChange({ target: { value: fieldInputProps.value === "selected" ? "not selected" : "selected" } }) }}
                    />
                </div>
            )
        case FIELD_TYPES.integer:
        case FIELD_TYPES.decimal:
            return (
                <Form.Control className='form-custom-control'
                    {...fieldInputProps}
                    type={'number'}
                    // add empty listener to mouse wheel to allow scroll to change input
                    onWheel={() => { }}
                    // onFocus={disableScroll}
                    // onBlur={enableScroll}
                />
            );
        case FIELD_TYPES.date:
            return (
                <>
                    <DatePicker
                        {...fieldInputProps}
                        selected={Data}
                        onChange={(date) => handleChange({ target: { value: date } })}
                        placeholderText="Click to select a date"
                        className={`form-control form-custom-control shadow-none ${isInvalid ? 'is-invalid' : ''}`}
                        dateFormat="dd/MM/yyyy"
                    />
                    <Form.Control style={{ display: 'none' }} isInvalid={isInvalid} />
                </>
            );
        case FIELD_TYPES.table:
            return (
                <>
                    <DynamicFieldTable
                        fieldHeaders={field.Table.Columns}
                        fieldRows={field.Table.Rows}
                        field={field}
                        enableScroll={enableScroll}
                        disableScroll={disableScroll}
                        isTableDisabled={disabled}
                        handleChange={handleChange}
                        setDynamicFields= {setDynamicFields}
                    />
                </>
            );
        case FIELD_TYPES.time:
        case FIELD_TYPES.singleText:
        default:
            return <Form.Control className='form-custom-control' {...fieldInputProps} type={'text'} />;
    }
};

export default TaskDynamicFieldsInput;
