import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

class ValidationMessages extends Component {
  state = {};

  render() {
    let activeView = null;
    let validationCss = "alert alert-warning";
    if (this.props.customCss) {
      validationCss = "alert alert-warning " + this.props.customCss;
    }
    if (this.props.valMsg.length > 0)
      activeView = (
        <div className="row">
          <div className="col-12">
            <div className={validationCss} role="alert">
              {this.props.valMsg.map(errors => (
                <div key={errors}>
                  <span>{errors}</span>
                  <br />
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    if (this.props.valMsg.length == 0) activeView = null;
    return <div>{activeView}</div>;
  }
}

export default ValidationMessages;
