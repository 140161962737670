import ApiService from '../../../services/ApiService';
import { FIELD_TYPES } from "../utils/utils"

const updateDynamicTableField = (field, setDynamicFields) => { 
    setDynamicFields((oldDynamicFields) =>
        oldDynamicFields.map((oldField) =>
            oldField.Id === field.Id
                ? {
                    ...oldField,
                    Table: field.Table,
                    touched: true,
                }
                : oldField
        )
    );
}

// api call to fetch dynamic fields
export async function getDynamicFields(DynamicFieldRequestDto,context) {
    try {
        const dynamicFields = await ApiService.getDynamicFields(DynamicFieldRequestDto,context);

        return dynamicFields.data.map((field) => {
            const { FieldType, Data } = field;
            const parsedData =
                FieldType === FIELD_TYPES.date && Data != null && Data !== ''
                    ? new Date(Data)
                    : Data;
            return {
                ...field,
                Data: parsedData ?? '',
            };
        });
    } catch (error) {
        console.error(error);
        return [];
    }
}

export function deleteDynamicTableRowHandler(field, row, setDynamicFields) { 
    const rowToDelete = field.Table.Rows.find((r) => r.Row === row);

    if (!rowToDelete)
        return;
    
    field.Table.Rows = field.Table.Rows.filter((r) => r.Row !== row);

    updateDynamicTableField(field, setDynamicFields);
}

export function createNewDynamicTableRowHandler(field, setDynamicFields) { 
    let lastRow = field.Table.Rows[field.Table.Rows?.length - 1];

    if (!lastRow) { 
        lastRow = { Row: 0 };
    }

    const newRowData = field.Table.Columns.map(column => {
        return {
            Id: 0,
            Value: '',
            ColumnId: column.Id,
            ColumnType: column.Type,
            LookupValues: column.LookupValues?.trim()?.split(',')?.map(item => item.trim()),
            ColumnLockStatus: column.LockStatus,
            ColumnName: column.Name
        }
    });

    field.Table.Rows = [...field.Table.Rows, { Id: 0, Row: lastRow.Row + 1, Data: newRowData }]

    updateDynamicTableField(field, setDynamicFields);
}

// change handler for input field
export function createDynamicInputChangeHandler({ field, setDynamicFields }) {
    if (field.FieldType === 'Table') { 
        return function (row, columnId, newValue) { 
            const existingRow = field.Table.Rows.find((r) => r.Row === row);

            if (!existingRow)
                return;
            
            existingRow.Data = existingRow.Data.map(oldData => oldData.ColumnId === columnId ? { ...oldData, Value: newValue } : oldData)
            field.Table.Rows = field.Table.Rows.map((prevRow) => prevRow.Row === row ? { ...prevRow, ...existingRow } : prevRow);
            
            updateDynamicTableField(field, setDynamicFields);
        }
    } else {
        return function (event) {
            const Data = event.target.value;
            setDynamicFields((oldDynamicFields) =>
                oldDynamicFields.map((oldField) =>
                    oldField.Id === field.Id
                        ? {
                            ...oldField,
                            Data,
                            touched: true,
                        }
                        : oldField
                )
            );
        };
    }
}
