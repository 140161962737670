import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import SuccessIcon from "../../resources/success.png";
import AppContext from "../../app/AppContext";

class TaskActionSaved extends Component {
  state = {
    action: this.props.action ?? "Cancel",
  };

  static contextType = AppContext;

  componentDidMount = () => {
    if(this.props.action === "Reminder"){
      this.props.setAppMode("task");
    }
    else {
      this.props.setAppMode("project");
    }
  }

  openWebPage = () => {
    window.open(this.context.applicationEndpoint);
  };

  render() {
    const reminderAction = this.state.action === "Reminder";

    return (
      <div className="mt-7">
        <div className="text-center">
          <img src={SuccessIcon} className="taskReassignedLogo"></img>
        </div>
        <div className="text-center mt-4 action-saved-text">
          <span className="projectCreatedTitle">
            {reminderAction ? "A reminder has been sent for this task." : "The task has been cancelled."}
          </span>
        </div>
        <div className="fixed-bottom pl-4 pr-4 pb-2 whiteBackground">
          <button
            className="loginButton btn-primary btn-block shadow-none blueButton"
            onClick={event => this.props.setPage("taskEditor")}
          >
            {reminderAction ? "Done" : "Go to Task List"}
          </button>
          <div className="text-center mb-4 mt-3">
            <a href="#" onClick={() => this.openWebPage()}>
              <span className="appFont underline projectCreatedWebLink">Switch to web application</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default TaskActionSaved;
