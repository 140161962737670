import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import errorPage500 from "../../resources/Images/Illustration - Error500.png";

class Error500 extends Component {
  state = {};

  render() {
    let activeView = null;
    activeView = (
      <div className="mt-7 error-margin">
        <div className="mt-4">
          <span className="error-title">
            <div className="error-title-margin">
              {" "}
              <b> Internal Server Error. </b>{" "}
            </div>
            Sorry, this is unexpected! We are doing our best to fix the problem. Please refresh or check back later.
          </span>
        </div>
        <div className="img-container">
          <img src={errorPage500} className="error500-image"></img>
        </div>
        {!this.props.isComposeMode ? (
          <div className="text-center error-text mb-4">
            <span className="appFont underline errorHomeLink" onClick={() => this.props.setPage("tasks")}>
              Go to Task Page
            </span>
          </div>
        ) : (
          <div className="text-center error-text mb-4">
            <span className="appFont underline errorHomeLink" onClick={() => this.props.setPage("emailTracking")}>
              Go to Link Email
            </span>
          </div>
        )}
      </div>
    );

    return <div>{activeView}</div>;
  }
}

export default Error500;
