import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import AppContext from "../../../app/AppContext";
import WarningIcon from "../../../resources/Icons/warning-120px-Gold-40.svg"
import "bootstrap/dist/css/bootstrap.min.css";

const EmailTrackingNotificationMenu = ({
  existingLegacyEmailTask,
  viewLegacyTask,
  setViewLegacyTask,
  setPage,
}) => {
  const context = useContext(AppContext);

  const isMemberAssignedToTask = (currentUserId, task) => {
    if (!task) return false;
    if (!task.Members?.length) return false;
    return task.Members.filter(m => m.UserId === currentUserId)?.length > 0;
  }

  const onTaskSelect = (task) => {
    context.showExistingLegacyEmailTask = true;
    if (task.Id == context.currentTaskId) {
      setPage("taskEditor");
    } else {
      context.currentTask = task;
      setPage("taskEditor");
    }
    context.previousPage = "emailTrackingTaskMenu";
  }

  const onLinkTask = () => {
    setViewLegacyTask(false);
    context.previousPage = "emailTrackingTaskMenu";
  };

  const taskList = () => {
    setPage("tasks");    
    context.previousPage = "emailTrackingTaskMenu";
  };

  const openWebPage = () => window.open(context.applicationEndpoint);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  let activeView = null;
  activeView = (
    <div className="mt-2 mr-3">
      <div className="text-center">
        <img src={WarningIcon} className="warningIcon"></img>
      </div>
      <div className="text-center mt-4">
        <span className="projectCreatedTitle">
            This email is associated with a Task on TRIYO.
        </span>
        <p className="mt-3 px-3 mb-0">
            Using the buttons below, you can view the Task Details for this associated Task, OR you can link it to another new/existing Task.
        </p>
        {
          !isMemberAssignedToTask(context.currUser.Id, existingLegacyEmailTask) &&
          <p>You do not have permission to view the existing task.</p>
        }
      </div>
      {
      isMemberAssignedToTask(context.currUser.Id, existingLegacyEmailTask) ?
        <div className="fixed-bottom pl-4 pr-4 pb-2 whiteBackground">
          <button
            className={`loginButton btn-primary blueButtonOutline ${isMemberAssignedToTask(context.currUser.Id, existingLegacyEmailTask) ? 'button50' : 'button100'} ml-1 shadow-none`}
            onClick={() => onTaskSelect(existingLegacyEmailTask)}
          >
            View Task
          </button>
          <button
            className="loginButton btn-primary blueButton button50 mr-1 shadow-none float-right"
            onClick={() => onLinkTask()}
          >
            Link Task
          </button>
          <div className="text-center mb-4 mt-3">
            <a href="#" onClick={e => openWebPage()}>
              <span className="appFont underline projectCreatedWebLink buttonLink">
                Switch to web application
              </span>
            </a>
          </div>
        </div>
      :
        <div className="fixed-bottom pl-3 pr-3 pb-2 whiteBackground">
          <button
            className="loginButton btn-primary btn-block shadow-none blueButton"
            onClick={() => taskList()}
          >
            Go to Task List
          </button>
          <div className="text-center mb-4 mt-3">
            <a href="#" onClick={() => openWebPage()}>
              <span className="appFont underline projectCreatedWebLink buttonLink">
                Switch to web application
              </span>
            </a>
          </div>
        </div>
      }
    </div>
  );
  return <div>{activeView}</div>;
}

EmailTrackingNotificationMenu.propTypes = {
  existingLegacyEmailTask: PropTypes.object,
  viewLegacyTask: PropTypes.bool,
  setViewLegacyTask: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default EmailTrackingNotificationMenu;