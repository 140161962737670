import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import Dropdown from "../dropdown/split-dropdown";
import AppContext from "../../app/AppContext";
import ApiService from "../../services/ApiService";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import EmailTrackingNotificationMenu from "./TaskComponents/EmailTrackingNotificationMenu";
import EmailTrackingTaskAdd from "../../components/email-tracking/EmailTrackingTaskAdd";
import EmailTrackingTaskEdit from "../../components/email-tracking/EmailTrackingTaskEdit";
import { notificationMessage, removeNotificationMessage } from "../../helpers/HelperMethods";
import {
    removeInitialHeaders,
    getEmailComposeType,
  } from "../../helpers/mailLinkUtils";
import usingComputer from "../../resources/Images/Image - Using Computer.svg";
import "../email-tracking/EmailTracking.scss";
import SuccessIcon from "../../resources/success.png";

const EmailTrackingTaskMenu = ({
    setPage,
}) => {
    const [hide, setHide] = useState(false);
    const [loading, setLoading] = useState(false);
    const [existingLegacyEmailTask, setExistingLegacyEmailTask] = useState(null);
    const [viewLegacyTask, setViewLegacyTask] = useState(null);
    const [selectedOption, setSelectedOption] = useState('newTask');
    const [pageTitle, setPageTitle] = useState('Link Email to Task');
    const [renderSelectedOption, setRenderSelectedOption] = useState(false);
    const [existingTasks, setExistingTasks] = useState(null);
    const [taskIdSelected, setTaskIdSelected] = useState(0);
    const [isEmailTrackingActivity, setIsEmailTrackingActivity] = useState(false);
    const [isAutoSync, setIsAutoSync] = useState(true);
    const [currentTaskName, setCurrentTaskName] = useState("");
    const [currentTaskCreatedById, setCurrentTaskCreatedById] = useState(0);
    const [taskAction, setTaskAction] = useState("");
    const [taskLinkCompleted, setTaskLinkCompleted] = useState(false);

    const context = useContext(AppContext);

    const hideContent = (hidden) => {
        if (hide != hidden) setHide(hidden);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const getGetUserTasksList = async () => {
        await ApiService.getUserTasksList(context)
            .then((result) => {
                if (result.status === 200) {
                    console.log("API [getUserTasksList] success ->");
                    console.log(result.data);
                    setExistingTasks(result.data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log("API [getUserTasksList] error ->");
                console.log(error);
            });
    }

    const setTaskNameAndCreatedBy = (name, id) => {
        setCurrentTaskName(name);
        setCurrentTaskCreatedById(id);
    };

    useEffect(() => {
        let token = localStorage.getItem("tmpTriyoToken");
        if (token == null) setPage("login");
        if (context.composeType === "") {
            const getCurrentComposeType = getEmailComposeType();
            if (getCurrentComposeType?.length > 0) context.composeType = getCurrentComposeType;
        }
        if (context.composeType == "reply" || context.composeType == "forward") {
            if (context.existingLegacyEmailTask) {
                setExistingLegacyEmailTask(context.existingLegacyEmailTask);
            }
        }
        getGetUserTasksList();
    }, []);

    useEffect(() => {
        if (context.composeType == "reply" || context.composeType == "forward") {
            if (context.existingLegacyEmailTask) {
                setExistingLegacyEmailTask(context.existingLegacyEmailTask);
            }
        }
    }, [context.existingLegacyEmailTask]);

    useEffect(() => {
        if (renderSelectedOption) {
            if (selectedOption === 'existingTask') {
                notificationMessage("This email will be tracked as a new Task on TRIYO when you click Send |");
                setPageTitle("Link Task");
            } else if (selectedOption === 'newTask') {
                notificationMessage("This email will be tracked as a new Task on TRIYO when you click Send |");
                setPageTitle("Create Task");
            }
        } else {
            setPageTitle("Link Email to Task");
            removeNotificationMessage("This email will be tracked on TRIYO when you click Send |");
            fetchRemoveInitialHeaders();
        }
    }, [renderSelectedOption]);

    const fetchRemoveInitialHeaders = async () => {
        try {
            await removeInitialHeaders()
            .then((response) => {
                console.log("removeInitialHeaders=> ");
            })
            .catch((error) => {
                console.error(error);
            });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <React.Fragment>
            { existingLegacyEmailTask && viewLegacyTask === null ?
                <div className="m-1">
                    <EmailTrackingNotificationMenu
                        existingLegacyEmailTask={existingLegacyEmailTask}
                        viewLegacyTask={viewLegacyTask}
                        setViewLegacyTask={setViewLegacyTask}
                        setPage={setPage}
                    />
                </div>
            :
                <React.Fragment>
                    {   !taskLinkCompleted &&
                        <div className="link-email-task-container">
                            {/* Title */}
                            <div className="link-email-task">
                                {pageTitle}
                            </div>
                            {/* Radio buttons: Link Email, Auto Sync and Email Tracking */}
                            {   renderSelectedOption &&
                                <React.Fragment>
                                    <LoadingOverlay loading={loading} hideContent={hideContent} minDuration={100} />
                                    <Form.Label className="appFontSubHeading">
                                        Task Settings <i className="red">*</i>
                                    </Form.Label>
                                    <div className="switch-checkbox-container">
                                        <div className="switch-checkbox-sub-container">
                                            <div className="switch-checkbox">
                                                <span className="mr-auto ml-2 float-start switch-checkbox-label">
                                                    Link email to a Task on TRIYO
                                                </span>
                                                <Form.Check
                                                    className="ml-auto mr-4"
                                                    type="switch"
                                                    id="link-email-switch"
                                                    checked={renderSelectedOption}
                                                    onChange={() => setRenderSelectedOption(!renderSelectedOption)}
                                                    //disabled={disableForm}
                                                />
                                            </div>
                                        </div>
                                        {   selectedOption === 'newTask' &&
                                            <div className="switch-checkbox-sub-container">
                                                <div className="switch-checkbox">
                                                    <span className="mr-auto ml-2 float-start switch-checkbox-label">
                                                        Auto-sync with email contents
                                                    </span>
                                                    <Form.Check
                                                        className="ml-auto mr-4"
                                                        type="switch"
                                                        id="sync-email-switch"
                                                        checked={isAutoSync}
                                                        onChange={() => setIsAutoSync(!isAutoSync)}
                                                        //disabled={disableForm}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="switch-checkbox-sub-container">
                                            <div className="switch-checkbox">
                                                <span className="mr-auto ml-2 float-start switch-checkbox-label">
                                                    Track Email Activity
                                                </span>
                                                <Form.Check
                                                    className="ml-auto mr-4"
                                                    type="switch"
                                                    id="link-email-track"
                                                    checked={isEmailTrackingActivity}
                                                    onChange={() => {setIsEmailTrackingActivity(!isEmailTrackingActivity)}}
                                                    //disabled={disableForm}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {   !renderSelectedOption &&
                                <React.Fragment>
                                    <div className="link-email-task-description">
                                        Link this email and begin tracking it as a new Task on TRIYO
                                    </div>
                                    <div className="img-container m-1">
                                        <img src={usingComputer} className="center using-computer-image" />
                                    </div>

                                    <div className="link-email-task-radio-title">
                                        Select Task Type
                                    </div>
                                    <div className="link-email-task-radio-container">
                                        <label className="link-email-task-radio-label">
                                            <input
                                                className="link-email-task-radio-button"
                                                type="radio"
                                                value="newTask"
                                                checked={selectedOption === 'newTask'}
                                                onChange={handleOptionChange}
                                            />
                                            New Task
                                        </label>
                                        <label className="link-email-task-radio-label">
                                            <input
                                                className="link-email-task-radio-button"
                                                type="radio"
                                                value="existingTask"
                                                checked={selectedOption === 'existingTask'}
                                                onChange={handleOptionChange}
                                            />
                                            Existing task
                                        </label>
                                    </div>
                                </React.Fragment>
                            }
                            {
                                (!renderSelectedOption && selectedOption === 'newTask') &&
                                <div className="link-email-placeholder"></div>
                            }
                            {
                                selectedOption === 'existingTask' &&
                                <Form.Group >
                                    {   renderSelectedOption && selectedOption === 'existingTask' ?
                                            <Form.Label className="appFontSubHeading">
                                                Select Task <i className="red">*</i>
                                            </Form.Label>
                                        :
                                            <Form.Label className="appFontSubHeading">
                                                Existing Tasks
                                            </Form.Label>
                                    }
                                    <Dropdown
                                        values={existingTasks?.map(task => {
                                            return { id: task.Id, name: task.Name };
                                        })}
                                        headingTop="Recent Tasks"
                                        topListSize={3}
                                        headingBottom="Existing Tasks"
                                        dropdownButtonText={"Select an existing task"}
                                        onSelect={
                                            (selectedValue) => {
                                                console.log("Selected task: ", selectedValue);
                                                setTaskIdSelected(selectedValue.id);
                                                //setTaskNameAndCreatedBy(selectedValue.name, selectedValue.createdById);
                                            }
                                        }
                                        disabled={!existingTasks}
                                        //currentSelected={values.Name}
                                        isSearchable={true}
                                    />
                                </Form.Group>
                            }
                            {
                                !renderSelectedOption &&
                                <div className="link-email-button-next-container">
                                    <button
                                        disabled={!(selectedOption === 'newTask' || (selectedOption === 'existingTask' && taskIdSelected !== 0))}
                                        type="button"
                                        className="btn-primary blueButton button50 shadow-none"
                                        style={{ width: "176px" }}
                                        onClick={() => {
                                            setRenderSelectedOption(true);
                                            setLoading(true);
                                            if (selectedOption === 'newTask') {
                                                console.log("New task module selected");
                                            } else {
                                                console.log("Existing task module selected");
                                            }
                                        }}
                                    >
                                        Next
                                    </button>
                                </div>
                            }
                        </div>
                    }
                    {   !taskLinkCompleted && renderSelectedOption && selectedOption === 'newTask'  &&
                        <EmailTrackingTaskAdd
                            setPage={setPage}
                            selectedOption={selectedOption}
                            isEmailTrackingDisabled={!renderSelectedOption}
                            isEmailTrackingActivity={isEmailTrackingActivity}
                            isAutoSync={isAutoSync}
                            taskLinkCompleted={taskLinkCompleted}
                            setTaskLinkCompleted={setTaskLinkCompleted}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    }
                    {   !taskLinkCompleted && renderSelectedOption && selectedOption === 'existingTask' &&
                        <EmailTrackingTaskEdit
                            setPage={setPage}
                            selectedOption={selectedOption}
                            isEmailTrackingDisabled={!renderSelectedOption}
                            isEmailTrackingActivity={isEmailTrackingActivity}
                            isAutoSync={isAutoSync}
                            setTaskNameAndCreatedBy={setTaskNameAndCreatedBy}
                            taskIdSelected={taskIdSelected}
                            taskLinkCompleted={taskLinkCompleted}
                            setTaskLinkCompleted={setTaskLinkCompleted}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    }
                    { taskLinkCompleted && (
                        <div className="mr-3">
                            <div className="text-center">
                                <img src={SuccessIcon} className="projectCreatedLogo"></img>
                            </div>
                            <div className="text-center mt-4">
                                <span className="projectCreatedTitle">
                                The task has been {selectedOption === 'existingTask'? "linked" : "created"}
                                <br />
                                successfully.
                                <br />
                                Now, please send the message.
                                </span>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            }
        </React.Fragment>
    );
};

EmailTrackingTaskMenu.propTypes = {
    setPage: PropTypes.func,
};

export default EmailTrackingTaskMenu;
