import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import SuccessIcon from "../../resources/success.png";

class TaskReassigned extends Component {
  state = {};

  render() {
    let activeView = null;
    activeView = (
      <div className="mt-7">
        <div className="text-center">
          <img src={SuccessIcon} className="taskReassignedLogo"></img>
        </div>
        <div className="text-center mt-4">
          <span className="projectCreatedTitle">
            The task has been
            <br />
            Re-Assigned.
          </span>
        </div>
        <div className="fixed-bottom pl-4 pr-4 pb-2 whiteBackground">
          <button
            className="loginButton btn-primary btn-block shadow-none blueButton"
            onClick={event => this.props.setPage("taskEditor")}
          >
            Back To Task Details
          </button>
        </div>
      </div>
    );
    return <div>{activeView}</div>;
  }
}

export default TaskReassigned;
