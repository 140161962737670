import React, { useEffect, useState, useContext } from 'react';
import { Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import {
    DISABLE_DYNAMIC_FIELDS,
    fetchDynamicFields,
    isAllDynamicFieldsEmpty,
    FIELD_TYPES,
    OBJECT_TYPES
} from '../utils/utils';
import AppContext from "../../../app/AppContext";
import "../dynamicFields.scss";
import TriyoForm from '../../triyoForm/TriyoForm';
import TaskDynamicFieldsInput from '../Input/TaskDynamicFieldsInput';
import moment from 'moment';
import Moment from "react-moment";

function formatFieldData(field) {
    try {
        if (!field.Data && field.FieldType !== FIELD_TYPES.checkbox) return 'N/A';
        switch (field.FieldType) {
            case FIELD_TYPES.date: {
                const date = new Date(field.Data);
                const DD = date.getDate().toString().padStart(2, '0');
                const MM = (date.getMonth() + 1).toString().padStart(2, '0');
                const YYYY = date.getFullYear().toString().padStart(4, '0');
                return `${DD}/${MM}/${YYYY}`;
            }
            case FIELD_TYPES.toggle:
                if (field.Data === "selected")
                    return "On";
                return "Off";
            case FIELD_TYPES.time:
                return (
                    <div>
                        <div className='timeHeader'>
                            HH : MM
                        </div>
                        <div>
                            {field.Data.split(':').join(' : ')}
                        </div>
                    </div>
                );
            case FIELD_TYPES.checkbox:
                return (
                    <TriyoForm.Checkboxes
                        className="options ml-2 mt-1"
                        lookupValues={field.LookupValues}
                        value={field.Data}
                        disabled={true}
                        onChange={() => { handleChange() }}
                    />)

            default:
                return field.Data;
        }
    }
    catch (err) {
        console.log("ERROR: formatFieldData : " + err)
    }

}

function handleChange() {
    return;
}

function toggleField(id, toggle, toggled) {
    let fieldHasToggle = toggled.filter(t => t.fieldsId === id);
    if (fieldHasToggle.length > 0) {
        let temp = [...toggled];
        temp.forEach(t => {
            if (t.fieldsId === id)
                t.toggle = !t.toggle;
        })
        return temp;
    }
    else if (toggled.length > 0) {
        let temp = [...toggled];
        temp.push({ fieldsId: id, toggle: toggle });
        return temp;
    }
    else {
        return [{ fieldsId: id, toggle: toggle }];
    }
}

const CustomRow = (props) => {
    const { name, overlay, field, toggled, setToggled } = props;

    switch (field.FieldType) {
        case FIELD_TYPES.multiText:
            return (
                <div>
                    <Accordion defaultActiveKey="0">
                        <div>
                            <div className="float-left taskDetailsLabel">{name}</div>
                            <div className="float-right text-right pr-2 taskDetailsText taskDescription">
                                <Accordion.Toggle
                                    as={"taskDetailsMultiText"}
                                    eventKey={field.Id}
                                >
                                    {toggled.length > 0 && toggled.find(t => t.fieldsId == field.Id)?.toggle ? (
                                        <div onClick={() => setToggled(toggleField(field.Id, false, toggled))}>
                                            <img
                                                className="dropdown-up-arrow"
                                                alt="dropdown-arrow"
                                            />
                                        </div>

                                    ) : (
                                        <div onClick={() => setToggled(toggleField(field.Id, true, toggled))}>
                                            <img
                                                className="dropdown-down-arrow"
                                                alt="dropdown-arrow"
                                            />
                                        </div>
                                    )}
                                </Accordion.Toggle>
                            </div>
                            <div className="float-right pr-2 taskDetailsText truncate"> {field.Data}</div>
                        </div>
                        <div className="col-md-12 row">
                            <Accordion.Collapse eventKey={field.Id} >
                                <div className='taskDetailsMultiText mt-2 ml-2'>
                                    {field.Data}
                                </div>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                </div>
            )
        case FIELD_TYPES.checkbox:
            return (
                <div>
                    <Accordion defaultActiveKey="0">
                        <div>
                            <div className="float-left taskDetailsLabel">{name}</div>
                            <div className="float-right pr-2 text-right taskDetailsText taskDescription">
                                <Accordion.Toggle
                                    as={"triyo-form"}
                                    eventKey={field.Id}
                                >
                                    {toggled.length > 0 && toggled.find(t => t.fieldsId == field.Id)?.toggle ? (
                                        <div onClick={() => setToggled(toggleField(field.Id, false, toggled))}>
                                            <img
                                                className="dropdown-up-arrow"
                                                alt="dropdown-arrow"
                                            />
                                        </div>
                                    ) : (
                                        <div onClick={() => setToggled(toggleField(field.Id, true, toggled))}>
                                            <img
                                                className="dropdown-down-arrow"
                                                alt="dropdown-arrow"
                                            />
                                        </div>
                                    )}
                                </Accordion.Toggle>
                            </div>
                        </div>
                        <div className="col-md-12 row">
                            <Accordion.Collapse eventKey={field.Id} >
                                <TriyoForm.Checkboxes
                                    className="options ml-2 mt-1"
                                    lookupValues={field.LookupValues}
                                    value={field.Data}
                                    disabled={true}
                                    onChange={() => { handleChange() }}
                                />
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                </div>
            )
        case FIELD_TYPES.table:
            return (
                <div>
                    <Accordion defaultActiveKey="0">
                        <div>
                            <div className="float-left taskDetailsLabel">{name}</div>
                            <div className="float-right pr-2 text-right taskDetailsText taskDescription">
                                <Accordion.Toggle
                                    as={"triyo-form"}
                                    eventKey={field.Id}
                                >
                                    {toggled.length > 0 && toggled.find(t => t.fieldsId == field.Id)?.toggle ? (
                                        <div onClick={() => setToggled(toggleField(field.Id, false, toggled))}>
                                            <img
                                                className="dropdown-up-arrow"
                                                alt="dropdown-arrow"
                                            />
                                        </div>
                                    ) : (
                                        <div onClick={() => setToggled(toggleField(field.Id, true, toggled))}>
                                            <img
                                                className="dropdown-down-arrow"
                                                alt="dropdown-arrow"
                                            />
                                        </div>
                                    )}
                                </Accordion.Toggle>
                            </div>
                        </div>
                        <div className="col-md-12 row">
                            <Accordion.Collapse eventKey={field.Id} >
                                {/* Accordion Collapse is a container, adding row makes elements within center */}
                                <TaskDynamicFieldsInput
                                    field={field}
                                    handleChange={handleChange}
                                    disableErrors={true}
                                    disabled={true}
                                />
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                </div>
            )
        default:
            return (
                <div>
                    <div className="float-left taskDetailsLabel" >
                        {name}
                    </div>
                    {overlay ?
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 250 }}
                            overlay={
                                <Tooltip id="tooltip-1">
                                    {overlay}
                                </Tooltip>
                            }
                        >
                            <div className="float-right text-right mr-2 taskDetailsText taskDescription">
                                {formatFieldData(field)}
                            </div>
                        </OverlayTrigger>
                        :
                        <div className="float-right text-right mr-2 taskDetailsText">
                            {formatFieldData(field)}
                        </div>}
                </div>
            )
    }
}

const TasksDynamicFieldsContent = (props) => {
    const { projectTypeId, taskTypeId, objectId, objectType, comment, task } = props;
    const [dynamicFields, setDynamicFields] = useState([]);
    const context = useContext(AppContext);
    const [toggled, setToggled] = useState([]);
    const [hide, setHide] = useState(true);

    useEffect(() => {
        if (objectId == null || DISABLE_DYNAMIC_FIELDS) return;
        trackPromise(
            fetchDynamicFields({ projectTypeId, taskTypeId, objectId, objectType }, context)
                .then(setDynamicFields)
                .catch((error) => {
                    setDynamicFields([]);
                    console.log(error);
                }),
            'tasks-comments-panel'
        );
    }, [projectTypeId, taskTypeId, objectId, task]);
    if (
        dynamicFields?.length === 0 ||
        isAllDynamicFieldsEmpty(dynamicFields) ||
        DISABLE_DYNAMIC_FIELDS
    ) {
        return null;
    }
    return (
        <div className='dynamicDetailBorder'>
            {(objectType === OBJECT_TYPES.taskComment) &&
                <div className="">
                    <div className='viewSubmitFormContainer' onClick={() => setHide(!hide)}>
                        <div className='viewSubmitFormBadge'>
                            <div>

                            </div>
                        </div>
                        <div>
                            View submitted Form
                        </div>
                    </div>
                    {!hide &&
                        <div className='viewSubmissionContainer'>
                            <div className='viewSubmission'>
                                <div className='submissionHeader'>
                                    <div className='Title'>
                                        <div>
                                            {comment?.CreatedBy?.Name + "   ·   "}
                                            <Moment format="MM/DD/YYYY hh:mm A">{moment.utc(comment.Created).toDate()}</Moment>
                                        </div>
                                        <div className='formSubmission'>
                                            Form Submission
                                        </div>
                                    </div>
                                    <div className='closeSubmission' onClick={() => setHide(true)} ></div>
                                </div>
                                <div className='viewSubmissionDynamicFields'>
                                    <div className='comment-files-row'>
                                        <div className={`dynamic-field-label`}>
                                            Submitted by
                                        </div>
                                        <div className="dynamic-field-mask-label userInfo">
                                            <div className="userInitialsDynamic">{comment?.CreatedBy?.Initials}</div>
                                            <div> {comment?.CreatedBy?.Name}</div>
                                        </div>
                                    </div>
                                    {dynamicFields.map((field, idx) => (
                                        <div className='comment-files-row pt-3' key={idx}>
                                            <div className={`dynamic-field-label`}>
                                                {field.Label}
                                                <span className="red">{field.Required ? ' *' : null}</span>
                                            </div>
                                            <div className="dynamic-field-mask-label">
                                                {field.FieldMask && field.Data ? field.FieldMask : null}
                                                <div className="">{formatFieldData(field)}</div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="comment-files-row pt-3">
                                        <div className="dynamic-field-label">
                                            Comments
                                        </div>
                                        <div className="dynamic-field-mask-label">
                                            <div className="">{comment.Note}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>}
            {
                (objectType === OBJECT_TYPES.taskDetail || objectType === OBJECT_TYPES.project) &&
                <div className="tasks-dynamic-fields-modal-content-wrapper">
                    {dynamicFields.map((field) => (
                        <div className="row ml-2 mr-1">
                            <div className="col-12 taskDetailsRow appFont">
                                <CustomRow name={field.Label} field={field} toggled={toggled} setToggled={setToggled}>
                                </CustomRow>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div >
    );
};

export default TasksDynamicFieldsContent;
