import React, { useState } from "react";
import { validFileTypes } from "../../../util/file-helpers.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import attachmentIcon from "../../../resources/Icons/Icon - Attachments.svg";
import removeAttachment from "../../../resources/Icons/Icon - Remove Attachment.svg";

const TaskCommentSection = ({ context, setFieldValue, handleChange, ...taskComment }) => {
  const [isError, setIsError] = useState(false);
  const [displayAlert, setDisplayAlert] = useState("");

  const showError = message => {
    setDisplayAlert(message);
    setIsError(true);
    displayError();
  };

  const displayError = () => {
    setTimeout(() => {
      setIsError(false);
    }, 5000);
  };

  const checkInvalidFile = fileObj => {
    if (fileObj) {
      if (
        !validFileTypes
          .map(type => {
            return fileObj.name.toLowerCase().includes(type);
          })
          .includes(true)
      ) {
        showError("File format must be .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .xlsm");
        return false;
      } else if (fileObj.size > 52428800) {
        showError("File size exceeds 50MB.");
        return false;
      }
      setIsError(false);
      return true;
    } else {
      showError("File cannot be uploaded.");
      return false;
    }
  };
  const filesToBase64 = files => {
    let convertedFiles = [];
    let loadedFilesCount = 0;

    files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        convertedFiles = [
          ...convertedFiles,
          { name: file.name, FileName: file.name, FileContentType: file.type, Base64String: base64String }
        ];
        loadedFilesCount++;

        if (loadedFilesCount === files.length) {
          onBrowseFilesLoaded(convertedFiles);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const onBrowseFilesLoaded = convertedFiles => {
    let files = taskComment?.values?.selectedFiles;

    if (files.length === 0) {
      convertedFiles.forEach(file => {
        if (!checkInvalidFile(file)) {
          return;
        }
        files.push(file);
      });
    } else {
      let fileFound = false;
      convertedFiles.forEach(file => {
        files.forEach(innerFile => {
          if (innerFile.name === file.name) {
            fileFound = true;
          }
        });
        if (!fileFound) {
          if (!checkInvalidFile(file)) {
            return;
          }
          files.push(file);
        }
      });
    }
    setFieldValue("selectedFiles", files);
  };

  const browseFile = e => {
    e.preventDefault();
    e.stopPropagation();
    if (context.officeAppType === "Word" || context.officeAppType === "PowerPoint") {
      let files = taskComment?.values?.selectedFiles;
      let newSelectedFiles = Array.from(e.target.files);
      e.target.value = null;
      if (files.length === 0) {
        files = newSelectedFiles;
      } else {
        let fileFound = false;
        newSelectedFiles.forEach(file => {
          for (let matchingFile of files) {
            if (matchingFile.name === file.name) {
              fileFound = true;
              break;
            }
          }
          if (!fileFound) {
            files.push(file);
          }
        });
      }
      setFieldValue("selectedFiles", files);
    } else {
      let newSelectedFiles = Array.from(e.target.files);
      e.target.value = null;
      filesToBase64(newSelectedFiles);
    }
  };

  const deleteBrowseFile = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    let files = taskComment?.values?.selectedFiles;
    let idx = files.findIndex(file => file.name === name);
    files.splice(idx, 1);
    setFieldValue("selectedFiles", files);
  };

  return (
    <>
      <Form.Group className="mt-4 mb-1">
        <Form.Label className="loginInput appFontSubHeading">Comments</Form.Label>
        <Form.Control
          className="form-control form-custom-control shadow-none projectDescription"
          as="textarea"
          id="commentNote"
          name="commentNote"
          rows="5"
          maxLength={2000}
          value={taskComment?.values?.commentNote}
          onChange={handleChange}
          placeholder="Enter comment"
        />
      </Form.Group>
      <Form.Group>
        <div className={"project-attachment" + (taskComment?.isError ? " invalid-border" : "")}>
          <div className="image-upload w-10">
            <div className="file-input-btn">
              <label htmlFor="file-input" className="w-10">
                <img className="pointer" src={attachmentIcon} alt="Attachment" />
              </label>
            </div>
            {taskComment?.values?.selectedFiles?.map(file => {
              return (
                <div className="file-pill pr-1 pb-1" key={file.name}>
                  <div className="fileAttachment w-10 d-flex align-items-center justify-content-between pl-2 pr-2 pb-1 pt-0">
                    <OverlayTrigger
                      placement="auto-start"
                      delay={{ show: 250, hide: 50 }}
                      overlay={
                        <Tooltip id="tooltip-1" className="customTooltip" placement="auto-start">
                          {file.name}
                        </Tooltip>
                      }
                    >
                      <span>{file.name}</span>
                    </OverlayTrigger>
                    <button
                      type="button"
                      className="pointer btn btn-outline-none pl-2 pr-0 pt-0 pb-0"
                      aria-label="Remove Attachment"
                      onClick={(e) => {
                        deleteBrowseFile(e, file.name);
                      }}
                      style={{ border: 'none', background: 'none', height: 'fit-content' }}
                    >
                      <img
                        src={removeAttachment}
                        alt="Remove Attachment"
                        style={{ height: '100%' }}
                      />
                    </button>
                  </div>
                </div>
              );
            })}
            <input
              type="file"
              id="file-input"
              accept=".doc, .docx, .ppt, .pptx, .xls, .xlsx, .pdf, .xlsm"
              multiple
              onChange={e => {
                browseFile(e)
              }}
            />
          </div>
        </div>
      </Form.Group>
    </>
  );
};

export default TaskCommentSection;
