import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import ApiService from "../../services/ApiService";

import {
  TaskManagementSection,
  TaskDetailsSection,
  TaskCommentSection,
  TaskButtonSection
} from "./TaskComponents";
import { OBJECT_TYPES, renderTaskDetailDynamicFields, saveDynamicFieldsDetails, isDynamicFieldsValid } from "../dynamicFields/utils/utils.js";
import DynamicFieldsFormWrapper from "../../components/dynamicFields/Form/DynamicFieldsFormWrapper";
import AppContext from "../../app/AppContext";
import TaskReassign from "./TaskReassign";

const TaskEdit = ({ setPage, currentTask }) => {
  const context = useContext(AppContext);
  const [editTask, setEditTask] = useState(currentTask);
  const [addedTaskUsers, setAddedTaskUsers] = useState([]);
  const [editProject, setEditProject] = useState(currentTask?.Project);
  const [editTaskName, setEditTaskName] = useState(currentTask?.Name);
  const [editProjectsList, setEditProjectsList] = useState([]);
  const [editTaskTypes, setEditTaskTypes] = useState([]);
  const [workItemId, setWorkItemId] = useState(editTask?.workItemType);
  const [loading, setLoading] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(currentTask?.Project?.Id);
  const [projectId, setProjectId] = useState(0);
  const [dynamicFields, settingDynamicFields] = useState([]);
  const [hide, setHide] = useState(false);
  const [taskMembers, setTaskMembers] = useState(currentTask.Members);
  const [taskOwner, setTaskOwner] = useState(null);
  const [ownerAsAGroup, setOwnerAsAGroup] = useState(null);

  let editTaskStartDate = new Date(editTask?.Start);
  let editTaskEndDate = new Date(editTask?.Due);
  let editTaskTaskType = editTask?.TaskType;
  let editTaskPriority = editTask?.Priority;
  let taskTypesName = editTaskTypes?.find(taskType => taskType?.Id === editTask?.workItemType)?.Type;

  useEffect(() => {
    getDataProjects();
    getTaskTypes();
    getGroupOwner();
  }, [loading]);

  const hideContent = hidden => {
    if (hide != hidden) {
      setHide(hidden);
    }
  };

  const isOwner = () =>{
    return (context.currUser.Id == editTask.CreatedById);
  }

  const getGroupOwner = () => {
    let temp = currentTask?.Groups?.filter(group => {
      if(group.IsOwner){
        return group;
      }
    })
    if(temp?.length > 0){
      setOwnerAsAGroup(temp[0]);
    }
  };

  const getDataProjects = () => {
    console.log("Waiting for [GetUserProjects]...");
    trackPromise(
      ApiService.getUserProjects(context)
        .then(result => {
          if (result.status === 200) {
            console.log(result);
            let projectsList = result.data;

            if (projectsList !== null && projectsList.length !== 0) {
              projectsList.forEach(project => {
                project = localizeProjectDates(project);
              });
            }
            setEditProjectsList(projectsList);
          } else {
            console.log("API [GetUserProjects] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetUserProjects] error ->");
          console.log(e);
          setAppPage("500 error");
        }),
      "project-list-area"
    );
  };

  const getTaskTypes = () => {
    trackPromise(
      ApiService.getTaskTypes(context)
        .then(result => {
          if (result.status === 200) {
            setEditTaskTypes(result.data);
          } else {
            console.log("API [GetTaskTypes] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetTaskTypes] error ->");
          console.log(e);
          setPage("500 error");
        }),
      "task-add-area"
    );
  };

  const setReassignedMembers = (newMembers, newMembersId) => {
    setTaskMembers(newMembers);
    setAddedTaskUsers(newMembersId);
  }

  const setEditTaskOwner = (receivedTaskOwner) => {
    setTaskOwner(receivedTaskOwner);
  }

  const localizeProjectDates = project => {
    if (project === null || typeof project.Start === "undefined" || project.Start === null) {
      return project;
    }

    let localizedStart = moment
      .utc(project.Start)
      .local()
      .toDate();
    let localizedEnd = moment
      .utc(project.End)
      .local()
      .toDate();
    project.Start = localizedStart;
    project.End = localizedEnd;

    return project;
  };

  const validateReassignedMembers = () => {
    let hasEditor = false;
    let ownerHasPermission = false;
    taskMembers.forEach(member => {
      if (member.User.Id === taskOwner?.Id) {
        if (member.CanView || member.CanApprove || member.CanEdit || member.IsAssigned)
          ownerHasPermission = true;
      }

      if (member.IsAssigned || member.CanEdit) {
        hasEditor = true;
      }
    });

    if (!hasEditor && ownerHasPermission  && ownerAsAGroup == null) {
      return false;
    }
    else if (!hasEditor && !ownerHasPermission  && ownerAsAGroup == null) {
      let reassignedMembers = taskMembers;
      reassignedMembers.forEach(m => {
        if (m.User.Id === taskOwner.Id) {
          m.CanEdit = true;
          m.IsAssigned = true;
        }
      });

      setReassignedMembers(reassignedMembers);
      return true;
    }

    return true;
  }

  const validateForm = (values,dynamicFields, setDynamicFields) => {
    let errors = {};
    let errorMessage = "This is a required field";

    if (!values.taskName) {
      errors.taskName = errorMessage;
    }
    else if(values.taskName.trim().length === 0){
      errors.taskName = "Task Name cannot contain only spaces";
    }

    if (!values.projectName) {
      errors.projectName = errorMessage;
    }

    if (!editTask.PrioritiesAvailable.includes(values.taskPriority)) {
      errors.taskPriority = errorMessage;
    }

    if (!values.taskStart) {
      errors.taskStart = errorMessage;
    }

    if (!values.taskDue) {
      errors.taskDue = errorMessage;
    }

    if (!validateReassignedMembers()) {
      errors.selectedMembers = "Please select at least one edit role";
    }

    if (!isDynamicFieldsValid(dynamicFields)) {
      setDynamicFields(dynamicFields.map(field => ({ ...field, touched: true })));
      errors.dynamicFields = "Dynamic Fields are Invalid";
    }

    return errors;
  };

  const cancelTaskCreation = async () => {
    await setPage("taskEditor");
  };

  const apiEditStandardTask = (editObj, dynamicFields,setSubmitting) => {
    trackPromise(
      ApiService.editStandardTask(editObj, context)
        .then(result => {
          if (result.status === 200) {
            context.currentTask = result.data;
            setReassignedMembers([]);
            setEditTaskOwner(null);
            setSubmitting(false);
            saveDynamicFieldsDetails(dynamicFields, result.data.Id, context);
            setPage("taskEditSuccess");
          }
        })
        .catch(e => {
          console.log("API [Edit Standard Task] error ->");
          console.log(e);
          setPage("500 error");
        }),
      "standard-task-edit-area"
    );
  };

  const onEditSubmit = (values, dynamicFields,setSubmitting) => {
    let reassignedMembers = taskMembers.filter(m => (m.UserId === taskOwner.Id && ownerAsAGroup == null) || m.CanView || m.CanApprove || m.CanEdit || m.IsAssigned);

    let EditObj = {
      Id: currentTask.Id,
      Due: values.taskDue,
      Name: values.taskName,
      Start: values.taskStart,
      Priority: values.taskPriority,
      WorkItemTypeId: values.taskType,
      Comments: [{ Note: values.commentNote, Files: values.selectedFiles }],
      Members: reassignedMembers,
    }

    // call edit Task Api
    apiEditStandardTask(EditObj, dynamicFields, setSubmitting);
  };

  // auto scroll to invalid form fields
  const scrollToError = (touched, errors) => {
    console.log(touched, errors);
    return null;
  };

  const setDynamicFields = (dynamicFields) => {
    settingDynamicFields(dynamicFields);
  }

  const onSubmitAllEditUpdates = async (values, dynamicFields,setSubmitting) => {
    let stringMemberIds = "";
    if(!addedTaskUsers){
      stringMemberIds = taskOwner?.Id.toString();
    }
    else{
      stringMemberIds = addedTaskUsers.toString();
    }
    await ApiService.addMembersToProject(currentProjectId, stringMemberIds, context)
      .then(res => {
        if (res.status == 200) {
          console.log("Project completed");
        }
      })
      .catch(error => {
        console.log("Error adding other Member =>");
        console.log(error);
      })
      .finally(()=> onEditSubmit(values, dynamicFields, setSubmitting));
  };

  return (
    <div>
      <LoadingOverlay area="task-reassign-area" inline="loading-overlay-inline" hideContent={hideContent} />
      <DynamicFieldsFormWrapper
        projectTypeId={0}
        taskTypeId={workItemId}
        objectType={OBJECT_TYPES.taskDetail}
        objectId={currentTask.Id}
        context={context}
      >
        {({ dynamicFields, setDynamicFields }) => (
          <Formik
            validate={(values)=>{return validateForm(values, dynamicFields, setDynamicFields)}}
            onSubmit={(values, { setSubmitting }) => {
              onSubmitAllEditUpdates(values, dynamicFields,setSubmitting);
            }}
            initialValues={{
              projectName: editProject?.Name || "",
              taskName: editTaskName,
              taskDue: editTaskEndDate,
              taskPriority: editTaskPriority,
              taskType: workItemId,
              taskStart: editTaskStartDate,
              commentNote: "",
              taskFilter: "",
              selectedMembers: [],
              selectedSlides: "",
              selectedFiles: []
            }}
          >
            {({
              setFieldValue,
              setFieldTouched,
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              isSubmitting,
              isValidating,
              isValid
            }) => (
              <Form onSubmit={handleSubmit} className="ml-2 mr-2">
                {!hide ? (
                  <>
                    {/* Task Details Section */}
                    <TaskDetailsSection
                      titleTask="Edit Task"
                      taskType={editTaskTaskType}
                      projectInfo={editProject}
                      isProjectDisabled={true}
                      setPage={setPage}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      setCurrentProjectId={setCurrentProjectId}
                      setProjectId={setProjectId}
                      handleChange={handleChange}
                      values={values}
                      touched={touched}
                      errors={errors}
                      context={context}
                      editTask={editTask}
                      isOwner = {isOwner()}
                    />
                    {/* Task Management Section */}
                    <TaskManagementSection
                      context={context}
                      projectInfo={editProject}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      taskTypesName={taskTypesName}
                      values={values}
                      touched={touched}
                      errors={errors}
                      taskTypes={editTaskTypes}
                      currentTaskPriority={editTaskPriority}
                      prioritiesAvailable={editTask.PrioritiesAvailable}
                      isEditTask={true}
                      setWorkItemId={setWorkItemId}
                      isOwner = {isOwner()}
                    />
                    {/* Dynamic Field Section */}
                    {renderTaskDetailDynamicFields({
                      dynamicFields: dynamicFields,
                      setDynamicFields,
                      disableErrors: false
                    })}
                    <Form.Group
                      data-testid="selectedMembers"
                      className={
                        touched?.selectedMembers && errors?.selectedMembers ? "mt-4" : ""
                      }
                      id="selectedMembers">
                        <TaskReassign
                          setPage={setPage}
                          taskId={editTask?.Id}
                          taskName={editTaskName}
                          createdById={editTask?.CreatedById}
                          taskTitle={"Assign Members"}
                          isEditTask={true}
                          errorFromEdit={errors?.selectedMembers}
                          errors={errors}
                          setEditTaskMembers={setReassignedMembers}
                          setEditTaskOwner={setEditTaskOwner}
                          task={currentTask}
                          currUserId={context.currUser.Id}
                          currProjId={currentProjectId}
                        />
                    </Form.Group>
                    {/* Comment Section */}
                    <TaskCommentSection
                      context={context}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      values={values}
                      touched={touched}
                      errors={errors}
                    />
                    {/* Buttons Section */}
                    <TaskButtonSection
                      taskFirstBtn="Cancel"
                      taskSecondBtn="Save"
                      cancelTaskCreation={cancelTaskCreation}
                      valMsg=""
                      isSubmitting={isSubmitting}
                      isValidating={isValidating}
                      isValid={isValid}
                      scrollToError={scrollToError}
                      touched={touched}
                      errors={errors}
                    />
                  </>
                ) : null}
              </Form>
            )}
          </Formik>
        )}
      </DynamicFieldsFormWrapper>
    </div>
  );
};

export default TaskEdit;
