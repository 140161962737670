import React, { Component } from "react";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import ProjectsList from "./ProjectsList";
import ProjectsAdd from "./ProjectsAdd";
import ProjectsAdded from "./ProjectsAdded";
import ProjectsClose from "./ProjectsClose";
import ProjectsDetails from "./ProjectDetails";
import AppContext from "../../app/AppContext";
import TaskAdd from "../tasks/TaskAdd";
import TaskAddedFail from "../tasks/TaskAddedFail";
import TaskAdded from "../tasks/TaskAdded";
import Tasks from "../tasks/Tasks";

class Projects extends Component {
  state = {
    currentPage: "projectsList",
    currentProject: null
  };

  static contextType = AppContext;

  setPage = page => {
    this.setState({ currentPage: page });
  };

  setProject = (page, project) => {
    let localizedProject = this.props.localizeProjectDates(project);
    console.log("Project Selected ->");
    console.log(localizedProject);
    this.context.currentProjectId = project.Id;
    this.props.setCurrentProject(localizedProject);
    this.setState({ currentPage: page, currentProject: localizedProject });
  };

  setCurrentProject = project => {
    this.props.setCurrentProject(project);
    console.log("Projects -> setCurrentProject -> ");
    console.log(project);
  };

  localizeTaskDates = (task) => {
    if(task === null || typeof(task.Due) === 'undefined' || task.Due ===  null){
      return task;
    }

    let localizedDueDate = moment.utc(task.Due).local().toDate();
    task.Due = localizedDueDate;

    return task;
  };

  render() {
    let activeView = null;
    if (this.state.currentPage == "projectsList" || this.state.currentPage == "projects") {
      activeView = <ProjectsList setPage={this.setPage} setProject={this.setProject} setAppPage={this.props.setPage} localizeProjectDates = {this.props.localizeProjectDates}/>
    }
    if (this.state.currentPage == "projectsAdd") {
      activeView = <ProjectsAdd setPage={this.setPage} setCurrentProject={this.setCurrentProject} setAppPage={this.props.setPage} />
    }
    if (this.state.currentPage == "projectsAdded") {
      activeView = <ProjectsAdded setPage={this.setPage} setAppPage={this.props.setPage} />
    }
    if (this.state.currentPage == "task") {
      activeView = (
        <TaskAdd
          setPage={this.setPage}
          setAppPage={this.props.setPage}
          isFromProject={true}
          projectSelected={this.state.currentProject}
        />
      );
    }
    if((this.state.currentPage == "task : regularTask") && (this.context.officeAppType === "Outlook")){
      activeView = (
        <TaskAdd
          setPage={this.setPage}
          setAppPage={this.props.setPage}
          projectSelected={this.state.currentProject}
          isRegularTask={true}
        />
      );
    }
    if (this.state.currentPage == "taskAddedFail") {
      activeView =
        <TaskAddedFail
          existingTask={this.context.existingTask}
          setPage={this.setPage}
          setAppPage={this.props.setPage}
          isFromProject={true}
        />
    }
    if (this.state.currentPage == "taskAdded") {
      activeView = <TaskAdded setPage={this.setPage} />
    }

    if (this.state.currentPage == "tasks") {
      activeView = <Tasks setPage={this.setPage} localizeTaskDates={this.localizeTaskDates}/>
    }
    if (this.state.currentPage == "projectsDetails") {
      activeView = (
        <ProjectsDetails
          setPage={this.setPage}
          setAppPage={this.props.setPage}
          projectInfo={this.state.currentProject}
          setCurrentProjectAddTask={this.props.setCurrentProjectAddTask}
        />
      );
    }
    if (this.context.closeInstance){
      activeView = <ProjectsClose />
    }
    return <div>{activeView}</div>;
  }
}

export default Projects;