import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import SuccessIcon from "../../resources/success.png";
import AppContext from "../../app/AppContext";
import ApiService from "../../services/ApiService"
import TaskCreateButton from "./TaskCreateButton.js";

class TaskAdded extends Component {
  state = {};

  static contextType = AppContext;

  taskList = () => {
    this.props.setPage("tasks");
  };

  createTask = (emailTask) => {
    if(this.context.officeAppType == "Outlook"){
      if(!emailTask) {
        this.props.setPage("task : regularTask");
        return;
      }

      ApiService.getEmailTask(this.context.currEmail?.item?.conversationId, this.context)
        .then(async (result) => {
          if (result.status === 200) {
            const emailThreadExists = result.data;  // Comparing against conversationid
            this.context.previousPage = "projects";
            this.context.existingTask = emailThreadExists;
            if (!emailThreadExists) {
              this.props.setPage("task");
            } else {
              this.props.setPage("taskAddedFail");
            }
          } else {
            this.props.setPage("taskAddedFail");
          }
        });
    } else {
      this.props.setPage("task");
    }
  };

  openWebPage = () => {
    window.open(this.context.applicationEndpoint);
  };

  componentDidMount =() => {
    window.scrollTo(0, 0);
  }
  
  redirectToTaskEditor = () => {
    this.props.setPage("taskEditor");
  }

  editTaskButton = () => {
    return (
      <button
        className="loginButton btn-primary blueButtonOutline button50 ml-1 shadow-none"
        onClick={() => this.redirectToTaskEditor()}
      >
        Back To Task
      </button>
    );
  }

  createTaskButton = () => {
    return (
      this.context.officeAppType == "Outlook" ? (
        <TaskCreateButton
          childButton = {
            <button
              className="loginButton btn-primary blueButtonOutline button50 shadow-none"
            >
              Create Task
            </button>
          }
          className="dropup"
          onClickEvent={this.createTask}
          isDropup={true}
        />
      ) : (
        <button
          className="loginButton btn-primary blueButtonOutline button50 ml-1 shadow-none"
          onClick={() => this.createTask()}
        >
          Create Task
        </button>
      )
    );
  }

  render() {
    let activeView = null;
    let isEditTask = this.props.isEditTask;
    activeView = (
      <div className="mt-7 mr-3">
        <div className="text-center">
          <img src={SuccessIcon} className="projectCreatedLogo"></img>
        </div>
        <div className="text-center mt-4">
          <span className="projectCreatedTitle">
            The task has been {isEditTask ? "saved" : "created"}
            <br />
            successfully.
          </span>
        </div>
        <div className="fixed-bottom pl-4 pr-4 pb-2 whiteBackground">
          { isEditTask ? this.editTaskButton() : this.createTaskButton() }
          <button
            className="loginButton btn-primary blueButton button50 mr-1 shadow-none float-right"
            onClick={() => this.taskList()}
          >
            Go to Task List
          </button>
          <div className="text-center mb-4 mt-3">
            <a href="#" onClick={() => this.openWebPage()}>
              <span className="appFont underline projectCreatedWebLink buttonLink">Switch to web application</span>
            </a>
          </div>
        </div>
      </div>
    );
    return <div>{activeView}</div>
  }
}

export default TaskAdded;