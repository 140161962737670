//Here we are going to start merging all the icons into one file so that we can import them all at once
import clipboardCheckBlue from "./Icon-Clipboard-check-16px-Blue.svg";
import clipboardCheckGray from "./Icon-Clipboard-check-16px-Gray.svg";
import clipboardCheckBlue13 from "./Icon-Clipboard-check-13px-Blue.svg";
import clipboardCheckGray13 from "./Icon-Clipboard-check-13px-Gray.svg";
import folderBlue from "./Icon-Folder-16px-Blue.svg";
import folderGray from "./Icon-Folder-16px-Gray.svg";
import notificationBlue from "./Icon-Notification-16px-Blue.svg";
import notificationGray from "./Icon-Notification-16px-Gray.svg";
import notificationRed from "./Icon-Notification-16px-Red.svg";
import profileBlue from "./Icon-Profile-16px-Blue.svg";
import profileGray from "./Icon-Profile-16px-Gray.svg";
import plusIcon from "./Icon-Plus-16px-White.svg";
import disabledIcon from "./Icon - Disabled Cross.svg";
import calendarIconBlue from "./Icon-Calendar-12px-Blue-60.svg";
import calendarIcon from "./Icon - calender.svg";
import attachmentIcon from "./Icon - Attachments.svg";
import emailIcon from "./Email-20px-Gray-30.svg";
import removeAttachment from "./Icon - Remove Attachment.svg";
import exclamationBlueIcon from "./Icon-Circle-exclamation-16px-Blue.svg";
import exclamationOrangeIcon from "./Icon-Circle-exclamation-16px-Orange.svg";
import exclamationRedIcon from "./Icon-Circle-exclamation-16px-Red.svg";
import exclamationMaroonIcon from "./Icon-Circle-exclamation-16px-Maroon.svg";
import lockGrayIcon from "./Icon-Lock-15px-Gray.svg";
import fileSearchBlueIcon from "./Icon-File-search-13px-Blue.svg";
import fileSearchGrayIcon from "./Icon-File-search-13px-Gray.svg";
import chevronUpBlueIcon from "./Icon-Chevron-up-16px-Blue.svg";
import chevronDownBlueIcon from "./Icon-Chevron-down-16px-Blue.svg";

export {
    clipboardCheckBlue,
    clipboardCheckGray,
    clipboardCheckBlue13,
    clipboardCheckGray13,
    folderBlue,
    folderGray,
    notificationBlue,
    notificationGray,
    notificationRed,
    profileBlue,
    profileGray,
    plusIcon,
    disabledIcon,
    calendarIconBlue,
    calendarIcon,
    attachmentIcon,
    emailIcon,
    removeAttachment,
    exclamationBlueIcon,
    exclamationOrangeIcon,
    exclamationRedIcon,
    exclamationMaroonIcon,
    lockGrayIcon,
    fileSearchBlueIcon,
    fileSearchGrayIcon,
    chevronUpBlueIcon,
    chevronDownBlueIcon,
};
