import React, { Fragment, useEffect, useState, useRef } from "react";
import { Container, Form, Dropdown } from "react-bootstrap";
import { removeDuplicates } from "../../../helpers/HelperMethods";
import removeAttachment from "../../../resources/Icons/Icon - Remove Attachment.svg";
import { OverlayTrigger } from "react-bootstrap";

const EmailTrackingTaskEditAssignMembers = ({
    context,
    assignedMembers,
    assignedOwner,
    filteredAssignedMembers,
    addMemberSuggestion,
    addMembersInForm,
    removeMembersInForm,
    permissionTaskMembers = [],
    task,
    currUserId,
    ownerGroup = null,
    isDisabled,
}) => {
    const [membersList, setMembersList] = useState([]);
    const [ownerMember, setOwnerMember] = useState({});
    const [editorMembers, setEditorMembers] = useState([]);
    const [viewerMembers, setViewerMembers] = useState([]);
    const [approverMembers, setApproverMembers] = useState([]);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState({ editor: false, viewer: false, approver: false });
    const [filteredMembersName, setFilterMembersName] = useState([]);
    const [inputField, setInputField] = useState({ editor: "", viewer: "", approver: "" });
    const [errorMessage, setErrorMessage] = useState({ editor: "", viewer: "", approver: "" });
    const assignMemberOptions = ["Editor", "Viewer", "Approver"];
    const [recentlyAddedMembers, setRecentlyAddedMembers] = useState([]);
    const node = useRef();
    
    let dropdownClassName = isDisabled ? "dropdown-members dropdown-button-disabled" : "dropdown-members";
    let ownerMembersClassName = "userInitialsCompactOwner";
    let ownerLabelClassName = "taskUserListTitle";
    let otherMembersClassName = isDisabled ? " userInitialsCompactOwnerDisabled" : "userInitialsCompactOwner";
    let crossBtnClassName = isDisabled ? "cross-button cross-button-disabled" : "pointer cross-button";
    let userPillClassName = isDisabled ? "pills-gray-background" : "pills-blue-background";
    let permissionLabelClassName = isDisabled ? "appFontSubHeadingDisabled" : "appFontSubHeading";
    
    useEffect(() => {
        if (ownerGroup && ownerGroup.GroupMembers) ownerGroup.Members = ownerGroup.GroupMembers;
        console.log("ownerGroup", ownerGroup);
    }, [ownerGroup])

    useEffect(() => {
        let newAssignedMembers = removeDuplicates(assignedMembers, "Id");
    
        const getAssignedMembers = permission => {
            let newPermissionTaskMembers = Array.from(new Set(permissionTaskMembers));
            let newPermissionMember = [];
            let availableMembers = [];

            if (permission === "editor") newPermissionMember = [...newPermissionTaskMembers.filter(p => p.canEdit === true)];
            if (permission === "viewer") newPermissionMember = [...newPermissionTaskMembers.filter(p => p.canView === true)];
            if (permission === "approver") newPermissionMember = [...newPermissionTaskMembers.filter(p => p.canApprove === true)];

            if (newPermissionMember.length > 0) {
                availableMembers = newAssignedMembers.filter((member) => {
                    return newPermissionMember.some((p) => {
                        return p.Id === member.Id;
                    });
                });
            }
            return availableMembers;
        };

        const getUpdatedFilterMembersName = (concatMembers) => {
            let updateFilterMembersName = [];
            let newFilteredNames = [];

            if (concatMembers) {
                concatMembers.map((member) => newFilteredNames.push(member.Name));
                updateFilterMembersName = filteredAssignedMembers.filter((name) => !newFilteredNames.includes(name));
            }
            return updateFilterMembersName;
        };

        let editorArray = getAssignedMembers("editor");
        let viewerArray = getAssignedMembers("viewer");
        let approverArray = getAssignedMembers("approver");
        let removeConcatMembers = getUpdatedFilterMembersName([...editorArray, ...viewerArray, ...approverArray]);

        setMembersList([...newAssignedMembers]);

        setOwnerMember(assignedOwner);
        setFilterMembersName([...removeConcatMembers]);
        setFilteredSuggestions([...removeConcatMembers]);
        setEditorMembers([...editorArray]);
        setViewerMembers([...viewerArray]);
        setApproverMembers([...approverArray]);
    }, [assignedMembers, assignedOwner, filteredAssignedMembers]);

    useEffect(() => {        
        console.log("ownerMember", ownerMember);
        console.log("editorMembers", editorMembers);
        console.log("viewerMembers", viewerMembers);
        console.log("approverMembers", approverMembers);
        console.log("filteredMembersName", filteredMembersName);
        console.log("filteredSuggestions", filteredSuggestions);
    }, [ownerMember, editorMembers, viewerMembers, approverMembers, filteredMembersName, filteredSuggestions]);

    const updateFilterMembersName = (permission, filterName) => {
        let newFilterNames = [];
        let resultFilterMembersName = [];

        if (permission === "editor") newFilterNames = editorMembers.find(member => member.Name === filterName);
        if (permission === "viewer") newFilterNames = viewerMembers.find(member => member.Name === filterName);
        if (permission === "approver") newFilterNames = approverMembers.find(member => member.Name === filterName);

        if (newFilterNames) {
            resultFilterMembersName = [...filteredMembersName];
            resultFilterMembersName.push(filterName);
        } else {
            resultFilterMembersName = [...filteredMembersName.filter(name => filterName !== name)];
        }
        return resultFilterMembersName;
    };

    const assignMembers = (permission) => {
        let newMembers = [];
        if (permission === "editor") newMembers = [...editorMembers];
        if (permission === "viewer") newMembers = [...viewerMembers];
        if (permission === "approver") newMembers = [...approverMembers];
        return newMembers;
    };

    const updateMembers = (permission, newMembers) => {
        if (permission === "editor") setEditorMembers(newMembers);
        if (permission === "viewer") setViewerMembers(newMembers);
        if (permission === "approver") setApproverMembers(newMembers);
    };

    const showUserMembers = (permission, isApproveDisable) => {
        let currMembers = assignMembers(permission);
        if (isApproveDisable) {
            return currMembers.map(user => {
                return (
                <div key={user.Id}>
                    <div className={`small d-flex d-flex-row pill-fill-container pills-gray-background`}>
                        <span className={`userInitialsCompact userInitialsCompactOwnerDisabled`}>{user.Initials}</span>
                        <span className={`w-8 pl-2 pr-1 taskUserListTitle taskUserListTitleDisabled pull-left`}>
                            {user.Name.length > 20 ? `${user.Name.slice(0, 20)}...` : user.Name}
                        </span>
                        <img src={removeAttachment} className={`cross-button cross-button-disabled`} alt="cross-button"/>
                    </div>
                </div>
                );
            });
        } else {
            return currMembers.map(user => {
                return (
                    <div key={user.Id}>
                        <div className={`small d-flex d-flex-row pill-fill-container ${userPillClassName}`}>
                            <span className={`userInitialsCompact ${otherMembersClassName}`}>{user.Initials}</span>
                            <span className={`w-8 pl-2 pr-1 ${ownerLabelClassName} pull-left`}>
                                {user.Name.length > 20 ? `${user.Name.slice(0, 20)}...` : user.Name}
                            </span>
                            <img
                                src={removeAttachment}
                                className={`${crossBtnClassName}`}
                                alt="cross-button"
                                onClick={() => {
                                    if (!isDisabled) deleteUserMembers(permission, user.Id);
                                }}
                            />
                        </div>
                    </div>
                );
            });
        }    
    };

    const onChangeMemberInput = (e, permission) => {
        let currInput = e.currentTarget.value;
        let availableSuggestions = filteredMembersName.filter(
            suggestion => suggestion.toLowerCase().indexOf(currInput.toLowerCase()) > -1
        );
        if (currInput) {
            setShowSuggestions({ ...showSuggestions, [permission]: true });
        } else {
            setShowSuggestions({ ...showSuggestions, [permission]: false });
        }
        setFilteredSuggestions(availableSuggestions);
        setErrorMessage({ ...errorMessage, [permission]: "" });
        setInputField({ ...inputField, [permission]: e.currentTarget.value });
    };

    const onKeyDownAddMembers = (e, permission) => {
        if (e.keyCode === 13) {
            setActiveSuggestion(0);
            setShowSuggestions({ ...showSuggestions, [permission]: false });
            setInputField({ ...inputField, [permission]: filteredSuggestions[activeSuggestion] });
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            setActiveSuggestion(activeSuggestion - 1);
        } else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1);
        }
    };
    
    useEffect(() => {        
        if (addMemberSuggestion != null && addMemberSuggestion?.length > 0) {
            console.log("addMemberSuggestion=>");
            console.log(addMemberSuggestion);    
            const uniqueAddMemberSuggestion = addMemberSuggestion?.filter(
                newMember => !recentlyAddedMembers?.some(member => member.Id === newMember.Id) &&
                !editorMembers?.some(member => member.Id === newMember.Id) &&
                !viewerMembers?.some(member => member.Id === newMember.Id) &&
                !approverMembers?.some(member => member.Id === newMember.Id)
            );
            
            uniqueAddMemberSuggestion?.forEach((newMember) => {
                onAddMemberSuggestion(newMember.Name, "viewer", false);
            });
            
            const removedMembers = recentlyAddedMembers?.filter(
                member => !addMemberSuggestion?.some(newMember => newMember.Id === member.Id)
            );

            console.log("removedMembers=>");
            console.log(removedMembers);    
            
            removedMembers?.forEach((removedMember) => {
                deleteUserMembers("viewer", removedMember.Id);
            });    
            
            setRecentlyAddedMembers([...(recentlyAddedMembers || []), ...uniqueAddMemberSuggestion]);
        } else if (recentlyAddedMembers?.length > 0) {
            console.log("recentlyAddedMembers=>");
            console.log(recentlyAddedMembers);    
            
            recentlyAddedMembers?.forEach((newMember) => {
                deleteUserMembers("viewer", newMember.Id);
            });    
            
            setRecentlyAddedMembers(null);
        }
    }, [addMemberSuggestion, editorMembers, viewerMembers, approverMembers]);
    

    const deleteUserMembers = (permission, removeId) => {
        let updatedMembers = assignMembers(permission);
        let removedMember = updatedMembers.find(member => member.Id === removeId);

        console.log("permission", permission);
        console.log("removeId", removeId);
        console.log("updatedMembers", updatedMembers);
        console.log("removedMember", removedMember);

        if (!removedMember) return;

        setFilterMembersName([...updateFilterMembersName(permission, removedMember.Name)]);
        setFilteredSuggestions([...updateFilterMembersName(permission, removedMember.Name)]);
        updatedMembers = updatedMembers.filter(member => member.Id !== removeId);
        removeMembersInForm(permission, removeId);
        updateMembers(permission, updatedMembers);
    };

    const onClickMemberSuggestion = (e, permission) => {
        console.log(e.currentTarget.innerText);
        console.log(permission);

        onAddMemberSuggestion(e.currentTarget.innerText, permission, true);
    };

    const onAddMemberSuggestion = (memberName, permission, manual) => {
        console.log(memberName);
        console.log(permission);

        let updatedMembers = assignMembers(permission);
        // New members filter now by words due to inner text remove last space in names automatically
        let newMember = membersList.filter(
            member => member.Name.split(" ").join("") === memberName.split(" ").join("")
        );
        
        const addId = newMember[0]?.Id;
        const checkEditorExists = editorMembers.some(member => member.Name === newMember[0]?.Name);
        const checkViewerExists = viewerMembers.some(member => member.Name === newMember[0]?.Name);
        const checkApproverExists = approverMembers.some(member => member.Name === newMember[0]?.Name);

        if (checkEditorExists || checkViewerExists || checkApproverExists) {
            if (manual) {
                setErrorMessage({
                    ...errorMessage,
                    [permission]: `${newMember[0].Name.length > 20 ? `${newMember[0].Name.slice(0, 20)}...` : newMember[0]?.Name}
                    already exists, please try a different member`
                });
            }
        } else {
            addMembersInForm(permission, addId);
            updatedMembers = [...updatedMembers, ...newMember];
            updateMembers(permission, updatedMembers);
        }
        setActiveSuggestion(0);
        setFilteredSuggestions([...updateFilterMembersName(permission, newMember[0]?.Name)]);
        setFilterMembersName([...updateFilterMembersName(permission, newMember[0]?.Name)]);
        setShowSuggestions({ ...showSuggestions, [permission]: false });
        setInputField({ ...inputField, [permission]: "" });
    };

    const handleLostFocus = (permission, event) => {
        setShowSuggestions({ ...showSuggestions, [permission]: false });
        setInputField({ ...inputField, [permission]: "" });
        setFilteredSuggestions(filteredMembersName);
        event.preventDefault();
    };

    const handleOnFocus = (permission, event) => {
        setShowSuggestions({ ...showSuggestions, [permission]: true });
        setInputField({ ...inputField, [permission]: "" });
        event.preventDefault();
    };

    const suggestionsListComponent = (option) => (    
        <Fragment>
            <div className="dropdown-main-top-level" ref={node}>
                <Dropdown className="dropdown-main" show={showSuggestions[option]}>
                {
                    (option === "approver" && ((task?.Permission === "Edit" || task?.UserRole === "Edit") && task?.CreatedById != currUserId)) ?
                    <Dropdown.Toggle
                        tabIndex="0"
                        id="dropdown-toggle"
                        className={`pill-wapper dropdown-button dropdown-members dropdown-button-disabled dropdown-input assignMembersWrapper`}
                        as="div"
                    >
                        {showUserMembers(option,true)}
                        <input
                            type="text"
                            value={inputField[option]}
                            onChange={(e) => onChangeMemberInput(e, option)}
                            onKeyDown={(e) => {
                                onKeyDownAddMembers(e, option);
                                e.key === "Enter" && e.preventDefault();
                            }}
                            onBlur={(e) => handleLostFocus(option, e)}
                            onFocus={(e) => handleOnFocus(option, e)}
                            placeholder="Enter new member"
                            disabled={true}
                        />
                    </Dropdown.Toggle>
                :
                    <Dropdown.Toggle
                        tabIndex="0"
                        id="dropdown-toggle"
                        className={`pill-wapper dropdown-button assignMembersWrapper ${dropdownClassName} dropdown-input`}
                        as="div"
                    >
                        {showUserMembers(option)}
                        <input
                            type="text"
                            value={inputField[option]}
                            onChange={(e) => onChangeMemberInput(e, option)}
                            onKeyDown={(e) => {
                                onKeyDownAddMembers(e, option);
                                e.key === "Enter" && e.preventDefault();
                            }}
                            onBlur={(e) => handleLostFocus(option, e)}
                            onFocus={(e) => handleOnFocus(option, e)}
                            placeholder="Enter new member"
                            disabled={isDisabled}
                        />
                    </Dropdown.Toggle>
                }
                <Dropdown.Menu className={`dropdown-main-menu`} as="div">
                    {filteredSuggestions.map((suggestion, index) => {
                        return (
                            <Fragment key={index}>
                                <Dropdown.Item
                                    onKeyDown={(e) => onKeyDownAddMembers(e)}
                                    className="dropdown-row"
                                    key={1}
                                    value={1}
                                    name={suggestion}
                                    onClick={(e) => onClickMemberSuggestion(e, option)}
                                    onMouseDown={(e) => e.preventDefault()} // This code is added to prevent onBlur to trigger before onClick
                                >
                                    <span className="dropdown-row-text">{suggestion}</span>
                                </Dropdown.Item>

                                {index !== filteredSuggestions.length - 1 && <Dropdown.Divider className="dropdown-row-divider" />}
                            </Fragment>
                        );
                    })}
                </Dropdown.Menu>
                </Dropdown>
                {errorMessage[option] !== "" && (
                    <Form.Label>
                        <div type="invalid" className="error-message-assign">
                            {errorMessage[option]}
                        </div>
                    </Form.Label>
                )}
            </div>
        </Fragment>
    );

    return (
        <div>
            <Container className="form-custom-control">
                <Form.Group className="mt-3">
                <Form.Label className={`loginInput ${permissionLabelClassName}`}>Owner</Form.Label>
                {ownerGroup!= null ? 
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 250 }}
                        overlay={
                            <div className="members-popover-container">
                                <div className="members-popover">
                                    <ul className={`text-right members-popover-scroll`}>
                                        {ownerGroup?.Members?.map(groupMembers => {
                                            return (
                                                <li key={groupMembers.UserId}>
                                                    <span key={groupMembers.User?.Id}
                                                        className={`userInitialsCompact appFont ${context?.currUser?.Id==groupMembers.UserId ? "userInitialsCompactOwner" :""} `}
                                                    >
                                                        {groupMembers.User.Initials}
                                                    </span>
                                                    <span className="user-name">
                                                        {groupMembers.User.Name}
                                                    </span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        }
                    >
                        <div className="d-flex d-flex-row">           
                            {ownerGroup?.Initials && (
                                <span className={`userInitialsCompact ${ownerMembersClassName}`}>{ownerGroup.Initials}</span>
                            )}
                            <span style={{ height: "2.2em" }} className={`w-8 pl-2 pr-1 pt-1 ${ownerLabelClassName} pull-left`}>
                                {ownerGroup?.Name ? ownerGroup.Name : "Select a Project before assigning members"}
                            </span>
                        </div>
                    </OverlayTrigger>
                :
                    <div className="d-flex d-flex-row">           
                        {ownerMember?.Initials && (
                            <span className={`userInitialsCompact ${ownerMembersClassName}`}>{ownerMember.Initials}</span>
                        )}
                        <span style={{ height: "2.2em" }} className={`w-8 pl-2 pr-1 pt-1 ${ownerLabelClassName} pull-left`}>
                            {ownerMember?.Name ? ownerMember.Name : "Select a Project before assigning members"}
                        </span>
                    </div>
                }
                </Form.Group>
                {   assignMemberOptions.map((option) => (
                        <Form.Group key={option.toLowerCase()}>
                            <Form.Label className={`loginInput ${permissionLabelClassName}`}>{option}</Form.Label>
                            {suggestionsListComponent(option.toLowerCase())}
                        </Form.Group>
                    ))
                }
            </Container>
        </div>
    );
};

export default EmailTrackingTaskEditAssignMembers;
