import React from "react";
import "./inline-notification.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";
import GreenTickIcon from "../../resources/Icons/Icon-CheckMark-16px-Green-60.svg";
import InfoIcon from "../../resources/Icons/Icon-Info-16px-Blue-60.svg";
import CancelIcon from "../../resources/Icons/Icon-Cancel-14px-Gray-60.svg"

const getNotificationTypeClass = type => {
  switch (type) {
    case "success":
      return "success";
    case "error":
      return "error";
    default:
      return "info";
  }
};

const InlineNotification = ({ type, title, description, options, style, cancelClick }) => {
  const className = `notification-card-container ${getNotificationTypeClass(type)}`;
  const iconSrc = type === "success" ? GreenTickIcon : InfoIcon;
  
  return (
    <div className={className} style={style} data-testid="notification-card-container">
      <img className="noti-icon" src={iconSrc} alt={type} />
      {cancelClick && 
        <button
          type="button"
          className="btn close-btn"
          onClick={cancelClick}
          aria-label="Close"
        >
          <img src={CancelIcon} alt={"Cancel"} />
        </button>
      }
      <h3 data-testid="notification-title">{title}</h3>
      <p data-testid="notification-description">{description}</p>
      <div className="d-inline-flex">
        {options?.map(({ text, onClick }, index) => (
            <button
              key={text}
              type="button"
              className="btn"
              onClick={onClick}
              data-testid={`notification-option-${index + 1}-button`}
            >
              {text}
            </button>
          ))}
      </div>
    </div>
  );
};

InlineNotification.propTypes = {
  type: PropTypes.oneOf(["success", "info", "error"]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ),
  style: PropTypes.object,
  cancelClick: PropTypes.func
};

export default InlineNotification;
