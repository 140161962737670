import React from "react";
import PropTypes from 'prop-types';
import emailIcon from "../../resources/Icons/Email-14px-Purple.svg";
import taskIcon from "../../resources/Icons/Task - Create-14x10-Purple.svg";
import "./TaskCreateButton.scss";

const TaskCreateOption = ({ icon, title, description, isWide, onClick }) => (
    <button
        type="button"
        className={`taskCreateOptions p-2 ${isWide ? "task-create-button-wide" : "task-create-button-narrow"}`}
        onClick={onClick}
    >
        <div className="taskCreateOptions-info">
            <img src={icon} className="task-create-icons" alt={title} />
            <div className="create-task-info">
                <div className="create-task-title overflow-info">{title}</div>
                <div className="create-task-description overflow-info">{description}</div>
            </div>
        </div>
    </button>
);

const TaskCreateButton = ({ childButton, onClickEvent, isWide, className, isDropup = false }) => {
    const handleClick = (state) => onClickEvent(state);

    return (
        <div className={`task-create-list show ${className}`}>
            <a
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                {childButton}
            </a>
            <ul className={`task-create-shadow dropdown-menu p-0 ${isDropup ? "email-menu-dropup" : "email-menu-dropdown"}`} aria-labelledby="dropdownMenuLink">
                <li className="task-create-list-item">
                    <TaskCreateOption
                        icon={emailIcon}
                        title="New Task with Email"
                        description="Use the selected email to create a new task"
                        isWide={isWide}
                        onClick={() => handleClick(true)}
                    />
                </li>
                <li className="task-create-list-item">
                    <TaskCreateOption
                        icon={taskIcon}
                        title="New Task"
                        description="Create a new Task"
                        isWide={isWide}
                        onClick={() => handleClick(false)}
                    />
                </li>
            </ul>
        </div>
    );
}

TaskCreateOption.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isWide: PropTypes.bool,
    onClick: PropTypes.func
};

TaskCreateButton.propTypes = {
    childButton: PropTypes.node.isRequired,
    onClickEvent: PropTypes.func,
    isWide: PropTypes.bool,
    className: PropTypes.string,
    isDropup: PropTypes.bool
};

TaskCreateButton.defaultProps = {
    isWide: false,
    className: '',
    isDropup: false
};

export default TaskCreateButton;
