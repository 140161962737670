import React, { useState, useEffect, useRef, useMemo } from "react";
import filterPinIconSelected from "../../../../assets/Pin-Icon-14px-Blue.svg";
import filterPinIconUnselected from "../../../../assets/Pin-Icon-14px-Transparent.svg";

const ToggleCheckboxItem = ({
  colKey,
  description,
  handleCheckboxClick,
  isChecked,
  handlePinnedClick,
  colName,
  viewAllCheckboxRef = null,
  isCheckboxItem = true,
  isPinned = {},
  onlyOneColumnFilled = false
}) => {
  let isItemOnlyPinned = onlyOneColumnFilled && isPinned.colPinned;

  const displayPinnedIcon = () => {
    let showSelectedPinIcon = null;
    if (isItemOnlyPinned) {
      showSelectedPinIcon = filterPinIconSelected;
    } else {
      showSelectedPinIcon = isPinned.colPinned ? filterPinIconSelected : filterPinIconUnselected;
    }
    return showSelectedPinIcon;
  };

  return (
    <div className={`popup-filter-${isCheckboxItem ? "item" : "view-all"}`} key={colKey}>
      {isCheckboxItem ? (
        <input
          className="col-checkbox"
          id={colKey}
          name={description}
          type="checkbox"
          onChange={handleCheckboxClick}
          checked={isChecked}
          data-col={colName}
        />
      ) : (
        <input
          ref={viewAllCheckboxRef}
          className="view-all-checkbox"
          id={colKey}
          name={description}
          type="checkbox"
          onChange={handleCheckboxClick}
          data-col={colName}
        />
      )}

      {isCheckboxItem && (
        <img
          className={`${isItemOnlyPinned ? "img-disabled" : ""}`}
          src={displayPinnedIcon()}
          alt="pin icon"
          onClick={() => {
            if (!isItemOnlyPinned) handlePinnedClick(colKey);
          }}
        />
      )}

      <span>{description}</span>
    </div>
  );
};

const DynamicFieldFilter = ({
  tablePopupRef,
  showTableFilter,
  handleCloseFilter,
  columnItems,
  tableClassName,
  setTablePinnedList,
  orderingColTables
}) => {
  const VIEW_ALL_CHECKBOX_STATES = {
    Checked: "Checked",
    Indeterminate: "Indeterminate",
    Empty: "Empty"
  };

  const [isFilterSubmitted, setIsFilterSubmitted] = useState(false);
  const [disabledFilterBtn, setDisabledFilterBtn] = useState(false);
  const [isCheckAllView, setIsCheckAllView] = useState(VIEW_ALL_CHECKBOX_STATES.Checked);
  const [isCheckList, setIsCheckList] = useState([]);
  const [isPinnedList, setIsPinnedList] = useState([]);
  const [filterListData, setFilterListData] = useState([]);
  const viewAllCheckboxRef = useRef();
  const totalColumnElms = isPinnedList.length;
  const sortedCheckboxes = isPinnedList.map(x => {
    return x.colId;
  });

  let isColumnFilled = isCheckList.length >= 1;
  let onlyOneColumnFilled = isCheckList.length === 1;
  let colCheckboxes = document.querySelectorAll(".col-checkbox");
  let columnFilterElms = columnItems.map(x => ({
    colId: tableClassName(x.Name) + "-" + x.Id,
    colName: x.Name,
    colClassName: `col-${tableClassName(x.Name)}-${x.Id}`
  }));
  let filteredPinnedColumns = columnItems.map(x => ({
    colName: x.Name + "-" + x.Id,
    colId: tableClassName(x.Name) + "-" + x.Id,
    colPinned: false
  }));
  let defaultPinnedColumns = columnItems.map((x, index) => ({
    colName: x.Name + "-" + x.Id,
    colId: tableClassName(x.Name) + "-" + x.Id,
    colPinned: index === 0 ? true : false
  }));
  let filteredColumnIds = columnFilterElms.map(x => x.colId);

  useEffect(() => {
    if (isCheckAllView === VIEW_ALL_CHECKBOX_STATES.Checked) {
      viewAllCheckboxRef.current.checked = true;
      viewAllCheckboxRef.current.indeterminate = false;
    } else if (isCheckAllView === VIEW_ALL_CHECKBOX_STATES.Empty) {
      viewAllCheckboxRef.current.checked = false;
      viewAllCheckboxRef.current.indeterminate = false;
    } else if (isCheckAllView === VIEW_ALL_CHECKBOX_STATES.Indeterminate) {
      viewAllCheckboxRef.current.checked = false;
      viewAllCheckboxRef.current.indeterminate = true;
    }
  }, [isCheckAllView]);

  useEffect(() => {
    setFilterListData(columnFilterElms);
    setIsPinnedList(defaultPinnedColumns);
    setTablePinnedList(defaultPinnedColumns);
    setIsCheckList(filteredColumnIds);
  }, []);

  useEffect(() => {
    checkFilterBtnStatus();
  }, [isColumnFilled]);

  const hideShowTableCol = (colName, checked) => {
    const cells = document.querySelectorAll(`.${colName}`);
    cells.forEach(cell => {
      cell.style.display = checked ? "table-cell" : "none";
    });
  };

  const onClickApplyFilter = () => {
    colCheckboxes.forEach(elm => {
      const checked = elm.checked;
      const colName = elm.getAttribute("data-col");
      hideShowTableCol(colName, checked);
    });
    handleCloseFilter();
  };

  const checkFilterBtnStatus = () => {
    if (isColumnFilled) {
      setDisabledFilterBtn(false);
    } else {
      setDisabledFilterBtn(true);
    }
  };

  const handleSelectAllClick = () => {
    let updatedChecked;

    if (isCheckAllView === VIEW_ALL_CHECKBOX_STATES.Checked) {
      setIsCheckList([]);
      setIsPinnedList(filteredPinnedColumns);

      updatedChecked = VIEW_ALL_CHECKBOX_STATES.Empty;
    } else if (
      isCheckAllView === VIEW_ALL_CHECKBOX_STATES.Empty ||
      isCheckAllView === VIEW_ALL_CHECKBOX_STATES.Indeterminate
    ) {
      setIsCheckList(filterListData.map(x => x.colId));

      updatedChecked = VIEW_ALL_CHECKBOX_STATES.Checked;
    }

    setIsCheckAllView(updatedChecked);
  };

  const handleIndeterminateMainCheckbox = checkedList => {
    const someElmsChecked = checkedList.length < totalColumnElms && checkedList.length >= 1;
    const allElmsChecked = checkedList.length === totalColumnElms;

    if (someElmsChecked) {
      setIsCheckAllView(VIEW_ALL_CHECKBOX_STATES.Indeterminate);
    } else if (allElmsChecked) {
      setIsCheckAllView(VIEW_ALL_CHECKBOX_STATES.Checked);
    } else {
      setIsCheckAllView(VIEW_ALL_CHECKBOX_STATES.Empty);
    }
  };

  const handleCheckboxClick = event => {
    const { id, checked } = event.target;
    const updatedArrayId = isPinnedList.findIndex(obj => obj.colId === id);
    const isOneCheckboxPinned = isPinnedList.some(obj => obj.colPinned === true);
    const currCheckedPin = isPinnedList[updatedArrayId].colPinned;
    let updatedCheckListItems = [...isCheckList, id];
    let sortChecklistItems = [];

    if (!checked) {
      updatedCheckListItems = isCheckList.filter(item => item !== id);
    }

    sortChecklistItems = [...updatedCheckListItems].sort(function(a, b) {
      return sortedCheckboxes.indexOf(a) - sortedCheckboxes.indexOf(b);
    });

    const firstSortChecklist = sortChecklistItems[0];

    if (currCheckedPin) {
      if (sortChecklistItems.length >= 1) {
        handlePinnedClick(firstSortChecklist);
      } else {
        setIsPinnedList(filteredPinnedColumns);
      }
    } else if (sortChecklistItems.length === 1 && !isOneCheckboxPinned) {
      handlePinnedClick(firstSortChecklist);
    }

    setIsCheckList(sortChecklistItems);

    handleIndeterminateMainCheckbox(sortChecklistItems);
  };

  const handlePinnedClick = colId => {
    let pinnedSet = [...filteredPinnedColumns];
    let updatedArrayId = isPinnedList.findIndex(obj => obj.colId === colId);
    let isCheckboxItem = isCheckList.includes(colId);
    let updatedCheckListItems = [];

    if (isCheckboxItem) {
      updatedCheckListItems = [...isCheckList];
    } else {
      updatedCheckListItems = [...isCheckList, colId];
    }

    pinnedSet[updatedArrayId].colPinned = isPinnedList[updatedArrayId].colPinned;

    let newPinnedSet = pinnedSet.map(elm => {
      if (elm.colId === colId) {
        return { ...elm, colPinned: !elm.colPinned };
      }
      return elm;
    });

    setIsCheckList(updatedCheckListItems);
    setIsPinnedList(newPinnedSet);

    handleIndeterminateMainCheckbox(updatedCheckListItems);
  };

  // Memo use to inmediately update order of tables before applying filter
  useMemo(async () => {
    await orderingColTables();
    onClickApplyFilter();
  }, [isFilterSubmitted]);

  return (
    <div className="popup-filter-container">
      <div className={`popup-filter-menu ${showTableFilter ? "shown" : ""}`} ref={tablePopupRef}>
        <div className="popup-filter-title">
          <span>Visibility settings</span>
        </div>
        <div className="popup-filter-content">
          <ToggleCheckboxItem
            colKey="selectAll"
            colName="selectAll"
            description="View all"
            handlePinnedClick={handlePinnedClick}
            handleCheckboxClick={handleSelectAllClick}
            isChecked={isCheckAllView}
            viewAllCheckboxRef={viewAllCheckboxRef}
            isCheckboxItem={false}
          />
          {filterListData.map(item => {
            return (
              <ToggleCheckboxItem
                colKey={item.colId}
                description={item.colName}
                handleCheckboxClick={handleCheckboxClick}
                handlePinnedClick={handlePinnedClick}
                isChecked={isCheckList.includes(item.colId)}
                isPinned={isPinnedList.find(target => target.colId === item.colId)}
                colName={item.colClassName}
                onlyOneColumnFilled={onlyOneColumnFilled}
              />
            );
          })}
          <button
            type="button"
            onClick={() => {
              setIsFilterSubmitted(!isFilterSubmitted);
              setTablePinnedList(isPinnedList);
            }}
            className={`popup-filter-${disabledFilterBtn ? "btn-disabled" : "btn"}`}
            disabled={disabledFilterBtn}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default DynamicFieldFilter;
