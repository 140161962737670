// Basic general file functions:

export const contentTypeMapper = {
    ".doc"  : "application/msword",
    ".docx" : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".ppt"  : "application/vnd.ms-powerpoint",
    ".pptx" : "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ".xls"  : "application/vnd.ms-excel",
    ".xlsx" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".xlsm" : "application/vnd.ms-excel.sheet.macroEnabled.12",
    ".pdf"  : "application/pdf",
    ".png"  : "image/png",
    ".html" : "text/html",
    ".jpg" : "image/jpeg",
    ".jpeg" : "image/jpeg",
    ".gif" : "image/gif",
    ".tif" : "image/tiff",
    ".tiff" : "image/tiff",
    ".svg" : "image/svg+xml",
    ".webp" : "image/webp"
}

export const validFileTypes = ['.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx', '.pdf', '.xlsm', '.jpeg', '.jpg', '.png', '.gif', '.tif', '.tiff', '.webp', '.svg'];

export const validFileTypesString = validFileTypes.join(", ");

export const isValidFileType = (fileObj) => { return validFileTypes.some(type => fileObj.name.toLowerCase().includes(type)); }

export const isImageFile = (fileObj) => {
    const imageExtensions = ['.jpeg', '.jpg', '.png', '.gif', '.tif', '.tiff', '.webp', '.svg'];
    return imageExtensions.includes(fileObj.name.toLowerCase().slice(-4));
}

export const shortenFileName = (fileName) => {
    if (fileName.length > 20) {
        let fileNameArr = fileName.split('.')
        let fileExtension = fileNameArr[fileNameArr.length - 1];

        return (fileName.slice(0, 20 - fileExtension.length - 4) + "...." + fileExtension);
    } else {
        return fileName;
    }
}

export const getFileTypeExtension = (filename) => {
    return filename?.substring(filename?.lastIndexOf(".") + 1, filename?.length) || filename;
};

export const mentionDisplayTransform = (id, display, type) => `@${display}:`;

export const setNewCommentState = (newCommentText) => newCommentText.replaceAll(/\[/g, "").replaceAll(/\]/g, ":");

export const getUsersMatchingQuery = (tags, query, callback) => {
    const matchedUsers = tags.map((tagItem, index) => ({
        id: index,
        display: tagItem
    })).filter(user => {
        const names = user.display.split(" ");
        return names.some(name => name.toLowerCase().startsWith(query.toLowerCase()));
    });
    callback(matchedUsers);
};

export const ToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
};
