import React from "react";
import { Form } from "react-bootstrap";
import ValidationMessages from "../../ValidationMessages";

const TaskButtonSection = ({
  taskFirstBtn,
  taskSecondBtn,
  cancelTaskCreation,
  valMsg,
  isSubmitting,
  isValidating,
  isValid,
  scrollToError,
  ...taskComments
}) => {
  return (
    <>
      <div style={{ paddingBottom: "140px" }} />
      <Form.Group>
        <div className="fixed-bottom pt-4 pb-4 whiteBackground taskAddButtons">
          <ValidationMessages valMsg={valMsg} />
          <div className="row">
            <div className="col-12 taskModeButtonContainer">
              <button
                className="loginButton btn-primary blueButtonOutline shadow-none taskModeButton"
                onClick={() => cancelTaskCreation()}
              >
                {taskFirstBtn}
              </button>
              <button
                className="loginButton btn-primary blueButton shadow-none float-right taskModeButton taskAddSubmitBtn"
                type="submit"
                disabled={isSubmitting}
              >
                {taskSecondBtn}
              </button>
            </div>
          </div>
        </div>
      </Form.Group>
      {isValidating && isSubmitting && !isValid ? scrollToError(taskComments.touched, taskComments.errors) : null}
    </>
  );
};

export default TaskButtonSection;
