import React from "react";
import {
    ClipboardTextLtrRegular as FluentClipboardTextLtrRegular,
    TasksAppFilled as FluentTasksAppFilled,
    ArrowDownloadRegular as FluentArrowDownloadRegular,
    SpinnerIosRegular as FluentSpinnerIosRegular
  } from '@fluentui/react-icons';

  export const FluentIcons = {
    ClipboardTextLtrRegular: (props) => <FluentClipboardTextLtrRegular {...props} />,
    TasksAppFilled: (props) => <FluentTasksAppFilled {...props} />,
    ArrowDownloadRegular: (props) => <FluentArrowDownloadRegular {...props} />,
    SpinnerIosRegular: (props) => <FluentSpinnerIosRegular {...props} />,
  };