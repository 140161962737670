import React from "react";
import moment from "moment";
import AppContext from "./AppContext";
import Menu from "../components/Menu";
import Login from "../components/Login";
import Projects from "../components/projects/Projects";
import ProjectsDetails from "../components/projects/ProjectDetails";
import Tasks from "../components/tasks/Tasks";
import TaskAdd from "../components/tasks/TaskAdd";
import TaskAdded from "../components/tasks/TaskAdded";
import TaskAddedFail from "../components/tasks/TaskAddedFail";
import TaskEditor from "../components/tasks/TaskEditor";
import TaskEditorComments from "../components/tasks/TaskEditorComments";
import TaskReassign from "../components/tasks/TaskReassign";
import TaskReassigned from "../components/tasks/TaskReassigned";
import TaskAction from "../components/tasks/TaskAction";
import TaskActionSaved from "../components/tasks/TaskActionSaved";
import Notifications from "../components/notifications/Notifications";
import Profile from "../components/profile/Profile";
import Error500 from "../components/errors/Error500";
import "bootstrap/dist/css/bootstrap.min.css";
import ApiService from "../services/ApiService";
import FileTransferService from "../services/FileTransferService";
import OfficeSharedService from "../services/OfficeSharedService";
import OfficeWordService from "../services/OfficeWordService";
import TaskEditorSaved from "../components/tasks/TaskEditorSaved";
import OnboardingSplash from "../components/onboarding/OnboardingSplash";
import ProjectsAdd from "../components/projects/ProjectsAdd";
import ProjectsAdded from "../components/projects/ProjectsAdded";
import ProjectsList from "../components/projects/ProjectsList";
import EmailTrackingNotificationMenu from "../components/email-tracking/TaskComponents/EmailTrackingNotificationMenu";
import EmailTrackingTaskMenu from "../components/email-tracking/EmailTrackingTaskMenu";
import EmailTrackingTaskAdd from "../components/email-tracking/EmailTrackingTaskAdd";
import EmailTrackingTaskDetails from "../components/email-tracking/EmailTrackingTaskDetails";
import EmailTrackingTaskDetailsPlaceholder from "../components/email-tracking/EmailTrackingTaskDetailsPlaceholder";
import TaskEdit from "../components/tasks/TaskEdit";
import { notificationMessage, insightNotificationMessage } from "../helpers/HelperMethods";
import { parseEmailHeaders } from "../helpers/mailParserUtils";

export default class AppLegacy extends React.Component {
  reloadOnceCompleted = false;
  state = {
    currentPage: "login",
    currentProject: null,
    userInfo: null,
    fileCheckComplete: true, // this legacy state variable used for documents.
    tokenCheck: true,
    currentTaskName: "",
    currentTaskCreatedById: 0,
    taskAction: "",
    saveDraft: false,
    currentConversationId: 0,
    prevTaskIdInserted: null,
    prevEmailPixelInserted: null,
    taskIdInserted: null,
    emailPixelInserted: null,
    //Flags to control better the intial render
    checkAuthenticationStarted: false,
    checkAuthenticationCompleted: false,
    checkFileStarted: false,
    checkFileCompleted: false, // is new state variable for check file used for outlook logic
    firstRenderStarted: false,
    firstRenderCompleted: false,
    checkTokenCompleted: false,
    getMailboxItemCompleted: false,
    getMailboxItemComposeCompleted: false,
    getEmailParserCompleted: false,
    getEmailParserTypeCompleted: false,
    getInitialEmailTaskCompleted: false,
    composeReFwCheckCompleted: false,
    isSSOLogin: this.context.loginSSO
  };

  static contextType = AppContext;

  // Same function in Email Parser - MVC
  GetBetween = (strSource, strStart, strEnd) => {
    let Start;
    let End;
    if (strSource.includes(strStart) && strSource.includes(strEnd)) {
      Start = strSource.indexOf(strStart, 0) + strStart.length;
      End = strSource.indexOf(strEnd, Start);
      return strSource.substring(Start, End);
    } else {
      return "";
    }
  };

  componentDidMount() {
    console.log("[0] App.js - componentDidMount => Started");
    if (!this.state.isSSOLogin) {
      this.checkToken();
    } else {
      //SSO logic it's implemented on login.js component
    }
    //Note: also if localstorage token it's not valid or doesn't exist, login.js component take care of that scenario
  }

  firstRenderLogic = () => {
    if (!this.state.firstRenderCompleted) {
      if (Office.context.mailbox !== undefined && this.context.officeAppType == "Outlook") {
        let encodingPrefix = this.context.emailTrackingEncodingPrefix;
        let encodingSuffix = this.context.emailTrackingEncodingSuffix;

        console.log("[3] START - Application initialization started for Outlook addin - [firstRenderLogic]...");

        // 1. If this compose and it's reply or FWD => use placeholder component
        // Else => Use taskDetail component
        if (Office.context.mailbox.item.displayReplyForm != undefined) {
          console.log("[3.1] Read mode detected... Not needed check compose type.");
        } else {
          let fetchBodyComposeType = new Promise((resolve, reject) => {
            console.log("[3.1] Compose mode detected. Checking compose type...");
            Office.context.mailbox.item.getComposeTypeAsync(function(asyncResult) {
              if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                resolve(asyncResult.value.composeType);
              } else {
                console.log(asyncResult.error);
                reject(false);
              }
            });
          });

          Promise.all([fetchBodyComposeType])
            .then(async composeTypeResult => {
              if (composeTypeResult[0].error) {
                this.context.taskDetailsPlaceholder = false;
                console.log("composeTypeResult => error");
                console.log(composeTypeResult[0].error);
                this.context.composeType = "";
              } else {
                console.log("composeTypeResult=>" + composeTypeResult[0]);
                this.context.composeType = composeTypeResult[0]; // value here should be => reply, forward or newMail
                this.context.taskDetailsPlaceholder = true;
              }
              console.log(this.context.taskDetailsPlaceholder);
            })
            .catch(error => {
              console.log("taskDetailsPlaceholder error");
              console.log(error);
              this.context.composeType = "";
            });
        }

        // 2. We read the email body looking for taskId or Pixels
        let fetchBodyText = new Promise((resolve, reject) => {
          console.log("[3.2] Searching taskId and/or pixel on the current email body...");
          Office.context.mailbox.item.body.getAsync("text", asyncResult => {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
              resolve(asyncResult.value);
            } else {
              console.log(asyncResult.error);
              reject(asyncResult.error);
            }
          });
        });

        Promise.all([fetchBodyText])
          .then(async bodyObject => {
            if (bodyObject[0] && bodyObject[0].length > 0) {
              const bodyData = bodyObject[0];
              const taskIdInserted = this.GetBetween(bodyData, encodingPrefix, encodingSuffix);
              const emailPixelInserted = this.GetBetween(bodyData, "/api/email/", encodingPrefix);

              this.context.prevTaskIdInserted = this.context.taskIdInserted;

              this.context.taskIdInserted = taskIdInserted;
              this.context.emailPixelInserted = emailPixelInserted;
              console.log("TaskIdInserted: " + taskIdInserted);
              console.log("EmailPixelInserted: " + emailPixelInserted);
            } else {
              this.context.prevTaskIdInserted = this.context.taskIdInserted;

              this.context.taskIdInserted = "";
              this.context.emailPixelInserted = "";
              console.log("No taskId or Pixel found...");
            }

            console.log("[3.4] Getting current mailboxItem (email)...");
            this.getMailboxItem();
            if (!this.state.getEmailParserCompleted) this.getEmailParser();

            //displayReplyAllForm
            if (Office.context.mailbox.item.displayReplyForm != undefined) {
              // Read Mode
              console.log("[3.2] Outlook -> Read Mode");
              this.context.outlookComposeMode = false;

              console.log("[3.3] Adding getMailboxItem handler...");
              Office.context.mailbox?.removeHandlerAsync(Office.EventType.ItemChanged, this.getMailboxItem);
              Office.context.mailbox?.addHandlerAsync(Office.EventType.ItemChanged, this.getMailboxItem);
            } else {
              // Compose Mode
              console.log("[3.5] Outlook -> Compose Mode");
              this.context.outlookComposeMode = true;
              this.context.emailRecipientsChanged = false;

              if (!(this.context.composeType == "reply" || this.context.composeType == "forward")) {
                this.context.userLogin = true;
                this.onLoginChange(true); // This function takes cares of redirecting
              }

              console.log("[3.6.1] Adding getMailboxItemCompose handler...");
              Office.context.mailbox?.removeHandlerAsync(Office.EventType.ItemChanged, this.getMailboxItemCompose);
              Office.context.mailbox?.addHandlerAsync(Office.EventType.ItemChanged, this.getMailboxItemCompose);

              console.log("[3.6.2] Adding getRecipientsChanged handler...");
              Office.context.mailbox?.item?.removeHandlerAsync(
                Office.EventType.RecipientsChanged,
                this.getRecipientsChanged
              );
              Office.context.mailbox?.item?.addHandlerAsync(
                Office.EventType.RecipientsChanged,
                this.getRecipientsChanged
              );
            }
            console.log("[3] END - firstRenderLogic completed...");
            this.setState({
              firstRenderCompleted: true,
              taskIdInserted: this.context.taskIdInserted
            });
          })
          .catch(error => {
            console.log("[3] END - firstRenderLogic completed. Some errors detected");
            console.log(error);
            this.setState({ firstRenderCompleted: true });
          });
      } else {
        console.log("[3] START - Application initialization started for Word/PPT addin - [firstRenderLogic]...");
        this.checkToken();
        console.log("[3] END - firstRenderLogic completed...");
        this.setState({ firstRenderCompleted: true });
      }
    }
  };

  getAllCustomAttributes() {
    try {
      Office.context.mailbox.item.loadCustomPropertiesAsync(asyncResult => {
        _customProps = asyncResult.value;
        var dictionary = _customProps.getAll();
        console.log("Custom Attributes dictionary 0=>");
        console.log(dictionary);
      });
    } catch (error) {
      console.log("getAllCustomAttributes error", error);
    }
  }

  setLinkedEmailHeaders = async refreshAddinFlag => {
    try {
      let fetchBCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.bcc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            console.log(asyncResult.error);
            reject(null);
          }
        });
      });

      Promise.all([fetchBCCRecipients])
        .then(async bccRecipientsObject => {
          let bccRecipients = bccRecipientsObject[0];
          console.log("bccRecipients =>", bccRecipients);

          if (Office.context?.mailbox?.item) {
            Office.context?.mailbox?.item?.loadCustomPropertiesAsync(result => {
              if (result.status === Office.AsyncResultStatus.Succeeded) {
                let customProps = result.value;
                let dictionary = customProps.getAll();

                // refreshAddin === "true" means that reply all or forward button was clicked
                if (dictionary?.refreshAddin && dictionary.refreshAddin === "true") {
                  try {
                    if (!refreshAddinFlag) customProps.set("refreshAddin", "false");
                    customProps.set(
                      `apiEndpoint`,
                      `${this.context.apiEndpoint ? this.context.apiEndpoint.toString() : ""}`
                    );
                    customProps.set(
                      "emailParser",
                      `${this.context.emailParser ? this.context.emailParser.toString() : ""}`
                    );
                    customProps.set(`hostName`, `${this.context.hostName ? this.context.hostName.toString() : ""}`);
                    customProps.set(`platform`, `${this.context.platform ? this.context.platform.toString() : ""}`);
                    customProps.set(`emailLinked`, `true`);
                    customProps.set(
                      `createButton`,
                      `${this.context.enableCreateButton ? this.context.enableCreateButton.toString() : "false"}`
                    );
                    customProps.set(
                      `taskId`,
                      `${this.context.taskIdInserted ? this.context.taskIdInserted.toString() : ""}`
                    );
                    customProps.set(
                      `taskPixel`,
                      `${this.context.emailPixelInserted ? this.context.emailPixelInserted.toString() : ""}`
                    );
                    customProps.set(
                      `bccRecipients`,
                      `${encodeURI(JSON.stringify(bccRecipients ? bccRecipients : ""))}`
                    );
                    customProps.saveAsync(saveAsyncResult => {
                      if (saveAsyncResult.status == Office.AsyncResultStatus.Failed) {
                        console.log("customProps => saveAsync error");
                        console.log(saveAsyncResult.error);
                      } else {
                        console.log("customProps => saveAsync successfully");
                        console.log("set emailParser => ", this.context.emailParser.toString());
                      }

                      this.context.composeReFwCheckCompleted = true;
                      this.setState({ composeReFwCheckCompleted: true });
                    });
                  } catch (error) {
                    console.log("customProps.set [error]", error);
                  }

                  try {
                    Office.context.mailbox.item.internetHeaders.setAsync(
                      {
                        "x-triyo-sender-hostname": this.context.hostName ? this.context.hostName.toString() : "",
                        "x-triyo-sender-platform": this.context.platform ? this.context.platform.toString() : "",
                        "x-triyo-email-parser": this.context.emailParser ? this.context.emailParser.toString() : "",
                        "x-triyo-email-linked": "true",
                        "x-triyo-task-id": this.context.taskIdInserted ? this.context.taskIdInserted.toString() : "",
                        "x-triyo-task-pixel": this.context.emailPixelInserted
                          ? this.context.emailPixelInserted.toString()
                          : "",
                        "x-triyo-task-bcc-recipients": bccRecipients ? encodeURI(JSON.stringify(bccRecipients)) : "",
                        "x-triyo-create-button": this.context.enableCreateButton
                          ? this.context.enableCreateButton.toString()
                          : "false"
                      },
                      headersAsyncResult => {
                        if (headersAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                          console.log("Successfully set headers");
                          // Just checking values. This could be removed after CR it's approved
                          Office.context.mailbox.item.internetHeaders.getAsync(
                            [
                              "x-triyo-sender-hostname",
                              "x-triyo-sender-platform",
                              "x-triyo-email-parser",
                              "x-triyo-email-linked",
                              "x-triyo-task-id",
                              "x-triyo-task-pixel",
                              "x-triyo-task-bcc-recipients"
                            ],
                            getHeadersAsyncResult => {
                              if (getHeadersAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                                console.log("getHeadersAsyncResult =>");
                                console.log(getHeadersAsyncResult.value);
                              } else {
                                console.log("getHeadersAsyncResult.error =>");
                                console.log(getHeadersAsyncResult.error);
                              }
                            }
                          );
                        } else {
                          console.log("Error setting headers: " + JSON.stringify(headersAsyncResult.error));
                        }
                      }
                    );
                  } catch (error) {
                    console.log("internetHeaders.setAsync [error]", error);
                  }
                }
              } else {
                console.log("loadCustomPropertiesAsync => error");
                console.log(result.error);

                //If we detect Office Js API error it's better to close the addin. The user could open manually again.
                if (!isOfficeOnline) Office.context.ui.closeContainer();

                this.context.composeReFwCheckCompleted = true;
                this.setState({ composeReFwCheckCompleted: true });
              }
            });
          }
        })
        .catch(error => {
          console.log(
            "Error fetching: [fetchSubject, fetchFrom, fetchToRecipients, fetchCCRecipients, fetchBCCRecipients]=>"
          );
          console.log(error);
        });
    } catch (error) {
      console.log(
        "Error setting headers: [fetchSubject, fetchFrom, fetchToRecipients, fetchCCRecipients, fetchBCCRecipients]"
      );
      console.log(error);
    }
  };

  setBccEmailHeaders = async () => {
    try {
      let fetchBCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.bcc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            console.log(asyncResult.error);
            reject(null);
          }
        });
      });

      Promise.all([fetchBCCRecipients])
        .then(async bccObject => {
          console.log(bccObject);
          if (bccObject[0]) {
            let bccRecipients = bccObject[0];
            console.log("bccRecipients =>", bccRecipients);
            if (Office.context?.mailbox?.item) {
              Office.context?.mailbox?.item?.loadCustomPropertiesAsync(result => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                  let customProps = result.value;
                  let dictionary = customProps.getAll();

                  if (dictionary?.emailLinked === "true") {
                    try {
                      customProps.set(
                        `bccRecipients`,
                        `${encodeURI(JSON.stringify(bccRecipients ? bccRecipients : ""))}`
                      );
                      customProps.saveAsync(saveAsyncResult => {
                        if (saveAsyncResult.status == Office.AsyncResultStatus.Failed) {
                          console.log("customProps [bccRecipients] => saveAsync error");
                          console.log(saveAsyncResult.error);
                        } else {
                          console.log("customProps [bccRecipients] => saveAsync successfully");
                        }
                      });
                    } catch (error) {
                      console.log("customProps.set [bccRecipients] => error");
                      console.log(error);
                    }

                    try {
                      Office.context.mailbox.item.internetHeaders.setAsync(
                        {
                          "x-triyo-task-bcc-recipients": bccRecipients ? encodeURI(JSON.stringify(bccRecipients)) : ""
                        },
                        headersAsyncResult => {
                          if (headersAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                            console.log("Successfully set bccRecipients header");
                            // Just checking values. This could be removed after CR it's approved
                            Office.context.mailbox.item.internetHeaders.getAsync(
                              ["x-triyo-task-bcc-recipients"],
                              getHeadersAsyncResult => {
                                // LaunchEvent informs here if the button Send was pressed and Task creation should start
                                if (getHeadersAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                                  console.log("getHeadersAsyncResult =>");
                                  console.log(getHeadersAsyncResult.value);
                                } else {
                                  console.log("getHeadersAsyncResult.error =>");
                                  console.log(getHeadersAsyncResult.error);
                                }
                              }
                            );
                          } else {
                            console.log("Error setting headers: " + JSON.stringify(headersAsyncResult.error));
                          }
                        }
                      );
                    } catch (error) {
                      console.log("internetHeaders.setAsync [error]");
                      console.log(error);
                    }
                  }
                }
              });
            }
          } else {
            console.log("bccRecipients Header error.");
          }
        })
        .catch(error => {
          console.log("Error fetching [fetchBCCRecipients]=>");
          console.log(error);
        });
    } catch (error) {
      console.log("Error setting headers [bccRecipients]:");
      console.log(error);
    }
  };

  //saving Roaming Settings for email clicked Read Mode
  saveLastEmailClicked = () => {
    if (Office.context.mailbox.item?.itemId) {
      let lastEmailSubject = Office.context.mailbox.item?.subject ? Office.context.mailbox.item.subject : "";
      let lastEmailTaskId = this.context.taskIdInserted ? this.context.taskIdInserted : "";
      let lastEmailPixel = this.context.emailPixelInserted ? this.context.emailPixelInserted : "";
      let lastEmailItemId = Office.context.mailbox.item?.itemId ? Office.context.mailbox.item.itemId : "";
      let lastEmailConversationId = Office.context.mailbox.item?.conversationId
        ? Office.context.mailbox.item.conversationId
        : "";
      let lastEmailDateCreated = Office.context.mailbox.item?.dateTimeCreated
        ? Office.context.mailbox.item.dateTimeCreated
        : "";

      Office.context.roamingSettings.set("lastEmailSubject", lastEmailSubject);
      Office.context.roamingSettings.set("lastEmailTaskId", lastEmailTaskId);
      Office.context.roamingSettings.set("lastEmailPixel", lastEmailPixel);
      Office.context.roamingSettings.set("lastEmailItemId", lastEmailItemId);
      Office.context.roamingSettings.set("lastEmailConversationId", lastEmailConversationId);
      Office.context.roamingSettings.set("lastEmailDateCreated", lastEmailDateCreated);

      try {
        // Save settings in the mailbox to make it available in future sessions.
        Office.context.roamingSettings.saveAsync(function(result) {
          if (result.status !== Office.AsyncResultStatus.Succeeded) {
            console.error(`Action failed with message ${result.error.message}`);
          } else {
            console.log(`Roaming Settings saved with status: ${result.status}`);
          }
        });
      } catch (error) {
        console.log("saveLastEmailClicked error", error);
      }
    }
  };

  getEmailTaskBySujectCompose = async () => {
    let fetchGetSubject = new Promise((resolve, reject) => {
      Office.context.mailbox.item.subject.getAsync(asyncResult => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          resolve(asyncResult.value);
        } else {
          console.log("Error getting subject =>", asyncResult.error);
          reject("");
        }
      });
    });

    Promise.all([fetchGetSubject])
      .then(async subjectObject => {
        if (subjectObject[0]?.length > 0) {
          await ApiService.getEmailTaskBySubjectCompose(subjectObject[0], this.context)
            .then(async emailtask => {
              if (emailtask.status === 200) {
                const emailThreadExists = emailtask.data;
                console.log("emailThreadExists => ", emailThreadExists);
                let lastEmailSubjectGet = Office.context.roamingSettings.get("lastEmailSubject");
                let lastEmailTaskIdGet = Office.context.roamingSettings.get("lastEmailTaskId");
                let lastEmailPixelGet = Office.context.roamingSettings.get("lastEmailPixel");

                if (emailThreadExists?.length === 1) {
                  this.context.prevTaskIdInserted = this.context.taskIdInserted;
                  this.context.taskIdInserted = emailThreadExists[0].Id;
                  this.context.emailPixelInserted = emailThreadExists[0].EmailTrackerId
                    ? emailThreadExists[0].EmailTrackerId
                    : "";
                  return emailThreadExists[0].Id;
                } else if (emailThreadExists?.length > 1) {
                  if (lastEmailTaskIdGet?.length > 0) {
                    this.context.prevTaskIdInserted = this.context.taskIdInserted;
                    this.context.taskIdInserted = lastEmailTaskIdGet;
                    this.context.emailPixelInserted = lastEmailPixelGet;
                    return lastEmailPixelGet;
                  } else {
                    return "";
                  }
                } else {
                  if (
                    lastEmailTaskIdGet?.length > 0 &&
                    subjectObject[0].toLowerCase().includes(lastEmailSubjectGet.toLowerCase())
                  ) {
                    this.context.prevTaskIdInserted = this.context.taskIdInserted;
                    this.context.taskIdInserted = lastEmailTaskIdGet;
                    this.context.emailPixelInserted = lastEmailPixelGet;
                    return lastEmailPixelGet;
                  } else {
                    return "";
                  }
                }
              }
            })
            .catch(error => {
              console.log("getEmailTaskBySubjectCompose [error] =>", error);
              return "";
            });
        }
      })
      .catch(error => {
        console.log("Error getting subject =>");
        console.log(error);
        return "";
      });
  };

  componentDidUpdate() {
    //[1] Go to login.js to check Authentication (We access here only once)
    //
    // Note#1:
    // If the user is not authenticated -> stay in login.js component until it's authenticated successfully
    //
    // Note#2:
    // this.context.tokenReady => works for cases when there is no token available and login.js retrieves that from
    //                            a normal login with username and password or just SSO login.

    const isOfficeOnline = this.context.hostName === "OutlookWebApp" || this.context.platform === "OfficeOnline";
    let intervalCheckToken;
    //This means token was successfully since beggining
    if (this.state.checkAuthenticationCompleted) {
      //console.log("Token available => Authentication completed")
      clearInterval(intervalCheckToken);

      //Here wait until login.js authenticates the user
    } else if (!this.state.checkAuthenticationStarted && !this.state.checkAuthenticationCompleted) {
      this.setState({ checkAuthenticationStarted: true }); // This ensures we access here only once
      if (!this.state.firstRenderCompleted) {
        if (!this.context.tokenReady) {
          console.log("componentDidUpdate => Checking in App.js SSO Token (Start)");
          intervalCheckToken = setInterval(() => {
            console.log("this.context.tokenReady", this.context.tokenReady);
            if (this.context.tokenReady) {
              this.setState({ checkAuthenticationCompleted: true });
              clearInterval(intervalCheckToken);
            } else {
              if (this.state.isSSOLogin) {
                console.log("Waiting for SSO token to be retrieved from login.js");
              } else {
                console.log("Waiting for normal token to be retrieved from login.js");
              }
            }
          }, 4000);
        } else if (this.context.tokenReady == true) {
          console.log("componentDidUpdate => Checking in App.js Token state in login.js");
          console.log("this.context.tokenReady", this.context.tokenReady);
          if (this.state.isSSOLogin) {
            console.log("SSO token check => valid -> Now we can continue logic...");
          } else {
            console.log("Token check => valid -> Now we can continue...");
          }

          this.setState({ checkAuthenticationCompleted: true });
        }
      }
    }

    //[2] checkFile (We access here only once) - to continue we need authentication to be satisfactory
    if (!this.state.checkFileStarted && !this.state.checkFileCompleted && this.state.checkAuthenticationCompleted) {
      this.setState({ checkFileStarted: true }); // This ensures we access here only once
      this.checkFile(); // Check file setup initial context variables. e.g. platform, OfficeContext, etc.
    }

    //[3] firstRenderLogic (We access here only once) - to continue OfficeContext needs to be satisfactory on checkFile()
    if (!this.state.firstRenderStarted && !this.state.firstRenderCompleted && this.state.checkFileCompleted) {
      this.setState({ firstRenderStarted: true }); // This ensures we access here only once
      this.firstRenderLogic();
    }

    //[4] If firstRender it's completed, now we can continue
    if (this.context.officeAppType == "Outlook" && this.state.firstRenderCompleted) {
      console.log("[4] componentDidUpdate. Looking for state changes...");
      // *** The state flags "completed" gives some sequential order to the first render of the application *** //

      // This means that first part of validations where completed. And taskId and emailPixel at least an empty string "" or have a value
      const firstValidationsCompleted =
        this.state.firstRenderCompleted && this.state.getMailboxItemCompleted && this.state.getEmailParserCompleted;

      //  this.context.firstValidationsCompleted ensures we don't render first part of validations again after states are hard-refresh.
      if (firstValidationsCompleted) {
        if (!this.context.firstValidationsCompleted) {
          console.log("[4.1] First validations completed...");
          this.context.firstValidationsCompleted = true;
        }
      }

      // This means that second part of validations where completed.
      const secondValidationsCompleted =
        this.context.firstValidationsCompleted &&
        (this.context.composeType == "newEmail" ||
        (this.context.composeType != "newEmail" &&
          this.context.composeType != "reply" &&
          this.context.composeType != "forward") || // This means it's read mode
          ((this.context.composeType == "reply" || this.context.composeType == "forward") &&
            this.state.getInitialEmailTaskCompleted));

      //  this.context.secondValidationsCompleted ensures we don't render second part of validations again after states are hard-refresh.
      if (secondValidationsCompleted) {
        if (!this.context.secondValidationsCompleted) {
          console.log("[4.2] Second validations completed...");
          this.context.secondValidationsCompleted = true;
        }
      }

      // ** STEP 4: Failsafe validation to check again existence of any pixel or taskId **/
      // We run this only for the compose modes RE/FWD
      // Note#1: This failsafe it's for PWA. If the conversastionid it's linked to a task we look for the pixel and task id as well
      // Note#2: In PWA body it's blank for reply and forward scenarios
      if (
        this.context.firstValidationsCompleted &&
        !this.context.secondValidationsCompleted &&
        !this.state.getInitialEmailTaskCompleted
      ) {
        // Failsafe validation to access to getEmailTask and obtain email pixel and task id for existing tasks
        const accessPWAFailsafeValidation =
          this.context.firstValidationsCompleted &&
          (this.context.taskIdInserted === null || this.context.taskIdInserted === "") &&
          (this.context.emailPixelInserted === null || this.context.emailPixelInserted === "");

        // This means that email pixel and task id exists and getEmailTask failsafe validation it's not needed
        // Note: usually this happens on Desktop mode when the email pixel and task id are obtained from the body on componentDidMount
        const accessPWAFailsafeNotNeeded =
          this.context.firstValidationsCompleted &&
          this.context.taskIdInserted &&
          this.context.taskIdInserted.length > 0 && // this means task Id exists
          this.context.emailPixelInserted &&
          this.context.emailPixelInserted.length > 0; // this means pixel exists

        if (
          accessPWAFailsafeValidation &&
          (this.context.composeType == "reply" || this.context.composeType == "forward")
        ) {
          console.log("Starting PWAFailsafeValidation...");
          console.log("this.context.taskIdInserted =>");
          console.log(this.context.taskIdInserted);
          console.log("this.context.emailPixelInserted =>");
          console.log(this.context.emailPixelInserted);

          let getTaskIdFromSubject = "";
          if (isOfficeOnline) getTaskIdFromSubject = this.getEmailTaskBySujectCompose(); //This failsafe is only for PWA

          const emailConversationId = Office.context.mailbox.item?.conversationId
            ? Office.context.mailbox.item.conversationId
            : "";
          console.log("emailConversationId =>");
          console.log(emailConversationId);
          if (emailConversationId && getTaskIdFromSubject == "") {
            ApiService.getEmailTask(emailConversationId, this.context)
              .then(emailtask => {
                if (emailtask.status === 200) {
                  const emailtaskData = emailtask.data;
                  if (emailtaskData) {
                    console.log("getEmailTask =>");
                    console.log(emailtaskData);
                    if (
                      (this.context.composeType == "reply" ||
                      this.context.composeType == "forward") &&
                      emailtaskData.Id
                      ) {
                        this.context.prevTaskIdInserted = this.context.taskIdInserted;
                        this.context.taskIdInserted = "";
                        this.context.emailPixelInserted = "";
                        this.context.existingLegacyEmailTask = emailtaskData;
                    } else {
                      this.context.prevTaskIdInserted = this.context.taskIdInserted;
                      this.context.taskIdInserted = emailtaskData.Id;
                      this.context.emailPixelInserted = emailtaskData.EmailTrackerId;
                    }
                  } else {
                    console.log("getEmailTask => no results found");

                    this.context.prevTaskIdInserted = this.context.taskIdInserted;
                    this.context.taskIdInserted = "";
                    this.context.emailPixelInserted = "";
                  }
                  if (!this.state.getInitialEmailTaskCompleted) {
                    this.setState({
                      getInitialEmailTaskCompleted: true,
                      taskIdInserted: this.context.taskIdInserted
                    });
                  }
                }
              })
              .catch(error => {
                console.log("getEmailTask => Error");
                console.log(error);

                this.context.prevTaskIdInserted = this.context.taskIdInserted;

                this.context.taskIdInserted = "";
                this.context.emailPixelInserted = "";
                if (!this.state.getInitialEmailTaskCompleted) {
                  this.setState({
                    getInitialEmailTaskCompleted: true,
                    taskIdInserted: this.context.taskIdInserted
                  });
                }
              });
          } else {
            this.context.taskIdInserted = "";
            this.context.emailPixelInserted = "";
            this.setState({
              getInitialEmailTaskCompleted: true,
              taskIdInserted: this.context.taskIdInserted
            });
          }
        } else if (accessPWAFailsafeNotNeeded) {
          this.setState({
            getInitialEmailTaskCompleted: true
          });
        }
      }

      // ** STEP 2: All the initial validations completed. Now we can continue with the rest of the logic **/
      if (
        this.context.firstValidationsCompleted &&
        this.context.secondValidationsCompleted &&
        this.context.taskIdInserted !== null &&
        this.context.emailPixelInserted !== null
      ) {
        let encodingPrefix = this.context.emailTrackingEncodingPrefix;
        let encodingSuffix = this.context.emailTrackingEncodingSuffix;
        let itemId;
        let conversationId;
        let currentEmailSubject;
        let currItemId;
        let prevItemId;

        console.log("[4.3] componentDidUpdate. Looking for state changes => Executing rest of the Logic");

        //2.1.Compose mode
        if (this.context.outlookComposeMode) {
          // 2.1.1. Logic for PWA and Outlook web
          // Note: For reply / FWD form. Because the Compose page doesn't render again properly. Just came do componentDidUpdate
          if (this.context.composeType == "reply" || this.context.composeType == "forward") {
            if (!this.context.composeReFwCheckCompleted) {
              //flag to don't access again
              this.setLinkedEmailHeaders(true);
            }
          }

          // 2.1.2. Rest of compose mode Logic
          this.context.currEmail = Office.context.mailbox;
          this.context.currEmailRecipients = Office.context.mailbox.item;

          // Compose mode reply and forward needs to validate itemid undefined
          itemId = Office.context.mailbox.item?.itemId ? Office.context.mailbox.item.itemId : "";
          console.log("itemid =>", itemId);

          conversationId = Office.context.mailbox.item?.conversationId
            ? Office.context.mailbox.item.conversationId
            : "";

          console.log("Current Email Composed-> ");
          console.log(this.context.currEmailRecipients);
          this.context.emailRecipientsChanged = true;
          //2.2. Read Mode
        } else {
          this.context.currEmail = Office.context.mailbox;

          itemId = Office.context.mailbox.item?.itemId ? Office.context.mailbox.item.itemId : "";
          conversationId = Office.context.mailbox.item?.conversationId
            ? Office.context.mailbox.item.conversationId
            : "";

          currentEmailSubject = this.context.currEmail?.item?.subject; // this is undefined if this is Reply/Fwd-Compose mode
          currItemId = this.context.currEmail?.item?.itemId;
          prevItemId = this.context.prevEmail?.item?.itemId;
        }

        let fetchHeaders = new Promise((resolve, reject) => {
          try {
            if (Office.context.mailbox.item) {
              Office.context.mailbox.item.getAllInternetHeadersAsync((getHeadersAsyncResult2) => {
                if (getHeadersAsyncResult2.status === Office.AsyncResultStatus.Succeeded) {
                  console.log("[getAllInternetHeadersAsync] succeeded.");
                  if (getHeadersAsyncResult2.value) {
                    resolve(getHeadersAsyncResult2.value);
                  } else {
                    console.log("Email doesn't contain headers");
                    resolve(null);
                  }
                } else {
                  //This usually happens when the email is in sent folder. We can't access the headers in sent folder with Office Js.
                  console.log("No Headers found or getAllInternetHeadersAsync function is not available.");
                  resolve(null);
                }
              });
            } else {
              //This usually happens when the email is in sent folder. We can't access the headers in sent folder with Office Js.
              console.log("No Headers found or getAllInternetHeadersAsync function is not available.");
              resolve(null);
            }
          } catch (error) {
            console.log("Error in [fetchHeaders]=> ", error);
            reject(error);
          }
        });

        //2.3.Failsafes
        if (currItemId != prevItemId) {
          // Look for existing taskId or email pixel in the email Body
          let fetchBodyText = new Promise((resolve, reject) => {
            Office.context.mailbox.item.body.getAsync("text", asyncResult => {
              if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                resolve(asyncResult.value);
              } else {
                reject(asyncResult.error);
              }
            });
          });

          let fetchCustomProperties = new Promise((resolve, reject) => {
            try {
              if (Office.context.mailbox.item) {
                Office.context.mailbox.item.loadCustomPropertiesAsync((customPropertiesAsyncResult1) => {
                  let _customProps = customPropertiesAsyncResult1.value;
                  let dictionary = _customProps.getAll();
                  console.log("[loadCustomPropertiesAsync] succeeded.");
                  console.log(dictionary);
                  resolve(dictionary);
                });
              } else {
                console.log("No Custom Properties found or loadCustomPropertiesAsync function is not available.");
                resolve(null);
              }
            } catch (error) {
              console.log("Error in [fetchCustomProperties]=> ", error);
              reject(error);
            }
          });

          Promise.all([fetchBodyText, fetchHeaders, fetchCustomProperties]).then(async bodyObject => {
            this.context.taskIdInserted = this.GetBetween(bodyObject[0], encodingPrefix, encodingSuffix);
            this.context.emailPixelInserted = this.GetBetween(bodyObject[0], "/api/email/", encodingPrefix);

            // This is another failsafe, usually helpful for PWA were task id and task pixel are not found in the body.
            // Note: when an email is responded by GMAIL, Hotmail, ICloud or any other email provider different than Outlook it's not guaranteed that the email will have the headers present.
            if (bodyObject[1] && bodyObject[1].length > 0) {
              let emailHeaders = bodyObject[1];
              let emailHeadersParsed = parseEmailHeaders(emailHeaders);

              console.log("emailHeadersParsed => ", emailHeadersParsed);

              if (!this.context.taskIdInserted || this.context.taskIdInserted === "") {
                this.context.taskIdInserted = emailHeadersParsed["x-triyo-task-id"] ?? "";
              }
              console.log("this.context.taskIdInserted => ", this.context.taskIdInserted);
              if (!this.context.emailPixelInserted || this.context.emailPixelInserted === "") {
                this.context.emailPixelInserted = emailHeadersParsed["x-triyo-task-pixel"] ?? "";
              }
              console.log("this.context.emailPixelInserted => ", this.context.emailPixelInserted);

              let lastEmailThreadIndex = emailHeadersParsed["thread-index"] ?? "";
              let lastEmailThreadTopic = emailHeadersParsed["thread-topic"] ?? "";
              let lastEmailCrossTenantId = emailHeadersParsed["x-ms-exchange-crosstenant-id"] ?? "";
              let lastEmailMessageId = emailHeadersParsed["message-id"] ?? "";
              let lastEmailReferences = emailHeadersParsed["references"] ?? "";

              Office.context.roamingSettings.set("lastEmailThreadIndex", lastEmailThreadIndex); //Outlook
              Office.context.roamingSettings.set("lastEmailThreadTopic", lastEmailThreadTopic); //Outlook
              Office.context.roamingSettings.set("lastEmailCrossTenantId", lastEmailCrossTenantId); //Outlook, Icloud
              Office.context.roamingSettings.set("lastEmailMessageId", lastEmailMessageId); //Outlook, Icloud
              Office.context.roamingSettings.set("lastEmailReferences", lastEmailReferences); //Outlook, Icloud

              try {
                // Save settings in the mailbox to make it available in future sessions.
                Office.context.roamingSettings.saveAsync(function(result) {
                  if (result.status !== Office.AsyncResultStatus.Succeeded) {
                    console.error(`Action failed with message ${result.error.message}`);
                  } else {
                    console.log(`Roaming Settings saved with status: ${result.status}`);
                  }
                });
              } catch (error) {
                console.log("Error in [roamingSettings]=> ", error);
              }
            }
            // This failsafe was created for the cases when user clicks any email on the sent folder and there are no headers present. In this case we parse for custom attributes in email object.
            else if (bodyObject[2] && bodyObject[2].length > 0) {
              let myPropTaskId = bodyObject[2].taskId ?? "";
              let myPropEmailPixel = bodyObject[2].taskPixel ?? "";

              if (myPropEmailPixel !== "" || myPropTaskId !== "") {
                if ((myPropTaskId.length > 0 && !this.context.taskIdInserted) || this.context.taskIdInserted === "") {
                  this.context.taskIdInserted = myPropTaskId;
                }
                console.log("this.context.taskIdInserted => ", this.context.taskIdInserted);

                if (
                  (myPropEmailPixel.length > 0 && !this.context.emailPixelInserted) ||
                  this.context.emailPixelInserted === ""
                ) {
                  this.context.emailPixelInserted = myPropEmailPixel;
                }
                console.log("this.context.emailPixelInserted => ", this.context.emailPixelInserted);
              } else {
                // This means custom properties [taskId, taskPixel] are not present in the email object on Sent folder.
                // Here we rely on failsafes above like e.g. [getEmailTaskBySubjectAndDate]
              }
            }

            // Validation for Email tasks created on Compose mode
            // We need to update itemid and conversationid under this scenario
            // => itemid changes because it's a copy of the original item (email)
            // => conversationid is an unique id  thread but is generated after sending email
            if (this.context.currEmail?.item?.conversationId) {
              this.context.currentConversationId = this.context.currEmail.item.conversationId;
              if (this.state.currentConversationId !== this.context.currentConversationId) {
                this.setState({ currentConversationId: this.context.currentConversationId });
              }

              // Failsafes for the different cases
              // Case # 1: Email has a taskId inserted
              // Works for Read, and Compose RE or FWD task emails
              if (this.context.taskIdInserted) {
                ApiService.getRegularTask(parseInt(this.context.taskIdInserted), this.context)
                  .then(async emailtask => {
                    if (emailtask.status === 200) {
                      const emailThreadExists = emailtask.data;
                      if (emailThreadExists) await this.updateEmailTask(emailThreadExists, itemId, conversationId);
                    }
                  })
                  .catch(error => {
                    console.log("getRegularTask [error] =>", error);
                  });
                // Case # 2: Email has a email pixel inserted and belongs to a task
                // Works for Read, and Compose RE or FWD task emails
              } else if (this.context.emailPixelInserted) {
                ApiService.getEmailTaskByPixel(this.context.emailPixelInserted, this.context)
                  .then(async emailtask => {
                    if (emailtask.status === 200) {
                      const emailThreadExists = emailtask.data;
                      if (emailThreadExists) await this.updateEmailTask(emailThreadExists, itemId, conversationId);
                    }
                  })
                  .catch(error => {
                    console.log("getEmailTaskByPixel [error] =>", error);
                  });
                // Case # 3: User clicked the email before any reply - Works for Read mode only
                // Note: This works if the users didn't replied the email before. Works for emails not responded with same subject
              } else if (currentEmailSubject) {
                let creationTimeItem1 = this.context.currEmail?.initialData?.dateTimeCreated;
                let creationTimeItem2 = this.context.currEmail?.item?.dateTimeCreated;

                if (creationTimeItem1 || creationTimeItem2) {
                  let taskObj = {
                    Subject: currentEmailSubject,
                    CreatedDate: creationTimeItem1 ? creationTimeItem1 : creationTimeItem2
                  };
                  ApiService.getEmailTaskBySubjectAndDate(taskObj, this.context)
                    .then(async emailtask => {
                      if (emailtask.status === 200) {
                        const emailThreadExists = emailtask.data;
                        if (emailThreadExists) await this.updateEmailTask(emailThreadExists, itemId, conversationId);
                      }
                    })
                    .catch(error => {
                      console.log("getEmailTaskBySubjectAndDate [error] =>", error);
                    });
                } else {
                  ApiService.getEmailTaskBySubject(currentEmailSubject, this.context)
                    .then(async emailtask => {
                      if (emailtask.status === 200) {
                        const emailThreadExists = emailtask.data;
                        if (emailThreadExists) await this.updateEmailTask(emailThreadExists, itemId, conversationId);
                      }
                    })
                    .catch(error => {
                      console.log("getEmailTaskBySubject [error] =>", error);
                    });
                }
              } else if (!currentEmailSubject || currentEmailSubject === "") {
                // Case # 4: Email corresponds to a thread and has the RE: or FWD: in the subject
                // to be developed once Mac improvements are merged into main
                // Note: pretty rare case. It's handy when pixel or taskId was erased
                // This still in development
                let fetchGetSubject = new Promise((resolve, reject) => {
                  Office.context.mailbox.item.subject.getAsync(asyncResult => {
                    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                      resolve(asyncResult.value);
                    } else {
                      reject(asyncResult.error);
                    }
                  });
                });

                Promise.all([fetchGetSubject])
                  .then(subjectObject => {
                    // if (subjectObject[0]) {
                    //   currentEmailSubject = this.context.currEmail?.item?.subject
                    // } else {
                    //   currentEmailSubject = "";
                    // }
                  })
                  .catch(error => {
                    console.log("Error getting subject =>");
                    console.log(error);
                    //currentEmailSubject = "";
                  });
              }
              // Case # 5: Email has a email header with the pixel or taskid on it
              // to be developed once Mac improvements are merged into main

              // Case # 6: Test if custom attributes are visible also in the reply mode once mac improvements are merged into main
            } else {
              this.context.currentConversationId = 0;
            }
          });
        }

        this.saveLastEmailClicked();

        //2.4.Function for Adding missing audit trail to task
        if (!this.context.outlookComposeMode === true) {
          let fetchBodyHtml = new Promise((resolve, reject) => {
            Office.context.mailbox.item.body.getAsync("html", asyncResult => {
              if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                resolve(asyncResult.value);
              } else {
                reject(asyncResult.error);
              }
            });
          });

          console.log("Current Email Selected-> ");
          console.log(this.context.currEmail);

          if ((currentEmailSubject?.toLowerCase().includes("deliver") && currentEmailSubject?.toLowerCase().includes("failure")) || (
            currentEmailSubject?.toLowerCase().includes("undeliver") && currentEmailSubject?.toLowerCase().includes("mail")))  {
            let data = {
              To: this.context.currEmail.item.to[0].emailAddress,
              From: this.context.currEmail.item.from.emailAddress,
              CreatedDate: this.context.currEmail.item.dateTimeCreated,
              Subject: this.context.currEmail.item.attachments[0].name
            };
            ApiService.addEmailAuditTrailUndeliverable(data, this.context).then(async result => {
              if (result.status === 200) {
                console.log("Audit Trail Logged - Undeliverable");
              }
            });
          }

          let fetchBodyText = new Promise((resolve, reject) => {
            Office.context.mailbox.item.body.getAsync("text", asyncResult => {
              if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                resolve(asyncResult.value);
              } else {
                reject(asyncResult.error);
              }
            });
          });

          let fetchSingleAttachment = function(attachment, index, count) {
            return new Promise((resolve, reject) => {
              Office.context.mailbox.item.getAttachmentContentAsync(attachment.id, asyncResult => {
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                  attachment.Base64String = asyncResult.value.content;
                  attachment.index = index;
                  resolve(attachment);
                } else {
                  reject(asyncResult.error);
                }
              });
            });
          };

          let fetchAttachment = new Promise((resolve, reject) => {
            let att = [];
            let count = 0;
            Office.context.mailbox.item.attachments.forEach((attachment, index) => {
              let value = fetchSingleAttachment(attachment, index, count);
              value.then(result => {
                att.push(result);
                count++;
                if (count == Office.context.mailbox.item.attachments.length) resolve(att);
              });
            });
            if (Office.context.mailbox.item.attachments.length == 0) resolve(att);
          });

          if (currItemId != prevItemId) {
            let emailItem = {
              item: this.context.currEmail.item
            };
            this.context.prevEmail = emailItem;
            try {
              fetchBodyHtml.then(htmlBody => {
                console.log("htmlBody->");
                fetchHeaders.then(htmlHeader => {
                  fetchBodyText.then(textBody => {
                  console.log("textBody->");
                  fetchAttachment.then(attachmentsResult => {
                    let attachmentMap = attachmentsResult.map(aR => {
                      return {
                        Id: aR.id,
                        FileName: aR.name,
                        Base64String: aR.Base64String,
                        FileContentType: aR.contentType
                      };
                    });
                    console.log("attachmentMap->");
                    htmlBody = encodeURIComponent(JSON.stringify(htmlBody));
                    let ccEmails = [];
                    Office?.context?.mailbox?.item?.cc.forEach(cc => {
                      ccEmails.push(cc?.emailAddress);
                    });
                    console.log("ccEmails->");
                    let headersObject = {
                      Sent: this.context.currEmail.item.dateTimeCreated,
                      From: this.context.currEmail.item.from,
                      To: this.context.currEmail.item.to,
                      Cc: this.context.currEmail.item.cc,
                      Bcc: this.context.currEmail.item.bcc,
                      Subject: this.context.currEmail.item.subject
                    };

                    let data = {
                      FromUserEmail: this.context.currEmail.item.from.emailAddress,
                      CreatedDate: this.context.currEmail.item.dateTimeCreated,
                      Attachments: attachmentMap,
                      HtmlBody: htmlBody,
                      TextBody: textBody,
                      CCEmailAddress: ccEmails,
                      Subject: this.context.currEmail.item.subject,
                      EmailHeaders: encodeURI(JSON.stringify(headersObject)),
                      InternalOrg: false
                    };
                    let parsedHeaders = parseEmailHeaders(htmlHeader);
                    data.InternalOrg = parsedHeaders["x-ms-exchange-organization-authas"] == "Internal";
                    ApiService.addEmailAuditTrail(data, this.context).then(async result => {
                      if (result.status === 200) {
                        console.log("Audit Trail Logged");
                      }
                    });
                  });
                  });
              });
              });
            } catch (e) {
              console.log("Error in adding audit trail");
              console.log(e);
            }
          }
        } else {
          if (
            this.context.taskIdInserted != this.context.taskIdInsertedRedirected ||
            (this.context.taskIdInserted == "" && this.context.taskIdInsertedRedirected == "")
          ) {
            if (!this.context.showExistingLegacyEmailTask) {
              this.redirectComposeWindow(); // We redirect. And redirect takes care if redirect happens if is not already on that page.
            }
          }
        }
      }
    }
  }

  checkUndeliverableEmail = () => {};

  redirectComposeWindow = () => {
    console.log("redirectComposeWindow =>");
    if (this.context.taskIdInserted || this.context.emailPixelInserted) {
      if (this.context.taskDetailsPlaceholder === true) {
        this.context.userLogin = true;
        this.context.currentTaskId = parseInt(this.context.taskIdInserted);

        if (this.state.currentPage !== "taskEditor") {
          notificationMessage("This email will be tracked as a new Task on TRIYO when you click Send |");
          this.setPage("taskEditor");
        }
      } else {
        if (this.state.currentPage !== "emailTrackingTaskDetails") {
          this.setPage("emailTrackingTaskDetails");
        }
      }
    } else {
      if (!this.context.showExistingLegacyEmailTask) {
        if (this.state.currentPage !== "emailTrackingTaskMenu") {
          this.setPage("emailTrackingTaskMenu");
        }
      }
    }
    this.context.taskIdInsertedRedirected = this.context.taskIdInserted;
  };

  updateEmailTask = async (emailThreadExists, itemId, conversationId) => {
    const object = {
      Id: emailThreadExists.Id,
      EmailId: itemId ? itemId : "",
      EmailConversationId: conversationId ? conversationId : ""
    };

    if (
      emailThreadExists.EmailConversationId == emailThreadExists.EmailId ||
      !emailThreadExists.EmailId ||
      !emailThreadExists.EmailConversationId
    ) {
      await ApiService.updateEmailTaskComposeMode(object, this.context)
        .then(result => {
          if (result.status === 200) {
            console.log("updateEmailTaskComposeMode =>", result);
            return result;
          }
        })
        .catch(error => {
          console.log("updateEmailTaskComposeMode [error] =>", error);
          return error;
        });
    } else {
      return;
    }
  };

  componentWillUnmount() {
    if (this.context.officeAppType == "Outlook") {
      if (!this.context.outlookComposeMode) {
        // Read Mode
        Office.context.mailbox.removeHandlerAsync(Office.EventType.ItemChanged, this.getMailboxItem);
      } else {
        // Compose Mode
        Office.context.mailbox.removeHandlerAsync(Office.EventType.ItemChanged, this.getMailboxItemCompose);
        Office.context.mailbox.item.removeHandlerAsync(Office.EventType.RecipientsChanged, this.getRecipientsChanged);
      }
    }
  }

  // Read Mode = Get mailbox and item(email) selected => Handler triggered when email selected when reading emails
  getMailboxItem = async () => {
    // Unpack the office context.
    const mailbox = Office.context.mailbox;
    const item = mailbox?.item;
    const getItemState = () => {
      if (!item) return undefined;
      const { from, to, internetMessageId, conversationId, itemType, cc, bcc, subject, body } = item;
      return {
        from,
        to,
        cc,
        bcc,
        internetMessageId,
        conversationId,
        itemType,
        subject,
        body
      };
    };

    const getMailboxState = () => {
      if (!mailbox) return undefined;

      const { userProfile } = mailbox;

      return {
        userProfile,
        item: getItemState()
      };
    };

     insightNotificationMessage("In case TRIYO Add-in is closed. Click =>", "read");
    this.setState({ mailbox: getMailboxState() });
    this.setState({ getMailboxItemCompleted: true });
  };

  // Compose Mode = Get mailbox reply/forward mode. Usually this is a draft, and for that reason we use office js functions instead
  // => Handler triggered when email selected and composing emails
  getMailboxItemCompose = async () => {
    const isOfficeOnline = this.context.hostName === "OutlookWebApp" || this.context.platform === "OfficeOnline";
    // Mailbox for compose mode it's an empty object and to retrieve data we require office js. All that functionality is available on EmailTrackingTaskAdd.js
    console.log("getMailboxItemCompose =>");

    let fetchGetSubject = new Promise((resolve, reject) => {
      Office.context.mailbox.item.subject.getAsync(asyncResult => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          console.log("getMailboxItemCompose -> subject.getAsync to check mailbox.item it's available");
          console.log(asyncResult.value);
          resolve(asyncResult.value);
        } else {
          console.log("getMailboxItemCompose -> subject.getAsync => Error getting subject");
          console.log(asyncResult.error);
          reject("");
        }
      });
    });

    Promise.all([fetchGetSubject])
      .then(async subjectObject => {
        // For PWA we cannot read body, so we read subject, roaming settings or conversation id
        if (isOfficeOnline) {
          const emailConversationId = Office.context.mailbox.item?.conversationId
            ? Office.context.mailbox.item.conversationId
            : "";
          console.log("emailConversationId =>");
          console.log(emailConversationId);

          if (subjectObject[0]?.length > 0) {
            await ApiService.getEmailTaskBySubjectCompose(subjectObject[0], this.context)
              .then(async emailtask => {
                if (emailtask.status === 200) {
                  const emailThreadExists = emailtask.data;
                  console.log("emailThreadExists => ", emailThreadExists);
                  let lastEmailSubjectGet = Office.context.roamingSettings.get("lastEmailSubject");
                  let lastEmailTaskIdGet = Office.context.roamingSettings.get("lastEmailTaskId");
                  let lastEmailPixelGet = Office.context.roamingSettings.get("lastEmailPixel");

                  if (emailThreadExists?.length === 1) {
                    this.context.taskIdInserted = emailThreadExists[0].Id;
                    this.context.emailPixelInserted = emailThreadExists[0].EmailTrackerId;
                  } else if (emailThreadExists?.length > 1) {
                    if (lastEmailTaskIdGet?.length > 0) {
                      this.context.taskIdInserted = lastEmailTaskIdGet;
                      this.context.emailPixelInserted = lastEmailPixelGet;
                    }
                  } else {
                    if (
                      lastEmailTaskIdGet?.length > 0 &&
                      subjectObject[0].toLowerCase().includes(lastEmailSubjectGet.toLowerCase())
                    ) {
                      this.context.taskIdInserted = lastEmailTaskIdGet;
                      this.context.emailPixelInserted = lastEmailPixelGet;
                    }
                  }
                }
              })
              .catch(error => {
                console.log("getEmailTaskBySubjectCompose [error] =>", error);
              });
          }

          if (emailConversationId && this.context.taskIdInserted == "") {
            await ApiService.getEmailTask(emailConversationId, this.context)
              .then(emailtask => {
                if (emailtask.status === 200) {
                  const emailtaskData = emailtask.data;
                  if (emailtaskData) {
                    console.log("getEmailTask =>");
                    console.log(emailtaskData);

                    this.context.prevTaskIdInserted = this.context.taskIdInserted;

                    this.context.taskIdInserted = emailtaskData.Id;
                    this.context.emailPixelInserted = emailtaskData.EmailTrackerId;
                  } else {
                    console.log("getEmailTask => no results found");

                    this.context.prevTaskIdInserted = this.context.taskIdInserted;

                    this.context.taskIdInserted = "";
                    this.context.emailPixelInserted = "";
                  }
                  if (!this.state.getInitialEmailTaskCompleted) {
                    this.setState({
                      firstRenderCompleted: true,
                      taskIdInserted: this.context.taskIdInserted
                    });
                  } else {
                    this.setState({
                      taskIdInserted: this.context.taskIdInserted
                    });
                  }
                }
              })
              .catch(error => {
                console.log("getEmailTask => Error");
                console.log(error);

                this.context.prevTaskIdInserted = this.context.taskIdInserted;

                this.context.taskIdInserted = "";
                this.context.emailPixelInserted = "";
                if (!this.state.getInitialEmailTaskCompleted) {
                  this.setState({
                    firstRenderCompleted: true,
                    taskIdInserted: this.context.taskIdInserted
                  });
                }
              });
          } else {
            this.context.taskIdInserted = "";
            this.context.emailPixelInserted = "";
            this.setState({
              firstRenderCompleted: true,
              taskIdInserted: this.context.taskIdInserted
            });
          }

          // We read body to check for taskid or pixel inserted
        } else {
          let encodingPrefix = this.context.emailTrackingEncodingPrefix;
          let encodingSuffix = this.context.emailTrackingEncodingSuffix;

          // Read the email body looking for taskId or Pixels
          let fetchBodyText = new Promise((resolve, reject) => {
            console.log("Searching taskId and/or pixel on the current email body...");
            Office.context.mailbox.item.body.getAsync("text", asyncResultBody => {
              if (asyncResultBody.status === Office.AsyncResultStatus.Succeeded) {
                resolve(asyncResultBody.value);
              } else {
                console.log(asyncResultBody.error);
                reject(asyncResultBody.error);
              }
            });
          });

          Promise.all([fetchBodyText])
            .then(async bodyObject => {
              if (bodyObject[0] && bodyObject[0].length > 0) {
                const bodyData = bodyObject[0];
                const taskIdInserted = this.GetBetween(bodyData, encodingPrefix, encodingSuffix);
                const emailPixelInserted = this.GetBetween(bodyData, "/api/email/", encodingPrefix);

                this.context.prevTaskIdInserted = this.context.taskIdInserted;

                this.context.taskIdInserted = taskIdInserted;
                this.context.emailPixelInserted = emailPixelInserted;

                console.log("TaskIdInserted: " + taskIdInserted);
                console.log("EmailPixelInserted: " + emailPixelInserted);

                this.setState({
                  firstRenderCompleted: true,
                  taskIdInserted: this.context.taskIdInserted
                });
              } else {
                this.context.prevTaskIdInserted = this.context.taskIdInserted;

                this.context.taskIdInserted = "";
                this.context.emailPixelInserted = "";
                console.log("No taskId or Pixel found...");

                this.setState({
                  firstRenderCompleted: true,
                  taskIdInserted: this.context.taskIdInserted
                });
              }
            })
            .catch(error => {
              console.log("Errors detected while looking for taskId or pixel in body: ");
              console.log(error);

              this.setState({
                firstRenderCompleted: true,
                taskIdInserted: this.context.taskIdInserted
              });
            });
        }
      })
      .catch(error => {
        console.log("Error getting subject =>");
        console.log(error);
        return "";
      });
  };

  //Compose Mode - Get item(email) in case of RecipientsChanged
  getRecipientsChanged = async () => {
    // Unpack the office context.
    const mailbox = Office.context.mailbox;
    const item = mailbox?.item;
    const getItemState = () => {
      if (!item) return undefined;
      const { from, to, cc, bcc, conversationId, subject, body } = item;
      return {
        from,
        to,
        cc,
        bcc,
        conversationId,
        subject,
        body
      };
    };

    const getRecipientsState = () => {
      if (!item) return undefined;

      return {
        item: getItemState()
      };
    };

    await this.setBccEmailHeaders();

    this.setState({ item: getRecipientsState() });
  };

  runAllOfficeWordServices = async () => {
    OfficeWordService.getFilePropertyProject(this.callbackSetContextProject);
    OfficeWordService.getFilePropertyDocument(this.callbackSetContextDocument);
    OfficeWordService.getFilePropertyScroll(this.callbackSetScrollPosition);
    OfficeWordService.getFilePropertyTask(this.callbackSetContextTask);
    OfficeWordService.getFilePropertyToken(this.callbackSetContextToken, this.context);
    OfficeWordService.getDocumentText(this.callbackGetDocumentText);
  };

  checkFile = async () => {
    if (this.state.tokenCheck) {
      console.log("[2] checkFile => Checking Office Context -> ");
      console.log(Office.context);

      // Starting with Mailbox requirement set 1.5, you can o use Office.context.diagnostics
      // Set diagnostics for Office.context
      if (Office.context?.diagnostics) {
        const contextInfo = Office.context.diagnostics;
        this.context.host = contextInfo?.host;
        this.context.platform = contextInfo?.platform;
        this.context.version = contextInfo?.version;
      }

      const OfficeSharedServiceResult = OfficeSharedService.getAppType(Office.context);

      if (OfficeSharedServiceResult == "Word") {
        console.log("[2.0.1] checkFile => Word Context detected");
        this.context.officeAppType = "Word";
        this.context.officeAppTypeDoc = 1;
        await this.runAllOfficeWordServices();
      } else if (OfficeSharedServiceResult == "PowerPoint") {
        console.log("[2.0.2] checkFile => PowerPoint Context detected");
        this.context.officeAppType = "PowerPoint";
        this.context.officeAppTypeDoc = 3;
        if (!this.context.fileCheck) {
          FileTransferService.uploadDocumentFile(Office.context, this.apiCheckFile);
        }
      } else if (OfficeSharedServiceResult == "Outlook") {
        console.log("[2.0.3.1] checkFile => Outlook mailbox Context detected");
        this.context.officeAppType = "Outlook";
        this.context.officeAppTypeDoc = 4;

        if (Office.context.mailbox) {
          console.log("[2.0.3.2] checkFile => Outlook mailbox.diagnostics(results)");
          console.log(Office.context.mailbox.diagnostics);
          const currentMailbox = Office.context.diagnostics;
          this.context.currEmail = Office.context.mailbox;
          this.context.hostName = currentMailbox.hostName;
          this.context.hostVersion = currentMailbox.hostVersion;
        }
        this.setState({ checkFileCompleted: true }); // this is used mostly for Outlook logic
      } else {
        this.context.fileCheck = true;
        this.setState({ fileCheckComplete: true });
      }
    }
  };

  callbackSetContextEmail = id => {
    console.log("callback Email [emailId] -> " + id);
    this.context.currentEmailId = id;
  };

  callbackSetContextProject = id => {
    console.log("callback File [projectId] -> " + id);
    this.context.currentProjectId = id;
  };

  callbackSetContextDocument = id => {
    console.log("callback File [documentId] -> " + id);
    this.context.currentProjectDocumentId = id;
    if (id > 0 && this.context.currentTaskId == 0) {
      this.context.currentProjectFileLock = true;
      this.context.appMode = "project";
    }
  };

  callbackSetScrollPosition = scroll => {
    const currentTaskScroll = parseFloat(scroll);
    console.log("callback File [scroll] -> " + currentTaskScroll);
    if (currentTaskScroll >= 0) {
      sessionStorage.setItem("downloadedScroll", currentTaskScroll);
      this.context.attachmentScroll = true;
    }
  };

  callbackSetContextTask = id => {
    console.log("callback File [taskId] -> " + id);
    if (this.context.currentTaskId == 0) {
      this.context.currentTaskId = id;
      sessionStorage.setItem("downloadedTaskId", id);
    }
  };

  callbackSetContextToken = id => {
    console.log("callback File [token] -> " + id);
    if (id != "") {
      this.context.userToken = id;
      this.context.userLogin = true;
      if (this.state.tokenCheck) {
        this.onLoginChange(true); // This function takes cares of redirecting
      }
      this.setState({ tokenCheck: false });
    }
    if (localStorage.getItem("tmpTriyoToken") != null) {
      if (!this.context.userLogin) {
        if (this.context.userToken == "") {
          console.log("Seeting token to -> " + localStorage.getItem("tmpTriyoToken"));
          this.context.userToken = localStorage.getItem("tmpTriyoToken");
          this.context.jwtUserToken = localStorage.getItem("tmpJwtTriyoToken");
          this.context.userLogin = true;
          if (this.state.tokenCheck) {
            this.onLoginChange(true); // This function takes cares of redirecting
          }
          this.setState({ tokenCheck: false });
        }
      } else {
        this.context.userLogin = false;
      }
    } else {
      this.context.userLogin = false;
    }
  };

  callbackGetDocumentText = text => {
    console.log("Document Length -> " + text.length);
    if (text.length < 5) {
      this.context.emptyDoc = true;
    }
  };

  apiCheckFile = docdataTaskSlices => {
    let file = FileTransferService.getFileFromSlices(docdataTaskSlices, this.context.officeAppType);
    const fd = new FormData();
    fd.append("file", file);
    ApiService.checkTaskDocument(fd, this.context)
      .then(result => {
        if (result.status === 200) {
          this.context.fileCheck = true;
          console.log("File Check -> ");
          console.log(result.data);
          this.setState({ fileCheckComplete: true });
          if (result.data.TaskId > 0) {
            console.log("File Check -> Task Detected -> " + result.data.TaskId);
            this.context.currentTaskId = result.data.TaskId;
            sessionStorage.setItem("downloadedTaskId", result.data.TaskId);
            sessionStorage.setItem("downloadedScroll", result.data.Scroll);
            this.context.attachmentScroll = true;
            if (result.data.Token != "" && result.data.Token.length > 40) {
              this.context.userToken = result.data.Token;
              this.context.userLogin = true;
            }
          }
          if (result.data.ProjectId > 0) {
            console.log("File Check -> Project Detected -> " + result.data.ProjectId);
            this.context.currentProjectId = result.data.ProjectId;
            this.context.currentProjectFileName = result.data.Name;
            this.context.currentProjectFileLock = true;
            this.context.currentProjectDocumentId = result.data.Id;
          }
          if (result.data.Token != "" && result.data.Token != null && result.data.Token.length > 40) {
            this.context.userToken = result.data.Token;
            this.context.userLogin = true;
            console.log("File Check -> Redirect to Login -> ");
            if (this.context.currentTaskId > 0) {
              this.onLoginChangeTaskMode(true);
            } else {
              this.onLoginChange(true); // This function takes cares of redirecting
            }
          }
        } else {
          this.context.fileCheck = true;
          console.log("API [AddProject] error status: " + result.status);
          this.setState({ fileCheckComplete: true });
        }
      })
      .catch(e => {
        this.context.fileCheck = true;
        console.log("API [AddProject] error ->");
        console.log(e);
        this.setState({ fileCheckComplete: true });
      });
  };

  setPage = page => {
    this.setState({ currentPage: page });
    this.context.currentPage = page;
  };

  setTaskAction = action => {
    this.setState({ currentPage: "taskEditor", taskAction: action });
    this.context.currentPage = "taskEditor";
  };

  resetTaskAction = () => {
    this.setState({ taskAction: "" });
  };

  setTaskNameAndCreatedBy = (name, id) => {
    this.setState({ currentTaskName: name, currentTaskCreatedById: id });
  };

  setAppMode = appmode => {
    this.context.appMode = appmode;
  };

  localizeProjectDates = project => {
    if (project === null || typeof project.Start === "undefined" || project.Start === null) {
      return project;
    }

    let localizedStart = moment
      .utc(project.Start)
      .local()
      .toDate();
    let localizedEnd = moment
      .utc(project.End)
      .local()
      .toDate();
    project.Start = localizedStart;
    project.End = localizedEnd;

    return project;
  };

  localizeTaskDates = task => {
    if (task === null || typeof task.Due === "undefined" || task.Due === null) {
      return task;
    }

    let localizedDueDate = moment
      .utc(task.Due)
      .local()
      .toDate();
    task.Due = localizedDueDate;

    return task;
  };

  setCurrentProject = project => {
    this.context.currentProjectId = project.Id;
    this.setState({ currentProject: this.localizeProjectDates(project) });
  };

  setProject = (page, project) => {
    let localizedProject = this.localizeProjectDates(project);
    this.context.currentProjectId = project.Id;
    this.setCurrentProject(localizedProject);
    this.setState({ currentPage: page, currentProject: localizedProject });
  };

  setCurrentProjectAddTask = newDocument => {
    this.setPage("task");
    this.context.newDoc = newDocument;
  };

  getEmailParser = async () => {
    await ApiService.getEmailParser(this.context)
      .then(emailParser => {
        console.log("email Parser =>");
        console.log(emailParser.data);
        if (emailParser.data && emailParser.data.length > 0) {
          this.context.emailParser = emailParser.data;
        } else {
          this.context.emailParser = "";
        }
        this.setState({ getEmailParserCompleted: true });
      })
      .catch(error => {
        console.log("API [GetEmailParser] error ->");
        console.log(error);
        this.context.emailParser = "";
        this.setState({ getEmailParserCompleted: true });
      });
  };

  getEmailParserType = async () => {
    await ApiService.getEmailParserType(this.context)
      .then(emailParserType => {
        console.log("email Parser Type =>");
        console.log(emailParserType.data);
        if (emailParserType.data && emailParserType.data.length > 0) {
          this.context.emailParserType = emailParserType.data;
        } else {
          this.context.emailParseTyper = "";
        }
        this.setState({ getEmailParserTypeCompleted: true });
      })
      .catch(error => {
        console.log("API [GetEmailParserType] error ->");
        console.log(error);
        this.context.emailParserType = "";
        this.setState({ getEmailParserTypeCompleted: true });
      });
  };

  checkToken = async () => {
    let token = localStorage.getItem("tmpTriyoToken");
    let jwtToken = localStorage.getItem("tmpJwtTriyoToken");
    let rememberString = localStorage.getItem("rememberUser");
    let rememberUser = JSON.parse(rememberString);

    let userStatus = token === null || token === "undefined" || !rememberUser ? false : true;

    if (userStatus) {
      this.setState({ userLoggedIn: true });
      this.context.userToken = token;
      this.context.jwtUserToken = jwtToken;
      const fetchReauthentication = await this.reauthenticateUser(this.context);
      this.setState({ checkTokenCompleted: true });
      return fetchReauthentication;
    } else {
      this.context.userLogin = false;
      this.setState({
        checkTokenCompleted: true,
        userLoggedIn: false
      });
      return false;
    }
  };

  reauthenticateUser = async value => {
    await ApiService.getCurrentUser(value)
      .then(async result => {
        console.log(result);
        if (result.status === 200) {
          if (!result.data.User.Active) {
            this.context.userToken = "";
            this.context.jwtUserToken = "";
            this.context.userLogin = false;
            return true;
          }
          this.context.currUser = result.data.User;
          this.context.userName = result.data.User.UserName;
          this.context.userNameFull = result.data.User.Name;
          this.context.userEmail = result.data.User.Email;

          this.context.tokenReady = true;
          this.setState({ checkAuthenticationCompleted: true });

          if (!(this.context.composeType == "reply" || this.context.composeType == "forward")) {
            this.context.userLogin = true;
            this.onLoginChange(true); // This function takes cares of redirecting
          }

          return true;
        } else {
          console.log("API [ApiDataService.auto-login] error status: " + result.status);
          this.setState({ checkAuthenticationCompleted: false });
          return false;
        }
      })
      .catch(error => {
        console.log("API [ApiDataService.auto-login] error ->");
        console.log(error);
        this.context.tokenReady = false;
        this.setState({ checkAuthenticationCompleted: false });
        return false;
      });
  };

  // This function takes care of redirections
  onLoginChange = async value => {
    console.log("onLoginChange => Starting...");
    if (this.context.outlookComposeMode) {
      console.log("onLoginChange => outlookComposeMode logic...");
      // It could the email has conversationId but it's not linked to any task
      if (
        this.context.currEmail?.item?.conversationId &&
        (this.context.taskIdInserted || this.context.emailPixelInserted)
      ) {
        if (this.context.taskDetailsPlaceholder === true) {
          this.context.currentTaskId = parseInt(this.context.taskIdInserted);
          this.context.userLogin = true;
          notificationMessage("This email will be tracked as a new Task on TRIYO when you click Send |");
          this.setPage("taskEditor");
        } else {
          this.setPage("emailTrackingTaskDetails");
        }
      } else {
        this.setPage("emailTrackingTaskMenu");
      }
    } else {
      console.log("onLoginChange => outlookRead Mode logic...");
      if (this.context.appMode == "project") {
        this.setState({ userLoggedIn: value });
        if (this.context.currentProjectId > 0 && this.context.currentTaskId == "0") this.setPage("task");
        else this.setPage("tasks");
      } else if (this.context.appMode == "task") {
        this.setState({ userLoggedIn: value });
        this.setPage("taskEditor");
      }
    }
  };

  onLoginChangeTaskMode = value => {
    this.setState({ userLoggedIn: value });
  };

  onLogout = async () => {
    let firstLoginString = localStorage.getItem("triyoFirstLoggedIn");
    let jwtUserToken = this.context.jwtUserToken;
    let data = {
      username: "",
      password: "",
      timezone: ""
    };

    if (jwtUserToken !== null || jwtUserToken !== "undefined") {
      await ApiService.logout(data, this.context)
        .then(async result => {
          if (result.status === 204) {
            localStorage.clear();
            localStorage.setItem("triyoFirstLoggedIn", firstLoginString);
            this.context.userToken = "";
            this.context.jwtUserToken = "";
            this.context.userName = "";
            this.context.userNameFull = "";
            this.context.userEmail = "";
            this.context.userRole = "";
            this.context.userLogin = false;
            this.context.currEmail = "";

            this.context.tokenReady = false;
            console.log("this.context.tokenReady", this.context.tokenReady);

            this.setPage("login");
          } else {
            console.log("API [ApiDataService.logout] error status: " + result.status);
          }
        })
        .catch(error => {
          console.log("API [ApiDataService.logout] error ->");
          console.log(error);
        });
    }
  };

  render() {
    const { isOfficeInitialized } = this.props;

    // This validation -> this.context.officeAppType != "Outlook" => avoids unnecesary calls to this.CheckFile()
    if (isOfficeInitialized && this.context.officeAppType != "Outlook" && this.context.officeAppType !== "") {
      // Use for PPT and Word addin
      this.checkFile();
    }

    if (!this.state.fileCheckComplete) {
      return <div className="mt-7 text-center">Checking file properties...</div>;
    }

    if (this.state.currentPage == "onboarding") {
      return (
        <div>
          <OnboardingSplash onLoginChange={this.onLoginChange} setPage={this.setPage} />
        </div>
      );
    }

    if (this.state.currentPage == "login") {
      return (
        <div>
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <Login onLoginChange={this.onLoginChange} setPage={this.setPage} />
        </div>
      );
    }

    if (this.state.currentPage == "projects") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <Projects
            setCurrentProject={this.setCurrentProject}
            setPage={this.setPage}
            setCurrentProjectAddTask={this.setCurrentProjectAddTask}
            localizeProjectDates={this.localizeProjectDates}
          />
        </div>
      );
    }

    if (this.state.currentPage == "projectsDetails") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <ProjectsDetails
            setPage={this.setPage}
            setAppPage={this.setPage}
            projectInfo={this.state.currentProject}
            setCurrentProjectAddTask={this.props.setCurrentProjectAddTask}
          />
        </div>
      );
    }

    if (this.state.currentPage == "projectsAdd") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <ProjectsAdd setPage={this.setPage} setCurrentProject={this.setCurrentProject} setAppPage={this.setPage} />
        </div>
      );
    }

    if (this.state.currentPage == "projectsAdded") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <ProjectsAdded setPage={this.setPage} setAppPage={this.setPage} />
        </div>
      );
    }

    if (this.state.currentPage == "projectsList") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <ProjectsList
            setPage={this.setPage}
            setProject={this.setProject}
            setAppPage={this.setPage}
            localizeProjectDates={this.localizeProjectDates}
          />
        </div>
      );
    }

    if (this.state.currentPage == "emailTrackingTaskMenu") {
      return (
        <div>
          <EmailTrackingTaskMenu setPage={this.setPage} />
        </div>
      )
    }

    if (this.state.currentPage == "emailTrackingTaskAdd") {
      return (
        <div>
          <EmailTrackingTaskAdd setPage={this.setPage} />
        </div>
      );
    }

    if (this.state.currentPage === "emailTrackingTaskDetails") {
      return (
        <div className="m-1">
          {this.state.currentConversationId ? (
            <EmailTrackingTaskDetails
              setPage={this.setPage}
              setAppPage={this.setPage}
              conversationId={this.state.currentConversationId}
              currUserId={this.context.currUser?.Id}
            />
          ) : null}
        </div>
      );
    } else if (this.state.currentPage === "emailTrackingTaskDetailsPlaceholder") {
      return (
        <div className="m-1">
          <div className="m-1">
            <EmailTrackingTaskDetailsPlaceholder />
          </div>
        </div>
      );
    }

    if (this.state.currentPage == "tasks") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <Tasks setPage={this.setPage} localizeTaskDates={this.localizeTaskDates} />
        </div>
      );
    }

    if (this.state.currentPage == "taskAdded") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskAdded setPage={this.setPage} />
        </div>
      );
    }

    if (this.state.currentPage == "task") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskAdd setPage={this.setPage} />
        </div>
      );
    }

    if (this.state.currentPage == "task : regularTask" && this.context.officeAppType === "Outlook") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskAdd
            setPage={this.setPage}
            setAppPage={this.props.setPage}
            projectSelected={this.state.currentProject}
            isRegularTask={true}
          />
        </div>
      );
    }

    if (this.state.currentPage == "taskEditor") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskEditor
            setPage={this.setPage}
            setTaskNameAndCreatedBy={this.setTaskNameAndCreatedBy}
            taskAction={this.state.taskAction}
            resetTaskAction={this.resetTaskAction}
            localizeTaskDates={this.localizeTaskDates}
            taskIdInserted={this.state.taskIdInserted}
          />
        </div>
      );
    }

    if (this.state.currentPage == "taskEditorSaved") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskEditorSaved setPage={this.setPage} />
        </div>
      );
    }

    if (this.state.currentPage == "tasksEdit") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskEdit setPage={this.setPage} currentTask={this.context.currentTask} />
        </div>
      );
    }

    if (this.state.currentPage == "taskEditSuccess") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskAdded
            existingTask={this.context.existingTask}
            setPage={this.setPage}
            setAppPage={this.setPage}
            isEditTask={true}
          />
        </div>
      );
    }

    if (this.state.currentPage === "taskReassign") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskReassign
            setPage={this.setPage}
            taskId={this.context.currentTaskId === 0 ? this.context.currentTask.Id : this.context.currentTaskId}
            taskName={this.state.currentTaskName}
            createdById={this.state.currentTaskCreatedById}
            task={this.context.currentTask}
            currUserId={this.context.currUser?.Id}
            currProjId={this.context?.currentProjectId}
          />
        </div>
      );
    }

    if (this.state.currentPage === "taskReassigned") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskReassigned setPage={this.setPage} />
        </div>
      );
    }

    if (this.state.currentPage.includes("taskAction:")) {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskAction
            setPage={this.setPage}
            taskId={this.context.currentTaskId}
            taskName={this.state.currentTaskName}
            action={this.state.currentPage.split(": ")[1]}
          />
        </div>
      );
    }

    if (this.state.currentPage == "taskAddedFail") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskAddedFail
            existingTask={this.context.existingTask}
            setPage={this.setPage}
            setAppPage={this.props.setPage}
          />
        </div>
      );
    }

    if (this.state.currentPage == "emailTrackingNotificationMenu") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <EmailTrackingNotificationMenu
            existingLegacyEmailTask={this.context.existingLegacyEmailTask}
            setPage={this.setPage}
            setAppPage={this.props.setPage}
          />
        </div>
      );
    }

    if (this.state.currentPage.includes("taskActionSaved:")) {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskActionSaved
            setPage={this.setPage}
            setAppMode={this.setAppMode}
            action={this.state.currentPage.split(": ")[1]}
          />
        </div>
      );
    }

    if (this.state.currentPage == "taskEditorComments") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <TaskEditorComments
            setPage={this.setPage}
            setTaskAction={this.setTaskAction}
            taskId={this.context.currentTaskId}
          />
        </div>
      );
    }

    if (this.state.currentPage == "notifications") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <Notifications
            setPage={this.setPage}
            setCurrentProject={this.setCurrentProject}
            setCurrentProjectAddTask={this.setCurrentProjectAddTask}
            localizeProjectDates={this.localizeProjectDates}
          />
        </div>
      );
    }

    if (this.state.currentPage == "profile") {
      return (
        <div className="m-1">
          <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
          <Profile onLogout={this.onLogout} />
        </div>
      );
    }

    if (this.state.currentPage == "500 error") {
      // Error Page for Compose Mode
      if (
        Office.context.mailbox !== undefined &&
        this.context.officeAppType == "Outlook" &&
        Office.context.mailbox.item?.displayReplyForm == undefined
      ) {
        return (
          <div className="m-1">
            <Error500 setPage={this.setPage} isComposeMode={true} />
          </div>
        );
      } else {
        return (
          <div className="m-1">
            <Menu onLoginChange={this.onLoginChange} setPage={this.setPage} />
            <Error500 setPage={this.setPage} />
          </div>
        );
      }
    }
  }
}
