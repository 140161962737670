import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import WarningIcon from "../../resources/Icons/warning-120px-Gold-40.svg"
import AppContext from "../../app/AppContext";

class NotificationsFail extends Component {
  state = {};

  static contextType = AppContext;

  render() {
    let activeView = null;
    activeView = (
      <div className="ml-5 mr-3">
        <div className="text-center">
          <img src={WarningIcon} className="notificationsErrorIcon"></img>
        </div>
        <div className="text-center mt-4">
          <span className="projectCreatedTitle">
            Unable to load notification
            <br />
            Please try again.
          </span>
        </div>
      </div>
    );
    return <div>{activeView}</div>;
  }
}

export default NotificationsFail;
