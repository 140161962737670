import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import DatePicker from 'react-datepicker';
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import AppContext from "../../app/AppContext";
import TaskDetails from "./TaskDetails";
import ApiService from "../../services/ApiService";
import FileTransferService from "../../services/FileTransferService";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import Dropdown from "../dropdown/dropdown";
import attachmentIcon from "../../resources/Icons/Icon - Attachments.svg";
import removeAttachment from "../../resources/Icons/Icon - Remove Attachment.svg";
import "bootstrap/dist/css/bootstrap.min.css";

const TaskEditor = ({ setTaskNameAndCreatedBy, taskAction, resetTaskAction, localizeTaskDates, setPage, taskIdInserted }) => {
  const [task, setTask] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [commentNote, setCommentNote] = useState("");
  const [currentView, setCurrentView] = useState("task");
  const [noteAction, setNoteAction] = useState(11);
  const [taskWithWorkflow, setTaskWithWorkflow] = useState([]);
  const [isCustomStatus, setIsCustomStatus] = useState([]);
  const [currentTab, setCurrentTab] = useState("Details");
  const [taskChangeCounter, setTaskChangeCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentSelectedCustomStatus, setCurrentSelectedCustomStatus] = useState([]);
  const [selectedEGUnit, setSelectedEGUnit] = useState("Business Days");
  const [selectedEGValue, setSelectedEGValue] = useState(1);
  const [isEGValid, setIsEGValid] = useState(true);
  const [hasDueDate, setHasDueDate] = useState(false);
  const [newDueDate, setNewDueDate] = useState("");
  const context = useContext(AppContext);

  let canEdit = taskWithWorkflow?.StatusAndButtons?.DocumentButtons.includes("Edit");
  let canRequestReview = taskWithWorkflow?.StatusAndButtons?.WorkflowButtons.includes("Request Review");
  let canApprove = taskWithWorkflow?.StatusAndButtons?.WorkflowButtons.includes("Approve");
  let defaultButtonStyling = "loginButton btn-primary shadow-none taskModeButton";

  const extensionDuration = [
    { id: "Days", name: "Days" },
    { id: "Business Days", name: "Business Days" },
  ];

  const getTask = async () => {
    if (context.currentTask) {
      if (context.currentTask.DocumentType == "PowerPoint") {
        trackPromise(
          ApiService.getTaskPowerpointComplete(context.currentTask.Id, context)
            .then(result => {
              if (result.status === 200) {
                context.currentTask = result.data;
                setTaskNameAndCreatedBy(result.data.Name, result.data.CreatedById);
                setTaskId(context.currentTask.Id);
                if (typeof taskAction !== "undefined" && taskAction && taskAction !== "") {
                  setTaskAction(taskAction, result.data);
                } else {
                  setTask(result.data);
                }
              } else {
                console.log("API [" + apiFunction + "] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [" + apiFunction + "] error ->");
              console.log(e);
              setPage("500 error");
            }),
          "task-editor-area"
        );
      } else if (context.currentTask.DocumentType == "Word") {
        trackPromise(
          ApiService.getTaskWordComplete(context.currentTask.Id, context)
            .then(result => {
              if (result.status === 200) {
                context.currentTask = result.data;
                setTaskNameAndCreatedBy(result.data.Name, result.data.CreatedById);
                setTaskId(context.currentTask.Id);
                if (typeof taskAction !== "undefined" && taskAction && taskAction !== "") {
                  setTaskAction(taskAction, result.data);
                } else {
                  setTask(result.data);
                }
              } else {
                console.log("API [" + apiFunction + "] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [" + apiFunction + "] error ->");
              console.log(e);
              setPage("500 error");
            }),
          "task-editor-area"
        );
      } else {
        const { Name, CreatedById } = context.currentTask;
        setTaskId(context.currentTask.Id);
        setTaskNameAndCreatedBy(Name, CreatedById);
        if (typeof taskAction !== "undefined" && taskAction && taskAction !== "") {
          setTaskAction(taskAction, context.currentTask);
        }
      }
    } else if (context.officeAppType == "PowerPoint") {
      trackPromise(
        ApiService.getTaskPowerpointComplete(context.currentTaskId, context)
          .then(result => {
            if (result.status === 200) {
              context.currentTask = result.data;
              setTaskNameAndCreatedBy(result.data.Name, result.data.CreatedById);
              setTaskId(context.currentTaskId);
              if (typeof taskAction !== "undefined" && taskAction && taskAction !== "") {
                setTaskAction(taskAction, result.data);
              } else {
                setTask(result.data);
              }
            } else {
              console.log("API [" + apiFunction + "] error status: " + result.status);
            }
          })
          .catch(e => {
            console.log("API [" + apiFunction + "] error ->");
            console.log(e);
            setPage("500 error");
          }),
        "task-editor-area"
      );
    } else if (context.officeAppType == "Word") {
      trackPromise(
        ApiService.getTaskWordComplete(context.currentTaskId, context)
          .then(result => {
            if (result.status === 200) {
              context.currentTask = result.data;
              setTaskNameAndCreatedBy(result.data.Name, result.data.CreatedById);
              setTaskId(context.currentTaskId);
              if (typeof taskAction !== "undefined" && taskAction && taskAction !== "") {
                setTaskAction(taskAction, result.data);
              } else {
                setTask(result.data);
              }
            } else {
              console.log("API [" + apiFunction + "] error status: " + result.status);
            }
          })
          .catch(e => {
            console.log("API [" + apiFunction + "] error ->");
            console.log(e);
            setPage("500 error");
          }),
        "task-editor-area"
      );
    // This is for email tasks linked to an existing task
    } else if (context.officeAppType == "Outlook" && !context.currentTask) {
      const currentInsertedTaskId = taskIdInserted ? taskIdInserted : context.taskIdInserted;
      if (currentInsertedTaskId) {
        await ApiService.getRegularTask(parseInt(currentInsertedTaskId), context)
          .then((result) => {
            if (result.status === 200) {
              let linkedTask = result.data;
              if (linkedTask) {
                context.currentTask = linkedTask;

                const { Name, CreatedById } = linkedTask;
                setTaskId(linkedTask.Id);
                setTaskNameAndCreatedBy(Name, CreatedById);

                if (typeof taskAction !== "undefined" && taskAction && taskAction !== "") {
                  setTaskAction(taskAction, context.currentTask);
                }

                getWorkflowButtons();
                return;
              } else {
                setPage("emailTrackingTaskDetailsPlaceholder");
              }
              //checkRolePrivilege(context.currUser, "Add", "Task Management");
            } else {
              console.log("API [getRegularTask] error status: " + result.status);
              //checkRolePrivilege(context.currUser, "Add", "Task Management");
            }
          })
          .catch((error) => {
            console.log("API [getRegularTask] error ->");
            console.log(error);
            getWorkflowButtons();
            return;
            //checkRolePivilege(context.currUser, "Add", "Task Management");
          })
      }
    }
    getWorkflowButtons();
  };

  const getWorkflowButtons = async() => {
    let TaskId = 0;
    let TaskType = "";
    const currentInsertedTaskId = taskIdInserted ? taskIdInserted : context.taskIdInserted;

    // This failsafe it's for specific case when we jump from the re/fw draft of one linked email
    // task - with the compose add-in opened - to another email draft also linked to a email task
    if (context.officeAppType == "Outlook") {
      if (context.composeType) {
        if (currentInsertedTaskId != context.prevTaskIdInserted) {
          console.log("getTaskWithWorkflow => comparing taskIdInserted == prevTaskIdInserted");
          await ApiService.getRegularTask(parseInt(currentInsertedTaskId), context)
            .then((result) => {
              if (result.status === 200) {
                let linkedTask = result.data;
                if (linkedTask) {
                  context.currentTask = linkedTask;

                const { Name, CreatedById } = linkedTask;
                setTaskId(linkedTask.Id);
                setTaskNameAndCreatedBy(Name, CreatedById);

                if (typeof taskAction !== "undefined" && taskAction && taskAction !== "") {
                  setTaskAction(taskAction, context.currentTask);
                }

              } else {
                setPage("emailTrackingTaskDetailsPlaceholder");
              }
              //checkRolePrivilege(context.currUser, "Add", "Task Management");
            }
            })
            .catch((error) => {
              console.log("API [getRegularTask] error ->");
              console.log(error);
              //checkRolePivilege(context.currUser, "Add", "Task Management");
            })
        }
      }
    }

    if (context.currentTask?.Id) {
      TaskId = context.currentTask.Id;
      TaskType = context.currentTask.TaskType;
    } else {
      TaskId = context.currentTaskId;
      TaskType = "Document";
    }
    setTaskId(TaskId);

    await ApiService.getTaskWithWorkflow(TaskId, TaskType, context) // context.currentTaskId is null for first page load
      .then(result => {
        if (result.status === 200) {
          const { Name, CreatedById } = context.currentTask;
          setTaskId(context.currentTask.Id);
          setTaskNameAndCreatedBy(Name, CreatedById);
          setTaskWithWorkflow(result.data);

          if (result.data.TaskType == "Regular") {
            context.currentTask = result.data;
            setTask(result.data);
            console.log("getTaskWithWorkflow => task");
            console.log(result.data);
          }
          setLoading(false);
        } else {
          console.log("API [" + apiFunction + "] error status: " + result.status);
          setLoading(false);
        }
      })
      .catch(e => {
        console.log("API [" + apiFunction + "] error ->");
        console.log(e);
        setLoading(false);
        setPage("500 error");
      })
  };

  useEffect(() => {
    if (taskIdInserted && context.prevTaskIdInserted) {
      if (taskIdInserted != context.prevTaskIdInserted) {
        setLoading(true);
        getWorkflowButtons();
        context.prevTaskIdInserted = taskIdInserted; //important to prevent infinite loop
      }
    }
  }, [taskIdInserted]);

  useEffect(() => {
    getTask();
  }, []);

  useEffect(() => {
    setTaskChangeCounter(taskChangeCounter + 1);
  }, [task]);

  useEffect(() => {
    setSelectedCustomStatus();
  }, [noteAction]);

  const setSelectedCustomStatus = () => {
    let selectedStatus =task?.Workflow?.Statuses?.filter(status => status.Id == noteAction);
    if (selectedStatus) {
      setCurrentSelectedCustomStatus(selectedStatus[0]);
      if(selectedStatus[0]?.DueDateExtension){
        setHasDueDate(true);
        let newDate = new Date();
        if (selectedStatus[0]?.DueDateExtensionType == "Business Days"){
          newDate = moment().businessAdd(selectedStatus[0]?.DueDateExtensionDefault)._d;
          setNewDueDate(newDate)
        } else {
          newDate.setDate(newDate.getDate() + selectedStatus[0]?.DueDateExtensionDefault);
          setNewDueDate(newDate)
        }
      } else {
        setHasDueDate(false);
      }
    }
  };

  const onSaveTaskAddCommentApprove = (actionId, isCustom = false) => {
    setCurrentView("comment");
    setNoteAction(actionId);
    setIsCustomStatus(isCustom);
  };

  const onBrowseFilesLoaded = convertedFiles => {
    let files = selectedFiles;

    if (files.length === 0) {
      files = convertedFiles;
    } else {
      let fileFound = false;
      convertedFiles.forEach(file => {
        for (let matchingFile of files) {
          if (matchingFile.name === file.name) {
            fileFound = true;
            break;
          }
        }
        if (!fileFound) {
          files.push(file);
        }
      });
    }
    getWorkflowButtons();
    setSelectedFiles(files);
  };

  const filesToBase64 = (files, onBrowseFilesLoaded) => {
    let convertedFiles = [];
    let loadedFilesCount = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        convertedFiles = [
          ...convertedFiles,
          { name: file.name, FileName: file.name, FileContentType: file.type, Base64String: base64String }
        ];
        loadedFilesCount++;

        if (loadedFilesCount === files.length) {
          onBrowseFilesLoaded(convertedFiles);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const browseFile = e => {
    e.preventDefault();
    e.stopPropagation();
    if (context.officeAppType === "Word" || context.officeAppType === "PowerPoint") {
      let files = selectedFiles;
      let newSelectedFiles = Array.from(e.target.files);
      e.target.value = null;
      if (files.length === 0) {
        files = newSelectedFiles;
      } else {
        let fileFound = false;
        newSelectedFiles.forEach(file => {
          for (let matchingFile of files) {
            if (matchingFile.name === file.name) {
              fileFound = true;
              break;
            }
          }
          if (!fileFound) {
            files.push(file);
          }
        });
      }

      setSelectedFiles(files);
    } else {
      let newSelectedFiles = Array.from(e.target.files);
      e.target.value = null;
      filesToBase64(newSelectedFiles, onBrowseFilesLoaded);
    }
  };

  const deleteBrowseFile = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    let files = selectedFiles;
    let idx = files.findIndex(file => file.name === name);
    files.splice(idx, 1);
    getWorkflowButtons();
    setSelectedFiles(files);
  };

  const addTaskComment = () => {
    let TaskId = 0;
    if (context.currentTask?.Id) TaskId = context.currentTask.Id;
    else TaskId = context.currentTaskId;
    //Regular Task
    if (context?.currentTask?.TaskType === "Regular") {
      let obj = {
        TaskId: TaskId,
        Note: commentNote,
        Action: noteAction === 2 ? "Complete Task" : "",
        Files: selectedFiles
      };
      let customObj ={
        TaskId: TaskId,
        WorkflowStatusId : noteAction,
        Note: commentNote,
        ActionId: currentSelectedCustomStatus?.Active ? 10 : 2, //Updated/Completed Enum
        Files: selectedFiles,
        TaskExtendedBy : currentSelectedCustomStatus?.ExtendReminderName !== null ? selectedEGValue : null,
        TaskExtendedByType : currentSelectedCustomStatus?.ExtendReminderName !== null ? selectedEGUnit : null,
        DueDate: hasDueDate ? newDueDate : null
      }
      if(isCustomStatus){
        ApiService.ChangeTaskStatus(customObj, context)
        .then(result => {
            setPage("taskEditorSaved");
        })
        .catch(e => {
          console.log("API [AddTaskComment] error ->");
          console.log(e);
          setPage("500 error");
        });
      }
      else if (noteAction === 11) {
        ApiService.RequestReviewTaskRegular(obj, context)
          .then(result => {
            if (result.status === 200) {
              setPage("taskEditorSaved");
            } else {
              console.log("API [RequestReviewTaskRegular] error status: " + result.status);
            }
          })
          .catch(e => {
            console.log("API [AddTaskComment] error ->");
            console.log(e);
            setPage("500 error");
          });
      } else if (noteAction === 4) {
        ApiService.DeclineTaskRegular(obj, context)
          .then(result => {
            if (result.status === 200) {
              setPage("taskEditorSaved");
            } else {
              console.log("API [DeclineTaskRegular] error status: " + result.status);
            }
          })
          .catch(e => {
            console.log("API [AddTaskComment] error ->");
            console.log(e);
            setPage("500 error");
          });
      } else if (noteAction === 2 || noteAction === 3) {
        ApiService.ApproveTaskRegular(obj, context)
          .then(result => {
            if (result.status === 200) {
              setPage("taskEditorSaved");
            } else {
              console.log("API [ApproveTaskRegular] error status: " + result.status);
            }
          })
          .catch(e => {
            console.log("API [AddTaskComment] error ->");
            console.log(e);
            setPage("500 error");
          });
      }
    }
    //DC tasks
    else {
      const fd = new FormData();
      selectedFiles.forEach(file => {
        fd.append("file", file);
      });
      fd.append("note", commentNote);
      fd.append("taskId", TaskId);
      fd.append("noteAction", noteAction);
      //trackPromise(
      ApiService.addTaskComment(fd, context)
        .then(result => {
          if (result.status === 200) {
            let currCommentId = result.data.Comments.pop().Id;
            onSubmitTask(currCommentId);
          } else {
            console.log("API [AddTaskComment] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [AddTaskComment] error ->");
          console.log(e);
          setPage("500 error");
        }); //, "task-editor-area"
      //);
    }
  };

  const addTaskCommentCancel = () => {
    setCurrentView("task");
    setSelectedFiles(null);
    setCommentNote("");
  };

  const onSaveTask = () => {
    //trackPromise(
    FileTransferService.uploadDocumentFile(Office.context, uploadSaveTask);
    //.then(result => {}).catch({}), "task-editor-area"
    //);
  };

  const uploadSaveTask = docdataSlices => {
    let file = FileTransferService.getFileFromSlices(docdataSlices, context.officeAppType);
    const fd = new FormData();
    fd.append("file", file);

    const fdComment = new FormData();
    fdComment.append("file", null);
    fdComment.append("note", "");
    fdComment.append("taskId", context.currentTaskId);
    fdComment.append("noteAction", "10");
    if (context.officeAppType == "PowerPoint") {
      trackPromise(
        ApiService.addTaskComment(fdComment, context)
          .then(commentResult => {
            if (commentResult.status === 200) {
              let currCommentId = commentResult.data.Comments.pop().Id;
              fd.append("commentId", currCommentId);
              ApiService.updateTaskPowerpointDocument(fd, context)
                .then(result => {
                  if (result.status === 200) {
                    setPage("taskEditorSaved");
                  } else {
                    console.log("API [UpdateTaskPowerpointDocument] error status: " + result.status);
                  }
                })
                .catch(e => {
                  console.log("API [UpdateTaskPowerpointDocument] error ->");
                  console.log(e);
                  setPage("500 error");
                });
            } else {
              console.log("API [AddTaskComment] error status: " + commentResult.status);
            }
          })
          .catch(e => {
            console.log("API [AddTaskComment] error ->");
            console.log(e);
            setPage("500 error");
          }),
        "task-editor-area"
      );
    } else if (context.officeAppType == "Word") {
      trackPromise(
        ApiService.addTaskComment(fdComment, context)
          .then(commentResult => {
            if (commentResult.status === 200) {
              console.log(commentResult);
              let currCommentId = commentResult.data.Comments.pop().Id;
              fd.append("commentId", currCommentId);
              ApiService.updateTaskWordDocument(fd, context)
                .then(result => {
                  if (result.status === 200) {
                    setPage("taskEditorSaved");
                  } else {
                    console.log("API [UpdateTaskWordDocument] error status: " + result.status);
                  }
                })
                .catch(e => {
                  console.log("API [UpdateTaskWordDocument] error ->");
                  console.log(e);
                  setPage("500 error");
                });
            } else {
              console.log("API [AddTaskComment] error status: " + result.commentResult);
            }
          })
          .catch(e => {
            console.log("API [AddTaskComment] error ->");
            console.log(e);
            setPage("500 error");
          }),
        "task-editor-area"
      );
    }
  };

  const onSubmitTask = currCommentId => {
    //trackPromise(
    FileTransferService.uploadDocumentFile(Office.context, uploadSubmitTask, currCommentId);
    //.then(result => {}).catch({});//, "task-editor-area"
    //);
  };

  const uploadSubmitTask = (docdataSlices, currCommentId) => {
    let file = FileTransferService.getFileFromSlices(docdataSlices, context.officeAppType);
    const fd = new FormData();
    fd.append("file", file);
    fd.append("commentId", currCommentId);
    fd.append("ActionId", noteAction);
    if (context.officeAppType == "PowerPoint") {
      if (noteAction == 4) {
        trackPromise(
          ApiService.declineTaskPowerPointDocument(fd, context)
            .then(result => {
              if (result.status === 200) {
                setPage("taskEditorSaved");
              } else {
                console.log("API [DeclineTaskPowerPointDocument] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [DeclineTaskPowerPointDocument] error ->");
              console.log(e);
              setPage("500 error");
            }),
          "task-editor-area"
        );
      } else {
        trackPromise(
          ApiService.submitTaskPowerPointDocument(fd, context)
            .then(result => {
              if (result.status === 200) {
                setPage("taskEditorSaved");
              } else {
                console.log("API [SubmitTaskPowerPointDocument] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [SubmitTaskPowerPointDocument] error ->");
              console.log(e);
              setPage("500 error");
            }),
          "task-editor-area"
        );
      }
    }
    if (context.officeAppType == "Word") {
      if (noteAction == 4) {
        trackPromise(
          ApiService.declineTaskWordDocument(fd, context)
            .then(result => {
              if (result.status === 200) {
                setPage("taskEditorSaved");
              } else {
                console.log("API [SubmitTaskWordDocument] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [SubmitTaskWordDocument] error ->");
              console.log(e);
              setPage("500 error");
            }),
          "task-editor-area"
        );
      } else {
        trackPromise(
          ApiService.submitTaskWordDocument(fd, context)
            .then(result => {
              if (result.status === 200) {
                setPage("taskEditorSaved");
              } else {
                console.log("API [SubmitTaskWordDocument] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [SubmitTaskWordDocument] error ->");
              console.log(e);
              setPage("500 error");
            }),
          "task-editor-area"
        );
      }
    }
  };

  const onChangeCommentNote = e => {
    setCommentNote(e.target.value);
  };

  const setNewPage = (currPage, currTask) => {
    if (currTask) {
      context.currentTaskId = task.Id;
      context.currentTask = task;
    } else {
      context.currentTaskId = 0;
    }
    setTask(currTask);

    if (currPage === "tasksEdit") {
      setPage("tasksEdit");
    } else {
      setPage("tasks");
    }
  };

  const setTaskAction = (action, currTask) => {
    switch (action) {
      case "Save":
        onSaveTask();
        break;
      case "Comment":
        setTask(currTask);
        setCurrentView("comment");
        break;
      case "Decline":
        setTask(currTask);
        setCurrentView("comment");
        setNoteAction(4);
        break;
      case "Approve":
        setTask(currTask);
        setCurrentView("comment");
        setNoteAction(3);
        break;
      default:
        break;
    }
    resetTaskAction();
  };

  const setSaveButtonText = actionId => {
    switch (actionId) {
      case 2:
        return "Complete Task";
      case 3:
        return "Approve Task";
      case 4:
        return "Decline Task";
      case 11:
        return "Request Review";
      default:
        return "Submit";
    }
  };

  const onSetStatusClick = (actionId, isCustom = false)=> {
    onSaveTaskAddCommentApprove(actionId, isCustom);
  };

  const validateEG = (value, unit) =>{
    let days = value;
    let bdays = value;
    if(unit == "Years"){
      days = 365 * value;
      bdays = days
    }
    else if(unit == "Months"){
      days = 30 * value;
    }
    else if(unit == "Weeks"){
      days = 7* value;
    }
    if(unit == "Years" || unit == "Weeks" || unit == "Months" || unit == "Days"){
      let tempdate = moment().add(days, 'days');
      bdays = tempdate.businessDiff(moment());
    }
    if(bdays > currentSelectedCustomStatus?.ExtendByDaysMax){
      setIsEGValid(false);
    }
    else{
      setIsEGValid(true);
    }
  };

  const showTaskEditor = () => {
    let activeView = null;
    let taskModeButtons = null;

    if (task && (context.officeAppType !== "Outlook" || (context.officeAppType === "Outlook" && task.TaskTypeId == 1))) {
      if (canEdit || canRequestReview) {
        taskModeButtons = ( currentTab=="Details" &&
          <div className={`col-12 taskModeButtonContainer`}>
            {canEdit && (
              <button
                className={`${defaultButtonStyling} blueButtonOutline`}
                style={!canRequestReview ? { textAlign: "center", width: "100%" } : {}}
                onClick={e => onSaveTask()}
              >
                Save
              </button>
            )}
          </div>
        );
      } else if (!canApprove && !canRequestReview && !canEdit) {
        taskModeButtons = (
          <div className="text-center col-12">
            {context.officeAppType !== "Outlook" && (
              <span className="projectCreatedTitle">Please close this Office window.</span>
            )}
          </div>
        );
      }
    }

    if (task != null) {
      activeView = (
        <React.Fragment>
        { loading &&
           <LoadingOverlay loading={loading} area="task-editor-area" inline="loading-overlay-inline" />
        }
        { taskChangeCounter &&
          <div className="mt-7">
            <TaskDetails
              taskId={task.Id}
              setPage={setNewPage}
              currTask={task}
              setAppPage={setPage}
              localizeTaskDates={localizeTaskDates}
              workflow={taskWithWorkflow?.StatusAndButtons}
              onClick={() => onSaveTaskAddCommentApprove(2)}
              onSetStatusClick={onSetStatusClick}
              setCurrentTab={setCurrentTab}
            />
            {/* <UseComponentDimensions> */}
              <div
                className={
                  (taskModeButtons!= null && context.officeAppType !== "Outlook")
                    ? "fixed-bottom pl-1 pr-1 whiteBackground pb-3 pt-3" :""
                }
              >
                <div className={task.UserRoleActive == false ? "" : "row"}>{taskModeButtons}</div>
              </div>
            {/* </UseComponentDimensions> */}
          </div>
        }
        </React.Fragment>
      );
    } else {
      activeView = <LoadingOverlay area="task-editor-area" inline="loading-overlay-inline" />;
    }

    if (currentView == "comment") {
      activeView = (
        <div className="mt-7 ml-2 mr-2">
          { !isCustomStatus &&
            <LoadingOverlay area="task-editor-area" inline="loading-overlay-inline" />
          }
          <div className="row">
            <div className="col-12 mb-2">
              <span className="appFont">{task.Name}</span>
            </div>
            {isCustomStatus &&
            <div className="col-md-12">
              <Form.Group className="row">
                  <Form.Label className="col-md-12 loginInput appFontSubHeading">Task Status <i className="red">*</i></Form.Label>
                  <div  className=" col-md-12 ">
                  <Dropdown
                    values={task?.Workflow?.Statuses?.map(status => {
                      return { id: status.Id, name: status.Name };
                    })}
                    dropdownButtonText={"Select a Status"}
                    onSelect={selectedValue => {
                      setNoteAction(selectedValue.id);
                    }}
                    currentSelected={noteAction}
                  />
                  </div>
                </Form.Group>
            </div>}
            {isCustomStatus && currentSelectedCustomStatus?.ExtendReminderName !== null &&
            <div className="col-md-12">
              <Form.Group className="row">
                  <Form.Label className="col-md-12 loginInput appFontSubHeading">Extended by <i className="red">*</i></Form.Label>
                  <div className="col-4">
                    <Form.Control
                            name="taskName"
                            id="taskName"
                            type="number"
                            className="form-control form-custom-control shadow-none extension-input-height"
                            onKeyDown={(e) => {
                              (e.key === "Enter" ||
                                e.key === "." ||
                                e.key === "-") &&
                                e.preventDefault();
                            }}
                            min={1}
                            value={selectedEGValue}
                            onChange={(e) => {
                              e.target.value = e.target.value == 0 ? 1 : e.target.value;
                              setSelectedEGValue(e.target.value);
                              validateEG(e.target.value,selectedEGUnit);
                            }}
                            isInvalid={!isEGValid}
                          />
                  </div>
                  <div  className="col-8 ">
                  <Dropdown
                    values={extensionDuration.map(type => {
                      return { id: type.id, name: type.name };
                    })}
                    onSelect={selectedUnit => {
                      setSelectedEGUnit(selectedUnit.id);
                      validateEG(selectedEGValue,selectedUnit.name);
                    }}
                    currentSelected={selectedEGUnit}
                  />
                  </div>
                  {!isEGValid &&
                      <div className="col-12 extension-granted-error-text">The extension period cannot be beyond {currentSelectedCustomStatus?.ExtendByDaysMax} business days.</div>
                  }
                </Form.Group>
            </div>
            }
            {hasDueDate && <Form.Group className="col-12">
                <Form.Label>Due Date</Form.Label>
                <DatePicker
                    className={`form-control form-custom-control shadow-none`}
                    id="Due"
                    name="Due"
                    placeholderText="Click to select a end date"
                    selected={newDueDate}
                    onChange={(value, e) => { setNewDueDate(value) }}
                    minDate={new Date()}
                />
            </Form.Group>}
            <div className="col-12">
              <textarea
                placeholder="Enter Comment"
                className="form-control form-custom-control shadow-none task-comment-box"
                rows="3"
                id="commentNote"
                name="commentNote"
                value={commentNote}
                onChange={event => onChangeCommentNote(event)}
              ></textarea>
            </div>
            <div className="col-12 mt-1 mb-3">
              <div className="task-attachment">
                <div className="image-upload w-10">
                  <div className="file-input-btn">
                    <label htmlFor="file-input" className="w-10">
                      <img className="pointer" src={attachmentIcon} alt="Attachment" />
                    </label>
                  </div>

                  {selectedFiles?.map(file => {
                    return (
                      <div className="file-pill" key={file.name}>
                        <div className="fileAttachment w-10">
                          <OverlayTrigger
                            placement="auto-start"
                            delay={{ show: 250, hide: 50 }}
                            overlay={
                              <Tooltip id="tooltip-1" className="customTooltip" placement="auto-start">
                                {file.name}
                              </Tooltip>
                            }
                          >
                            <span>{file.name}</span>
                          </OverlayTrigger>
                          <div className="w-1 float-right">
                            <img
                              src={removeAttachment}
                              className="pointer float-right"
                              alt="Remove Attachment"
                              onClick={e => {
                                deleteBrowseFile(e, file.name);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <input
                    type="file"
                    id="file-input"
                    accept=".doc, .docx, .ppt, .pptx, .xls, .xlsx, .pdf, .xlsm"
                    multiple
                    onChange={e => {
                      browseFile(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-12"></div>
            <div className="pb-3" />
            <div className="fixed-bottom pl-3 pr-3 pt-3 pb-3 whiteBackground">
              <div className="row">
                <div className="col-12 taskModeButtonContainer">
                  <button className={`${defaultButtonStyling} blueButtonOutline`} onClick={e => addTaskCommentCancel()}>
                    Cancel
                  </button>
                  {isCustomStatus && currentSelectedCustomStatus?.ExtendReminderName !== null ?
                   <button disabled={!isEGValid} className={`${defaultButtonStyling} blueButton float-right ${!isEGValid ? "extension-granted-disabled": ""}`} onClick={e => addTaskComment()}>
                   Submit
                 </button>:
                  <button className={`${defaultButtonStyling} blueButton float-right`} onClick={e => addTaskComment()}>
                    {setSaveButtonText(noteAction)}
                  </button>}
                </div>
              </div>
            </div>
          </div>
        </div>

      );
    }

    return activeView;
  };

  return <div>{showTaskEditor()}</div>;
};

export default TaskEditor;
