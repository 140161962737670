import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import clockIcon from "../../resources/Icons/Clock-20px-Black.svg";
import checkMarkIcon from "../../resources/Icons/CheckMark-20px-Green.svg";
import recurringIcon from "../../resources/Icons/Recurring-18px-Black.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./TaskReminders.scss";

const TaskReminders = ({ reminders, visible }) => {
  if (visible && reminders?.length > 0) {
    return reminders.map((header, i) => {
      return <span key={i}>
        {
          header.TaskReminders.map((reminder, index) => {
            const isTextLightGray = reminder.Sent ? "text-light-gray" : "";
            const isTextGray = reminder.Sent ? "text-gray" : "";
            const reminderIcon = reminder.Type == "Recurring" ? recurringIcon : clockIcon;

            return <span key={index} className="row task-reminders-container">
              <span className="col-1 task-reminders-icon-container">
                <img
                  className="task-icons task-reminders-icon"
                  alt="Expand Reminders"
                  src={reminder.Sent ? checkMarkIcon : reminderIcon}
                />
              </span>
              <div className="col-8 task-reminders-type-container">
                <p className={`task-reminders-type-subtitle ${isTextLightGray}`}>{reminder.Type}</p>
                <OverlayTrigger
                  placement="bottom-start"
                  delay={{ show: 250, hide: 250 }}
                  overlay={
                    <Tooltip id="tooltip-1">
                      <div className="tooltip-row" style={{ textAlign: "left", padding: "4px", fontSize: "0.75rem" }}>
                        Triggered via {reminder.TaskType}
                      </div>
                    </Tooltip>
                  }
                >
                  <h5 className={`task-reminders-type-title ${isTextGray} ${reminder.Name == null ? "text-gray" : ""}`}>
                    {reminder.Name != null ? reminder.Name : "Task Reminder"}
                  </h5>
                </OverlayTrigger>
              </div>
              <div className="col-3 task-reminders-date-container">
                <p className={`task-reminders-date-text ${isTextLightGray}`}>
                  { reminder.Sent ? reminder.SentDateString : reminder.DateString }
                </p>
                <p className={`task-reminders-date-text ${isTextLightGray}`}>
                  { reminder.Sent ? reminder.SentTimeString : reminder.TimeString }
                </p>
              </div>
            </span>
          })
        }
        </span>
      }
    );
  } else {
    return null;
  }
};
export default TaskReminders;
