import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import InlineNotification from "../inline-notification/inline-notification";

const commonProps = {
  type: "success",
  title: "Success"
};

const EmailTrackingInlineNotification = ({ currentProject, updateProjectEndDate }) => {
  const [projectDueDateKeptSame, setProjectDueDateKeptSame] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const projectDueDateMoment = moment(currentProject?.end).startOf("day");
  const selectedDueDateMoment = moment(currentProject?.selectedEndDate).startOf("day");

  const options = [
    {
      text: "Keep Due Date",
      onClick: () => {
        setProjectDueDateKeptSame(true);
        setErrorMessage(null);
      }
    },
    {
      text: "Extend Project",
      onClick: async () => {
        setProjectDueDateKeptSame(false);
        await updateProjectEndDate(result => {
          if (result.status !== 200) {
            setErrorMessage(result.message);
          }
        });
      }
    }
  ];

  useEffect(() => {
    setProjectDueDateKeptSame(false);
    setErrorMessage(null);
  }, [currentProject]);

  if (errorMessage) {
    return <InlineNotification type={"error"} title={"Error"} description={errorMessage} />;
  }

  if (!currentProject.endDateExtended && projectDueDateKeptSame) {
    return <InlineNotification {...commonProps} description={"Project Due Date has been left unchanged."} />;
  }

  if (currentProject.endDateExtended && !projectDueDateKeptSame) {
    return (
      <InlineNotification
        {...commonProps}
        description={"Project Due Date has been updated to accommodate the Task Due Date."}
      />
    );
  }

  return (
    selectedDueDateMoment.isAfter(projectDueDateMoment) && (
      <InlineNotification
        type={"info"}
        title={"Important"}
        description={`The Selected Task Due Date exceeds the Due Date of the '${currentProject?.name}' Project.`}
        options={options}
      />
    )
  );
};

EmailTrackingInlineNotification.propTypes = {
  currentProject: PropTypes.shape({
    name: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    endDateExtended: PropTypes.bool.isRequired,
    selectedEndDate: PropTypes.instanceOf(Date).isRequired
  }).isRequired,
  updateProjectEndDate: PropTypes.func.isRequired
};

export default EmailTrackingInlineNotification;
