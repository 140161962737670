import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import Dropdown from "../../dropdown/dropdown";
import { plusIcon, lockGrayIcon } from "../../../resources/Icons/icons";

const TaskDetailsSection = ({
  titleTask,
  setPage,
  setFieldValue,
  setFieldTouched,
  setCurrentProjectId,
  setProjectId,
  context,
  projectInfo,
  projectList = [],
  isProjectDisabled = false,
  isOwner,
  ...taskDetails
}) => {
  const showTaskTitle = () => {
    return (
      <div className="row mt-4 mb-3">
        <div className="col-12">
          <span className="projectPageTitle appFont ml-2">{titleTask}</span>
        </div>
      </div>
    );
  };

  const showProjectInput = () => {
    let projectInput = "";
    let projectTitle = (
      <Form.Label className="loginInput appFontSubHeading">
        Project <i className="red">*</i>
      </Form.Label>
    );
    let projectDropdown = (
      <React.Fragment>
        <Dropdown
          values={projectList.map(project => {
            return { id: project.Id, name: project.Name };
          })}
          dropdownButtonText={projectInfo?.Name?.length > 0 ? projectInfo?.Name : "Select a Project"}
          onSelect={selectedValue => {
            setCurrentProjectId(selectedValue.id);
            setProjectId(selectedValue.id);
            setFieldValue("projectId", selectedValue.id);
            setFieldValue("projectName", selectedValue.name);
            setFieldTouched("projectName");
          }}
          disabled={isProjectDisabled}
        />
        { taskDetails?.values?.projectName === "" && taskDetails?.touched?.projectName &&
          <div type="invalid" className="error-message">
            {taskDetails?.errors?.projectName}
          </div>
        }
      </React.Fragment>
    );
    let projectAddBtn = (
      <button
        type="button"
        className="btn btn-outline-secondary shadow-none blueButtonInput"
        onClick={() => setPage("projectsAdd")}
        aria-label="Add Project"
      >
        <img src={plusIcon} alt="Add Project"/>
      </button>
    );

    let projectFeedback = (
      <Form.Control.Feedback type="invalid" className="error-message">
        {taskDetails?.errors?.projectName}
      </Form.Control.Feedback>
    );

    if (taskDetails?.taskType === "Regular") {
      projectInput = (
        <Form.Group id="projName">
          {projectTitle}
          <div className="mb-3" style={{ paddingLeft: 0 }}>
            {projectDropdown}
          </div>
          {projectFeedback}
        </Form.Group>
      );
    } else if (context.officeAppType === "Outlook") {
      projectInput = (
        <Form.Group id="projName">
          {projectTitle}
          <div className="input-group mb-3" style={{ paddingLeft: 0 }}>
            <div className="input-group-append col-12" style={{ paddingLeft: 0 }}>
              <div className="col-11" style={{ paddingLeft: 0 }}>
                {projectDropdown}
              </div>
              {projectAddBtn}
            </div>
          </div>
          {projectFeedback}
        </Form.Group>
      );
    } else if (context.officeAppType === "PowerPoint") {
      projectInput = (
        <React.Fragment>
          <Form.Group>
            <Form.Label className="loginInput appFontSubHeading">
              <img src={lockGrayIcon} alt="Locked Slides"/> Locked Slides
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control form-custom-control shadow-none"
              id="lockedSlides"
              name="lockedSlides"
              disabled="disabled"
              placeholder="-"
              value={taskDetails?.getUnavailableSlidesList(taskDetails?.slidesUnavailable)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="loginInput appFontSubHeading">
              Select Slides <i className="red">*</i>
            </Form.Label>
            {
              <React.Fragment>
                <Form.Control
                  type="text"
                  className="form-control form-custom-control shadow-none"
                  id="selectedSlides"
                  name="selectedSlides"
                  placeholder="Enter slide range, ie '1,3,5-12'"
                  onChange={x => {
                    taskDetails?.updateSelectSlidesValue(x, taskDetails?.setFieldTouched);
                  }}
                  isInvalid={
                    (taskDetails?.touched?.selectedSlides && taskDetails?.errors.selectedSlides) ||
                    taskDetails?.selectionErrors?.length > 0
                  }
                  autoComplete="off"
                  onKeyDown={e => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <Form.Control.Feedback type="invalid" className="error-message">
                  {taskDetails?.errors.selectedSlides ?? taskDetails?.selectionErrors}
                </Form.Control.Feedback>
              </React.Fragment>
            }
          </Form.Group>
        </React.Fragment>
      );
    }

    return projectInput;
  };

  const showTaskName = () => {
    return (
      <Form.Group className={taskDetails?.touched?.selectedSlides && taskDetails?.errors.selectedSlides ? "mt-4" : ""}>
        <Form.Label className="loginInput appFontSubHeading">
          Task Name <i className="red">*</i>
        </Form.Label>
        <Form.Control
          name="taskName"
          id="taskName"
          type="text"
          disabled = {!isOwner}
          className="form-control form-custom-control shadow-none"
          value={taskDetails?.values?.taskName}
          onChange={taskDetails?.handleChange}
          onBlur={taskDetails?.handleBlur}
          isInvalid={taskDetails?.touched?.taskName && taskDetails?.errors?.taskName}
          placeholder="Enter task name"
          onKeyDown={e => {
            e.key === "Enter" && e.preventDefault();
          }}
        />
        <Form.Control.Feedback type="invalid" className="error-message">
          {taskDetails?.errors.taskName}
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  return (
    <div className="mt-7">
      {showTaskTitle()}
      {showProjectInput()}
      {showTaskName()}
    </div>
  );
};

TaskDetailsSection.propTypes = {
  titleTask: PropTypes.string.isRequired,
  setPage: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setCurrentProjectId: PropTypes.func,
  setProjectId: PropTypes.func,
  context: PropTypes.object.isRequired,
  projectInfo: PropTypes.object.isRequired,
  projectList: PropTypes.array,
  isProjectDisabled: PropTypes.bool,
  isOwner: PropTypes.bool.isRequired,
};

export default TaskDetailsSection;
