class OfficePowerpointService {
  createDocument(document) {
    PowerPoint.createPresentation(document);
  }

  getSlideCount() {
    return new Promise(function(resolve, reject) {
      PowerPoint.run(function(context) {
        context.presentation.load("slides");
        return context.sync().then(function() {
          var slides = context.presentation.slides;
          var slideCount = slides.getCount();
          return context.sync().then(function() {
            resolve(slideCount.value);
          })
        })
      }).catch(function(e) {
        console.log("PowerPoint -> Property [slides] error -> ");
        console.log(e.message);
        reject("fail");
      });
    });
  }
}

export default new OfficePowerpointService();
