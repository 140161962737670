import React, { useState, useEffect, useContext } from "react";
import AppContext from "../AppContext";
import ApiService from "../../services/ApiService";
import OfficeWordService from "../../services/OfficeWordService";
import AppRender from "../AppRender";
import { localizeTaskDates, localizeProjectDates } from "../../helpers/HelperMethods";

const AppWord = () => {
  const [currentPage, setCurrentPage] = useState("login");
  const [callbackProjectCompleted, setCallbackProjectCompleted] = useState(false);
  const [callbackDocumentCompleted, setCallbackDocumentCompleted] = useState(false);
  const [callbackScrollCompleted, setCallbackScrollCompleted] = useState(false);
  const [callbackTaskCompleted, setCallbackTaskCompleted] = useState(false);
  const [callbackTokenCompleted, setCallbackTokenCompleted] = useState(false);
  const [callbackDocumentTextCompleted, setCallbackDocumentTextCompleted] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentTaskName, setCurrentTaskName] = useState("");
  const [currentTaskCreatedById, setCurrentTaskCreatedById] = useState(0);
  const [taskAction, setTaskAction] = useState("");
  const [taskIdInserted, setTaskIdInserted] = useState(null);
  const [checkFileStarted, setCheckFileStarted] = useState(false);
  const [checkFileCompleted, setCheckFileCompleted] = useState(false);
  const [firstRenderCompleted, setFirstRenderCompleted] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const context = useContext(AppContext);

  useEffect(() => {
    console.log("[1.0] AppWord.js - Checking Word File looking for valid tokens...");
    if (!checkFileStarted && !checkFileCompleted) {
      setCheckFileStarted(true);
      checkFile();
    }
  }, []);

  const checkFile = async () => {
    if (!context.fileCheck) {
      console.log("[1.1] checkFile => Initial [Word Context]", Office.context);
      await runAllOfficeWordServices();
    }
  };

  useEffect(() => {
    if (
      callbackProjectCompleted &&
      callbackDocumentCompleted &&
      callbackScrollCompleted &&
      callbackTaskCompleted &&
      callbackTokenCompleted &&
      callbackDocumentTextCompleted
    ) {
      console.log("[1.2] runAllOfficeWordServices - Completed");
      if (!context.fileCheck) {
        context.fileCheck = true;
        setCheckFileCompleted(true);
      }
    }
  }, [
    callbackProjectCompleted,
    callbackDocumentCompleted,
    callbackScrollCompleted,
    callbackTaskCompleted,
    callbackTokenCompleted,
    callbackDocumentTextCompleted
  ]);

  const runAllOfficeWordServices = async () => {
    OfficeWordService.getFilePropertyProject(callbackSetContextProject);
    OfficeWordService.getFilePropertyDocument(callbackSetContextDocument);
    OfficeWordService.getFilePropertyScroll(callbackSetScrollPosition);
    OfficeWordService.getFilePropertyTask(callbackSetContextTask);
    OfficeWordService.getDocumentText(callbackGetDocumentText);
    OfficeWordService.getFilePropertyToken(callbackSetContextToken, context);
  };

  const callbackSetContextProject = async id => {
    console.log("callback File [projectId] -> " + id);
    context.currentProjectId = id;
    setCallbackProjectCompleted(true);
  };

  const callbackSetContextDocument = id => {
    console.log("callback File [documentId] -> " + id);
    context.currentProjectDocumentId = id;
    if (id > 0 && context.currentTaskId == 0) {
      context.currentProjectFileLock = true;
      context.appMode = "project";
    }
    setCallbackDocumentCompleted(true);
  };

  const callbackSetScrollPosition = scroll => {
    const currentTaskScroll = parseFloat(scroll);
    console.log("callback File [scroll] -> " + currentTaskScroll);
    if (currentTaskScroll >= 0) {
      sessionStorage.setItem("downloadedScroll", currentTaskScroll);
      context.attachmentScroll = true;
    }
    setCallbackScrollCompleted(true);
  };

  const callbackSetContextTask = id => {
    console.log("callback File [taskId] -> " + id);
    if (context.currentTaskId == 0) {
      context.currentTaskId = id;
      sessionStorage.setItem("downloadedTaskId", id);
    }
    setCallbackTaskCompleted(true);
  };

  const callbackGetDocumentText = text => {
    console.log("Document Length -> " + text.length);
    if (text.length < 5) context.emptyDoc = true;
    setCallbackDocumentTextCompleted(true);
  };

  const callbackSetContextToken = id => {
    console.log("callback File [token] -> " + id);
    if (id != "") {
      context.userToken = id;
      context.userLogin = true;
    }
    if (localStorage.getItem("tmpTriyoToken") != null) {
      if (context.userLogin) {
        if (context.userToken == "") {
          console.log("Setting token to -> " + localStorage.getItem("tmpTriyoToken"));
          context.userToken = localStorage.getItem("tmpTriyoToken");
          context.jwtUserToken = localStorage.getItem("tmpJwtTriyoToken");
          context.userLogin = true;
        }
      } else {
        context.userLogin = false;
      }
    } else {
      context.userLogin = false;
    }
    setCallbackTokenCompleted(true);
  };

  useEffect(() => {
    if (checkFileCompleted && !firstRenderCompleted) {
      console.log("[1.3] Check Token from File... ");
      if (context.userToken != "") {
        context.tokenReady = true;
        onLoginChange(true); // This function takes cares of redirecting
      } else {
        checkToken(); //no token in file, now checking cookies
      }
      setFirstRenderCompleted(true);
    }
  }, [checkFileCompleted]);

  const onLoginChange = value => {
    console.log("[1.4] onLoginChange => [Word] redirecting to initial page...");
    if (context.appMode == "project") {
      if (context.currentProjectId > 0 && context.currentTaskId == "0") {
        setPage("task");
      } else {
        setPage("tasks");
      }
      setUserLoggedIn(value);
    } else if (context.appMode == "task") {
      setPage("taskEditor");
      setUserLoggedIn(value);
    }
  };

  const checkToken = async () => {
    console.log("[3.2] Check Token from Cookies... ");
    let token = localStorage.getItem("tmpTriyoToken");
    let jwtToken = localStorage.getItem("tmpJwtTriyoToken");
    let rememberString = localStorage.getItem("rememberUser");
    let rememberUser = JSON.parse(rememberString);
    let userStatus = token && rememberUser;

    if (userStatus) {
      context.userToken = token;
      context.jwtUserToken = jwtToken;
      const fetchReauthentication = await reauthenticateUser(context);
      return fetchReauthentication;
    } else {
      context.userLogin = false;
      return false;
    }
  };

  const reauthenticateUser = async value => {
    await ApiService.getCurrentUser(value)
      .then(async result => {
        console.log(result);
        if (result.status === 200) {
          if (!result.data.User.Active) {
            context.userToken = "";
            context.jwtUserToken = "";
            context.userLogin = false;
            return true;
          }
          context.currUser = result.data.User;
          context.userName = result.data.User.UserName;
          context.userNameFull = result.data.User.Name;
          context.userEmail = result.data.User.Email;

          context.tokenReady = true;
          onLoginChange(true); // This function takes cares of redirecting
          return true;
        } else {
          console.log("API [ApiDataService.auto-login] error status: " + result.status);
          return false;
        }
      })
      .catch(error => {
        console.log("API [ApiDataService.auto-login] error ->");
        console.log(error);
        context.tokenReady = false;
        return false;
      });
  };

  const onLogout = async () => {
    let firstLoginString = localStorage.getItem("triyoFirstLoggedIn");
    let jwtUserToken = context.jwtUserToken;
    let data = {
      username: "",
      password: "",
      timezone: ""
    };

    if (jwtUserToken !== null || jwtUserToken !== "undefined") {
      await ApiService.logout(data, context)
        .then(async result => {
          if (result.status === 204) {
            localStorage.clear();
            localStorage.setItem("triyoFirstLoggedIn", firstLoginString);
            context.userToken = "";
            context.jwtUserToken = "";
            context.userName = "";
            context.userNameFull = "";
            context.userEmail = "";
            context.userRole = "";
            context.userLogin = false;
            context.currEmail = "";

            context.tokenReady = false;
            console.log("context.tokenReady", context.tokenReady);

            setPage("login");
          } else {
            console.log("API [ApiDataService.logout] error status: " + result.status);
          }
        })
        .catch(error => {
          console.log("API [ApiDataService.logout] error ->");
          console.log(error);
        });
    }
  };

  const setAppMode = appmode => (context.appMode = appmode);

  const setPage = page => {
    context.currentPage = page;
    setCurrentPage(page);
  };

  const setTaskActionFunc = action => {
    setCurrentPage("taskEditor");
    setTaskAction(action);
    context.currentPage = "taskEditor";
  };

  const resetTaskAction = () => setTaskAction("");

  const setTaskNameAndCreatedBy = (name, id) => {
    setCurrentTaskName(name);
    setCurrentTaskCreatedById(id);
  };

  const setCurrentProjectFunc = project => {
    context.currentProjectId = project.Id;
    setCurrentProject(localizeProjectDates(project));
  };

  const setProject = (page, project) => {
    let localizedProject = localizeProjectDates(project);
    context.currentProjectId = project.Id;
    setCurrentProjectFunc(localizedProject);
    setCurrentPage(page);
    setCurrentProject(localizedProject);
  };

  const setCurrentProjectAddTask = newDocument => {
    setPage("task");
    context.newDoc = newDocument;
  };

  return (
    <AppRender
      context={context}
      setPage={setPage}
      currentPage={currentPage}
      setAppMode={setAppMode}
      onLoginChange={onLoginChange}
      onLogout={onLogout}
      setProject={setProject}
      currentProject={currentProject}
      setCurrentProject={setCurrentProjectFunc}
      setCurrentProjectAddTask={setCurrentProjectAddTask}
      localizeProjectDates={localizeProjectDates}
      setTaskNameAndCreatedBy={setTaskNameAndCreatedBy}
      taskAction={taskAction}
      setTaskAction={setTaskActionFunc}
      resetTaskAction={resetTaskAction}
      localizeTaskDates={localizeTaskDates}
      taskIdInserted={taskIdInserted}
      currentTaskName={currentTaskName}
      currentTaskCreatedById={currentTaskCreatedById}
    />
  );
};

export default AppWord;
