import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import LoadingOverlay from "../../loading-overlay/loading-overlay";
import EmailTrackingTaskEditAssignMembers from "./EmailTrackingTaskEditAssignMembers";
import ApiService from "../../../services/ApiService";
import { removeDuplicates } from "../../../helpers/HelperMethods";
import "bootstrap/dist/css/bootstrap.min.css";

const EmailTrackingTaskEditReassign = ({
  context,
  createdById,
  errorFromEdit,
  setEditTaskMembers,
  setEditTaskOwner,
  task,
  currUserId,
  allRecipients,
  reassignRefreshCounter,
}) => {
  const [taskMembers, setTaskMembers] = useState([]);
  const [taskOtherMembers, setTaskOtherMembers] = useState([]);
  const [projectInfo, setProjectInfo] = useState(null);
  const [intMemberIds, setIntMemberIds] = useState([]);
  const [permissionTaskMembers, setPermissionTaskMembers] = useState([]);
  const [taskAssigned, setTaskAssigned] = useState(false);
  const [assignedMembers, setAssignedMembers] = useState([]);
  const [assignedOwner, setAssignedOwner] = useState({});
  const [assignedOtherDepMembers, setAssignedOtherDepMembers] = useState([]);
  const [filterAssignedMembers, setFilterAssignedMembers] = useState([]);
  const [filteredOtherDepMembers, setFilteredOtherDepMembers] = useState([]);
  const [hide, setHide] = useState(false);  
  const [errorMessage, setErrorMessage] = useState([]);
  const [ownerAsAGroup, setOwnerAsAGroup] = useState(null);
  const [addMemberSuggestion, setAddMemberSuggestion] = useState(null);
  const [previousAddMemberSuggestion, setPreviousAddMemberSuggestion] = useState(null);  
  const [removeMemberSuggestion, setRemoveMemberSuggestion] = useState(null);
  const [previousRemoveMemberSuggestion, setPreviousRemoveMemberSuggestion] = useState(null);
  const [getAllTaskMembersCompleted, setGetAllTaskMembersCompleted] = useState(false);
  const [getProjectAddCompleted, setGetProjectAddCompleted] = useState(false);
  const [getGroupOwnerCompleted, setGetGroupOwnerCompleted] = useState(false);
  
  useEffect(() => {
    getAllTaskMembers();
    getProjectAdd();
    getGroupOwner();
  }, []);

  useEffect(() => {
    if (reassignRefreshCounter > 0) {
      setTaskMembers([]);
      setTaskOtherMembers([]);
      setProjectInfo(null);
      setIntMemberIds([]);
      setPermissionTaskMembers([]);
      setTaskAssigned(false);
      setAssignedMembers([]);
      setAssignedOwner({});
      setAssignedOtherDepMembers([]);
      setFilterAssignedMembers([]);
      setFilteredOtherDepMembers([]);
      setHide(false);
      setErrorMessage([]);
      setOwnerAsAGroup(null);
      setAddMemberSuggestion(null);
      setPreviousAddMemberSuggestion(null);
      setRemoveMemberSuggestion(null);
      setPreviousRemoveMemberSuggestion(null);
      setGetAllTaskMembersCompleted(false);
      setGetProjectAddCompleted(false);
      setGetGroupOwnerCompleted(false);      
    }
    getAllTaskMembers();
    getProjectAdd();
    getGroupOwner();
  }, [reassignRefreshCounter]);

  useEffect(() => {
    if ((taskMembers?.length || ownerAsAGroup != null) && !taskAssigned) {
      getAllAssignedMembers(taskMembers, taskOtherMembers);
    }
  }, [taskMembers, taskOtherMembers, taskAssigned, ownerAsAGroup]);
  
  const getGroupOwner = () => {
    let temp = task.Groups?.filter((group) => {
        if(group.IsOwner) return group;
    })
    if(temp?.length > 0) setOwnerAsAGroup(temp[0]);
    setGetGroupOwnerCompleted(true);
  };

  const getAllTaskMembers = async () => {
    if (context.currentTask.Members?.length || context.currentTask.Groups?.length) {
      await ApiService.getAllDepUsers(context).then(
        async (result) => {
          if (result.status === 200) {
            let allDepMembers=[];

            result.data.forEach((member)=>{
              let newMembers = {UserId : member.Id, User: member};
              allDepMembers.push(newMembers);
            });
            let projectMembers = allDepMembers?.concat(context.currentTask.Members);
            let initialMemberList = removeDuplicates(projectMembers, "UserId");
            setTaskMembers(initialMemberList);
            setGetAllTaskMembersCompleted(true);

            console.log("initialMemberList =>");
            console.log(initialMemberList);
          } else {
            console.log("API [GetProject] error status: " + result.status);
            setGetAllTaskMembersCompleted(true);
            //setPage("500 error");
          }
        },
        (error) => {
          console.log("API [GetProject] error ->", error);
          //setPage("500 error");
        }
      );
    }
  };

  const getProjectAdd = async () => {
    await ApiService.getProjectWithDep(task.ProjectId, context).then(
      async (result) => {
        if (result.status === 200) {
          console.log("Project was added in reassign", result.data);
          setProjectInfo(result.data);
          setTaskOtherMembers(result.data.DepMembers);
          setGetProjectAddCompleted(true);

          console.log("TaskOtherMembers =>");
          console.log(result.data.DepMembers);
        } else {
          console.log("API [GetProject] error status: " + result.status);
          setGetProjectAddCompleted(true);
          //setPage("500 error");
        }
      },
      (error) => {
          console.log("API [GetProject] error ->", error);
          //setPage("500 error");
      }
    );
  };

  const getAllAssignedMembers = (projMembers, otherProjMembers) => {
    let taskOwner = {};
    let currMembers = [];
    let otherMembers = [];
    let filterMembersNames = [];
    let otherFilterMembersNames = [];
    let permissionMembers = [];

    projMembers.forEach(function (member) {
      if (member.UserId === createdById) {
        taskOwner = member.User;
      }
      filterMembersNames.push(member.User.Name);
      currMembers.push(member.User);
      permissionMembers.push({
        Id: member.UserId,
        canApprove: member.CanApprove,
        canEdit: member.CanEdit || member.IsAssigned,
        canReassign: member.CanReassign,
        canView: member.CanView
      });
    });

    otherProjMembers.forEach(function (member) {
      otherFilterMembersNames.push(member.Name);
      otherMembers.push(member);
    });

    let uniqueMembers = removeDuplicates(currMembers, "Id");
    let uniqueOtherMembers = removeDuplicates(otherMembers, "Id");
    let uniquePermissions = removeDuplicates(permissionMembers, "Id");

    setAssignedOwner(taskOwner);
    setEditTaskOwner(taskOwner);
    setAssignedMembers([...uniqueMembers]);
    setAssignedOtherDepMembers([...uniqueOtherMembers]);
    setPermissionTaskMembers([...uniquePermissions]);
    setFilterAssignedMembers([...new Set(filterMembersNames)]);
    setFilteredOtherDepMembers([...new Set(otherFilterMembersNames)]);
  }

  // returns permission of user
  const userPermission = (member) => {
    if (member.CanView) {
        return "View";
    } else if (member.CanEdit || member.IsAssigned) {
        return "Edit";
    } else if (member.CanApprove) {
        return "Approve";
    } else {
        return "None";
    }
  };

  const checkAllRecipients = () => {
    if (allRecipients?.length > 0) {
      setRecipientAssignMembers(taskMembers, allRecipients);
    } else {
      setAddMemberSuggestion(null);
    }
  };

  useEffect(() => {
    checkAllRecipients();
  }, [allRecipients]);

  const setRecipientAssignMembers = async (currentProjectEmailsMembers, emailRecipients) => {
    let newMemberSuggestion = [];
    currentProjectEmailsMembers?.forEach((projectMember) => {
      const email = projectMember?.User.Email.toLowerCase();

      if (emailRecipients?.includes(email)) {
        let memberExist = false;
        task?.Members.forEach((member) => {
          if (member.User.Id === projectMember.User.Id) {
            memberExist = true;
          }
        });

        newMemberSuggestion.push({
          Id: projectMember.User.Id,
          Name: projectMember.User.Name,
          canApprove: false,
          canEdit: false,
          canReassign: false,
          canView: true
        });
      }
    });

    let uniqueNewMembers = removeDuplicates(newMemberSuggestion, "Id");
    setAddMemberSuggestion(uniqueNewMembers);    
  };

  const addMembersInForm = (permission, id) => {
    setErrorMessage("");
    const loweredPermission = permission.toLowerCase();
    const addedMembersId = [...intMemberIds];    

    let updatedTaskMembers = taskMembers.map((member) => {
      if (member.UserId == id) {
        addedMembersId.push(id);
        return {
          ...member,
          CanApprove: loweredPermission == "approver",
          CanEdit: loweredPermission == "editor",
          IsAssigned: loweredPermission == "editor",
          CanView: loweredPermission == "viewer"
        };
      } else {
        return member;
      }
    });

    setEditTaskMembers(updatedTaskMembers, addedMembersId);
    setIntMemberIds(addedMembersId);
    setTaskMembers(updatedTaskMembers);
    setTaskAssigned(true);
  };

  const removeMembersInForm = (permission, id) => {
    const updatedMembers = taskMembers.filter(m => m.UserId !== id);
    const updatedTaskUsers = intMemberIds.filter(userId => userId !== id);
    const removedMember = taskMembers.filter(m => m.UserId == id);

    if (!removedMember || removedMember?.length === 0) return;

    if (permission === "editor") {
        removedMember[0].CanEdit = false;
        removedMember[0].IsAssigned = false;
    } else if (permission === "viewer") {
        removedMember[0].CanView = false;
    } else if (permission === "approver") {
        removedMember[0].CanApprove = false;
    }
    updatedMembers.push(removedMember[0]);

    setEditTaskMembers(updatedMembers, updatedTaskUsers);
    setTaskMembers(updatedMembers);
    setIntMemberIds(updatedTaskUsers);
    setErrorMessage("");
  };

  // hides component body when loading overlay is displayed (security purposes, prevents inspect element to remove overlay)
  const hideContent = (hidden) => {
    if (hide != hidden) setHide(hidden);
  };

  const errorMessageDisplay = (message) => {
    return (
      (message !== "" && message !== undefined) && (
        <Form.Label>
            <div type="invalid" className="error-message-assign">
                {message}
            </div>
        </Form.Label>
      )
    );
  };
  
  return (
    <div>
      <LoadingOverlay area="task-reassign-area" inline="loading-overlay-inline" hideContent={hideContent} />
      { !hide &&
        <React.Fragment>
          <Form.Group data-testid="selectedMembers">
            <Form.Label className="loginInput appFontSubHeading">
                Assigned Members<i className="red">*</i>
            </Form.Label>
            <EmailTrackingTaskEditAssignMembers
              context={context}
              assignedMembers={assignedOtherDepMembers}
              assignedOwner={assignedOwner}
              filteredAssignedMembers={filteredOtherDepMembers}
              addMemberSuggestion={addMemberSuggestion}
              removeMemberSuggestion={removeMemberSuggestion}
              addMembersInForm={addMembersInForm}
              removeMembersInForm={removeMembersInForm}
              permissionTaskMembers={permissionTaskMembers}
              task={task}
              currUserId={currUserId}
              ownerGroup={ownerAsAGroup}
            />
            {errorMessageDisplay(errorFromEdit)}
          </Form.Group>
        </React.Fragment>
      }
    </div>
  );
};

export default EmailTrackingTaskEditReassign;
