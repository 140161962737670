import momentBusiness from 'moment-business-days';
import { workDays } from "./HelperMethods";
let _customProps;

export const removeHtmlTagsFromEmail = (content) => {
  if (!content) return;
  // Regular expression to identify HTML tags in the input string.
  // Replacing the identified HTML tag with an empty string.
  return content.replace(/(<([^>]+)>)/gi, "");
};

export async function addInitialHeaders(context, taskTypeId) {
    let fetchtInitialCustomAttributes = new Promise((resolve, reject) => {
        Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
            let customProps = result.value;
            customProps.set(`apiEndpoint`, `${context.apiEndpoint ? context.apiEndpoint.toString() : ""}`);
            customProps.set(`emailParser`, `${context.emailParser ? context.emailParser.toString() : ""}`);
            customProps.set(`userToken`, `${context.userToken ? context.userToken.toString() : ""}`);
            customProps.set(`hostName`, `${context.hostName ? context.hostName.toString() : ""}`);
            customProps.set(`platform`, `${context.platform ? context.platform.toString() : ""}`);
            customProps.set(`taskName`, `New Email Task`);
            customProps.set(`emailThread`, `New Email Task`);
            customProps.set(`description`, `New Email Task`);
            customProps.set(`taskDue`, ``);
            customProps.set(`projectId`, `0`);
            customProps.set(`priority`, `Medium`);
            customProps.set(`taskTypeId`, `${taskTypeId ? taskTypeId.toString() : ''}`);
            customProps.set(`emailId`, ``);
            customProps.set(`emailConversationId`, ``);
            customProps.set(`isEmailTrackingStatus`, `true`);
            customProps.set(`taskStatus`, `1`);
            customProps.set(`isEmailTrackActivity`, `false`);
            customProps.set(`selectedMembersNone`, ``);
            customProps.set(`selectedMembersView`, ``);
            customProps.set(`selectedMembersEdit`, ``);
            customProps.set(`selectedMembersApprove`, ``);
            customProps.set(`stringMemberIds`, ``); // used for endpoint: addMembersToProject
            customProps.set(`toUserId`, ``); // used for endpoint: addEmailPixel
            customProps.set(`toUserEmail`, ``); // used for endpoint: addEmailPixel
            customProps.set(`bccRecipients`, ``);
            customProps.set(`userName`, `${context.userName ? context.userName.toString() : ""}`);
            customProps.set(`userNameFull`, `${context.userNameFull ? context.userNameFull.toString() : ""}`);
            customProps.set(`userEmail`, `${context.userEmail ? context.userEmail.toString() : ""}`);
            customProps.set(`createButton`, `${context.enableCreateButton ? context.enableCreateButton.toString() : "false"}`);
            customProps.saveAsync((asyncResult) => {
                if (asyncResult.status == Office.AsyncResultStatus.Failed) {
                    console.log("Error setting custom attributes: " + JSON.stringify(asyncResult.error));
                    reject(false);
                } else {
                    console.log("Initial custom attributes setup completed.");
                    console.log(asyncResult.status);
                    resolve(true);
                }
            });
        });
    });

    // 1. x-triyo-draft-saved = true
    // 2. x-triyo-sending-email = false         (false after saving draft and it's updated on launchevent.js)
    // 3. x-triyo-task-created = false          (false after saving draft and it's updated after task creation endpoint finish creating task)
    let fetchtInitialHeaders = new Promise((resolve, reject) => {
        Office.context.mailbox.item.internetHeaders.setAsync(
            {
                "x-triyo-sender-hostname" : context.hostName ? context.hostName.toString() : "",
                "x-triyo-sender-platform" : context.platform ? context.platform.toString() : "",
                "x-triyo-email-parser":  context.emailParser ? context.emailParser.toString() : "",
                "x-triyo-draft-saved": "true",
                "x-triyo-sending-email": "false",
                "x-triyo-task-created"  : "false",
                "x-triyo-create-button" : context.enableCreateButton ? context.enableCreateButton.toString() : "false",
                "x-triyo-email-linked" : "true",
                "x-triyo-task-id" : "", // Failsafe in case taskiD it's dropped from body. Later, we can add logic to re-insert on body if needed
                "x-triyo-task-pixel"  : "", // Failsafe in case pixel it's dropped from body. Later, we can add logic to re-insert on body if needed
                "x-triyo-task-bcc-recipients"  : "",
                "x-triyo-username" : context.userName ? context.userName.toString() : "",
                "x-triyo-username-full" : context.userNameFull ? context.userNameFull.toString() : "",
                "x-triyo-user-email" : context.userEmail ? context.userEmail.toString() : "",
                "x-triyo-dynamicfields-valid" : "false"
            },
            (headersAsyncResult) => {
                if (headersAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                    console.log("Successfully set headers");
                    resolve(true);
                } else {
                    console.log("Error setting headers: " + JSON.stringify(headersAsyncResult.error));
                    reject(false);
                }
            }
        );
    });


    Promise.all([fetchtInitialCustomAttributes, fetchtInitialHeaders]).then( (initialAttributes) => {        
        return true;
    });
}

export async function removeInitialHeaders() {
    let fetchtInitialCustomAttributes = new Promise((resolve, reject) => {
        Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
          let customProps = result.value;
          customProps.remove(`apiEndpoint`);
          customProps.remove(`emailParser`);
          customProps.remove(`userToken`);
          customProps.remove(`hostName`);
          customProps.remove(`platform`);
          customProps.remove(`taskName`);
          customProps.remove(`emailThread`);
          customProps.remove(`description`);
          customProps.remove(`taskDue`);
          customProps.remove(`projectId`);
          customProps.remove(`priority`);
          customProps.remove(`taskTypeId`);
          customProps.remove(`emailId`);
          customProps.remove(`emailConversationId`);
          customProps.remove(`isEmailTrackingStatus`);
          customProps.remove(`taskStatus`);
          customProps.remove(`isEmailTrackActivity`);
          customProps.remove(`selectedMembersNone`);
          customProps.remove(`selectedMembersView`);
          customProps.remove(`selectedMembersEdit`);
          customProps.remove(`selectedMembersApprove`);
          customProps.remove(`stringMemberIds`);
          customProps.remove(`toUserId`);
          customProps.remove(`toUserEmail`);
          customProps.remove(`bccRecipients`);
          customProps.remove(`userName`);
          customProps.remove(`userNameFull`);
          customProps.remove(`userEmail`);
          customProps.saveAsync((asyncResult) => {
            if (asyncResult.status == Office.AsyncResultStatus.Failed) {
              console.log("asyncResult.status Error");
              console.log(asyncResult);
              reject(false);
            } else {
              console.log("Initial custom attributes removal completed.");
              console.log(asyncResult);
              resolve(true);
            }
          })
        });
      });

      let triyoHeaders = [
        "x-triyo-sender-hostname",
        "x-triyo-sender-platform",
        "x-triyo-email-parser",
        "x-triyo-draft-saved",
        "x-triyo-sending-email",
        "x-triyo-task-created",
        "x-triyo-email-linked",
        "x-triyo-create-button",
        "x-triyo-task-id",
        "x-triyo-task-pixel",
        "x-triyo-task-bcc-recipients",
        "x-triyo-username",
        "x-triyo-username-full",
        "x-triyo-user-email",
        "x-triyo-dynamicfields-valid"
      ];

      let fetchtInitialHeaders = new Promise((resolve, reject) => {
        Office.context.mailbox.item.internetHeaders.removeAsync(
          triyoHeaders,
          (headersAsyncResult) => {
            if (headersAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
              console.log("Successfully removed headers");
              resolve(true);
            } else {
              console.log("Error revoving headers: " + JSON.stringify(headersAsyncResult.error));
              reject(false);
            }
          }
        );
      });

      Promise.all([fetchtInitialCustomAttributes, fetchtInitialHeaders]).then(async (initialAttributes) => {
        // Here we can handle true case
        if (initialAttributes[0]) {
          console.log("initial Custom Attributes => removed");
          console.log(initialAttributes[0]);

          Office.context.mailbox.item.internetHeaders.getAsync(
            triyoHeaders,
            (headersAsyncResult) => {
              if (headersAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                let headersToRemove = [];
                for(let key in headersAsyncResult.value) {
                  if (key.startsWith('x-triyo-')) headersToRemove.push(key);
                }

                if (headersToRemove.length > 0) {
                  try {
                    Office.context.mailbox.item.internetHeaders.removeAsync(
                      headersToRemove,
                      (removeAsyncResult) => {
                        if (removeAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                          console.log("Successfully removed headers: " + JSON.stringify(headersToRemove));
                        } else {
                          console.log("Error removing headers: " + JSON.stringify(removeAsyncResult.error));
                        }
                      }
                    );
                  } catch (error) {
                    console.log("Error removing headers: " + JSON.stringify(error));
                  }
                } else {
                  console.log("No Triyo Headers found");
                }
              } else {
                console.log("Error getting headers: " + JSON.stringify(headersAsyncResult.error));
              }
            }
          );
        }
        if (initialAttributes[1]) {
          console.log("initial Headers => removed");
          console.log(initialAttributes[1]);
        }
      });
    return null;
};

export async function getEmailComposeType() {
  let fetchBodyComposeType = new Promise((resolve, reject) => {
    Office.context.mailbox.item.getComposeTypeAsync(function(asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value.composeType);
      } else {
        console.error(asyncResult.error);
        reject(false);
      }
    });
  });

  Promise.all([fetchBodyComposeType])
    .then( async(composeTypeResult) => {
      if (composeTypeResult[0].error) {
        console.log("composeTypeResult => error");
        console.log(composeTypeResult[0].error);
        return "";
      } else {
        console.log("composeTypeResult=>");
        console.log(composeTypeResult[0]);
        return composeTypeResult[0]; // value here should be => reply, forward or newMail
      }
    }).catch((error) => {
      console.log(error);
      return "";
    });
};

export async function addEmailPixelToEmail(taskId, pixelObject, hostName, platform, BCCObjectString) {
  let addPixelMac;
  let addPixelWindows;

  if (hostName === "OutlookIOS" || platform === "Mac") {
    addPixelMac = new Promise((resolve, reject) => {
      Office.context.mailbox.item.body.prependAsync(
        pixelObject.Pixel,
        { coercionType: Office.CoercionType.Html },
        (asyncResult) => {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            console.log("prependAsync failed with error: ");
            console.log(asyncResult.error);
            reject(false);
          }
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            console.log("prependAsync succesfull");
            resolve(true);
          }
        }
      );
    });
  } else {
    addPixelWindows = new Promise((resolve, reject) => {
      Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, 
        (bodyResult) => {
          if (bodyResult.status === Office.AsyncResultStatus.Succeeded) {
            Office.context.mailbox.item.body.setAsync(
              bodyResult.value + pixelObject.Pixel,
              { coercionType: Office.CoercionType.Html },
              (asyncResult) => {
                if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                  console.log("body.setAsync failed with error: ");
                  console.log(asyncResult.error);

                  console.log("As a failsafe => prependAsync pixel ");
                  Office.context.mailbox.item.body.prependAsync(
                    pixelObject.Pixel,
                    { coercionType: Office.CoercionType.Html },
                    (asyncResult) => {
                      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                        console.log("prependAsync failed with error: ");
                        console.log(asyncResult.error);
                        reject(false);
                      }
                      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        console.log("prependAsync succesfull");
                        resolve(true);
                      }
                    }
                  );
                }
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                  console.log("body.setAsync succesfull");
                  resolve(true);
                }
              }
            );
          } else {
            console.log(bodyResult.error.message);
          }
        }
      );
    });
  };

  let addNewHeaders = new Promise((resolve, reject) => {
    Office.context.mailbox.item.internetHeaders.setAsync(
      {
        "x-triyo-sender-hostname" : hostName ? hostName.toString() : "",
        "x-triyo-sender-platform" : platform ? platform.toString() : "",
        "x-triyo-draft-saved": "true",
        "x-triyo-sending-email": "true",
        "x-triyo-email-linked" : "true",
        "x-triyo-task-created": "true",
        "x-triyo-task-id" : taskId ? taskId.toString() : "",
        "x-triyo-task-pixel": pixelObject?.Id ? pixelObject.Id.toString() : "",
        "x-triyo-task-bcc-recipients" : BCCObjectString ? BCCObjectString : "",
      },
      asyncResult2 => {
        if (asyncResult2.status === Office.AsyncResultStatus.Succeeded) {
          resolve(true);
        } else {
          console.log(JSON.stringify(asyncResult2.error));
          reject(false);
        }
      }
    );
  });

  let addNewCustomAttributes = new Promise((resolve, reject) => {
    try {
      Office.context.mailbox.item.loadCustomPropertiesAsync((asyncResultLoadAttributes) => {
        let customProps = asyncResultLoadAttributes.value;
        customProps.set(`taskId`, `${taskId ? taskId.toString() : ""}`);
        customProps.set(`taskPixel`, `${pixelObject?.Id ? pixelObject.Id.toString() : ""}`);
        customProps.set(`bccRecipients`, `${BCCObjectString ? BCCObjectString : ""}`);
        customProps.saveAsync((asyncResultCustomAttributes) => {
          if (asyncResultCustomAttributes.status == Office.AsyncResultStatus.Failed) {
            console.log("Error setting custom attributes [taskId] and [taskPixel]: " + JSON.stringify(asyncResultCustomAttributes.error));
            reject(false);
          } else {
            resolve(true);
          }
        })
      });
    } catch (error) {
      console.log("Error setting custom attributes [taskId] and [taskPixel]: " + JSON.stringify(error));
      reject(false);
    };
  });

  Promise.all([addPixelMac, addPixelWindows, addNewHeaders, addNewCustomAttributes]).then((pixelAdded) => {    
    if (pixelAdded[0] || pixelAdded[1]) {      
      console.log("pixelAdded => successfull");
      console.log(pixelAdded[0] ? pixelAdded[0] : pixelAdded[1]);
    } else {
      console.log("pixelAdded => not added");
    }
    
    if (pixelAdded[2]) {
      console.log("Successfully setting headers: sending process, pixel and taskId!");
    } else {
      console.log("Error setting headers: sending process, pixel and taskId!");
    }
    
    if (pixelAdded[3]) {
      console.log("Setting custom attributes - [taskId] and [taskPixel] - completed");
    } else {
      console.log("Error setting custom attributes - [taskId] and [taskPixel]");
    }

    //getAllCustomAttributes(); // For debugging purposes. Uncomment if needed to check custom attributes are saving correctly.
  });
};

export async function addEmailParserToEmail(emailParser) {
  let addEmailParser = new Promise((resolve, reject) => {
    try {
      Office.context.mailbox.item.cc.addAsync([emailParser], (parserAddedAsyncResult) => {
        if (parserAddedAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
          console.log("Parser added : ");
          console.log(parserAddedAsyncResult);
          resolve(true);
        } else {
          console.log(parserAddedAsyncResult.error.message);
          resolve(false);
        }
      });
    } catch (error) {
      console.log("Error adding parser [item.cc.addAsync]: " + JSON.stringify(error));
      reject(false);
    };    
  });

  Promise.all([addEmailParser]).then((parserAdded) => {
    if (parserAdded[0]) {
      console.log("Parser added - completed");
    } else {
      console.log("Error adding email parser");
    }
  });
};

export function setEndDate (currTaskType) {
  momentBusiness.updateLocale('us', workDays);
  let duration = currTaskType?.Days;
  let isBusinessDays = currTaskType?.IsBusinessDays;
  let today = new Date();
  if (isBusinessDays) {
    today = momentBusiness().businessAdd(duration)._d;
  } else{
    today.setDate(today.getDate() + duration);
  }
  let pEnd = duration !== 0 ? today : "";
  return pEnd;
};

export function customPropsCallback(asyncResult) {
  _customProps = asyncResult.value;
  console.log(_customProps);
};

export function updateProperty(name, value) {
  _customProps.set(name, value);
  _customProps.saveAsync(saveCallback);
};

export function removeProperty(name) {
  _customProps.remove(name);
  _customProps.saveAsync(saveCallback);
};

export function saveCallback(asyncResult) {
  if (asyncResult.status == Office.AsyncResultStatus.Failed) {
    if (asyncResult.status == Office.AsyncResultStatus.Failed) {
      console.log("asyncResult.status Error");
      console.log(asyncResult);
    }
  }
  console.log("saveCallback");
  console.log(asyncResult.value);
  console.log(asyncResult.status);
};

export function getCustomInfo() {
  Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
    console.log(result);
  });
};

export function getAllCustomAttributes() {
  Office.context.mailbox.item.loadCustomPropertiesAsync((asyncResult) => {
    _customProps = asyncResult.value;
    var dictionary = _customProps.getAll();
    console.log("Custom Attributes dictionary =>");
    console.log(dictionary);
    console.log(dictionary.userToken);
  });
};

export function getCustomAttritube(customAttribute) {
  Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
    console.log(result);
    const customProps = result.value;
    const myProp = customProps.get(`${customAttribute}`);
    console.log(`${customAttribute}`); // First run on current item will return `undefined`.
    console.log(myProp);
  });
};

export function setCustomInfo(customAttribute, customAttributeData) {
  Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
      let customProps = result.value;
      customProps.set(`${customAttribute}`, `${customAttributeData}`);
      customProps.saveAsync((asyncResult) => {
        if (asyncResult.status == Office.AsyncResultStatus.Failed) {
          console.log("asyncResult.status Error");
          console.log(asyncResult);
        }
        //getCustomInfo();
        //getCustomAttritube(`${customAttribute}`);
      })
  })
};