import React, { useState, createRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/TaskMembersPopover.scss";
import { OverlayTrigger } from "react-bootstrap";

const TaskReassignMemberPopover = ({ reassignMembers }) => {
    const [members] = useState(reassignMembers);
    const [showPopover, setShowPopover] = useState(false);
    const [membersPopover, setMembersPopover] = useState([]);
    const [showPopoverScroll, setShowPopoverScroll] = useState(false);

    const membersRef = createRef();
    useEffect(() => {
        updateMembersPopoverList();
    }, [reassignMembers]);

    const sortByName = currMembers => {
        if (!currMembers || !currMembers.length) return [];
        return currMembers.sort((a, b) => a.Name.localeCompare(b.Name));
    };

    const updateMembersPopoverList = () => {
        setMembersPopover(sortByName(reassignMembers));
        setShowPopoverScroll(reassignMembers.length > 2);
    };

    const handlePopoverOnMouseEnter = () => {
        setShowPopover(true);
    };

    const handlePopoverOnMouseLeave = () => {
        setShowPopover(false);
    };

    const showTaskMembersPopover = () => {
        let activeView = null;
        if (members) {
            if (members.length > 0) {
                let extraMembers = members?.length - 4;
                activeView = (
                    <div className="task-members-popover" ref={membersRef}>

                        <OverlayTrigger
                            placement="top"
                            show={showPopover}
                            container={membersRef}
                            delay={{ show: 250, hide: 250 }}
                            overlay={
                                <div className="members-popover-container ">
                                    <div
                                        className="members-popover"
                                        onMouseEnter={handlePopoverOnMouseEnter}
                                        onMouseLeave={handlePopoverOnMouseLeave}
                                    >
                                        <ul className={`text-right ${showPopoverScroll ? "members-popover-scroll" : ""}`}>
                                            <div className="viewMembers taskAuditCardBorder">
                                                View Member(s)
                                            </div>
                                            {membersPopover.map((member,index) => {
                                                return (
                                                    <div className={`${index == membersPopover?.length-1 ?"" : "taskAuditCardBorder"}`} key={`li-${member.User?.Id}`}>
                                                        <li key={`li-${member.Id}`}>

                                                            <div
                                                                className={`userInitialsCompact userInitialsCompactOwner appFont}`}
                                                                key={member.Id}
                                                            >
                                                                {member.Initials}
                                                            </div>

                                                            <div className="reassignUserInfo">
                                                                <div className="user-name">
                                                                    {member?.Name?.length > 15 ? `${member.Name.slice(0, 15)}...` : member?.Name}
                                                                </div>
                                                                <div className="userEmail">
                                                                    {member.Email}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </div>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div >
                            }
                        >
                            <div onMouseEnter={handlePopoverOnMouseEnter} onMouseLeave={handlePopoverOnMouseLeave}>
                                {members.slice(0, 4).map(member => {

                                    return (
                                        <span
                                            className={`userInitialsCompact userInitialsCompactOwner appFont `}
                                            key={member?.Id}
                                        >
                                            {member?.Initials}
                                        </span>
                                    );
                                })}
                                {extraMembers > 0 && (
                                    <span className={"userInitialsCompact appFont userInitialsExtra"}>{`+${extraMembers}`}</span>
                                )}
                            </div>
                        </OverlayTrigger >
                    </div >
                );
            }
        } else {
            activeView = <div>-</div>;
        }

        return activeView;
    };

    return <div>{showTaskMembersPopover()}</div>;
};

export default TaskReassignMemberPopover;
