import React from "react";
import DatePicker from "react-datepicker";
import Dropdown from "../../dropdown/dropdown";
import { Form } from "react-bootstrap";

import calendarIcon from "../../../resources/Icons/Icon - calender.svg";

const TaskManagementSection = ({
  context,
  projectInfo,
  setFieldTouched,
  setFieldValue,
  taskTypesName,
  taskTypes,
  prioritiesAvailable,
  currentTaskPriority,
  isEditTask = false,
  setWorkItemId,
  isOwner,
  ...taskManagement
}) => {
  const showTaskManagementType = (
    <>
      {context.officeAppType === "Outlook" && (
        <Form.Group
          className={taskManagement?.touched?.taskName && taskManagement?.errors?.taskName ? "mt-4" : ""}
          id="taskType"
        >
          <Form.Label className="loginInput appFontSubHeading">           
                Task Type <i className="red">*</i>           
          </Form.Label>
          <Dropdown
            values={taskTypes.map(taskType => {
              return { id: taskType.Id, name: taskType.Type };
            })}
            dropdownButtonText={taskTypesName?.length > 0 ? taskTypesName : "Select an Option"}
            onSelect={selectedValue => {
              setFieldValue("taskType", selectedValue.id);
              setFieldTouched("taskType");              
              setWorkItemId(selectedValue.id);
            }}
            disabled = {!isOwner}
          />
          {taskManagement?.values?.taskType === "" && taskManagement?.touched?.taskType && (
            <div type="invalid" className="error-message">
              {taskManagement?.errors?.taskType}
            </div>
          )}
        </Form.Group>
      )}
    </>
  );

  const showPriorities = (
    <>
      <Form.Group
        className={taskManagement?.touched?.taskPriority && taskManagement?.errors?.taskPriority ? "mt-4" : ""}
        id="taskPriority"
      >
        <Form.Label className="loginInput appFontSubHeading">
          Priority <i className="red">*</i>
        </Form.Label>
        <Dropdown
          values={prioritiesAvailable.map(p => {
            return { id: p, name: p };
          })}
          dropdownButtonText={currentTaskPriority?.length > 0 ? currentTaskPriority : "Select an Option"}
          onSelect={selectedValue => {
            setFieldValue("taskPriority", selectedValue.name);
            setFieldTouched("taskPriority");
          }}
          currentSelected={taskManagement?.value?.taskPriority}
          disabled = {!isOwner}
        />
        {taskManagement?.values?.taskPriority === "" && taskManagement?.touched?.taskPriority && (
          <div type="invalid" className="error-message">
            {taskManagement?.errors?.taskPriority}
          </div>
        )}
      </Form.Group>
    </>
  );

  const showCompletionDates = (
    <>
      {context?.officeAppType === "Outlook" && (
        <Form.Group className={taskManagement?.touched?.taskStart && taskManagement?.errors?.taskStart ? "mt-4" : ""}>
          <Form.Label className="loginInput appFontSubHeading">
            Start Date <i className="red">*</i>
          </Form.Label>
          <DatePicker
            className={`form-control form-custom-control shadow-none ${
              taskManagement?.touched?.taskStart && taskManagement?.errors?.taskStart ? "is-invalid" : ""
            }`}
            id="taskStart"
            name="taskStart"
            selected={taskManagement?.values?.taskStart}
            disabled = {!isOwner}
            onChange={value => {
              value.setUTCHours(23, 59, 59, 0);
              setFieldValue("taskStart", value);
            }}
            onBlur={() => setFieldTouched("taskStart")}
            minDate={
              typeof projectInfo !== "undefined" && projectInfo !== null ? Date.parse(projectInfo?.Start) : new Date()
            }
            maxDate={
              typeof taskManagement?.values?.taskDue !== "undefined"
                ? taskManagement?.values?.taskDue
                : new Date()
            }
            placeholderText="MM/DD/YYYY"
            autoComplete="off"
            popperPlacement="top-start"
          />
          <img className={isOwner?"calendar-icon" : "calendar-icon sendCommentDisable"} src={calendarIcon} alt="Calendar Icon" />
          {taskManagement?.touched?.taskStart && taskManagement?.errors?.taskStart && (
            <div type="invalid" className="error-message">
              {taskManagement?.errors?.taskStart}
            </div>
          )}
        </Form.Group>
      )}

      <Form.Group className={taskManagement?.touched?.taskDue && taskManagement?.errors?.taskDue ? "mt-4" : ""}>
        <Form.Label className="loginInput appFontSubHeading">
          Due Date <i className="red">*</i>
        </Form.Label>
        <DatePicker
          className={`form-control form-custom-control shadow-none ${
            taskManagement?.touched?.taskDue && taskManagement?.errors?.taskDue ? "is-invalid" : ""
          }`}
          id="taskDue"
          name="taskDue"
          selected={taskManagement?.values?.taskDue}
          disabled = {!isOwner}
          onChange={value => {
            value.setUTCHours(23, 59, 59, 0);
            setFieldValue("taskDue", value);
          }}
          onBlur={() => setFieldTouched("taskDue")}
          minDate={ typeof taskManagement?.values?.taskStart !== "undefined"
          ? taskManagement?.values?.taskStart
          : new Date()}
          maxDate={
            typeof projectInfo !== "undefined" && projectInfo !== null ? Date.parse(projectInfo?.End) : new Date()
          }
          placeholderText="MM/DD/YYYY"
          autoComplete="off"
        />
        <img className={isOwner?"calendar-icon" : "calendar-icon sendCommentDisable"} src={calendarIcon} alt="Calendar Icon" />
        {taskManagement?.touched?.taskDue && taskManagement?.errors?.taskDue && (
          <div type="invalid" className="error-message">
            {taskManagement?.errors.taskDue}
          </div>
        )}
      </Form.Group>
    </>
  );
  return (
    <div>
      {/* Task Management Type */}
      {showTaskManagementType}
      {/* Priority */}
      {showPriorities}
      {/* Start Date - Due Date */}
      {showCompletionDates}
    </div>
  );
};

export default TaskManagementSection;
