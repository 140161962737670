import moment from "moment";

export const notificationMessage = (customMessage) => {
  try {
    const details = {
      type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
      message: customMessage,
      icon: "Triyo.tpicon_80x80", //This icon matches with one from the Manifest. Don't change it here or in the manifest file
      persistent: false
    };
    Office.context.mailbox.item.notificationMessages.addAsync("1", details);
  } catch (error) {
    console.log("notificationMessage [error] =>", error);
  }
};

export const removeNotificationMessage = (customMessage) => {
  try {
    const details = {
      type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
      message: customMessage,
      icon: "Triyo.tpicon_80x80", //This icon matches with one from the Manifest. Don't change it here or in the manifest file
      persistent: false
    };
    Office.context.mailbox.item.notificationMessages.removeAsync("1", details);
  } catch (error) {
    console.log("removeNotificationMessage [error] =>", error);
  }
};

export const insightNotificationMessage = (customMessage, mode) => {
  try {
    if (Office.context.mailbox.item) {
      Office.context.mailbox.item.notificationMessages.addAsync(`${mode}2`, {
        type: Office.MailboxEnums.ItemNotificationMessageType.InsightMessage,
        message: customMessage,
        icon: mode == "read" ? "Triyo.tpicon_80x80" : "Triyo.compose_80x80",
        actions: [
          {
            actionText: mode == "read" ? "Show View Tasks Pane" : "Show Create Tasks Pane" ,
            actionType: Office.MailboxEnums.ActionType.ShowTaskPane,
            commandId: mode == "read" ? "Triyo.readOpnPaneBt" : "Triyo.compOpenPaneBtn",
            contextData: "{''}",
          },
        ],
      });
    } else {
      console.log("Office.context.mailbox.item is null or undefined or notificationMessages is not available.");
    }
  } catch (error) {
    console.log("insightNotificationMessage [error] =>", error);
  }
};

export const removeInsightNotificationMessage = (customMessage, mode) => {
  try {
    Office.context.mailbox.item.notificationMessages.removeAsync(`${mode}2`, {
      type: Office.MailboxEnums.ItemNotificationMessageType.InsightMessage,
      message: customMessage,
      icon: mode == "read" ? "Triyo.tpicon_80x80" : "Triyo.compose_80x80",
      actions: [
        {
          actionText: mode == "read" ? "Show View Tasks Pane" : "Show Create Tasks Pane" ,
          actionType: Office.MailboxEnums.ActionType.ShowTaskPane,
          commandId: mode == "read" ? "Triyo.readOpnPaneBt" : "Triyo.compOpenPaneBtn",
          contextData: "{''}",
        },
      ],
    });
  } catch (error) {
    console.log("removeInsightNotificationMessage [error] =>", error);
  }
};

export const removeDuplicates = (originalArray, prop) => {
  let newArray = [];
  let lookupObject = {};
  for (let i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (let i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

export const arrayContainsString = (array, substring) => {
  return Boolean(array.find(element => element.includes(substring)));
};

export const workDays = {
  workingWeekdays: [1, 2, 3, 4, 5]
};

export const convertUTCDateToLocalDate = (date) => {
  return moment.utc(date).toDate();
}

export const localizeTaskDates = task => {
  if (task === null || typeof task.Due === "undefined" || task.Due === null) {
    return task;
  }

  let localizedDueDate = moment
    .utc(task.Due)
    .local()
    .toDate();
  task.Due = localizedDueDate;

  return task;
};

export  const localizeProjectDates = project => {
  if (project === null || typeof project.Start === "undefined" || project.Start === null) {
    return project;
  }

  let localizedStart = moment
    .utc(project.Start)
    .local()
    .toDate();
  let localizedEnd = moment
    .utc(project.End)
    .local()
    .toDate();
  project.Start = localizedStart;
  project.End = localizedEnd;

  return project;
};
