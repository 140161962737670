import React, { useRef, useState, useEffect, Fragment } from 'react'
import { Dropdown } from 'react-bootstrap'

import iconCheck from "../../resources/Icons/Icon\ -\ Check.svg";
import dropdownArrow from "../../resources/Icons/Icon\ -\ ArrowDown.svg";

import './dropdown.css';

// values MUST be an array in the format {id: ..., name: ..., }
//   id is the 'unique' property of each item, and name is the displayed value
//   disabled is an optional field that just greys out the option's text, and disallows the user from selecting it
const DropdownMain = ({ values, dropdownButtonText, onSelect, currentSelected, disabled, isSearchable = false }) => {

    // are there >= 6 total items?
    const [isScroll, setIsScroll] = useState(false);

    const [selected, setSelected] = useState(null);

    // workaround to display up arrow icon when dropdown toggled, and down arrow otherwise
    const [toggled, setToggled] = useState(false);
    const [toggled1, setToggled1] = useState(false);

    // input state for filter
    const [filterValue, setFilterValue] = useState('');

    const node = useRef();

    useEffect(() => {
        if (currentSelected) {
            let selectedName = values?.find(value => value.id === currentSelected);
            setSelected({ id: currentSelected, name: selectedName?.name });
        }
        setIsScroll(values && values.length >= 6);
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [values])

    const handleClick = (e) => {
        if (node.current && node.current.contains(e.target)) {
            setToggled(!toggled);
            return;
        }
        setToggled1(false);
    }

    // when new item is selected from dropdown
    const handleChange = (value) => {
        onSelect(value);
        setSelected(value);
        setFilterValue('');
        node.current.children[0].children[0].children[0].value = value.name;
    }

    // determine if value should be displayed or not
    const isDisplayValue = (value) => {
        if (!selected) return true;
        return selected.id !== value.id;
    }

    // determine if selected value should be displayed in position or not
    // show if: item selected and less than 6 total items in the list
    const isDisplaySelected = (value) => {
        return selected && selected.id === value.id && !isScroll;
    }

    const onKeyDownDropdownItem = (keyEvent, value) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            handleChange(value);
            setToggled(false);
            setToggled1(false);
        }
    }

    const onKeyDropDownToggle = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 40) {
            setToggled1(true);
        }
    }

    const displaySelected = () => {
        return (
        <>
            <Dropdown.Item  onKeyDown={(keyEvent) => onKeyDownDropdownItem(keyEvent,selected)} className='dropdown-row dropdown-selected-row' key={selected.id} value={selected.id} onClick={() => {handleChange(selected)}}>
                <span className='dropdown-row-text'>
                    {selected.name}
                </span>
                <img alt="checkmark" src={iconCheck} className="dropdown-selected-row-checkmark"/>
            </Dropdown.Item>
            <Dropdown.Divider className='dropdown-row-divider' />
        </>
        )
    }

    // styling for a disabled dropdown
    if (disabled) {
        return (
            <div  className="dropdown-main-disabled">
                <Dropdown className="dropdown-main">
                    <Dropdown.Toggle id='dropdown-toggle-disabled' className={'dropdown-button dropdown-button-disabled'} as='div'>
                        {selected ?
                            <span className='dropdown-button-selected-text'>
                                {selected.name}
                            </span>
                        :
                            <span className='dropdown-button-text'> {dropdownButtonText || "Select an item"} </span>
                        }

                        <img className='dropdown-arrow dropdown-arrow-disabled' alt='dropdown-arrow' src={dropdownArrow}/>

                    </Dropdown.Toggle>
                </Dropdown>
            </div>
        )
    }

    // toggled && toggled1 is true when the dropdown toggle is active, and false otherwise
    return (
        <div className="dropdown-main-top-level" ref={node} onClick={(e) => handleClick(e)}>
            <Dropdown show = {toggled1} className="dropdown-main" onClick={() => { setToggled1(!toggled1)}}>
                <Dropdown.Toggle onKeyDown = {onKeyDropDownToggle} id='dropdown-toggle' className={toggled && toggled1 ? 'pill-wapper dropdown-input dropdown-button selected-button' : 'pill-wapper dropdown-input dropdown-button'} as='div' >
                    {isSearchable ?
                        <input
                            placeholder={selected?.name || dropdownButtonText}
                            type='text'
                            onChange={e => {
                                setFilterValue(e.target.value);
                            }}
                            onFocus={e => {
                                setFilterValue("");
                            }}
                            onBlur={e => {
                                e.target.value = selected?.name || "";
                            }}
                            className='dropdown-input-field'
                        >
                        </input>
                    :
                        <span className='dropdown-button-text'> {selected?.name || dropdownButtonText || "Select an item"} </span>
                    }

                    {
                        <img className='dropdown-arrow' alt='dropdown-arrow' src={dropdownArrow}/>
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu className={`dropdown-main-menu`} as='div'>
                    {/* Selected item always remains at top with checkmark next to its value */}
                    {selected && isScroll && displaySelected()}

                    {values &&
                        values.map((value, index) => value?.name?.toString().toLowerCase().includes(filterValue.toLowerCase()) && (
                            <Fragment key={index}>
                                {isDisplaySelected(value) && displaySelected()}

                                {/* don't display the item if it's already selected */}
                                {isDisplayValue(value) &&
                                    <>
                                        <Dropdown.Item onKeyDown={(keyEvent) => onKeyDownDropdownItem(keyEvent,value)} className='dropdown-row' key={value.id} value={value.id} disabled={value.disabled} onClick={() => {handleChange(value)}}>
                                            <span className={value.disabled ? 'dropdown-row-text dropdown-disabled-text' : 'dropdown-row-text'}>
                                                {value.name}
                                            </span>
                                        </Dropdown.Item>

                                        {(index !== values.length - 1) && <Dropdown.Divider className='dropdown-row-divider' />}
                                    </>
                                }
                            </Fragment>
                            )
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default DropdownMain;