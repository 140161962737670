import React from "react";
import { usePromiseTracker} from "react-promise-tracker";
import { PulseLoader} from 'react-spinners';
import { CSSTransition } from 'react-transition-group';

import "./loading-overlay.scss";

const LoadingOverlay = props => {
    let params = props.area === null ? {delay: 100} : {delay: 100, area: props.area};
    let minDuration = props.minDuration ?? 300;
    const { promiseInProgress } = usePromiseTracker(params);
    let isLoading = props.loading ?? false;
    let name = 'loading-spinner-wrapper';
    let inlineStyle = "loading-overlay " + (props.inline ?? "");

    if(typeof(props.hideContent) == 'function'){
        (isLoading || promiseInProgress) ? props.hideContent(true) : props.hideContent(false);
    }

    return(
        <CSSTransition
            in={isLoading || promiseInProgress}
            timeout={minDuration}
            classNames={inlineStyle}
            onEnter={props.onEnter}
            onEntering={props.onEntering}
            onEntered={props.onEntered}
            onExit={props.onExit}
            onExited={props.onExited}
            unmountOnExit
        >
            <>
                {
                    (
                        <div className={inlineStyle} area={props.area}>
                            <div className= {name} style={props.location}>
                                <PulseLoader className="loading-spinner"></PulseLoader>
                            </div>
                        </div>
                    )
                }
            </> 
        </CSSTransition>
    )
};

export default LoadingOverlay;
