import React, { useContext, useState, useEffect, useCallback, Fragment, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import AppContext from "../../app/AppContext";
import ApiService from "../../services/ApiService";
import TaskAuditSingleComment from "./TaskAuditSingleComment";
import TaskAuditDynamicCommentForm from "./TaskAuditDynamicCommentForm";
import { saveDynamicFieldsDetails } from "../dynamicFields/utils/utils";
import "./TaskAudit.scss";

const TaskAudit = ({ task, setAppPage }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [comments, setComments] = useState([]);
  const [newCommentText, setNewCommentText] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [renderComments, setRenderComments] = useState(true);
  const [isEmailTask, setIsEmailTask] = useState(false);

  // Action Floating Button & Tooltip related
  const [firstRenderCompleted, setFirstRenderCompleted] = useState(false);
  const [areUnreadCommentsPresent, setAreUnreadCommentsPresent] = useState(false);
  const [actionsTooltipVisible, setActionsTooltipVisible] = useState(false);
  const [isActionFloatinVisible, setIsActionFloatinVisible] = useState(false);

  const context = useContext(AppContext);
  const commentsEndRef = useRef(null);
  const latestComment = useRef(null);
  let commentsDiv = document.getElementById("commentsAudit");

  const scrollToFixedPosition = () => {
    try{
      let fixedTaskId = parseInt(sessionStorage.getItem("downloadedTaskId")) || 0;
      let fixedPosition = parseFloat(sessionStorage.getItem("downloadedScroll")) || 0;
      let checkOfficeAppType = context.officeAppType !== "Outlook";
  
      if (fixedPosition > 0 && task.Id === fixedTaskId && checkOfficeAppType && commentsDiv) {
        commentsDiv.scrollTo(0, fixedPosition);
      } else {
        commentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
    catch(err){
      console.log("scrollToFixedPosition", err)
    }    
  };


  const scrollToLatestComment = () => {
    // Handles scrolling to the latest comment
    commentsEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    setAreUnreadCommentsPresent(false);
  };

  const sortDBResultInTaskComments = (result) => {
    try{
      const sortedDB = result?.data?.sort(function(a, b) {
        if(a.dtCreatedDate > b.dtCreatedDate) return -1;
  
        return a.dtCreatedDate < b.dtCreatedDate ? 1 : 0;
      });
  
      return sortedDB;
    }    
    catch(err){
      console.log("sortDBResultInTaskComments", err)
      return result;
    }  
  }

  const getApiTaskComments = (response) => {
    response
      .then(result => {
        if (result.status === 200) {
          let dbResult = sortDBResultInTaskComments(result);

          setComments(dbResult);

          dbResult.forEach(comm => {
            if (comm.Note === "Email Read" || comm.Note === "Email Responded") {
              setIsEmailTask(true);
            }
          });
        } else {
          console.log("API [getTaskComments] error status: " + result.status);
        }
      })
      .catch(e => {
        console.log("API [AddProject] error ->", e);
      })
      .finally(() => setRenderComments(false));
  }

  const getTaskComments = useCallback(() => {
    let response = null;
    if (task.TaskType === "Regular") {
      response = ApiService.getRegularTaskComments(task.Id, context);
    } else {
      response = ApiService.getDocumentTaskComments(task.Id, context);
    }

    getApiTaskComments(response);

  }, [task.Id, context.userToken]);

  // Initial load
  useEffect(() => {
    getTaskComments();
  }, [renderComments, task]);

  useEffect(() => {
    scrollToFixedPosition();
  }, [commentsDiv, renderComments]);
  
  // Get any new comments
  // IMPROVEMENT: use set intersection to get find new comments
  // This could be used to show a number of new comments
  useEffect(
    () => () => {
      try{
        if (!firstRenderCompleted) {
          setFirstRenderCompleted(true);
          latestComment.current = comments?.at(-1);
        }
        else if (comments?.length > 0 && comments?.at(-1).Id !== latestComment?.current?.Id) {
          setAreUnreadCommentsPresent(true);
          console.log("New comment added");
          latestComment.current = comments?.at(-1);
        }
      }
      catch(err){
        console.log("ERROR: Comment useEffect : " + err)
      }
     
    }, [comments]
  );

  const getCommentsScrollPosition = () => {
    if (commentsDiv) {
      const onScroll = () => {
        setScrollPosition(commentsDiv.scrollTop);
      };

      commentsDiv.addEventListener("scroll", onScroll, { passive: true });

      // Show/hide action button
      setIsActionFloatinVisible(
          // Making sure the content is overflown, 82 is leeway for the action button
          commentsDiv.scrollTop < (commentsDiv.scrollHeight - commentsDiv.offsetHeight - 82)
      );

      return () => commentsDiv.removeEventListener("scroll", onScroll);
    }
  };

  const addTaskComment = (data, dynamicFields, myCallback) => {
    let response = null;

    if (task.TaskType === "Regular") {
      response = ApiService.addTaskRegularComment(data, context);
    } else {
      response = ApiService.addTaskDocumentComment(data, context);
    }

    trackPromise(
      response
        .then(result => {
          if (result.status === 200) {
            if (dynamicFields != null) {
              saveDynamicFieldsDetails(dynamicFields, result.data?.Comments[0]?.Id, context);
              myCallback();
            }
            setNewCommentText("");
            setSelectedFiles([]);
          } else {
            console.log("API [AddTaskComment] error status: " + result.status);
          }
        })
        .catch(err => {
          console.log("API [AddTaskComment] error ->", err);
          setAppPage("500 error");
        }),
      "task-editor-comments-area"
    ).finally(() => {
      setRenderComments(true);
    });
  };

  const getFileTypeExtension = filename => {
    return filename?.substring(filename?.lastIndexOf(".") + 1, filename?.length) || filename;
  };

  // If you make improvements here - double check getDownloadingEndpointMac
  const getDownloadingEndpoint = (fileId, fileContentType, fileName) => {
    let response = null;
    let downloadedFileType = getFileTypeExtension(fileName);
    let fileDownloadDetails = {
      Id: fileId,
      AttachmentType: downloadedFileType,
      TaskId: task.Id,
      Scroll: scrollPosition.toString()
    };

    if (task.TaskType === "Regular") {
      response = ApiService.downloadRegularCommentAttachment(fileId, fileContentType, fileDownloadDetails, context);
    } else if (task.TaskType === "Document") {
      response = ApiService.downloadCommentAttachment(fileId, fileContentType, fileDownloadDetails, context);
    }
    return response;
  };

  const getDownloadingEndpointMac = (fileId, fileContentType, fileName) => {
    let response = null;
    let downloadedFileType = getFileTypeExtension(fileName);
    let fileDownloadDetails = {
      Id: fileId,
      AttachmentType: downloadedFileType,
      TaskId: task.Id,
      Scroll: scrollPosition.toString()
    };

    const downloadURL = `${window.location.origin}/assets/download_file_Mac.html?apiUrl=${context.apiEndpoint}&id=${fileId}&taskType=${task.TaskType}&fileType=${fileContentType}&scroll=${fileDownloadDetails.Scroll? fileDownloadDetails.Scroll : "0"}&taskId=${fileDownloadDetails.TaskId}&fileName=${encodeURIComponent(fileName)}&token=${context.userToken}`;
    Office.context.ui.openBrowserWindow(downloadURL);
  };

  const downloadSingleFile = (file) => {
    console.log("This is file for PowerPoint", file);
    if ((context.hostName === "OutlookIOS") || (context.platform === "Mac")) {
      getDownloadingEndpointMac(file.FileId, file.FileContentType, file.Name);
    } else {
      trackPromise(
        getDownloadingEndpoint(file.FileId, file.FileContentType, file.Name).then(
          (result) => {
            const blob = new Blob([result.data]);
            saveAs(blob, file.Name);
          },
          (error) => {
            setDisplayAlert("The file could not be downloaded");
            console.log(error);
          }
        ),
        "pdf-viewer"
      );
    }
  };

  // We just send the commentId. Backend will process all the files. And send back the ZIP file.
  // Note#1: currently implemented for Mac. Later will replace front-end ZIp file processing.
  const downloadMultipleFilesMac = async (commentId) => {
    let date = new Date();
    let dateStr = "Attachments-" + date.getDate() + "_" + (date.getMonth() + 1) + "_" + date.getFullYear();
    let dateStrZip = dateStr + ".zip";

    if (task.TaskType === "Regular") {
      await ApiService.downloadAllAttachmentsRegularZip(commentId, context).then(
        (result) => {
          const blob = new Blob([result.data]);
          saveAs(blob, dateStrZip);
        },
        (error) => {
          setDisplayAlert("The file could not be downloaded");
          console.log(error);
        }
      );
    } else {
      await ApiService.downloadAllAttachmentsDocumentZip(commentId, context).then(
        (result) => {
          const blob = new Blob([result.data]);
          saveAs(blob, dateStrZip);
        },
        (error) => {
          setDisplayAlert("The file could not be downloaded");
          console.log(error);
        }
      );
    }
  };

  const downloadMultipleFiles = (filesList) => {
    let zip = new JSZip();
    let date = new Date();
    let dateStr = "Attachments-" + date.getDate() + "_" + (date.getMonth() + 1) + "_" + date.getFullYear();
    let dateStrZip = dateStr + ".zip";
    let folder = zip.folder(dateStr);

    trackPromise(
      Promise.all(
        filesList.map((file, index) => {
          return getDownloadingEndpoint(file.FileId, file.FileContentType, file.Name)
            .then(result => {
              if (result.status === 200) {
                const blob = new Blob([result.data], {
                  type: file.FileContentType
                });
                return [file, index, blob];
              } else {
                console.log("API [DownloadCommentAttachment] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [DownloadCommentAttachment] error ->", e);
            });
        })
      )
        .then(values => {
          values.forEach(value => {
            folder.file(filesList[value[1]].Name, value[2]);
          });
          zip.generateAsync({ type: "blob" }).then(function(content) {
            saveAs(content, dateStrZip);
          });
        })
        .catch(e => {
          console.log("API [DownloadCommentAttachment] error ->", e);
        }),
      "task-editor-comments-area"
    );
  };

  const updateScrollInTask = () => {
    /* To be used once scroll is finished -> sessionStorage.removeItem("scrollPosition"); */
    // 1. Remove session storage
    sessionStorage.removeItem("downloadedTaskId");
    sessionStorage.removeItem("downloadedScroll");
    // 2. Add new session storage
    sessionStorage.setItem("downloadedTaskId", task.Id);
    sessionStorage.setItem("downloadedScroll", scrollPosition);
  };

  const onDownloadingFiles = (filesList) => {
    const isMultipleFiles = Array.isArray(filesList);

    if (isMultipleFiles && filesList.length > 1) {
      if ((context.hostName === "OutlookIOS") || (context.platform === "Mac")) {
        //downloadMultipleFiles(filesList[0].TaskCommentId);
        const downloadURL = `${window.location.origin}/assets/download_file_Mac.html?apiUrl=${context.apiEndpoint}&id=${filesList[0].TaskCommentId}&taskType=${task.TaskType}&multipleFiles=true&token=${context.userToken}`;
        Office.context.ui.openBrowserWindow(downloadURL);
      } else {
        downloadMultipleFiles(filesList);
      }
    } else {
      downloadSingleFile(filesList);
    }

    updateScrollInTask();
  };

  //In the future if any comment content needs to be filtered.
  const filterCommentStrings = string => {
    return string;
  };

  const checkNoComment = string => {
    let newComment = string.replace(/[^\w\s]/gi, "");
    if (newComment === "No comments entered") {
      return "taskAuditComment taskAuditNoComment mt-1 text-break";
    } else {
      return "taskAuditComment taskAuditNormalComment mt-1 text-break";
    }
  };

  const onDownloadingEmailDocumentFile = (index) => {
    if(index ===0 ){
      let lastFile = comments[index]?.Files.length - 1;
      downloadSingleFile(comments[index]?.Files[lastFile]);
    }
    else{
      downloadSingleFile(comments[index]?.Files[0]);
    }
  };

  const getTaskRole = comment => {
    if(comment?.ActionId == 16)
      return "System notification";
    if (comment?.CreatedById === task?.CreatedById) return "Task Owner";
    for (let i = 0; i < task?.Members?.length; i++) {
      if (task.Members[i].UserId === comment?.CreatedById) {
        if (task.Members[i].CanApprove) return "Approver";
        else if (task.Members[i].CanEdit || task.Members[i].IsAssigned) return "Editor";
        else if (task.Members[i].CanView) return "Viewer";
      }
    }
    if(task?.Groups?.length > 0) {
      let role = "";
      for(let i = 0 ; i < task?.Groups?.length; i++){
        for(let j = 0; j < task?.Groups[i]?.GroupMembers?.length; j++){
          if(task?.Groups[i]?.GroupMembers[j]?.UserId === comment?.CreatedById){
            if(task?.Groups[i]?.IsOwner) role = "Task Owner";
            else if(task?.Groups[i]?.CanApprove && (role == "" || role == "Editor" || role == "Viewer")) role = "Approver";         
            else if(task?.Groups[i]?.CanEdit && (role == ""|| role == "Viewer")) role = "Editor";
            else if(task?.Groups[i]?.CanView && role == "") role = "Viewer";
          }
        }      
      }
      return role;      
    }    
    return "";
  };

  return (
    <Fragment>
      <div className="commentFlex">
        <div className="pl-3 pr-3 commentsAudit" id="commentsAudit" onScroll={getCommentsScrollPosition}>
          {comments?.length > 0 &&
            comments.map((comment,index) => (
              <div key={comment?.Id}>
                <TaskAuditSingleComment
                  comment={comment}
                  downloadFiles={onDownloadingFiles}
                  userInitials={comment?.CreatedBy?.Initials}
                  startDate={comment?.Created}
                  actionType={comment?.Action}
                  userName={comment?.CreatedBy?.Name}
                  userEmail={comment?.CreatedBy?.Email}
                  commentClass={checkNoComment(comment?.Note)}
                  userComments={filterCommentStrings(comment?.Note)}
                  documentList={comment?.Files}
                  emailTask={comment?.Action == "Email Sent" || 
                             comment?.Action == "Email Response - Internal" || comment?.Action == "Email Response - External" ||
                             comment?.Action == "Email Forwarded - Internal" || comment?.Action == "Email Forwarded - External" ||
                             comment?.Action == "Out of Office"} 
                  downloadEmailDocument={onDownloadingEmailDocumentFile}
                  taskRole={getTaskRole(comment)}
                  index={index}
                  isLastComment = {index == comments?.length-1}
                  readCount={comment?.ReadCount}
                /> 
                <div ref={commentsEndRef} />
              </div>
            ))}
          {
            isActionFloatinVisible || areUnreadCommentsPresent ?
                <OverlayTrigger
                    placement="top-end"
                    show={actionsTooltipVisible}
                    trigger={["hover", "hover"]}
                    onToggle={toggle => setActionsTooltipVisible(toggle)}
                    overlay={<Tooltip>{areUnreadCommentsPresent ? "View new updates" : "Scroll to the latest update"}</Tooltip>}
                >
                  <div
                      className={areUnreadCommentsPresent ? "floating-action-button-new" : "floating-action-button-latest"}
                      onClick={scrollToLatestComment}
                  ></div>
                </OverlayTrigger>
                : null
          }
          
        </div>
        
            
        <div>
          <TaskAuditDynamicCommentForm
            task={task}
            taskType={task.TaskType}
            taskId={task.Id}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            newCommentText={newCommentText}
            setNewCommentText={setNewCommentText}
            addTaskComment={addTaskComment}
            setRenderComments={setRenderComments}
            className="fixed-bottom pl-1 pr-1 whiteBackground pb-3 pt-3"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TaskAudit;
