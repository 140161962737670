import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import chevronDownIcon from "../../resources/Icons/ChevronDown-14px-Blue.svg";
import chevronUpIcon from "../../resources/Icons/ChevronUp-14px-Blue.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./TaskRemindersHeader.scss";

const TaskRemindersHeader = ({ expandReminders, setExpandReminders, headers }) => {
    let newQtyCompletedReminders = 0;
    let newQtyScheduledReminders = 0;
    let totalReminders = 0;
    let stringCounter = "";

    for (let header of headers) {
        newQtyScheduledReminders = newQtyScheduledReminders + header.QtyScheduledReminders ?? 0;
        newQtyCompletedReminders = newQtyCompletedReminders + header.QtyCompletedReminders ?? 0;
    }

    totalReminders = newQtyScheduledReminders + newQtyCompletedReminders;
    stringCounter = `${newQtyCompletedReminders}/${totalReminders}`;

    let badgeColor = "notStartedBadge";
    if ((newQtyCompletedReminders > 0) && (newQtyCompletedReminders === totalReminders)) {
        badgeColor = "triggeredBadge";
    } else  if ((newQtyCompletedReminders > 0) && (newQtyCompletedReminders < totalReminders)) {
        badgeColor = "partiallyTriggeredBadge";
    }

    return (
        <span className="mr-2 task-reminders-header-container">
            <OverlayTrigger
                placement="bottom-end"
                delay={{ show: 250, hide: 250 }}
                overlay={
                <Tooltip id="tooltip-1" className="task-reminders-header-tooltip">
                    {
                    <span className="tooltip-container">
                        <span className="">
                            <div className="tooltip-row" style={{ textAlign: "left", padding: "4px", fontSize: "0.75rem", borderBottom: "1px solid #FFFFFF" }}>
                                Triggered {newQtyCompletedReminders}
                            </div>
                            <div className="tooltip-row" style={{ textAlign: "left", padding: "4px", fontSize: "0.75rem", borderBottom: "1px solid #FFFFFF" }}>
                                Scheduled {newQtyScheduledReminders}
                            </div>
                            <div className="tooltip-row" style={{ textAlign: "left", padding: "5px", fontSize: "0.75rem" }}>
                                Total {totalReminders}
                            </div>
                        </span>
                    </span>
                    }
                </Tooltip>
                }
            >
                <span className={`badge statusBadge ${badgeColor} appFont task-reminders-counter`}>
                    {stringCounter}
                </span>
            </OverlayTrigger>
            <button
                style={{ cursor: "pointer", border: "none", background: "none", padding: 0 }}
                className="task-icons task-reminder-icon"
                onClick={setExpandReminders}
                aria-label="Expand Reminders"
            >
                <img
                    src={expandReminders ? chevronUpIcon : chevronDownIcon}
                    alt="Expand Reminders"
                />
            </button>
        </span>
    );
};

TaskRemindersHeader.propTypes = {
    expandReminders: PropTypes.bool.isRequired,
    setExpandReminders: PropTypes.func,
    headers: PropTypes.array.isRequired,
};

export default TaskRemindersHeader;
