import React from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";

const LastActivity = ({ lastActivity, lastActivityDateTime }) => {
    return (
        <OverlayTrigger
            placement={"top"}
            delay={{ show: 150, hide: 150 }}
            overlay={
                <Tooltip>
                    <div>
                        {lastActivityDateTime}
                    </div>
                    <div>
                        {lastActivity}
                    </div>
                </Tooltip>
            }
        >
            <span className="badge statusBadge notStartedBadge appFont statusBadgeSpan">{lastActivity}</span>
        </OverlayTrigger>
    );
};
export default LastActivity;