import React from "react";
import usingComputer from "../../resources/Images/Image - Using Computer.svg";
import "../email-tracking/EmailTracking.scss";

const EmailTrackingTaskDetailsPlaceholder = () => {
  return (
    <div className="email-tracking-task-details">
      <div className="img-container m-1">
        <img src={usingComputer} className="center using-computer-image"></img>
      </div>
      <div className="center create-task-from-email m-1">Email is Linked to a Task</div>
      <div className="ml-2 create-task-from-email-description m-1 mb-3">
        This email thread is being tracked as a Task on TRIYO
      </div>
    </div>
  );
};

export default EmailTrackingTaskDetailsPlaceholder;
