import React, { useEffect, useState } from 'react';
import './TriyoForm.scss';
import { Form } from 'react-bootstrap';

const TriyoForm = (props) => {
    const { children } = props;
    return (
        <div className="triyo-form">
            {children}
        </div>
    );
}

TriyoForm.Label = (props) => {
    const { className, label, required } = props;
    return <Form.Label className={className}>{label || "Label"}{required && <span className="input-required">{" *"}</span>}</Form.Label>
}

TriyoForm.Input = (props) => {
    const { children, label, required, value, onChange, isInvalid, placeholder, type, errorMessage, hideLabel, labelClassName, inputClassName, errorClassName } = props;
    return (
        <Form.Group>
            {!hideLabel &&
                <TriyoForm.Label className={labelClassName} label={label} required={required} />
            }
            <Form.Control
                className={inputClassName}
                value={value}
                onChange={onChange}
                isInvalid={isInvalid}
                type={type || "text"}
                placeholder={placeholder}
            />
            <Form.Control.Feedback className={errorClassName} type="invalid">
                {errorMessage || "Required value"}
            </Form.Control.Feedback>
            {children}
        </Form.Group>
    )
}

TriyoForm.Toggle = (props) => {
    const { className, checked, disabled, onChange, id, label } = props;
    return (
        <Form.Switch
            className={className + " triyo-toggle"}
            id={id}
            disabled={disabled}
            type="switch"
            checked={checked}
            onChange={onChange}
            label={label}
        />
    )
}

const Checkboxes = (props) => {
    const { className, lookupValues, value, valueType = "text", onChange, disabled } = props;
    const [checked, setChecked] = useState(new Set(value.split(", ")));

    useEffect(() => {
        if (valueType === "text") {
            onChange(Array.from(checked).join(', '))
        }
        else {
            onChange(Array.from(checked))
        }
    }, [checked])

    useEffect(() => {
        if (value === "TriyoReset") {
            setChecked(new Set("".split(", ")));
        }
    }, [value])

    const handleChange = (newValue) => {
        if (checked.has(newValue)) {
            setChecked(prev => new Set([...prev].filter(x => x !== newValue)))
        }
        else {
            setChecked(prev => new Set(prev.add(newValue)))
        }
    }

    return (
        <TriyoForm>
            <div className={className + " triyo-multi-options"}>
                {lookupValues?.map((item) => {
                    return (<Form.Check
                        className="checkbox"
                        type="checkbox"
                        disabled={disabled}
                        checked={checked.has(item)}
                        onChange={() => { handleChange(item) }}
                        label={item}
                    />)
                })}
            </div>
        </TriyoForm>
    )
}
TriyoForm.Checkboxes = Checkboxes;

TriyoForm.RadioButtons = (props) => {

    const { className, lookupValues, value, onChange } = props;

    return (
        <TriyoForm>
            <div className={className + " triyo-multi-options"}>
                {lookupValues?.map((item) => {
                    return (<Form.Check
                        className="checkbox-circular"
                        type="checkbox"
                        checked={value === item}
                        onChange={() => { onChange(item) }}
                        label={item}
                    />)
                })}
            </div>
        </TriyoForm>
    );
}

TriyoForm.Divider = () => {
    return <div className="border"></div>
}

export default TriyoForm;