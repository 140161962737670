import React from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import { exclamationBlueIcon, exclamationOrangeIcon, exclamationRedIcon, exclamationMaroonIcon } from "../../resources/Icons/icons";

const priorityDetails = {
  "Low": { icon: exclamationBlueIcon },
  "Medium": { icon: exclamationOrangeIcon },
  "High": { icon: exclamationRedIcon },
  "Urgent": { icon: exclamationMaroonIcon }
}

const TaskPriority = ({ priority }) => {
    const detail = priorityDetails[priority] || null;

    return detail ? (
      <div className={`taskPriority${priority}`}>
        <img src={detail.icon} alt={priority}/> {priority}
      </div>
    ) : <div>-</div>;
}

TaskPriority.propTypes = {
  priority: PropTypes.oneOf(['Low', 'Medium', 'High', 'Urgent'])
};

export default TaskPriority;
