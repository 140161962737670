import ApiService from "../../../services/ApiService";
import React, {useEffect} from "react";
import TaskDynamicFieldsForm from "../Form/TaskDynamicFieldsForm";
import { createDynamicInputChangeHandler } from "./fieldsUtils";
export const DISABLE_DYNAMIC_FIELDS = false;

export const FIELD_TYPES = {
    singleText: 'Short Text Entry',
    multiText: 'Long Text Entry',
    dropDown: 'Dropdown',
    radioButtons: 'Radio Buttons',
    checkbox: 'Checkbox',
    date: 'Date',
    time: 'Time',
    integer: 'Numeric (Integer)',
    decimal: 'Numeric (Decimal)',
    table: 'Table',
    toggle: 'Toggle',
};

export const OBJECT_TYPES = {
    taskComment: 'TaskComment',
    taskDetail: 'TaskDetail',
    project: 'Project'
};

export const FIELD_REGEX_DATA_TYPES = {
    any: null,
    string: 'String',
    int: 'Integer',
    number: 'Decimal',
    time: 'HoursMinutes',
};

const defaultField = { Label: '', Required: false };

const newFieldsDropdownItems = [
    {
        iconCSSClass: 'text-icon',
        name: 'Long Text Entry',
        field: { ...defaultField, FieldType: FIELD_TYPES.multiText, Placeholder: 'Enter Comments' },
    },
    {
        iconCSSClass: 'text-icon',
        name: 'Short Text Entry',
        field: {
            ...defaultField,
            FieldType: FIELD_TYPES.singleText,
            Placeholder: 'Enter Comments',
        },
    },
    {
        iconCSSClass: 'date-icon',
        name: 'Date',
        field: { ...defaultField, FieldType: FIELD_TYPES.date, Placeholder: 'DD/MM/YYYY' },
    },
    {
        iconCSSClass: 'clock-icon',
        name: 'Time',
        field: { ...defaultField, FieldType: FIELD_TYPES.time, Placeholder: 'HH:MM' },
    },
    {
        iconCSSClass: 'number-icon',
        name: 'Numeric (Integer)',
        field: { ...defaultField, FieldType: FIELD_TYPES.integer, Placeholder: '###' },
    },
    {
        iconCSSClass: 'number-icon',
        name: 'Numeric (Decimal)',
        field: { ...defaultField, FieldType: FIELD_TYPES.decimal, Placeholder: '#.#' },
    },
    {
        iconCSSClass: 'text-icon',
        name: 'Dropdown',
        field: { ...defaultField, FieldType: FIELD_TYPES.dropDown, Placeholder: 'Enter Text', LookupValues: ["", ""], Lookup: true },
    },
    {
        iconCSSClass: 'checkbox-icon',
        name: 'Checkbox',
        field: { ...defaultField, FieldType: FIELD_TYPES.checkbox, Placeholder: 'Enter Text', LookupValues: ["", ""], Lookup: true },
    },
    {
        iconCSSClass: 'radio-icon',
        name: 'Radio Buttons',
        field: { ...defaultField, FieldType: FIELD_TYPES.radioButtons, Placeholder: 'Enter Text', LookupValues: ["", ""], Lookup: true },
    },
    {
        iconCSSClass: 'text-icon',
        name: 'Table',
        field: { ...defaultField, FieldType: FIELD_TYPES.table, Placeholder: '', Table: { Columns: [] } },
    },
    {
        iconCSSClass: 'toggle-icon',
        name: 'Toggle',
        field: { ...defaultField, FieldType: FIELD_TYPES.toggle, Placeholder: 'Enter Text' },
    },
];

export function getDefaultPlaceholderForFieldType(fieldType) {
    return (
        newFieldsDropdownItems.find(({ field }) => field.FieldType === fieldType)?.field
            .Placeholder ?? ''
    );
}

export function renderTaskDetailDynamicFields({ dynamicFields, setDynamicFields, disableErrors, onChange }) {
    if (!dynamicFields || dynamicFields.length <= 0) return null;

    return dynamicFields.map((field) => {
        return (
            <TaskDynamicFieldsForm key={field}
                field={field}
                handleChange={createDynamicInputChangeHandler({
                    field,
                    setDynamicFields,
                })}
                setDynamicFields={setDynamicFields}
                disableErrors={disableErrors}
                onChange={onChange}
            />
        );
    });
}


export async function saveDynamicFieldsDetails(dynamicFields, assignID, context) {
    const dynamicFieldsData = dynamicFields?.map((field) => ({
        DynamicFieldId: field.Id,
        Data: field.Data,
        Table: field.Table,
        ObjectId: assignID,
    }));
    return ApiService.saveDynamicFields(dynamicFieldsData, context);
}

export async function fetchDynamicFields({ projectTypeId, taskTypeId, objectId, objectType }, context) {
    try {
        const dynamicFields = await ApiService.getDynamicFields({
            projectTypeId,
            taskTypeId,
            objectType,
            objectId,
        }, context);
        return dynamicFields.data.map((field) => ({
            ...field,
            Data: field.Data ?? '',
            touched: false,
        }));
    } catch (error) {
        return [];
    }
}

export function isMaskValid(field) {
    const { Data, FieldMask } = field;

    if (!FieldMask) return true;
    if (Data.length !== FieldMask.length) return false;

    for (let i = 0; i < FieldMask.length; i++) {
        const maskCh = FieldMask[i];
        const fieldCh = Data[i];

        switch (maskCh) {
            case '#':
                if (fieldCh < '0' || fieldCh > '9') return false;
                break;
            default:
                if (maskCh !== fieldCh) return false;
        }
    }

    return true;
}

export function isRegexValid(field) {
    const { Data, FieldRegex, FieldRegexDataType } = field;

    if (Data === "") return true;
    if (FieldRegex) {
        const regex = new RegExp(FieldRegex);
        if (!regex.test(Data)) return false;
    }
    switch (FieldRegexDataType) {
        case FIELD_REGEX_DATA_TYPES.int:
            return Number.isInteger(Number(Data));
        case FIELD_REGEX_DATA_TYPES.string:
            return typeof Data === 'string' || Data instanceof String;
        case FIELD_REGEX_DATA_TYPES.number:
            const dataNumber = Number(Data);
            return typeof dataNumber === 'number' && !Number.isNaN(dataNumber);
        default:
            return true;
    }
}

export function isFieldTypeValid(field) {
    switch (field.FieldType) {
        case FIELD_TYPES.time: {
            // split "HH:MM" into [HH, MM] and convert them to numbers
            const [HH, MM] = field.Data.split(':').map(Number);
            // check if either are falsy and not 0
            if ([HH, MM].some((val) => !val && val !== 0)) return false;
            else return HH >= 0 && MM >= 0 && MM <= 59;
        }
        default:
        // should not reach here
    }
    return true;
}

// field: DynamicFieldDto
export function isDynamicTableFieldDataValid(field, row, columnId) {
    if (!field.Table) return;
    if (!field.Table.Rows) return;

    const existingRow = field.Table.Rows.find((r) => r.Row === row);

    if (!existingRow) return;
    if (!existingRow.Data) return;

    const rowData = existingRow.Data.find((d) => d.ColumnId == columnId);

    if (!rowData) return;

    if (rowData.ColumnType === 'Date') {
        const isDate = (date) => {
            return (new Date(date).toString() !== "Invalid Date") && !isNaN(new Date(date));
        }

        return rowData.Value && isDate(rowData.Value);
    }
}

export function isDynamicFieldValid(field) {
    const { Required } = field;

    if (field.FieldType === FIELD_TYPES.table) {
        const hasRows = field.Table.Rows?.length > 0;
        const hasValues = field.Table.Rows?.some((rowData) => rowData.Value !== '');

        return Required ? hasRows && hasValues : true;
    } else {
        const { Data } = field;
        const isEmpty = Data === '' || Data == null;
        const isWhitespace = !/\S/.test(Data);
        const isPassedChecks = [isMaskValid, isRegexValid, isFieldTypeValid].every((func) =>
            func(field)
        );

        return Required ? isPassedChecks && !isEmpty && !isWhitespace : isEmpty || isPassedChecks;
    }
}

// dynamicFields: DynamicFieldDto[]
export function isDynamicFieldsValid(dynamicFields) {
    for (const field of dynamicFields) {
        if (!isDynamicFieldValid(field)) return false;
    }

    return true;
}

export function isAllDynamicFieldsEmpty(dynamicFields) {
    for (const field of dynamicFields) {
        const { Data, Table } = field;
        if (field.FieldType !== "Table") {
            var isEmpty = Data === '';
            var isWhitespace = !/\S/.test(Data);
        }
        else {
            isEmpty = Table === null;
            isWhitespace = false;
        }



        if (!isEmpty && !isWhitespace) return false;
    }
    return true;
}

export function isUserViewer(user, taskMembers) {
    if (!user || !taskMembers) return false;
    const userInTask = taskMembers.find((member) => member.userID === user.profile.userID);

    return (userInTask?.permission === 2 || userInTask?.view) ?? false;
}


export function useAutoRefreshForAddin(fetchMetrics, refreshAutoTime) {
    useEffect(() => {
        const interval = setInterval(fetchMetrics, refreshAutoTime);
        return () => clearInterval(interval);
    }, []);
}