import "../dynamicFields.scss";
import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import DynamicFieldFilter from "./DynamicFieldFilter";
import { Form } from "react-bootstrap";
import { isDynamicTableFieldDataValid } from "../utils/utils";
import filterTableIcon from "../../../../assets/GearView_Icon_16px_Black.svg";

import boardPlus from "../../../../assets/Icon-Plus-13px-Blue.svg";
import Moment from "react-moment";
import { createNewDynamicTableRowHandler, deleteDynamicTableRowHandler } from "../utils/fieldsUtils";

const showColumnTypes = (rowData, field, row, enableScroll, disableScroll, isTableDisabled, handleChange) => {
  if (isTableDisabled) {
    if (rowData.Value) {
      return rowData.ColumnType === "Date" ? (
        <Moment format="MM/DD/YYYY">{rowData.Value}</Moment>
      ) : (
        <div className="w-100">{rowData.Value}</div>
      );
    } else {
      return <div className="w-100 disabled-text">{rowData.Value}</div>;
    }
  }
  switch (rowData.ColumnType) {
    case "Date":
      return (
        <div className="w-100">
          <DatePicker
            selected={
              rowData.Value && isDynamicTableFieldDataValid(field, row.Row, rowData.ColumnId)
                ? new Date(rowData.Value)
                : ""
            }
            onChange={date => handleChange(row.Row, rowData.ColumnId, date)}
            placeholderText="YYYY/MM/DD"
            className={`form-control ${
              rowData.Value && !isDynamicTableFieldDataValid(field, row.Row, rowData.ColumnId) ? "is-invalid" : ""
            }`}
            dateFormat="yyyy/MM/dd"
          />
          <Form.Control
            style={{ display: "none" }}
            isInvalid={rowData.Value && !isDynamicTableFieldDataValid(field, row.Row, rowData.ColumnId)}
          />
        </div>
      );
    case "String":
      return (
        <Form.Control
          type={"text"}
          value={rowData.Value}
          placeholder={`Enter ${field.Table.Columns.find(col => col.Id == rowData.ColumnId)?.Name}`}
          onChange={e => handleChange(row.Row, rowData.ColumnId, e.target.value)}
        />
      );
    case "Numeric":
      return (
        <Form.Control
          type={"number"}
          step="any"
          value={rowData.Value}
          onWheel={() => {}}
          onBlur={enableScroll}
          onFocus={disableScroll}
          placeholder="Enter number"
          onChange={e => handleChange(row.Row, rowData.ColumnId, e.target.value)}
        />
      );
    case "Lookup":
      return (
        <Form.Control
          as="select"
          defaultValue={rowData.Value}
          onChange={e => handleChange(row.Row, rowData.ColumnId, e.target.value)}
        >
          <option value="">-Select-</option>
          {rowData.LookupValues?.map((lookupValue, idx) => (
            <option value={lookupValue} key={idx}>
              {lookupValue}
            </option>
          ))}
        </Form.Control>
      );
    default:
      return <div className="w-100 disabled-text">{rowData.Value}</div>;
  }
};

const addRow = (field, setDynamicFields) => {
  setDynamicFields(oldDynamicFields =>
    oldDynamicFields.map(oldField =>
      oldField.Id === field.Id
        ? {
            ...oldField,
            Table: {
              ...oldField.Table,
              Rows: []
            }
          }
        : oldField
    )
  );
};

const TableHeaders = ({ headers, isTableDisabled, tableClassName, tablePinnedList }) => (
  <thead>
    <tr style={{ height: 40 }}>
      {headers.map((header, index) => {
        let cellClassName = tableClassName(header.Name) + "-" + header.Id;
        let isCellPinned = tablePinnedList.find(x => x.colId === cellClassName)?.colPinned;
        let pinClassName = isCellPinned ? "active" : "";

        return (
          <th className={`col-${cellClassName} ${pinClassName}`} key={index}>
            {header.Name}
          </th>
        );
      })}
      {!isTableDisabled && <th className="deleteColumn"></th>}
    </tr>
  </thead>
);

const TableRow = ({
  row,
  index,
  field,
  enableScroll,
  disableScroll,
  isTableDisabled,
  handleChange,
  setDynamicFields,
  tableClassName,
  tablePinnedList
}) => {
  return (
    <tr key={row.Row} style={{ height: 40 }}>
      {row.Data.map(rowData => {
        let cellClassName = tableClassName(rowData.ColumnName) + "-" + rowData.ColumnId;
        let isCellPinned = tablePinnedList.find(x => x.colId === cellClassName)?.colPinned;
        return (
          <td key={rowData.ColumnId + "_" + row.Row} className={`col-${cellClassName} ${isCellPinned ? "active" : ""}`}>
            {showColumnTypes(rowData, field, row, enableScroll, disableScroll, isTableDisabled, handleChange)}
          </td>
        );
      })}
      {!isTableDisabled && (
        <td className="deleteColumn">
          <div
            className="delete-icon"
            onClick={() => deleteDynamicTableRowHandler(field, row.Row, setDynamicFields)}
          ></div>
        </td>
      )}
    </tr>
  );
};

const DynamicFieldTable = props => {
  const {
    fieldHeaders,
    fieldRows,
    field,
    enableScroll,
    disableScroll,
    isTableDisabled,
    handleChange,
    setDynamicFields
  } = props;
  const [showTableFilter, setShowTableFilter] = useState(false);
  const [tablePinnedList, setTablePinnedList] = useState([]);
  const [customColHeaders, setCustomColHeaders] = useState(fieldHeaders);
  const [customColRows, setCustomColRows] = useState(fieldRows);
  const tablePopupRef = useRef();
  const documentClickHandler = useRef();
  const headers = fieldHeaders;
  const rows = fieldRows;
  const titleTable = field.Label;
  let isTablePinned = tablePinnedList.some(x => x.colPinned === true);
  useEffect(() => {
    documentClickHandler.current = e => {
      if (tablePopupRef.current?.contains(e.target)) return;

      setShowTableFilter(false);
      removeDocumentClickHandler();
    };
  }, []);

  useEffect(() => {
    setCustomColRows(field.Table.Rows);
  }, [field]);

  const removeDocumentClickHandler = () => {
    document.removeEventListener("click", documentClickHandler.current);
  };

  const handleToggleFilterClick = () => {
    if (showTableFilter) return;
    setShowTableFilter(true);
    document.addEventListener("click", documentClickHandler.current);
  };

  const handleCloseFilterClick = () => {
    setShowTableFilter(false);
    removeDocumentClickHandler();
  };

  const convertToClassName = cellName => {
    return cellName.toLowerCase().replace(/[^A-Z0-9]+/gi, "-");
  };

  // if(!rows){
  //   createNewDynamicTableRowHandler(field, setDynamicFields);
  // }

  const resetColTables = (newHeaders, newRows) => {
    newHeaders.sort(function(a, b) {
      return a.Id - b.Id;
    });
    newRows.forEach(newRow => {
      newRow.Data.sort(function(a, b) {
        return a.Id - b.Id;
      });
    });
  };

  const orderingColTables = () => {
    let newHeaders = [...headers];
    let newRows = [...rows];

    resetColTables(newHeaders, newRows);

    if (isTablePinned) {
      let updatedColItem = tablePinnedList.find(x => x.colPinned === true);
      let sendColToFirst = updatedColItem.colName;

      // Improve classname reference to link tables and ids. Task required quick solution 
      newHeaders.some(
        (item, idx) => item.Name + "-" + item.Id === sendColToFirst && newHeaders.unshift(newHeaders.splice(idx, 1)[0])
      );
      newRows.forEach(newRow => {
        newRow.Data.some(
          (item, idx) =>
            item.ColumnName + "-" + item.ColumnId === sendColToFirst &&
            newRow.Data.unshift(newRow.Data.splice(idx, 1)[0])
        );
      });
    }

    setCustomColHeaders(newHeaders);
    setCustomColRows(newRows);
  };

  return (
    <div className="table-fixed-content">
      {/* Table Title */}
      <div className="table-label-container">
        <span>{titleTable}</span>
        <div className="table-img">
          <img src={filterTableIcon} alt="Filter icon" onClick={handleToggleFilterClick} />
          {rows && (
            <DynamicFieldFilter
              tablePopupRef={tablePopupRef}
              showTableFilter={showTableFilter}
              handleCloseFilter={handleCloseFilterClick}
              columnItems={headers}
              tableClassName={convertToClassName}
              setTablePinnedList={setTablePinnedList}
              orderingColTables={orderingColTables}
            />
          )}
        </div>
      </div>
      {/* Table Body */}
      <div className="outer-container">
        <div className="inner-container">
          <table>
            <TableHeaders
              headers={customColHeaders}
              isTableDisabled={isTableDisabled}
              tableClassName={convertToClassName}
              tablePinnedList={tablePinnedList}
            />
            <tbody>
              {customColRows == null
                ? addRow(field, setDynamicFields)
                : customColRows.map((row, index) => (
                    <TableRow
                      row={row}
                      index={index}
                      field={field}
                      enableScroll={enableScroll}
                      disableScroll={disableScroll}
                      isTableDisabled={isTableDisabled}
                      handleChange={handleChange}
                      setDynamicFields={setDynamicFields}
                      tableClassName={convertToClassName}
                      tablePinnedList={tablePinnedList}
                    />
                  ))}
              {/* Table Button */}
              {!isTableDisabled ? (
                <tr className="table-btn-container">
                  <td>
                    <div
                      type="button"
                      className="table-btn"
                      onClick={() => createNewDynamicTableRowHandler(field, setDynamicFields)}
                    >
                      Add row
                      <img src={boardPlus} alt="plus icon" />
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DynamicFieldTable;
