import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import { trackPromise} from "react-promise-tracker";
import ApiService from "../../services/ApiService";
import FileTransferService from "../../services/FileTransferService";
import AppContext from "../../app/AppContext";

class TaskAction extends Component {
    state = {
        commentNote: "",
        hide: false,
        action: this.props.action ?? "Cancel",
    };

    static contextType = AppContext;

    onChangeCommentNote = e => {
        this.setState({commentNote: e.target.value});
    };

    hideContent = hidden => {
        if(this.state.hide != hidden){
            this.setState({ hide: hidden });
        }
    }

    onCancelTask = (currCommentId) => {
        FileTransferService.uploadDocumentFile(Office.context, this.cancelSubmitTask, currCommentId);
    };

    cancelSubmitTask = (docdataSlices, currCommentId) => {
        let classScope = this;
        let file = FileTransferService.getFileFromSlices(docdataSlices, this.context.officeAppType);
        const fd = new FormData();
        fd.append("file", file);
        fd.append("commentId", currCommentId)
        fd.append("docType", this.context.officeAppType);

        trackPromise(
            ApiService.cancelTask(fd, this.context)
            .then(result => {
                if (result.status === 200) {
                    classScope.props.setPage("taskActionSaved: Cancel");
                } else {
                    console.log("API [CancelTask] failed");
                }
            })
            .catch(e => {
                console.log("API [CancelTask] error ->");
                console.log(e);
                this.props.setPage("500 error");
            }), "task-cancel-area"
        );
    }

    addTaskComment = () => {
        const noteAction = this.state.action === "Reminder" ? 12 : 8;
        let TaskId=0;
        if(this.context.currentTask?.Id)
            TaskId=this.context.currentTask.Id;
        else  
            TaskId=this.context.currentTaskId;
            
        if(this.state.action === "Cancel" && this.context?.currentTask?.TaskType==="Regular"){           
            const obj ={TaskId : TaskId, Note : this.state.commentNote, ActionId : noteAction};
            trackPromise(
                ApiService.CancelTaskRegular(obj, this.context)
                .then(result => {
                    if (result.status === 200) {
                        this.props.setPage("taskActionSaved: Cancel");
                    } 
                    else {
                        console.log("API [AddTaskComment] error status: " + result.status);
                    }
                })
                .catch(e => {
                    console.log("API [Cancel Regular Task] error ->");
                    console.log(e);
                    this.props.setPage("500 error");
                }), "task-cancel-area"
            );
        }
        else if(this.state.action === "Reminder" && this.context?.currentTask?.TaskType==="Regular"){
            const obj ={TaskId : TaskId, Comment : this.state.commentNote, UserId : this.context.currUser.Id};
            trackPromise(
                ApiService.ReminderTaskRegular(obj, this.context)
                .then(result => {
                    if (result.status === 200) {
                        this.props.setPage("taskActionSaved: Reminder");
                    } 
                    else {
                        console.log("API [AddTaskComment] error status: " + result.status);
                    }
                })
                .catch(e => {
                    console.log("API Remind Regular Task] error ->");
                    console.log(e);
                    this.props.setPage("500 error");
                }), "task-remind-area"
            );
        }
        else{
            const fd = new FormData();
            fd.append("note", this.state.commentNote);
            fd.append("taskId", TaskId);
            fd.append("noteAction", noteAction);
            trackPromise(
                ApiService.addTaskComment(fd, this.context)
                .then(result => {
                    if (result.status === 200) {
                        if(this.state.action === "Reminder"){
                            this.props.setPage("taskActionSaved: Reminder");
                        }
                        else{
                            let currCommentId = result.data.Comments.pop().Id;
                            this.onCancelTask(currCommentId);
                        }
                    } 
                    else {
                        console.log("API [AddTaskComment] error status: " + result.status);
                    }
                })
                .catch(e => {
                    console.log("API [AddTaskComment] error ->");
                    console.log(e);
                    this.props.setPage("500 error");
                }), "task-cancel-area"
            );
        }        
    };


    render() {
        const reminderAction = this.state.action === "Reminder";
        return (
            <div>
                <LoadingOverlay area="task-cancel-area" hideContent={this.hideContent} inline="loading-overlay-inline"/>
                {!this.state.hide ?
                    <div className="mt-7 mr-3">
                    <div className="row">
                        <div className="col-12 ml-2 mr-2 appFont reassignPageTitle">
                            {reminderAction ? 'Send Reminder' : 'Cancel Task'}
                        </div>
                    </div>
                    { !reminderAction &&
                        <>
                            <div className="row">
                                <div className="col-12 ml-2 mr-2 reassignTaskName">
                                    {this.props.taskName}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 ml-2 mr-2 cancelBoxTitle">
                                    Comments (optional)
                                </div>
                            </div>
                        </>
                    }
                    <div className="row">
                        <div className="col-12 ml-2 mr-2">
                            <textarea
                                placeholder="Enter Comment"
                                className="form-control form-custom-control shadow-none task-comment-box-cancel"
                                rows="3"
                                id="commentNote"
                                name="commentNote"
                                value={this.state.commentNote}
                                onChange={event => this.onChangeCommentNote(event)}
                            ></textarea>
                        </div>
                    </div>
                    <div className="fixed-bottom ml-3 mr-3 pb-3 pt-3 whiteBackground">
                        <div className="row">
                            <div className="col-12 taskModeButtonContainer">
                                <button
                                    className="loginButton btn-primary blueButtonOutline shadow-none taskModeButton"
                                    onClick={() => this.props.setPage("taskEditor")}
                                >
                                    {reminderAction ? 'Cancel' : 'Back'}
                                </button>
                                <button
                                    className="loginButton btn-primary blueButton shadow-none float-right taskModeButton"
                                    onClick={() => this.addTaskComment()}
                                >
                                    {reminderAction ? 'Send' : this.state.action + " Task"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                    null
                }
            </div>
        );
    }
}


export default TaskAction;
