import React from "react";
import DatePicker from "react-datepicker";
import Dropdown from "../../dropdown/dropdown";
import { Form } from "react-bootstrap";
import {
  setEndDate,
} from "../../../helpers/mailLinkUtils";
import calendarIcon from "../../../resources/Icons/Icon - calender.svg";

const EmailTrackingTaskManagementSection = ({
  context,
  projectInfo,
  projectList = [],
  isProjectDisabled = false,
  setFieldTouched,
  setFieldValue,
  setCurrentProjectId,
  setProjectId,
  taskTypesName,
  taskTypes,
  prioritiesAvailable,
  taskTypeId,
  setTaskTypeId,
  taskPriority,
  setTaskPriority,
  taskTypeDue,
  setTaskTypeDue,
  taskDue,
  setTaskDue,
  endDateChange,
  setEndDateChange,
  isEditTask = false,
  isOwner,
  ...taskManagement
}) => {
  const showTaskManagementType = (
    <React.Fragment>
      <Form.Group
        className={taskManagement?.touched?.taskName && taskManagement?.errors?.taskName ? "mt-4" : ""}
        id="taskType"
      >
        <Form.Label className="loginInput appFontSubHeading">
          Task Type <i className="red">*</i>
        </Form.Label>
        <Dropdown
          values={taskTypes.map(taskType => {
            return { id: taskType.Id, name: taskType.Type };
          })}
          dropdownButtonText={taskTypesName?.length > 0 ? taskTypesName : "Select an Option"}
          onSelect={selectedValue => {
            setFieldValue("taskType", selectedValue.id);
            setFieldTouched("taskType");
            setTaskTypeId(selectedValue.id);

            const newWorkItemType = parseInt(selectedValue.id);
            const currTaskType = taskTypes.find(type => type.Id === newWorkItemType);
            if (!endDateChange) {
              const newEndDate = setEndDate(currTaskType);
              setFieldValue("taskDue", newEndDate);
              setTaskTypeDue(newEndDate);
              setTaskDue(newEndDate);
            }
          }}
          currentSelected={taskTypeId}
          onChange={(value) => {
            setTaskTypeId(value);
          }}
          onBlur={() => setFieldTouched("taskType")}
          disabled = {!isOwner}
        />
        {taskManagement?.values?.taskType === "" && taskManagement?.touched?.taskType && (
          <div type="invalid" className="error-message">
            {taskManagement?.errors?.taskType}
          </div>
        )}
      </Form.Group>      
    </React.Fragment>
  );

  const showProjectInput = () => {
    let projectInput = "";
    let projectTitle = (
      <Form.Label className="loginInput appFontSubHeading">
        Project <i className="red">*</i>
      </Form.Label>
    );
    let projectDropdown = (
      <React.Fragment>
        <Dropdown
          values={projectList.map(project => {
            return { id: project.Id, name: project.Name };
          })}
          dropdownButtonText={projectInfo?.Name?.length > 0 ? projectInfo?.Name : "Select a Project"}
          onSelect={selectedValue => {
            setCurrentProjectId(selectedValue.id);
            setProjectId(selectedValue.id);
            setFieldValue("projectId", selectedValue.id);
            setFieldValue("projectName", selectedValue.name);
            setFieldTouched("projectName");
          }}
          disabled={isProjectDisabled}
        />
        {taskManagement?.values?.projectName === "" && taskManagement?.touched?.projectName && (
          <div type="invalid" className="error-message">
            {taskManagement?.errors?.projectName}
          </div>
        )}
      </React.Fragment>
    );

    let projectFeedback = (
      <Form.Control.Feedback type="invalid" className="error-message">
        {taskManagement?.errors?.projectName}
      </Form.Control.Feedback>
    );

    projectInput = (
      <Form.Group id="projName">
        {projectTitle}
        <div className="mb-3" style={{ paddingLeft: 0 }}>
          {projectDropdown}
        </div>
        {projectFeedback}
      </Form.Group>
    );

    return projectInput;
  };

  const showPriorities = (
    <React.Fragment>
      <Form.Group
        className={taskManagement?.touched?.taskPriority && taskManagement?.errors?.taskPriority ? "mt-4" : ""}
        id="taskPriority"
      >
        <Form.Label className="loginInput appFontSubHeading">
          Priority <i className="red">*</i>
        </Form.Label>
        <Dropdown
          values={prioritiesAvailable.map(p => {
            return { id: p, name: p };
          })}
          dropdownButtonText={taskPriority?.length > 0 ? taskPriority : "Select an Option"}
          onSelect={(selectedValue) => {
            setFieldValue("taskPriority", selectedValue.id);
            setTaskPriority(selectedValue.id);
            setFieldTouched("taskPriority", selectedValue.id);
          }}
          currentSelected={taskPriority}
          onChange={(value) => {
            setTaskPriority(value);
          }}
          onBlur={() => setFieldTouched("taskPriority")}
          disabled = {!isOwner}
        />
        {taskManagement?.values?.taskPriority === "" && taskManagement?.touched?.taskPriority && (
          <div type="invalid" className="error-message">
            {taskManagement?.errors?.taskPriority}
          </div>
        )}
      </Form.Group>
    </React.Fragment>
  );

  const showCompletionDates = (
    <React.Fragment>
      <Form.Group className={taskManagement?.touched?.taskStart && taskManagement?.errors?.taskStart ? "mt-4" : ""}>
        <Form.Label className="loginInput appFontSubHeading">
          Start Date <i className="red">*</i>
        </Form.Label>
        <DatePicker
          className={`form-control form-custom-control shadow-none ${
            taskManagement?.touched?.taskStart && taskManagement?.errors?.taskStart ? "is-invalid" : ""
          }`}
          id="taskStart"
          name="taskStart"
          selected={taskManagement?.values?.taskStart}
          disabled = {!isOwner}
          onChange={(value) => {
            value.setUTCHours(23, 59, 59, 0);
            setFieldValue("taskStart", value);
          }}
          onBlur={() => setFieldTouched("taskStart")}
          minDate={
            typeof projectInfo !== "undefined" && projectInfo !== null ? Date.parse(projectInfo?.Start) : new Date()
          }
          maxDate={
            typeof taskManagement?.values?.taskDue !== "undefined"
              ? taskManagement?.values?.taskDue
              : new Date()
          }
          placeholderText="MM/DD/YYYY"
          autoComplete="off"
          popperPlacement="top-start"
        />
        <img className={isOwner?"calendar-icon" : "calendar-icon sendCommentDisable"} src={calendarIcon} alt="Calendar Icon" />
        {taskManagement?.touched?.taskStart && taskManagement?.errors?.taskStart && (
          <div type="invalid" className="error-message">
            {taskManagement?.errors?.taskStart}
          </div>
        )}
      </Form.Group>      

      <Form.Group className={taskManagement?.touched?.taskDue && taskManagement?.errors?.taskDue ? "mt-4" : ""}>
        <Form.Label className="loginInput appFontSubHeading">
          Due Date <i className="red">*</i>
        </Form.Label>
        <DatePicker
          className={`form-control form-custom-control shadow-none ${
            taskManagement?.touched?.taskDue && taskManagement?.errors?.taskDue ? "is-invalid" : ""
          }`}
          id="taskDue"
          name="taskDue"
          selected={endDateChange ? taskManagement?.values?.taskDue : taskTypeDue}
          onChange={(value) => {
            value.setUTCHours(23, 59, 59, 0);
            setFieldValue("taskDue", value);
            setTaskDue(value);
            setEndDateChange(true);
          }}
          onBlur={() => setFieldTouched("taskDue")}
          minDate={ typeof taskManagement?.values?.taskStart !== "undefined"
          ? taskManagement?.values?.taskStart
          : new Date()}
          maxDate={typeof projectInfo !== "undefined" && projectInfo !== null ? Date.parse(projectInfo?.End) : new Date()}
          placeholderText="MM/DD/YYYY"
          autoComplete="off"
          disabled = {!isOwner}
        />
        <img className={isOwner?"calendar-icon" : "calendar-icon sendCommentDisable"} src={calendarIcon} alt="Calendar Icon" />
        {taskManagement?.touched?.taskDue && taskManagement?.errors?.taskDue && (
          <div type="invalid" className="error-message">
            {taskManagement?.errors.taskDue}
          </div>
        )}
      </Form.Group>
    </React.Fragment>
  );

  return (    
    <div>
      {showTaskManagementType}
      {showProjectInput()}
      {showPriorities}      
      {showCompletionDates}
    </div>
  );
};

export default EmailTrackingTaskManagementSection;
