import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AppContext from "../../app/AppContext";
import LoadingOverlay from "../loading-overlay/loading-overlay"
import noResultsImage from "../../resources/Images/Image\ -\ No\ Projects.svg";
import Moment from "react-moment";
import NotificationsFail from "./NotificationsFail";
import { convertUTCDateToLocalDate } from "../../helpers/HelperMethods";
import { trackPromise } from "react-promise-tracker";
import ApiService from "../../services/ApiService";

class Notifications extends Component {
  state = {
    notificationsList: [],
    filterList: "",
    hide: false,
    task: null,
    currentProject: null,
    currentPage: "notificationsList",
  };

  static contextType = AppContext;

  setFilter = value => {
    this.setState({ filterList: value });
  };

  getNotifications = () => {
    console.log("Waiting for [GetAllNotificationByUser]...");
    trackPromise(
      ApiService.getAllNotificationByUser(this.context)
        .then(result => {
          if (result.status === 200) {
            let currentNotifications = result.data;
            this.setState({ notificationsList: currentNotifications });
          } else {
            console.log("API [GetAllNotificationByUser] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetAllNotificationByUser] error ->");
          console.log(e);
          this.props.setPage("500 error");
        }), 'notification-list-area'
    );
  };

  getProject = async (id) => {
    trackPromise(
      ApiService.getProject(id, this.context)
        .then(async (result) => {
          if (result.status === 200) {
            let currentProject = result.data;
            this.setState({ project: currentProject });
            this.setProject("projectsDetails", currentProject);
          } else {
            console.log("API [getProject] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [getProject] error ->");
          console.log(e);
          this.props.setPage("500 error");
        }), "get-project-area"
    );
  };

  setProject = async (page, project) => {
    let localizedProject = this.props.localizeProjectDates(project);
    this.context.currentProjectId = project.Id;
    this.props.setCurrentProject(localizedProject);
    this.setState({ currentPage: page, currentProject: localizedProject });
    this.props.setPage(page);

  };

  getRegularTask = async (id) => {
    trackPromise(
      (async () => {
        try {
          const getRegularTaskResult = await ApiService.getRegularTask(id, this.context);
          if (getRegularTaskResult.status !== 200) {
            return console.log("API [getRegularTask] error status: " + getRegularTaskResult.status);
          }
          let currentTask = getRegularTaskResult.data;
          this.setState({ task: currentTask });
          this.context.currentTask = currentTask;
          this.props.setPage("taskEditor");
        } catch (e) {
          console.log("API [getRegularTask] error ->");
          console.log(e);
          this.props.setPage("500 error");
        }
      })(), "get-regular-task-area"
    );
  };

  getTask = async (id) => {
    trackPromise(
      (async () => {
        try {
          const getDocumentTaskResult = await ApiService.getTask(id, this.context);

          if (getDocumentTaskResult.status !== 200) {
            return console.log("API [getTask] error status: " + getDocumentTaskResult.status);
          }
          let currentTask = getDocumentTaskResult.data;
          this.setState({ task: currentTask });
          this.context.currentTask = currentTask;
          this.props.setPage("taskEditor");
        } catch (e) {
          console.log("API [getTask] error ->");
          console.log(e);
          this.props.setPage("500 error");
        }
      })(), "get-document-task-area"
    );
  };

  getCurrUser() {
    trackPromise(
      ApiService.getCurrentUser(this.context)
        .then(result => {
          if (result.status === 200) {
            this.context.currUser = result.data.User
          } else {
            console.log("API [GetCurrentUser] error status: " + result.status);
          }
        }).catch(e => {
          console.log("API [GetCurrentUser] error ->");
          console.log(e);
          this.props.setPage("500 error");
        }), 'profile-area'
    );
  }


  openNotification(notification) {
    if (!notification.IsSeen) {
      trackPromise(
        ApiService.clickNotification(notification.Id, this.context)
          .then(result => {
            if (result.status === 200) {
              let readNotification = result.data;
              let index = this.state.notificationsList.findIndex(item => item.Id === notification.Id);
              this.setState(prevState => {
                const updatedList = [...prevState.notificationsList];
                updatedList[index] = readNotification;
                return { notificationsList: updatedList };
              });
            } else {
              console.log("API [clickNotification] error status: " + result.status);
            }
          }).catch(e => {
            console.log("API [clickNotification] error ->");
            console.log(e);
            this.props.setPage("500 error");
          }), 'profile-area'
      );
    }
    //navigate to the task/project
    if (notification.WorkItemId != 0) {
      this.getRegularTask(notification.WorkItemId);
    } else if (notification.AssignmentId != 0) {
      this.getTask(notification.AssignmentId);
    } else if (notification.ProjectId != 0) {
      this.getProject(notification.ProjectId);
    }
  }

  getInitials = string => {
    let names = string.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  openWebPage = () => {
    window.open(this.context.applicationEndpoint);
  };

  componentDidMount() {
    this.getNotifications();
    this.getCurrUser();
  }

  render() {
    // For testing the error screen
    let failedLoading = false;

    let activeView = null;
    activeView = (
      <div>
        <LoadingOverlay area="project-list-area" inline="loading-overlay-inline show-cancel-btn" hideContent={this.hideContent} />
        {!this.state.hide ?
          <div className="mt-7 ml-2 mr-2 ">
            <div className="row mt-4">
              <div className="col-12 mb-2">
                <span className="projectPageTitle appFont">Notifications</span>
              </div>
              <div className="col-12 mb-2">
                <div className="input-group mb-3">
                  <input
                    id="filterList"
                    name="filterList"
                    type="search"
                    value={this.state.filterList}
                    className="form-control form-custom-control searchInput appFont textIconInput project-list-search"
                    placeholder="Search for notifications"
                    onChange={event => this.setFilter(event.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {
                // displays 'Unable to load notification' if there is a loading error
                failedLoading === true ? <NotificationsFail></NotificationsFail>
                  :
                  // display 'no notifications' if search yields no results 
                  this.state.notificationsList
                    .filter(
                      f => f.CreatorName.toLowerCase().includes(this.state.filterList.toLowerCase()) || this.state.filterList === ""
                    ).length === 0 ?
                    <div className="no-projects-img">
                      <img src={noResultsImage} />
                      <span>No notifications.</span>
                    </div>
                    :
                    // otherwise display results as usual
                    this.state.notificationsList
                      .filter(
                        f => f.CreatorName.toLowerCase().includes(this.state.filterList.toLowerCase()) || this.state.filterList === ""
                      )
                      .sort((a, b) => a.CreationTime > b.CreationTime ? -1 : 1)
                      .sort((a, b) => a.IsSeen == false > b.IsSeen == true ? -1 : 1)
                      .map((notification, index) => (
                        <div
                          className={`col-12 notificationListRow ${index === this.state.notificationsList.length - 1 ? "projectListLastRow pb-3" : ""}`}
                          key={notification.Id}
                          onClick={event => this.openNotification(notification)}
                        >
                          <div className={`project-list-desc ${notification.IsSeen === false ? "unreadNotificationBackground" : ""}`}>
                            <div className={`projectListTitle unreadNotificationTitle ${notification.IsSeen === true ? "readNotificationTitle" : ""}`}>
                              <div className="d-flex">
                                <div className="pt-2">
                                  <span className={`userInitialsCompact userInitialsCompactOwner`}>
                                    {this.getInitials(notification.CreatorName)}
                                  </span>
                                </div>
                                <div className="taskAuditDetails pl-3 flex-grow-1">
                                  <div className="d-flex">
                                  </div>
                                  <div className="taskListTitle">{notification.CreatorName}</div>
                                  <div className="taskAuditComment taskAuditSubName">{notification.Description}</div>
                                </div>
                                <div className="taskAuditComment taskAuditSubName" style={{ textAlign: "right" }}>
                                  <Moment format="MM/DD/YYYY">{convertUTCDateToLocalDate(notification.CreationTime)}</Moment>{" "}
                                  <br />
                                  <Moment format="hh:mm A">{convertUTCDateToLocalDate(notification.CreationTime)}</Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

              <div className="pb-7" />
              <div className="fixed-bottom mt-5 whiteBackground">
                <div className="text-center switchToWeb">
                  <a href="#" onClick={() => this.openWebPage()}>
                    <span className="appFont underline buttonLink">Switch to web application</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          :
          null
        }
      </div>
    );
    return <div>{activeView}</div>;
  }
}

export default Notifications;