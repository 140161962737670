class FileTransferService {
  uploadDocumentFile(officeContext, callbackFunction, currCommentId = 0) {
    var classScope = this;
    officeContext.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 65536 /*64 KB*/ }, function(result) {
      if (result.status == "succeeded") {
        var myFile = result.value;
        var sliceCount = myFile.sliceCount;
        var slicesReceived = 0,
          gotAllSlices = true,
          docdataSlices = [];
        console.log("File size:" + myFile.size + " #Slices: " + sliceCount);

        classScope.getTaskSliceAsync(
          myFile,
          0,
          sliceCount,
          gotAllSlices,
          docdataSlices,
          slicesReceived,
          callbackFunction,
          currCommentId
        );

      } else {
        console.log("Error (uploadDocument): " + JSON.stringify(result.error));
      }
    });
  }

  getTaskSliceAsync = (file, nextSlice, sliceCount, gotAllSlices, docdataSlices, slicesReceived, callbackFunction, currCommentId) => {
    var classScope = this;
    console.log("getTaskSliceAsync - START ->");
    file.getSliceAsync(nextSlice, function(sliceResult) {
      if (sliceResult.status == "succeeded") {
        if (!gotAllSlices) {
          return;
        }
        docdataSlices[sliceResult.value.index] = sliceResult.value.data;
        if (++slicesReceived == sliceCount) {
          file.closeAsync();
          classScope.onGotAllTaskSlices(docdataSlices, callbackFunction, currCommentId);
        } else {
          classScope.getTaskSliceAsync(
            file,
            ++nextSlice,
            sliceCount,
            gotAllSlices,
            docdataSlices,
            slicesReceived,
            callbackFunction,
            currCommentId
          );
        }
      } else {
        gotAllSlices = false;
        file.closeAsync();
        console.log("getTaskSliceAsync Error:" + JSON.stringify(sliceResult.error));
      }
    });
  };

  onGotAllTaskSlices = (docdataSlices, callbackFunction, currCommentId) => {
    console.log("File Ready!!!");
    if(currCommentId > 0){
      callbackFunction(docdataSlices, currCommentId);
    }
    else{
      callbackFunction(docdataSlices);
    }
  };

  getFileFromSlices = (docdataSlices, docType) => {
    let type = "";
    switch (docType) {
      case "PowerPoint":
        type = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        break;
      case "Excel":
        type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case "Word":
        type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      default:
        type = "";
    }
    var docdata = [];
    for (var i = 0; i < docdataSlices.length; i++) {
      docdata = docdata.concat(docdataSlices[i]);
    }
    var fileContent = new String();
    for (var j = 0; j < docdata.length; j++) {
      fileContent += String.fromCharCode(docdata[j]);
    }
    var file = new Blob([new Uint8Array(docdata)], {
      type: type
    });
    console.log("File Size ->");
    console.log(fileContent.length);
    return file;
  };
}

export default new FileTransferService();