import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import SuccessIcon from "../../resources/success.png";
import AppContext from "../../app/AppContext";

class ProjectsClose extends Component {
  state = {};

  static contextType = AppContext;

  openWebPage = () => {
    window.open(this.context.applicationEndpoint + "projects");
  };

  projectCreate = () => {
    return (
      <div className="text-center mt-4">
        <span className="projectCreatedTitle">
          The project has been created
          <br />
          successfully.
          <br /><br />
          Please close this window and continue assigning Tasks in the new Office window.
          <br />
        </span>
      </div>
    );
  }

  taskCreate = () => {
    return (<>
      <div className="text-center mt-4">
        <span className="projectCreatedTitle">
          Please close this window to continue creating Tasks in the new Office window.
        </span>
      </div>
    </>);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    let activeView = null;
    activeView = (
      <div className="mt-7 mr-3">
        <div className="text-center">
          <img src={SuccessIcon} className="projectCreatedLogo"/>
        </div>
        {this.context.previousPage != "taskCreate" ? this.projectCreate() : this.taskCreate()}
        <div className="fixed-bottom ml-4 mr-4 pb-2 whiteBackground">
          <div className="text-center mb-4 mt-3">
            <a href="#" onClick={() => this.openWebPage()}>
              <span className="appFont underline projectCreatedWebLink buttonLink">Switch to web application</span>
            </a>
          </div>
        </div>
      </div>
    )
    return <div>{activeView}</div>
  }
}

export default ProjectsClose;
