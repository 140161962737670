import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import SuccessIcon from "../../resources/success.png";
import AppContext from "../../app/AppContext";

class TaskEditorSaved extends Component {
  state = {};

  static contextType = AppContext;

  openWebPage = () => {
    window.open(this.context.applicationEndpoint);
  };

  onDone = () => {
    this.props.setPage("taskEditor");
  }

  render() {
    let activeView = null;

    activeView = (
      <div className="mr-3">
        <div className="text-center">
          <img src={SuccessIcon} className="projectCreatedLogo"></img>
        </div>
        <div className="text-center mt-4">
          <span className="projectCreatedTitle">
          The Task has been Updated
          </span>
        </div>
        <div className="fixed-bottom pl-4 pr-4 pb-2 whiteBackground">
          <button className="loginButton btn-primary blueButton button100 ml-1 shadow-none" onClick={this.onDone}>
            Done
          </button>
          <div className="text-center mb-4 mt-3">
            <a href="#" onClick={e => this.openWebPage()}>
              <span className="appFont underline buttonLink">Switch to web application</span>
            </a>
          </div>
        </div>
      </div>
    );

    return <div>{activeView}</div>;
  }
}

export default TaskEditorSaved;
