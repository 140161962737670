import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import AppContext from "../AppContext";
import ApiService from "../../services/ApiService";
import AppRender from "../AppRender";
import FileTransferService from "../../services/FileTransferService";
import { localizeTaskDates, localizeProjectDates } from "../../helpers/HelperMethods";

const AppPowerPoint = ({ isOfficeInitialized }) => {
  const [currentPage, setCurrentPage] = useState("login");
  const [currentProject, setCurrentProject] = useState(null);
  const [currentTaskName, setCurrentTaskName] = useState("");
  const [currentTaskCreatedById, setCurrentTaskCreatedById] = useState(0);
  const [taskAction, setTaskAction] = useState("");
  const [taskIdInserted, setTaskIdInserted] = useState(null);
  const [checkFileStarted, setCheckFileStarted] = useState(false);
  const [checkFileCompleted, setCheckFileCompleted] = useState(false);
  const [firstRenderCompleted, setFirstRenderCompleted] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const context = useContext(AppContext);

  useEffect(() => {
    console.log("[1] AppPowerPoint.js - Checking PowerPoint File looking for valid tokens...");
    if (!checkFileStarted && !checkFileCompleted) {
      setCheckFileStarted(true);
      checkFile();
    }
  }, []);

  const checkFile = async () => {
    console.log("[1.0] checkFile => PowerPoint Context detected");
    if (!context.fileCheck) {
      console.log("[1.1] checkFile => [uploadDocumentFile] uploading file...");
      FileTransferService.uploadDocumentFile(Office.context, apiCheckFile);
    }
  };

  const handleTaskData = (data) => {
    if (data.TaskId > 0) {
      console.log("File Check -> Task Detected -> " + data.TaskId);
      context.currentTaskId = data.TaskId;
      sessionStorage.setItem("downloadedTaskId", data.TaskId);
      sessionStorage.setItem("downloadedScroll", data.Scroll);
      context.attachmentScroll = true;
    }
  }

  const handleProjectData = (data) => {
    if (data.ProjectId > 0) {
      console.log("File Check -> Project Detected -> " + data.ProjectId);
      context.currentProjectId = data.ProjectId;
      context.currentProjectFileName = data.Name;
      context.currentProjectFileLock = true;
      context.currentProjectDocumentId = data.Id;
    }
  }

  const handleTokenData = (data) => {
    if (data.Token != "" && data.Token != null && data.Token.length > 40) {
      context.userToken = data.Token;
      context.userLogin = true;
      console.log("File Check -> Redirect to Login -> ");
      if (context.currentTaskId > 0) {
        setUserLoggedIn(true);
      } else {
        onLoginChange(true); // This function takes cares of redirecting
      }
    }
  }

  const apiCheckFile = async (docdataTaskSlices) => {
    console.log("[1.2] apiCheckFile => [getFileFromSlices] from file");
    let file = FileTransferService.getFileFromSlices(docdataTaskSlices, context.officeAppType);
    const fd = new FormData();
    fd.append("file", file);
    await ApiService.checkTaskDocument(fd, context)
      .then(result => {
        if (result.status === 200) {
          context.fileCheck = true;
          console.log("File Check -> [checkTaskDocument] Status 200");
          console.log(result.data);
          handleTaskData(result.data);
          handleProjectData(result.data);
          handleTokenData(result.data);
          setCheckFileCompleted(true);
        } else {
          context.fileCheck = true;
          console.log("API [AddProject] error status: " + result.status);
          setCheckFileCompleted(true);
        }
      })
      .catch(e => {
        context.fileCheck = true;
        console.log("API [AddProject] error ->");
        console.log(e);
        setCheckFileCompleted(true);
      });
  };

  useEffect(() => {
    if (checkFileCompleted && !firstRenderCompleted) {
      console.log("[2.0] Check Token from File... ");
      if (context.userToken != "") {
        context.tokenReady = true;
        onLoginChange(true); // This function takes cares of redirecting
      } else {
        checkToken(); //no token in file, now checking cookies
      }
      setFirstRenderCompleted(true);
    }
  }, [checkFileCompleted]);

  const onLoginChange = value => {
    console.log("onLoginChange => PowerPoint redidecting...");
    if (context.appMode == "project") {
      if (context.currentProjectId > 0 && context.currentTaskId == "0") {
        setPage("task");
      } else {
        setPage("tasks");
      }
      setUserLoggedIn(value);
    } else if (context.appMode == "task") {
      setPage("taskEditor");
      setUserLoggedIn(value);
    }
  };

  const checkToken = async () => {
    console.log("[2.2] Check Token from Cookies... ");
    let token = localStorage.getItem("tmpTriyoToken");
    let jwtToken = localStorage.getItem("tmpJwtTriyoToken");
    let rememberString = localStorage.getItem("rememberUser");
    let rememberUser = JSON.parse(rememberString);
    let userStatus = token && rememberUser;

    if (userStatus) {
      context.userToken = token;
      context.jwtUserToken = jwtToken;
      const fetchReauthentication = await reauthenticateUser(context);
      return fetchReauthentication;
    } else {
      context.userLogin = false;
      return false;
    }
  };

  const reauthenticateUser = async value => {
    await ApiService.getCurrentUser(value)
      .then(async result => {
        console.log(result);
        if (result.status === 200) {
          if (!result.data.User.Active) {
            context.userToken = "";
            context.jwtUserToken = "";
            context.userLogin = false;
            return true;
          }
          context.currUser = result.data.User;
          context.userName = result.data.User.UserName;
          context.userNameFull = result.data.User.Name;
          context.userEmail = result.data.User.Email;

          context.tokenReady = true;
          onLoginChange(true); // This function takes cares of redirecting
          return true;
        } else {
          console.log("API [ApiDataService.auto-login] error status: " + result.status);
          return false;
        }
      })
      .catch(error => {
        console.log("API [ApiDataService.auto-login] error ->");
        console.log(error);
        context.tokenReady = false;
        return false;
      });
  };

  const onLogout = async () => {
    let firstLoginString = localStorage.getItem("triyoFirstLoggedIn");
    let jwtUserToken = context.jwtUserToken;
    let data = {
      username: "",
      password: "",
      timezone: ""
    };

    if (jwtUserToken !== null || jwtUserToken !== "undefined") {
      await ApiService.logout(data, context)
        .then(async result => {
          if (result.status === 204) {
            localStorage.clear();
            localStorage.setItem("triyoFirstLoggedIn", firstLoginString);
            context.userToken = "";
            context.jwtUserToken = "";
            context.userName = "";
            context.userNameFull = "";
            context.userEmail = "";
            context.userRole = "";
            context.userLogin = false;
            context.currEmail = "";

            context.tokenReady = false;
            console.log("context.tokenReady", context.tokenReady);

            setPage("login");
          } else {
            console.log("API [ApiDataService.logout] error status: " + result.status);
          }
        })
        .catch(error => {
          console.log("API [ApiDataService.logout] error ->");
          console.log(error);
        });
    }
  };

  const setAppMode = appmode => (context.appMode = appmode);

  const setPage = page => {
    setCurrentPage(page);
    context.currentPage = page;
  };

  const setTaskActionFunc = action => {
    setCurrentPage("taskEditor");
    setTaskAction(action);
    context.currentPage = "taskEditor";
  };

  const resetTaskAction = () => setTaskAction("");

  const setTaskNameAndCreatedBy = (name, id) => {
    setCurrentTaskName(name);
    setCurrentTaskCreatedById(id);
  };

  const setCurrentProjectFunc = project => {
    context.currentProjectId = project.Id;
    setCurrentProject(localizeProjectDates(project));
  };

  const setProject = (page, project) => {
    let localizedProject = localizeProjectDates(project);
    context.currentProjectId = project.Id;
    setCurrentProjectFunc(localizedProject);
    setCurrentPage(page);
    setCurrentProject(localizedProject);
  };

  const setCurrentProjectAddTask = newDocument => {
    setPage("task");
    context.newDoc = newDocument;
  };

  return (
    isOfficeInitialized && checkFile() &&
      <AppRender
        context={context}
        setPage={setPage}
        currentPage={currentPage}
        setAppMode={setAppMode}
        onLoginChange={onLoginChange}
        onLogout={onLogout}
        setProject={setProject}
        currentProject={currentProject}
        setCurrentProject={setCurrentProjectFunc}
        setCurrentProjectAddTask={setCurrentProjectAddTask}
        localizeProjectDates={localizeProjectDates}
        setTaskNameAndCreatedBy={setTaskNameAndCreatedBy}
        taskAction={taskAction}
        setTaskAction={setTaskActionFunc}
        resetTaskAction={resetTaskAction}
        localizeTaskDates={localizeTaskDates}
        taskIdInserted={taskIdInserted}
        currentTaskName={currentTaskName}
        currentTaskCreatedById={currentTaskCreatedById}
      />
  );
};

AppPowerPoint.propTypes = {
  isOfficeInitialized: PropTypes.bool.isRequired,
};

export default AppPowerPoint;
