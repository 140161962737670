import "bootstrap/dist/css/bootstrap.min.css";
import "./TaskDetails.scss"
import React, { useState, useEffect, useContext, useRef, Fragment } from "react";
import { trackPromise } from "react-promise-tracker";
import AppContext from "../../app/AppContext";
import TaskDetailsHeader from "./TaskDetailsHeader";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import TaskAudit from "./TaskAudit";
import ApiService from "../../services/ApiService";
import SecurityMatrixService from "../../services/SecurityMatrixService";
import ManualRefreshButton from "../manual-refresh-button/manual-refresh-button";
import editIcon from "../../resources/Icons/Icon - Edit.svg";
import { clipboardCheckBlue13, clipboardCheckGray13, fileSearchBlueIcon, fileSearchGrayIcon } from "../../resources/Icons/icons";
import { TaskStatusEnums } from "../../util/const-helpers";
import FluentUiIcon from "../../resources/fluent-ui-icon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InlineNotification from "../inline-notification/inline-notification";
import Spinner from "../spinner/spinner";

const notificationForActionStyle = {
  position: 'fixed',
  bottom: '10px',
  left: '10px',
  width: '280px',
  backgroundColor: '#EBF5ED',
  border: '1px solid #1D7D2C'
};

const TaskDetails = ({ currTask, taskId, localizeTaskDates, setPage, setAppPage, workflow, onClick, onSetStatusClick,setCurrentTab }) => {
  const [task, setTask] = useState(null);
  const [project, setProject] = useState(null);
  const [hide, setHide] = useState(false);
  const [taskOptions, setTaskOptions] = useState("Details");
  const [active, setActive] = useState("detailsButton");
  const [paddingTop, setPaddingTop] = useState(0);
  const [isCalledOnce, setIsCalledOnce] = useState(false);
  const [currUser, setCurrUser] = useState(null);
  const [manualRefreshBoolean, setManualRefreshBoolean] = useState(false);
  const [downloadState, setDownloadState] = useState({
    isActionPanelVisible: false,
    isDownloading: false,
    isDownloadCompleteNotificationVisible: false,
  });
  // The following states are for Word Documents
  let [imagesPreviewBaseDocument, setImagesPreviewBaseDocument] = useState(null);
  let [wordDocumentPdfOriginalBase64, setWordDocumentPdfOriginalBase64] = useState(null);
  let [wordDocumentPdfUpdatedBase64, setWordDocumentPdfUpdatedBase64] = useState(null);
  let [wordProjectDocumentPdfPreviewBase64, setWordProjectDocumentPdfPreviewBase64] = useState(null);
  let [wordProjectDocumentPdfOriginalBase64, setWordProjectDocumentPdfOriginalBase64] = useState(null);
  let [wordProjectDocumentPdfUpdatedBase64, setWordProjectDocumentPdfUpdatedBase64] = useState(null);
  let [wordDocumentBlacklinePdfUpdatedBase64, setWordDocumentBlacklinePdfUpdatedBase64] = useState(null);
  // The following state are for PPT Documents
  let [imagesPreview, setImagesPreview] = useState({
    WordDocumentPdfOriginalBase64: null,
    WordDocumentPdfUpdatedBase64: null,
    WordProjectDocumentPdfPreviewBase64: null,
    WordProjectDocumentPdfOriginalBase64: null,
    WordProjectDocumentPdfUpdatedBase64: null,
    WordDocumentBlacklinePdfUpdatedBase64: null
  });

  const context = useContext(AppContext);
  const toolbarRef = useRef();

  const addActiveClass = id => {
    if (active != id) {
      setActive(id);
    }
  };

  const handleDownloadClick = async () => {
    setDownloadState({ ...downloadState, isDownloading: true })
    const result = await ApiService.getTaskAuditTrailDocumentOutlook(task.Id, context);
    if (result.status === 200) {
      let pdfData = result.data;
      const blob = new Blob([pdfData], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'audit-trail.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      setDownloadState({ ...downloadState, isDownloading: false, isDownloadCompleteNotificationVisible: true })
    } else {
      console.log("API [getTaskAuditTrailDocumentOutlook] error status: " + result.status);
    }
  };

  const setStatePreviewImages = data => {
    setImagesPreview({
      WordDocumentBlacklinePdfUpdatedBase64: data.WordDocumentBlacklinePdfUpdatedBase64,
      WordDocumentPdfOriginalBase64: data.WordDocumentPdfOriginalBase64,
      WordDocumentPdfUpdatedBase64: data.WordDocumentPdfUpdatedBase64,
      WordProjectDocumentPdfPreviewBase64: data.WordProjectDocumentPdfPreviewBase64,
      WordProjectDocumentPdfOriginalBase64: data.WordProjectDocumentPdfOriginalBase64,
      WordProjectDocumentPdfUpdatedBase64: data.WordProjectDocumentPdfUpdatedBase64
    });
  };

  const getWordPreviewImages = id => {
    if (context.currentTask) {
      if (context.currentTask?.DocumentType === "PowerPoint") {
        trackPromise(
          ApiService.getTaskPowerpointComplete(id, context)
            .then(result => {
              if (result.status === 200) {
                setStatePreviewImages(result.data);
              } else {
                console.log("API [" + apiFunction + "] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [" + apiFunction + "] error ->");
              console.log(e);
              setAppPage("500 error");
            }),
          "preview-mode"
        );
      } else if (context.currentTask?.DocumentType === "Word") {
        //Original
        trackPromise(
          ApiService.getWordDocPdfOriginalBase64(id, context)
            .then(result => {
              if (result.status === 200) {
                if (imagesPreviewBaseDocument) setImagesPreviewBaseDocument(result.data);

                setWordDocumentPdfOriginalBase64(result.data.WordDocumentPdfOriginalBase64);
              } else {
                console.log("API [" + apiFunction + "] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [" + apiFunction + "] error ->");
              console.log(e);
              setAppPage("500 error");
            }),
          "preview-mode-original"
        );
        //Updated
        trackPromise(
          ApiService.getWordDocPdfUpdatedBase64(id, context)
            .then(result => {
              if (result.status === 200) {
                if (imagesPreviewBaseDocument) setImagesPreviewBaseDocument(result.data);

                setWordDocumentPdfUpdatedBase64(result.data.WordDocumentPdfUpdatedBase64);
              } else {
                console.log("API [" + apiFunction + "] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [" + apiFunction + "] error ->");
              console.log(e);
              setAppPage("500 error");
            }),
          "preview-mode-updated"
        );
        //Live
        setTimeout(() => {
          trackPromise(
            ApiService.getWordProjectDocumentPdfPreviewBase64(id, context)
              .then(result => {
                if (result.status === 200) {
                  if (imagesPreviewBaseDocument) setImagesPreviewBaseDocument(result.data);

                  setWordProjectDocumentPdfPreviewBase64(result.data.WordProjectDocumentPdfPreviewBase64);
                } else {
                  console.log("API [" + apiFunction + "] error status: " + result.status);
                }
              })
              .catch(e => {
                console.log("API [" + apiFunction + "] error ->");
                console.log(e);
                setAppPage("500 error");
              }),
            "preview-mode-live"
          );
        }, 2000);
        //ProjectUpdated
        trackPromise(
          ApiService.getWordProjectDocPdfUpdatedBase64(id, context)
            .then(result => {
              if (result.status === 200) {
                if (imagesPreviewBaseDocument) setImagesPreviewBaseDocument(result.data);

                setWordProjectDocumentPdfUpdatedBase64(result.data.WordProjectDocumentPdfUpdatedBase64);
              } else {
                console.log("API [" + apiFunction + "] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [" + apiFunction + "] error ->");
              console.log(e);
              setAppPage("500 error");
            }),
          "preview-mode-project-updated"
        );
        //ProjectOriginal
        trackPromise(
          ApiService.getWordProjectDocPdfOriginalBase64(id, context)
            .then(result => {
              if (result.status === 200) {
                if (imagesPreviewBaseDocument) setImagesPreviewBaseDocument(result.data);

                setWordProjectDocumentPdfOriginalBase64(result.data.WordProjectDocumentPdfOriginalBase64);
                //BlacklineUpdated
                trackPromise(
                  ApiService.getWordDocBlacklinePdfUpdatedBase64(id, context)
                    .then(docBlackline => {
                      if (docBlackline.status === 200) {
                        setWordDocumentBlacklinePdfUpdatedBase64(
                          docBlackline.data.WordDocumentBlacklinePdfUpdatedBase64
                        );
                      } else {
                        console.log("API [" + apiFunction + "] error status: " + docBlackline.status);
                      }
                    })
                    .catch(e => {
                      console.log("API [" + apiFunction + "] error ->");
                      console.log(e);
                      setAppPage("500 error");
                    }),
                  "preview-mode-blackline-updated"
                );
              } else {
                console.log("API [" + apiFunction + "] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [" + apiFunction + "] error ->");
              console.log(e);
              setAppPage("500 error");
            }),
          "preview-mode-project-original"
        );
      }
    } else {
      if (context.officeAppType === "PowerPoint") {
        trackPromise(
          ApiService.getTaskPowerpointComplete(id, context)
            .then(result => {
              if (result.status === 200) {
                setStatePreviewImages(result.data);
              } else {
                console.log("API [" + apiFunction + "] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [" + apiFunction + "] error ->");
              console.log(e);
              setAppPage("500 error");
            }),
          "preview-mode"
        );
      } else if (context.officeAppType === "Word") {
        trackPromise(
          ApiService.getWordPreviewImages(id, context)
            .then(result => {
              if (result.status === 200) {
                setStatePreviewImages(result.data);
              } else {
                console.log("API [" + apiFunction + "] error status: " + result.status);
              }
            })
            .catch(e => {
              console.log("API [" + apiFunction + "] error ->");
              console.log(e);
              setAppPage("500 error");
            }),
          "preview-mode"
        );
      }
    }
  };
  // Only provide the user with task details, and exclude document preview images
  useEffect(() => {
    setTask(localizeTaskDates(currTask));
  }, [currTask]);

  useEffect(() => {
    setProject(task?.Project ? task?.Project : context.currentTask?.Project);

    if (context.currUser == null) {
      getCurrUser();
    } else {
      setCurrUser(context.currUser);
    }
  }, [task]);

  useEffect(()=>{
    calculateTaskDetailsHeight();
  }, [task])

  // Only update document preview images once the user requests to do so
  useEffect(() => {
    if (wordDocumentBlacklinePdfUpdatedBase64 == null && !isCalledOnce) {
      setIsCalledOnce(true);
      getWordPreviewImages(taskId);
    }

    if (!task?.Project && context.currentTask?.Project) setProject(context.currentTask?.Project);
  }, []);

  const getCurrUser = () => {
    trackPromise(
      ApiService.getCurrentUser(context)
        .then(result => {
          if (result.status === 200) {
            context.currUser = result.data.User;
            setCurrUser(result.data.User);
          } else {
            console.log("API [GetCurrentUser] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetCurrentUser] error ->");
          console.log(e);
          setAppPage("500 error");
        })
    );
  };

  const calculateTaskDetailsHeight = () => {
    if (toolbarRef.current) {
      setPaddingTop(toolbarRef.current.offsetHeight + 10);
    }
  };

  const hideContent = hidden => {
    if (hide != hidden) {
      setHide(hidden);
    }
  };

  const reloadTask = () => {
    trackPromise(
      ApiService.getTaskWithWorkflow(taskId, task.TaskType, context)
        .then(result => {
          if (result.status === 200) {
            let reloadedTask = task;
            reloadedTask.LastActivity = result.data.LastActivity;
            setTask(reloadedTask);
          } else {
            console.log("API [GetTask] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetTask] error ->");
          console.log(e);
          setPage("500 error");
        }),
      "task-editor-details-button"
    );
  };
  const showTaskOptions = options => (
    <React.Fragment>
      {// PowerPoint and Outlook
      options === "Details" && context.currentTask?.DocumentType !== "Word" && (
        <TaskDetailsHeader
          task={task}
          project={project}
          imagesPreview={imagesPreview}
          setAppPage={setAppPage}
          workflow={workflow}
          onClick={onClick}
          onSetStatusClick={onSetStatusClick}
          configurableWorkflow = { task?.Workflow}
        />
      )}
      {// Here we send each document in a separate prop to allow load them in parallel, and show each one as soon as jpegs are processed
      options === "Details" && context.currentTask?.DocumentType === "Word" && (
        <TaskDetailsHeader
          task={task}
          project={project}
          imagesPreviewBaseDocument={imagesPreviewBaseDocument}
          WordDocumentPdfOriginalBase64={wordDocumentPdfOriginalBase64}
          WordDocumentPdfUpdatedBase64={wordDocumentPdfUpdatedBase64}
          WordProjectDocumentPdfPreviewBase64={wordProjectDocumentPdfPreviewBase64}
          WordProjectDocumentPdfUpdatedBase64={wordProjectDocumentPdfUpdatedBase64}
          WordDocumentBlacklinePdfUpdatedBase64={wordDocumentBlacklinePdfUpdatedBase64}
          WordProjectDocumentPdfOriginalBase64={wordProjectDocumentPdfOriginalBase64}
          setAppPage={setAppPage}
          workflow={workflow}
          onClick={onClick}
          onSetStatusClick={onSetStatusClick}
          configurableWorkflow = { task?.Workflow}
        />
      )}
      {options === "Audit" && <TaskAudit task={task} setAppPage={setAppPage} />}
    </React.Fragment>
  );

  const showTaskButtons = task => (
    <React.Fragment>
      <div className="row ml-4 mr-4 mb-3">
        <div className="projectPageTitle bold">{task.Name}</div>
      </div>
      {workflow?.Status === "Overdue" && (
        <div className="col-12">
          <div className="alert alert-warning overdueTextMargin" role="alert">
            This Task is Overdue. Change the Due Date to renew.
          </div>
        </div>
      )}
      <div className="btn-group d-flex pr-2 pl-4" role="group" aria-label="Task Details Panel">
        <button
          id="detailsButton"
          type="button"
          onClick={() => {
            setTaskOptions("Details");
            addActiveClass("detailsButton");
            setCurrentTab("Details");
            reloadTask();
            setDownloadState({ ...downloadState, isActionPanelVisible : false });
          }}
          className={`btn btn-light grayButton shadow-none grayButtonHeadingWidth ${
            active === "detailsButton" ? "grayButtonSelected" : ""
          }`}
        >
          <img src={active === "detailsButton" ? clipboardCheckBlue13 : clipboardCheckGray13} alt="Task details" />
          <span style={{ fontSize: "14px" }}>&nbsp;Details</span>
        </button>
        <button
          id="auditButton"
          type="button"
          onClick={() => {
            setTaskOptions("Audit");
            setCurrentTab("Audit");
            addActiveClass("auditButton");
            setDownloadState({ ...downloadState, isActionPanelVisible : true });
          }}
          className={`btn btn-light grayButton shadow-none grayButtonHeadingWidth ${
            active === "auditButton" ? "grayButtonSelected" : ""
          }`}
        >
          <img src={active === "auditButton" ? fileSearchBlueIcon : fileSearchGrayIcon} alt="Audit Trail"/>
          <span style={{ fontSize: "14px" }}>&nbsp;Audit Trail</span>
        </button>
      </div>
      {downloadState.isActionPanelVisible && 
        <div className="col-md-12 audit-trail-action-panel" aria-label="Audit Trail Action Panel">
          {downloadState.isDownloading ?
            <Spinner />
            :
            <OverlayTrigger placement="top" overlay={<Tooltip>{"Download Audit Trail . PDF"}</Tooltip>}>
              <button onClick={handleDownloadClick}>
                <FluentUiIcon
                  name="ArrowDownloadRegular"
                  color="#0078d4"
                  size={20}
                />
              </button>
            </OverlayTrigger>
          }
        </div>
      }
      {downloadState.isDownloadCompleteNotificationVisible && 
        <InlineNotification
          type={"success"}
          title={"Download Complete"}
          description={`The Audit Trail of the Task has been successfully downloaded.`}
          style={notificationForActionStyle}
          cancelClick={() => setDownloadState({ ...downloadState, isDownloadCompleteNotificationVisible: false })}
        />
      }
      
    </React.Fragment>
  );

  const setInitialTaskDetailsOnFirstRender = () => {
    let initializedTaskDoc = context.addinFirstRender && context.officeAppType !== "Outlook";

    const getDownloadedTaskId = parseInt(sessionStorage.getItem("downloadedTaskId")) || 0;
    const getDownloadedScroll = parseFloat(sessionStorage.getItem("downloadedScroll"));
    const checkDownloadedExists = getDownloadedTaskId > 0 && getDownloadedScroll >= 0 && context.attachmentScroll;
    // If scroll exists with task id go to audit trail, otherwise move to details that is set as default
    if (initializedTaskDoc && checkDownloadedExists) {
      setIsCalledOnce(false);
      setTaskOptions("Audit");
      addActiveClass("auditButton");
    }

    // This variable manages logic of render for first render when a page is reloaded
    context.addinFirstRender = false;
  };

  const showTaskDetails = () => {
    let activeView = null;

    if (task != null) {
      setInitialTaskDetailsOnFirstRender();

      activeView = (
        <div>
          <LoadingOverlay area="task-details-area" inline="loading-overlay-inline" hideContent={hideContent} />
          {!hide ? (
            <div className="mt-7">
              <div className="row toolbarSticky" ref={toolbarRef}>
                <div className="d-flex justify-content-between col-12 mb-3 ml-2 mr-2 pt-4">
                  <span className="appFont taskGoItems tasksGoBack" onClick={() => {
                    if (context.showExistingLegacyEmailTask) {
                      context.showExistingLegacyEmailTask = false;
                      setPage("emailTrackingTaskMenu");
                    } else {
                      setPage("tasksList", task);
                    }
                  }}>
                    Back
                  </span>
                  {task.TaskType === "Regular" &&
                  currUser &&
                  SecurityMatrixService.checkRolePrivilege(context.currUser, "Edit", "Task Management") &&
                  task.Permission !== "View" ? (
                    <div className="d-flex">
                      {
                        (task?.StatusId !== TaskStatusEnums.Approved && task?.StatusId !== TaskStatusEnums.Completed && task?.StatusId !== TaskStatusEnums.Cancelled) &&
                        <span
                          className="manual-refresh-button-container"
                          onClick={() => setPage("tasksEdit", task)}
                        >
                          <img src={editIcon} alt="Edit" className="edit-icon" />
                        </span>
                      }
                      <ManualRefreshButton
                        refreshService={
                          () => {
                            setManualRefreshBoolean(!manualRefreshBoolean);
                          }
                        }
                      />
                    </div>
                  ) : (
                    <ManualRefreshButton
                      refreshService={
                        () => {
                          setManualRefreshBoolean(!manualRefreshBoolean);
                        }
                      }
                    />
                  )}
                </div>

                {showTaskButtons(task)}
              </div>
              { manualRefreshBoolean &&
                <div className={"scrollTaskDetails"} style={{ paddingTop: paddingTop + "px" }}>
                  {showTaskOptions(taskOptions)}
                </div>
              }
              { !manualRefreshBoolean &&
                <div className={"scrollTaskDetails"} style={{ paddingTop: paddingTop + "px" }}>
                  {showTaskOptions(taskOptions)}
                </div>
              }
            </div>
          ) : null}
        </div>
      );
    } else {
      activeView = (
        <div>
          <LoadingOverlay area="task-details-area" inline="loading-overlay-inline" loading={true} />
        </div>
      );
    }
    return activeView;
  };

  return <div>{showTaskDetails()}</div>;
};

export default TaskDetails;
