export const taskNameToolTipInfo =
  "This field will sync with the email subject line when auto-sync is enabled. To edit manually, disable auto-sync.";
export const assignedMembersToolTipInfo =
  "This field will sync with the email recipients when auto-sync is enabled. To edit manually, disable auto-sync.";
export const otherMembersToolTipInfo = "These members are not part of the selected project.";

export const TaskStatusEnums = {
  Assigned: 1,
  Completed: 2,
  Approved: 3,
  Declined: 4,
  Reassigned: 5,
  Published: 6,
  Comment: 7,
  Cancelled: 8,
  InProgress: 9,
  PendingReview: 10,
  PendingApproval: 11,
};
