import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import OfficeSharedService from "../services/OfficeSharedService";
import AppContext from "../app/AppContext";
import AppWord from "./word/AppWord";
import AppPowerPoint from "./powerpoint/AppPowerPoint";
import AppLegacy from "./AppLegacy";

const App = ({ isOfficeInitialized }) => {
  const context = useContext(AppContext);
  const [currentOfficeAppType, setCurrentOfficeAppType] = useState("");

  useEffect(() => {
    console.log("[0] App.js - useEffect => Started");
    const OfficeSharedServiceResult = OfficeSharedService.getAppType(Office.context);
    if (OfficeSharedServiceResult !== "") {
      console.log("[0.1] App.js - useEffect => [OfficeSharedServiceResult] completed: ", OfficeSharedServiceResult);
      context.officeAppType = OfficeSharedServiceResult;
      setCurrentOfficeAppType(OfficeSharedServiceResult);
      if (OfficeSharedServiceResult == "Word") context.officeAppTypeDoc = 1;
      else if (OfficeSharedServiceResult == "PowerPoint") context.officeAppTypeDoc = 3;
      else if (OfficeSharedServiceResult == "Outlook") context.officeAppTypeDoc = 4;
    }
  }, []);

  useEffect(() => {
    console.log("[0.2] App.js - useEffect => State changes detected...");
  }, [currentOfficeAppType]);

  return (
    <div>
      { currentOfficeAppType == "Word" && <AppWord /> }
      { currentOfficeAppType == "PowerPoint" && <AppPowerPoint isOfficeInitialized={isOfficeInitialized} /> }
      { currentOfficeAppType == "Outlook" && <AppLegacy /> }
    </div>
  );
};

App.propTypes = {
  isOfficeInitialized: PropTypes.bool.isRequired,
};

export default App;

// Components to be decoupled and unit-tested later:
// - AppOutlookDesktop.js
// - AppOutlookPWA.js
//
// Code:
// import AppOutlookDesktop from "./outlook/AppOutlookDesktop";
// import AppOutlookPWA from "./outlook/AppOutlookPWA";
//
// { currentOfficeAppType == "Outlook" && <AppOutlookDesktop /> }
// { currentOfficeAppType == "OutlookPWA" && <AppOutlookPWA /> }
// { currentOfficeAppType == "" && <AppLegacy /> }
//
// Note: AppLegacy, AppOutlookPWA, and AppOutlook are exact copy of the
// original App.js and remain unchanged
