import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import AppContext from "../../app/AppContext";
import TaskAuditCommentForm from "./TaskAuditCommentForm.js";
import "../dynamicFields/dynamicFields.scss";
import { OBJECT_TYPES, renderTaskDetailDynamicFields } from "../dynamicFields/utils/utils.js";
import DynamicFieldsFormWrapper from "../dynamicFields/Form/DynamicFieldsFormWrapper.js";

const TaskAuditDynamicCommentForm = ({
  task,
  taskType,
  taskId,
  selectedFiles,
  setSelectedFiles,
  newCommentText,
  setNewCommentText,
  addTaskComment,
  setRenderComments
}) => {
  const [activeTab, setActivetab] = useState("Comments");
  const [expand, setExpand] = useState(null);
  const [hasDynamicFields, setHasDynamicFields] = useState(false);
  const [disableDynamicError, setDisableDynamicError] = useState(true);
  const context = useContext(AppContext);

  return (
    <div className={"dynamicCommentContainer taskAuditStickyBox " + (expand == null ? "minimizeComments" : expand ? "expandComments" : "minimizeComments") + (hasDynamicFields ? " dynamicComment" : " regularComment")}>
      {hasDynamicFields && <div className="row ml-3 mr-3 tabContainer">
        <div className="row">
          <div className={activeTab === "Comments" ? "auditCommentTab activeState" : "auditCommentTab"}
            onClick={() => { setActivetab("Comments"); setExpand(false); setNewCommentText(""); }}
          >Comments</div>
          <div className={activeTab === "Dynamic" ? "auditDynamicTab activeState" : "auditDynamicTab"}
            onClick={() => { setActivetab("Dynamic"); setNewCommentText("Task Form submitted") }}
          >Dynamic</div>
        </div>
        <div className={activeTab === "Dynamic" ? "auditExpand " : "auditExpand inActiveState"}
          onClick={() => { if (activeTab !== "Comments") setExpand(!expand) }}>
          Expand
        </div>
      </div>
      }

      <div className={"auditContainer pl-2 pr-2" + (activeTab != "Comments" ? " auditContainerOverflow" : "")}>
        <div>
          <DynamicFieldsFormWrapper
            projectTypeId={0}
            taskTypeId={context.currentTask.workItemType}
            objectType={OBJECT_TYPES.taskComment}
            objectId={0}
            context={context}
          >
            {({ dynamicFields, setDynamicFields }) => (
              <div>
                {dynamicFields.length > 0 ? setHasDynamicFields(true) : setHasDynamicFields(false)}
                {activeTab != "Comments" && <div>
                  {renderTaskDetailDynamicFields({
                    dynamicFields: dynamicFields,
                    setDynamicFields,
                    disableErrors: disableDynamicError
                  })}
                </div>}
                <div>
                  <TaskAuditCommentForm
                    taskType={taskType}
                    taskId={taskId}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    newCommentText={newCommentText}
                    setNewCommentText={setNewCommentText}
                    addTaskComment={addTaskComment}
                    setRenderComments={setRenderComments}
                    className="fixed-bottom  whiteBackground pb-3"
                    dynamicFields={dynamicFields}
                    activeTab={activeTab}
                    setDynamicFields={setDynamicFields}
                    setDisableDynamicError={setDisableDynamicError}
                  />
                </div>
              </div>
            )}
          </DynamicFieldsFormWrapper>
        </div>
      </div>
    </div>
  );
};

TaskAuditDynamicCommentForm.propTypes = {
  task: PropTypes.object,
  taskType: PropTypes.string,
  taskId: PropTypes.number,
  selectedFiles: PropTypes.array,
  setSelectedFiles: PropTypes.func,
  newCommentText: PropTypes.string,
  setNewCommentText: PropTypes.func,
  addTaskComment: PropTypes.func,
  setRenderComments: PropTypes.func
};

export default TaskAuditDynamicCommentForm;
