import React, { useState, useEffect, useContext } from "react";
import usingComputer from "../../resources/Images/Image - Using Computer.svg";
import Moment from "react-moment";
import TaskStatus from "../tasks/TaskStatus";
import TaskPriority from "../tasks/TaskPriority";
import TaskMembersPopover from "../tasks/TaskMembersPopover";
import ApiService from "../../services/ApiService";
import AppContext from "../../app/AppContext";
import { notificationMessage } from "../../helpers/HelperMethods";

import "../email-tracking/EmailTracking.scss";

const CustomRow = props => {
  const { name, children } = props;

  return (
    <div className="row ml-2 mr-1">
      <div className="col-12 taskDetailsRow appFont">
        <div className="float-left taskDetailsLabel">{name}</div>
        <div className="float-right text-right mr-2 taskDetailsText">{children}</div>
      </div>
    </div>
  );
};

// If currentTask here is null and it's replyall form
  // -> render tasklist for now
  // -> don't render email is linked to a task => because it's not true


const EmailTrackingTaskDetails = ({ conversationId, currUserId }) => {
  const context = useContext(AppContext);
  let [currentTask, setCurrentTask] = useState([]);
  let [currrentProject, setCurrentProject] = useState([]);
  let [localConversationId, setLocalConversationId] = useState(false);

  useEffect(() => {
    if (conversationId && conversationId != localConversationId) {
      console.log("conversationId => before getEmailTask call");
      console.log(conversationId);

      ApiService.getEmailTask(conversationId, context).then(async(result) => {
        if (result.status === 200) {
          setCurrentTask(result.data);

          console.log("getEmailTask => to save it in currentTask");
          console.log(result.data);

          await ApiService.getProject(currentTask?.ProjectId, context).then(async result => {
            if (result.status === 200) {
              setCurrentProject(result.data);
            }
          });
        }
      });
      setLocalConversationId(conversationId);
      notificationMessage("This email is currently being tracked as a Task on TRIYO |");
    }
  }, [conversationId]);

  return (
    <div className="email-tracking-task-details">
      <div className="img-container m-1">
        <img src={usingComputer} className="center using-computer-image"></img>
      </div>
      <div className="center create-task-from-email m-1">Email is Linked to a Task</div>
      <div className="ml-2 create-task-from-email-description m-1 mb-3">
        This email thread is being tracked as a Task on TRIYO
      </div>

      {/* Task Details */}
      <CustomRow name="Project Name">{currrentProject?.Name}</CustomRow>
      <CustomRow name="Start Date">
        <Moment format="MM/DD/YYYY">
          {currentTask?.TaskTypeId == 0 ? currentTask?.Created : currentTask?.Start}
        </Moment>
      </CustomRow>

      <CustomRow name="Due Date">
        <Moment format="MM/DD/YYYY">{currentTask?.Due}</Moment>
      </CustomRow>

      <CustomRow name="Status">
        <TaskStatus status={currentTask?.Status} customStatus ={currentTask?.Workflow?.Status} statusEmail={currentTask?.StatusEmail} statusEmailDateTime={currentTask?.StatusEmailDateTime}></TaskStatus>
      </CustomRow>

      <CustomRow name="Last Activity">
        <TaskStatus status={currentTask?.Status} statusEmail={currentTask?.StatusEmail} statusEmailDateTime={currentTask?.StatusEmailDateTime}></TaskStatus>
      </CustomRow>

      <CustomRow name="Priority">
        <TaskPriority priority={currentTask?.Priority}></TaskPriority>
      </CustomRow>
      {currentTask?.TaskTypeId == 1 && (
        <CustomRow name="Permission">
          {currUserId === currentTask?.CreatedById && currentTask?.Permission != "Owner"
            ? "Owner (" + currentTask?.Permission + ")"
            : currentTask?.Permission}
        </CustomRow>
      )}
      <CustomRow name="Members">
        {currentTask && currentTask.length !== 0 && (
          <TaskMembersPopover projMembers={currentTask.Members} ownerId={currentTask.CreatedById}></TaskMembersPopover>
        )}
      </CustomRow>
    </div>
  );
};

export default EmailTrackingTaskDetails;
