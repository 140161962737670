import React, { useState, useEffect, useContext } from "react";
import { trackPromise } from "react-promise-tracker";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAutoRefreshForAddin } from "../dynamicFields/utils/utils";
import "bootstrap/dist/css/bootstrap.min.css";
import ApiService from "../../services/ApiService";
import SecurityMatrixService from "../../services/SecurityMatrixService";
import Moment from "react-moment";
import AppContext from "../../app/AppContext";
import TaskMembers from "../tasks/TaskMembers";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import noProjectsImage from "../../resources/Images/Image - No Projects.svg";
import { disabledIcon, plusIcon } from "../../resources/Icons/icons";
import ManualRefreshButton from "../manual-refresh-button/manual-refresh-button";

const ProjectsList = ({ localizeProjectDates, setAppPage, setPage, setProject }) => {
  const [projects, setProjects] = useState([]);
  const [filterList, setFilterList] = useState("");
  const [hide, setHide] = useState(false);
  const [loading, setLoading] = useState(true);

  const context = useContext(AppContext);

  let fileLockWarning = null;

  const getDataProjects = async () => {
    console.log("Waiting for [GetUserProjects]...");
    trackPromise(
      ApiService.getUserProjects(context)
        .then(result => {
          if (result.status === 200) {
            let projectsList = result.data;

            if (projectsList !== null && projectsList.length !== 0) {
              projectsList.forEach(project => {
                project = localizeProjectDates(project);
              });
            }
            setProjects(projectsList);
            setLoading(false);
          } else {
            console.log("API [GetUserProjects] error status: " + result.status);
            setLoading(false);
          }
        })
        .catch(e => {
          console.log("API [GetUserProjects] error ->");
          console.log(e);
          setLoading(false);
          setAppPage("500 error");
        }),
      "project-list-area"
    );
  };

  const getCurrUser = async () => {
    trackPromise(
      ApiService.getCurrentUser(context)
        .then(result => {
          if (result.status === 200) {
            context.currUser = result.data.User;
          } else {
            console.log("API [GetCurrentUser] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetCurrentUser] error ->");
          console.log(e);
          setAppPage("500 error");
        }),
      "profile-area"
    );
  };
  // Data projects continuously updating after first render of time - Project List
  useAutoRefreshForAddin(getDataProjects, 15000);
  // First time rendering content
  useEffect(() => {
    getDataProjects();
    getCurrUser();
  }, []);


  const hideContent = hidden => {
    setHide(hidden);
  };

  const showProjectListElms = () => {
    let activeView = null;
    activeView = (
      <div>
        <LoadingOverlay loading={loading} hideContent={hideContent} minDuration={100}/>
        {!hide ? (
          <div className="mt-7 ml-2 mr-2 ">
            {fileLockWarning}
            <div className="row mt-4">
              <div className="d-flex justify-content-between col-12 mt-3">
                <span className="projectPageTitle appFont">Projects</span>
                <ManualRefreshButton refreshService={getDataProjects} />
              </div>
              <div className="col-12 mb-2">
                <div className="input-group mb-3">
                  <input
                    id="filterList"
                    name="filterList"
                    type="search"
                    value={filterList}
                    className="form-control form-custom-control searchInput appFont textIconInput project-list-search"
                    placeholder="Search Projects"
                    onChange={event => setFilterList(event.target.value)}
                  />
                  {context.currUser?.RolePrivilege &&
                  SecurityMatrixService.checkRolePrivilege(context.currUser, "Add", "Project") ? (
                    <div className="input-group-append">
                      <span
                        className="btn btn-outline-secondary shadow-none blueButtonInput"
                        onClick={() => setPage("projectsAdd")}
                      >
                        <img src={plusIcon} alt="Projects List"/>
                      </span>
                    </div>
                  ) : (
                    <OverlayTrigger
                      placement="bottom-end"
                      overlay={
                        <Tooltip>
                          You don't have access to create projects. Please contact administration for assistance.
                        </Tooltip>
                      }
                    >
                      <div>
                        <img src={disabledIcon} alt="disabled add icon"></img>
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              {// display 'no results found' if search yields no results
              projects.filter(f => f.Name.toLowerCase().includes(filterList.toLowerCase()) || filterList === "")
                .length === 0 ? (
                <div className="no-projects-img">
                  <img src={noProjectsImage} alt="No Active Projects" />
                  <span>No results found.</span>
                </div>
              ) : (
                // otherwise display results as usual
                projects
                  .filter(f => f.Name.toLowerCase().includes(filterList.toLowerCase()) || filterList === "")
                  .map((project, index) => (
                    <div
                      className={`col-12 projectListRow ${index === projects.length - 1 ? "projectListLastRow" : ""}`}
                      key={project.Id}
                      onClick={() => setProject("projectsDetails", project)}
                    >
                      <div className="projectListRowBottomBorder mt-2">
                        <div className="project-list-desc">
                          <span className="mb-2 projectListTitle project-title appFontLargeOne">{project.Name}</span>
                          <br />
                          <span className="appFont appFontLightOne fontGrayLIght project-list-date">
                            End Date: <Moment format="DD MMM, YYYY">{project.End}</Moment>
                          </span>
                          <div className="mb-2 projectMembers">
                            <TaskMembers members={project.Members} ownerId={project.CreatedById}></TaskMembers>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
    return activeView;
  };

  const checkProjectListMatrixServices = () => {
    if (context.currentProjectFileLock) {
      let projectName = "";
      let lockedProject = null;

      if (context.currentProjectDocumentId !== 0) {
        projects.forEach(element => {
          element.Documents.forEach(doc => {
            if (doc.Id == context.currentProjectDocumentId) {
              projectName = element.Name;
              lockedProject = element;
            }
          });
        });
      }

      if (projectName != "") {
        SecurityMatrixService.checkRolePrivilege(context.currUser, "Add", "Task Management")
          ? (fileLockWarning = (
              <div
                className="alert alert-info projectLockedInfo text-center pointer"
                onClick={() => {
                  context.currentProjectId = lockedProject.Id;
                  setAppPage("task");
                }}
              >
                This file is currently locked to project <b>{projectName}</b>
              </div>
            ))
          : (fileLockWarning = (
              <div className="alert alert-info projectLockedInfo text-center">
                This file is currently locked to project <b>{projectName}</b>
              </div>
            ));
      }
    }
  };

  checkProjectListMatrixServices();

  return <div>{showProjectListElms()}</div>;
};

export default ProjectsList;
