import React from "react";

const LogoutButton = ({
    onLogout
}) => {
    return (
        <button className="loginButton btn-primary btn-block shadow-none blueButton" onClick={() => onLogout()}>
            Logout
        </button>
    )
}

export default LogoutButton;
