import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const StatusBadgeWithTooltip = ({ status, className, overrideClassName, tag }) => {
  const Tag = tag || "span";

  return (
    <OverlayTrigger
      placement="auto-start"
      delay={{ show: 250, hide: 50 }}
      overlay={
        <Tooltip id={`tooltip-1`} placement="auto-start">
          {status}
        </Tooltip>
      }
    >
      <Tag className={overrideClassName ? `${overrideClassName} statusBadgeSpan` : `badge statusBadge appFont statusBadgeSpan ${className}`}>
        {Tag === "div" ? <p>{status}</p> : status}
      </Tag>
    </OverlayTrigger>
  );
};

const statusMap = {
  "Not Started": "notStartedBadge",
  "Assigned": "notStartedBadge",
  "In Progress": "progressBadge",
  "InProgress": "progressBadge",
  "Declined": "progressBadge",
  "Submitted": "submitted",
  "Awaiting Approval": "approvalBadge",
  "PendingApproval": "approvalBadge",
  "PendingReview": "approvalBadge",
  "Pending Review": "approvalBadge",
  "Pending Approval": "approvalBadge",
  "Assigned - Pending review": "approvalBadge",
  "Inactive": "inactive",
  "Completed": "completedBadge",
  "Approved": "completedBadge",
  "Cancelled": "cancelBadge",
  "Archived": "not-started",
  "Overdue": "cancelBadge",
  "Active": "active",
};

const TaskStatus = ({ status, customStatus }) => {
  const displayStatus = customStatus || status;
  const className = statusMap[status] || "active";
  const overrideClassName = ["Submitted", "Inactive", "Archived", "Active"].includes(status) ? `status ${className}` : null;
  const tag = ["Archived", "Active"].includes(status) ? "div" : null;

  return <StatusBadgeWithTooltip status={displayStatus} className={className} overrideClassName={overrideClassName} tag={tag} />;
};

export default TaskStatus;
