import React, { useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { getDynamicFields } from '../utils/fieldsUtils';

/** DynamicFieldsFormWrapper is a render prop used to encapsulate
 *  the functionality of fetching dynamic fields so that it can
 *  be reused with any way you want to display the data
 *
 * Props used:
 * children: the function used as a render prop for the wrapper
 * projectTypeId and taskTypeId: self-explanatory
 * objectType: see dynamic-fields-api.js or Enums.cs in Triyo.API for valid object types
 * objectId: id of object that dynamic data is attached to
 * area: any string, used to differentiate between components to track promises
 */
const DynamicFieldsFormWrapper = (props) => {
    const {
        children,
        projectTypeId,
        taskTypeId,
        objectType,
        objectId,
        context,
        area = 'dynamic-fields-form-wrapper-area',
    } = props;
    const [dynamicFields, setDynamicFields] = useState([]);
    const { promiseInProgress } = usePromiseTracker(area);

    useEffect(() => {
        trackPromise(
            getDynamicFields({
                projectTypeId,
                taskTypeId,
                objectType,
                objectId,
            },context)
                .then(setDynamicFields)
                .catch((error) => {
                    setDynamicFields([]);
                    console.error(error);
                }),
            area
        );
    }, [projectTypeId, taskTypeId, objectType, objectId]);

    return children({ dynamicFields, setDynamicFields, promiseInProgress });
};

export default DynamicFieldsFormWrapper;
