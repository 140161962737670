import React from "react";
import PropTypes from "prop-types";
import Menu from "../components/Menu";
import Login from "../components/Login";
import Projects from "../components/projects/Projects";
import ProjectsDetails from "../components/projects/ProjectDetails";
import Tasks from "../components/tasks/Tasks";
import TaskAdd from "../components/tasks/TaskAdd";
import TaskAdded from "../components/tasks/TaskAdded";
import TaskAddedFail from "../components/tasks/TaskAddedFail";
import TaskEditor from "../components/tasks/TaskEditor";
import TaskEditorComments from "../components/tasks/TaskEditorComments";
import TaskReassign from "../components/tasks/TaskReassign";
import TaskReassigned from "../components/tasks/TaskReassigned";
import TaskAction from "../components/tasks/TaskAction";
import TaskActionSaved from "../components/tasks/TaskActionSaved";
import Notifications from "../components/notifications/Notifications";
import Profile from "../components/profile/Profile";
import Error500 from "../components/errors/Error500";
import "bootstrap/dist/css/bootstrap.min.css";
import TaskEditorSaved from "../components/tasks/TaskEditorSaved";
import OnboardingSplash from "../components/onboarding/OnboardingSplash";
import ProjectsAdd from "../components/projects/ProjectsAdd";
import ProjectsAdded from "../components/projects/ProjectsAdded";
import ProjectsList from "../components/projects/ProjectsList";
import EmailTrackingNotificationMenu from "../components/email-tracking/TaskComponents/EmailTrackingNotificationMenu";
import EmailTrackingTaskMenu from "../components/email-tracking/EmailTrackingTaskMenu";
import EmailTrackingTaskAdd from "../components/email-tracking/EmailTrackingTaskAdd";
import EmailTrackingTaskDetails from "../components/email-tracking/EmailTrackingTaskDetails";
import EmailTrackingTaskDetailsPlaceholder from "../components/email-tracking/EmailTrackingTaskDetailsPlaceholder";
import TaskEdit from "../components/tasks/TaskEdit";

const AppRender = ({
  context,
  setPage,
  currentPage,
  setAppMode,
  onLoginChange,
  onLogout,
  setProject,
  currentProject,
  setCurrentProject,
  setCurrentProjectAddTask,
  localizeProjectDates,
  setTaskNameAndCreatedBy,
  taskAction,
  setTaskAction,
  resetTaskAction,
  localizeTaskDates,
  taskIdInserted,
  currentTaskName,
  currentTaskCreatedById,
}) => {
  if (currentPage == "onboarding") {
    return (
      <div>
        <OnboardingSplash onLoginChange={onLoginChange} setPage={setPage} />
      </div>
    );
  }

  if (currentPage == "login") {
    return (
      <div>
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <Login onLoginChange={onLoginChange} setPage={setPage} />
      </div>
    );
  }

  if (currentPage == "projects") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <Projects
          setCurrentProject={setCurrentProject}
          setPage={setPage}
          setCurrentProjectAddTask={setCurrentProjectAddTask}
          localizeProjectDates={localizeProjectDates}
        />
      </div>
    );
  }

  if (currentPage == "projectsDetails") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <ProjectsDetails
          setPage={setPage}
          setAppPage={setPage}
          projectInfo={currentProject}
          setCurrentProjectAddTask={setCurrentProjectAddTask}
        />
      </div>
    );
  }

  if (currentPage == "projectsAdd") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <ProjectsAdd setPage={setPage} setCurrentProject={setCurrentProject} setAppPage={setPage} />
      </div>
    );
  }

  if (currentPage == "projectsAdded") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <ProjectsAdded setPage={setPage} setAppPage={setPage} />
      </div>
    );
  }

  if (currentPage == "projectsList") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <ProjectsList
          setPage={setPage}
          setProject={setProject}
          setAppPage={setPage}
          localizeProjectDates={localizeProjectDates}
        />
      </div>
    );
  }

  if (currentPage == "emailTrackingTaskMenu") {
    return (
      <div>
        <EmailTrackingTaskMenu setPage={setPage} />
      </div>
    );
  }

  if (currentPage == "emailTrackingTaskAdd") {
    return (
      <div>
        <EmailTrackingTaskAdd setPage={setPage} />
      </div>
    );
  }

  if (currentPage === "emailTrackingTaskDetails") {
    return (
      <div className="m-1">
        {currentConversationId ? (
          <EmailTrackingTaskDetails
            setPage={setPage}
            setAppPage={setPage}
            conversationId={currentConversationId}
            currUserId={context.currUser?.Id}
          />
        ) : null}
      </div>
    );
  } else if (currentPage === "emailTrackingTaskDetailsPlaceholder") {
    return (
      <div className="m-1">
        <div className="m-1">
          <EmailTrackingTaskDetailsPlaceholder />
        </div>
      </div>
    );
  }

  if (currentPage == "tasks") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <Tasks setPage={setPage} localizeTaskDates={localizeTaskDates} />
      </div>
    );
  }

  if (currentPage == "taskAdded") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskAdded setPage={setPage} />
      </div>
    );
  }

  if (currentPage == "task") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskAdd setPage={setPage} />
      </div>
    );
  }

  if (currentPage == "task : regularTask" && context.officeAppType === "Outlook") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskAdd setPage={setPage} setAppPage={setPage} projectSelected={currentProject} isRegularTask={true} />
      </div>
    );
  }

  if (currentPage == "taskEditor") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskEditor
          setPage={setPage}
          setTaskNameAndCreatedBy={setTaskNameAndCreatedBy}
          taskAction={taskAction}
          resetTaskAction={resetTaskAction}
          localizeTaskDates={localizeTaskDates}
          taskIdInserted={taskIdInserted}
        />
      </div>
    );
  }

  if (currentPage == "taskEditorSaved") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskEditorSaved setPage={setPage} />
      </div>
    );
  }

  if (currentPage == "tasksEdit") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskEdit setPage={setPage} currentTask={context.currentTask} />
      </div>
    );
  }

  if (currentPage == "taskEditSuccess") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskAdded existingTask={context.existingTask} setPage={setPage} setAppPage={setPage} isEditTask={true} />
      </div>
    );
  }

  if (currentPage === "taskReassign") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskReassign
          setPage={setPage}
          taskId={context.currentTaskId === 0 ? context.currentTask.Id : context.currentTaskId}
          taskName={currentTaskName}
          createdById={currentTaskCreatedById}
          task={context.currentTask}
          currUserId={context.currUser?.Id}
          currProjId={context?.currentProjectId}
        />
      </div>
    );
  }

  if (currentPage === "taskReassigned") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskReassigned setPage={setPage} />
      </div>
    );
  }

  if (currentPage.includes("taskAction:")) {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskAction
          setPage={setPage}
          taskId={context.currentTaskId}
          taskName={currentTaskName}
          action={currentPage.split(": ")[1]}
        />
      </div>
    );
  }

  if (currentPage == "taskAddedFail") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskAddedFail existingTask={context.existingTask} setPage={setPage} setAppPage={setPage} />
      </div>
    );
  }

  if (currentPage == "emailTrackingNotificationMenu") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <EmailTrackingNotificationMenu
          existingLegacyEmailTask={context.existingLegacyEmailTask}
          setPage={setPage}
          setAppPage={setPage}
        />
      </div>
    );
  }

  if (currentPage.includes("taskActionSaved:")) {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskActionSaved setPage={setPage} setAppMode={setAppMode} action={currentPage.split(": ")[1]} />
      </div>
    );
  }

  if (currentPage == "taskEditorComments") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <TaskEditorComments setPage={setPage} setTaskAction={setTaskAction} taskId={context.currentTaskId} />
      </div>
    );
  }

  if (currentPage == "notifications") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <Notifications
          setPage={setPage}
          setCurrentProject={setCurrentProject}
          setCurrentProjectAddTask={setCurrentProjectAddTask}
          localizeProjectDates={localizeProjectDates}
        />
      </div>
    );
  }

  if (currentPage == "profile") {
    return (
      <div className="m-1">
        <Menu onLoginChange={onLoginChange} setPage={setPage} />
        <Profile onLogout={onLogout} />
      </div>
    );
  }

  if (currentPage == "500 error") {
    // Error Page for Compose Mode
    if (
      Office.context.mailbox !== undefined &&
      context.officeAppType == "Outlook" &&
      Office.context.mailbox.item?.displayReplyForm == undefined
    ) {
      return (
        <div className="m-1">
          <Error500 setPage={setPage} isComposeMode={true} />
        </div>
      );
    } else {
      return (
        <div className="m-1">
          <Menu onLoginChange={onLoginChange} setPage={setPage} />
          <Error500 setPage={setPage} />
        </div>
      );
    }
  }
};

AppRender.propTypes = {
    context: PropTypes.object,
    setPage: PropTypes.func,
    currentPage: PropTypes.string.isRequired,
    setAppMode: PropTypes.func,
    onLoginChange: PropTypes.func.isRequired,
    onLogout: PropTypes.func,
    setProject: PropTypes.func,
    currentProject: PropTypes.object,
    setCurrentProject: PropTypes.func,
    setCurrentProjectAddTask: PropTypes.func,
    localizeProjectDates: PropTypes.func,
    setTaskNameAndCreatedBy: PropTypes.func,
    taskAction: PropTypes.object,
    setTaskAction: PropTypes.func,
    resetTaskAction: PropTypes.func,
    localizeTaskDates: PropTypes.func,
    taskIdInserted: PropTypes.string,
    currentTaskName: PropTypes.string,
    currentTaskCreatedById: PropTypes.string,
};

export default AppRender;
