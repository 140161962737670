import React, { useRef, useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import iconCheck from "../../resources/Icons/Icon\ -\ Check.svg";
import dropdownArrow from "../../resources/Icons/Icon\ -\ ArrowDown.svg";
import "./dropdown.css";

// values MUST be an array in the format {id: ..., name: ..., }
//   id is the 'unique' property of each item, and name is the displayed value
//   disabled is an optional field that just greys out the option's text, and disallows the user from selecting it
const DropdownMain = ({ topListSize, values, headingTop, headingBottom, dropdownButtonText, onSelect, currentSelected, disabled, isSearchable = false }) => {
    // are there >= 6 total items?
    const [isScroll, setIsScroll] = useState(false);
    const [selected, setSelected] = useState(null);
    const [toggled, setToggled] = useState(false);
    const [toggled1, setToggled1] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const node = useRef();

    useEffect(() => {
        if (currentSelected) {
            let selectedName = values?.find(value => value.id === currentSelected);
            setSelected({ id: currentSelected, name: selectedName?.name });
        }
        setIsScroll(values && values.length >= 6);
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [values])

    const handleClick = (e) => {
        if (node.current && node.current.contains(e.target)) {
            setToggled(!toggled);
            return;
        }
        setToggled1(false);
    }

    const handleChange = (value) => {
        onSelect(value);
        setSelected(value);
        setFilterValue('');
        node.current.children[0].children[0].children[0].value = value.name;
    }

    const isDisplayValue = (value) => {
        if (!selected) return true;
        return selected.id !== value.id;
    }

    const onKeyDownDropdownItem = (keyEvent, value) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            handleChange(value);
            setToggled(false);
            setToggled1(false);
        }
    }

    const onKeyDropDownToggle = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 40) {
            setToggled1(true);
        }
    }

    const displaySelected = () => {
        return (
            <Dropdown.Item  onKeyDown={(keyEvent) => onKeyDownDropdownItem(keyEvent,selected)} className='dropdown-row dropdown-selected-row mt-0 mb-0 ' key={selected.id} value={selected.id} onClick={() => {handleChange(selected)}}>
                <span className='dropdown-row-text'>
                    {selected.name}
                </span>
                <img alt="checkmark" src={iconCheck} className="dropdown-selected-row-checkmark"/>
            </Dropdown.Item>
        )
    }

    if (disabled) {
        return (
            <div  className="dropdown-main-disabled">
                <Dropdown className="dropdown-main">
                    <Dropdown.Toggle id='dropdown-toggle-disabled' className={'dropdown-button dropdown-button-disabled'} as='div'>
                        {selected ?
                            <span className='dropdown-button-selected-text'>
                                {selected.name}
                            </span>
                        :
                            <span className='dropdown-button-text'> {dropdownButtonText || "Select an item"} </span>
                        }
                        <img className='dropdown-arrow dropdown-arrow-disabled' alt='dropdown-arrow' src={dropdownArrow}/>
                    </Dropdown.Toggle>
                </Dropdown>
            </div>
        )
    }

    // toggled && toggled1 is true when the dropdown toggle is active, and false otherwise
    return (
        <div className="dropdown-main-top-level" ref={node} onClick={(e) => handleClick(e)}>
            <Dropdown show = {toggled1} className="dropdown-main" onClick={() => { setToggled1(!toggled1)}}>
                <Dropdown.Toggle onKeyDown = {onKeyDropDownToggle} id='dropdown-toggle' className={toggled && toggled1 ? 'pill-wapper dropdown-input dropdown-button selected-button' : 'pill-wapper dropdown-input dropdown-button'} as='div' >
                    {isSearchable ?
                        <input
                            placeholder={selected?.name || dropdownButtonText}
                            type='text'
                            onChange={e => {
                                setFilterValue(e.target.value);
                            }}
                            onFocus={e => {
                                setFilterValue("");
                            }}
                            onBlur={e => {
                                e.target.value = selected?.name || "";
                            }}
                            className='dropdown-input-field'
                        >
                        </input>
                    :
                        <span className='dropdown-button-text'> {selected?.name || dropdownButtonText || "Select an item"} </span>
                    }

                    {
                        <img className='dropdown-arrow' alt='dropdown-arrow' src={dropdownArrow}/>
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu className={`dropdown-main-menu pt-0 pb-0 d-flex flex-column`} as='div'>
                    {values.slice(0,topListSize) && values.slice(0,topListSize).length > 0 && values.slice(0,topListSize).find(task => task.name.toLowerCase().includes(filterValue.toLowerCase())) && <div className='section-botton-border d-flex flex-column'>
                        <div className='dropdown-list-header dropdown-row-text'>
                                {headingTop}
                        </div>
                        <div className='d-flex flex-column'>
                            {/* Selected item always remains at top with checkmark next to its value */}
                            {selected && values.slice(0,topListSize).findIndex(task => task.id === selected.id) !== -1 && isScroll && displaySelected()}

                            {values.slice(0,topListSize).map((value, index) => value?.name?.toString().toLowerCase().includes(filterValue.toLowerCase()) && (
                                    <Fragment key={index}>
                                        {isDisplayValue(value) &&
                                            <Dropdown.Item onKeyDown={(keyEvent) => onKeyDownDropdownItem(keyEvent,value)} className='mt-0 mb-0 dropdown-row' key={value.id} value={value.id} disabled={value.disabled} onClick={() => {handleChange(value)}}>
                                                <span className={value.disabled ? 'dropdown-row-text dropdown-disabled-text' : 'dropdown-row-text'}>
                                                    {value.name}
                                                </span>
                                            </Dropdown.Item>
                                        }
                                    </Fragment>
                                    )
                                )
                            }
                        </div>
                    </div>}
                    {values.slice(topListSize) && values.slice(topListSize).length > 0 && values.slice(topListSize).find(task => task.name.toLowerCase().includes(filterValue.toLowerCase())) && <div className='d-flex flex-column'>
                        <div className='dropdown-list-header dropdown-row-text'>
                                {headingBottom}
                        </div>
                        <div className='d-flex flex-column'>
                            {/* Selected item always remains at top with checkmark next to its value */}
                            {selected && values.slice(topListSize).findIndex(task => task.id === selected.id) !== -1 && isScroll && displaySelected()}

                            {values.slice(topListSize).map((value, index) => value?.name?.toString().toLowerCase().includes(filterValue.toLowerCase()) && (
                                    <Fragment key={index}>
                                        {isDisplayValue(value) &&
                                            <React.Fragment>
                                                <Dropdown.Item onKeyDown={(keyEvent) => onKeyDownDropdownItem(keyEvent,value)} className='mt-0 mb-0 dropdown-row' key={value.id} value={value.id} disabled={value.disabled} onClick={() => {handleChange(value)}}>
                                                    <span className={value.disabled ? 'dropdown-row-text dropdown-disabled-text' : 'dropdown-row-text'}>
                                                        {value.name}
                                                    </span>
                                                </Dropdown.Item>
                                            </React.Fragment>
                                        }
                                    </Fragment>
                                    )
                                )
                            }
                        </div>
                    </div>}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

DropdownMain.propTypes = {
    topListSize: PropTypes.number,
    values: PropTypes.array.isRequired,
    headingTop: PropTypes.string,
    headingBottom: PropTypes.string,
    dropdownButtonText: PropTypes.string,
    onSelect: PropTypes.func,
    currentSelected: PropTypes.number,
    disabled: PropTypes.bool,
    isSearchable: PropTypes.bool,
};

export default DropdownMain;