import React from "react";
import { FluentIcons } from "./icons";
import PropTypes from "prop-types";

export const FluentIconDefaults = {
  color: '#0e75bb',
  size: 24,
};

const FluentUiIcon = ({
  name,
  color = FluentIconDefaults.color,
  size = FluentIconDefaults.size,
}) => {
  const Icon = FluentIcons[name];

  if (!Icon) {
    console.error(`Fluent UI Icon ${name} not found`);
    return null;
  }

  const iconStyle = {
    color,
    width: size,
    height: size,
  };

  return <Icon style={iconStyle} data-testid={name} />;
};

FluentUiIcon.propTypes = {
  name: PropTypes.oneOf(Object.keys(FluentIcons)).isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
};
export default FluentUiIcon;