import React, { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import SuccessIcon from "../../resources/success.png";
import WarningIcon from "../../resources/Icons/warning-120px-Gold-40.svg"
import AppContext from "../../app/AppContext";

const TaskAddedFail = (props) => {
  const { existingTask, setPage } = props;
  const context = useContext(AppContext);

  const isMemberAssignedToTask = (currentUserId, task) => {
    if (!task) return false;
    if (!task.Members?.length) return false;
    return task.Members.filter(m => m.UserId === currentUserId)?.length > 0;
  }

  const onTaskSelect = (task) => {
    if (task.Id == context.currentTaskId) {
      setPage("taskEditor");
    } else {
      context.currentTask = task;
      setPage("taskEditor");
    }
  }

  const taskList = () => {
    if (context.previousPage === "projects") {
      setPage("projects");
    } else {
      setPage("tasks");
    }
    context.previousPage = "taskAddedFail";
  };

  const openWebPage = () => {
    window.open(context.applicationEndpoint);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  let activeView = null;
  activeView = (
    <div className="mt-7 mr-3">
      <div className="text-center">
        <img src={WarningIcon} className="projectCreatedLogo"></img>
      </div>
      <div className="text-center mt-4">
        <span className="projectCreatedTitle">
              The task failed to create.
        </span>
        <p className="mt-3 px-3 mb-0">
          A new task could not be created because a user has already created a task on this email thread.
        </p>
        {
          !isMemberAssignedToTask(context.currUser.Id, existingTask) &&
          <p>You do not have permission to view the existing task.</p>
        }
      </div>
      {
      isMemberAssignedToTask(context.currUser.Id, existingTask) ?
        <div className="fixed-bottom pl-4 pr-4 pb-2 whiteBackground">
          <button
            className={`loginButton btn-primary blueButtonOutline ${isMemberAssignedToTask(context.currUser.Id, existingTask) ? 'button50' : 'button100'} ml-1 shadow-none`}
            onClick={() => taskList()}
          >
            {context.previousPage === "projects"? "Go to Project List" : "Go to Task List"}
          </button>
          <button
            className="loginButton btn-primary blueButton button50 mr-1 shadow-none float-right"
            onClick={() => onTaskSelect(existingTask)}
          >
            View Task
          </button>
          <div className="text-center mb-4 mt-3">
            <a href="#" onClick={e => openWebPage()}>
              <span className="appFont underline projectCreatedWebLink buttonLink">
                Switch to web application
              </span>
            </a>
          </div>
        </div>
      :
        <div className="fixed-bottom pl-3 pr-3 pb-2 whiteBackground">
          <button
            className="loginButton btn-primary btn-block shadow-none blueButton"
            onClick={() => taskList()}
          >
            Go to Task List
          </button>
          <div className="text-center mb-4 mt-3">
            <a href="#" onClick={() => openWebPage()}>
              <span className="appFont underline projectCreatedWebLink buttonLink">
                Switch to web application
              </span>
            </a>
          </div>
        </div>
      }
    </div>
  );
  return <div>{activeView}</div>;
}

export default TaskAddedFail;