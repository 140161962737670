import React, { useContext, useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Moment from "react-moment";
import Word from "../../resources/word.png";
import Excel from "../../resources/excel.png";
import Powerpoint from "../../resources/powerpoint.png";
import StdTaskIcon from "../../resources/standard_task_40px.png";
import { chevronUpBlueIcon, chevronDownBlueIcon } from "../../resources/Icons/icons";
import TaskStatus from "./TaskStatus";
import AppContext from "../../app/AppContext";
import OfficeWordService from "../../services/OfficeWordService";
import OfficePowerPointService from "../../services/OfficePowerpointService";
import ApiService from "../../services/ApiService";

const TasksListDetails = props => {
  const { tasks, docType, setAppPage, filterTasks } = props;
  const context = useContext(AppContext);
  const [updatedKey, setUpdatedKey] = useState(1);
  const [taskColorCss, setTaskColorCss] = useState("");
  const [taskIcon, setTaskIcon] = useState("");
  const [main, setMain] = useState(props.main);
  let getDownloadedTaskId = parseInt(sessionStorage.getItem("downloadedTaskId")) || 0;
  let getDownloadedScroll = parseFloat(sessionStorage.getItem("downloadedScroll")) || 0;

  const orderTasks = () => {
    switch (docType) {
      case "Regular":
        setTaskColorCss("projectListTitle taskListTitleRegular");
        break;
      case "Word":
        setTaskColorCss("projectListTitle taskListTitleWord");
        setTaskIcon(Word);
        break;
      case "Excel":
        setTaskColorCss("projectListTitle taskListTitleExcel");
        setTaskIcon(Excel);
        break;
      case "PowerPoint":
        setTaskColorCss("projectListTitle taskListTitlePowerpoint");
        setTaskIcon(Powerpoint);
        break;
      default:
        setTaskColorCss("projectListTitle taskListTitleOther");
        break;
    }
  };

  useEffect(() => {
    if (updatedKey < 2) orderTasks();
  }, [updatedKey]);

  useEffect(() => {
    const checkFirstRenderExists = context.addinFirstRender;
    const checkOfficeAppType = context.officeAppType !== "Outlook";
    let checkDownloadedTaskExists = tasks.length > 0 && getDownloadedTaskId > 0;

    if (checkDownloadedTaskExists && checkFirstRenderExists && checkOfficeAppType) {
      const dowloadedTaskInList = tasks.filter(task => task.Id === getDownloadedTaskId)[0];
      onTaskSelect(dowloadedTaskInList);
    }
  }, [tasks]);

  const getTotalTasksDisplayed = () => {
    if (main === "") {
      return 3;
    } else if (main === docType) {
      return tasks.length;
    } else {
      return 0;
    }
  };

  const toggleTasksDisplaySize = () => {
    let totalDisplayed = getTotalTasksDisplayed();
    if (totalDisplayed === 3 || totalDisplayed === 0) {
      setMain(docType);
    } else {
      setMain("");
    }
  };

  const getTaskDocumentWord = async id => {
    await ApiService.getTaskDocumentWord(id, context)
      .then(result => {
        if (result.status === 200) {
          OfficeWordService.createDocument(result.data);
        } else {
          console.log("API [GetTaskDocumentWord] error status: " + result.status);
        }
      })
      .catch(error => {
        console.log("API [GetTaskDocumentWord] error ->");
        console.log(error);
        setAppPage("500 error");
      });
  };

  const getTaskDocumentPowerPoint = async id => {
    await ApiService.getTaskDocumentPowerpoint(id, context)
      .then(result => {
        if (result.status === 200) {
          OfficePowerPointService.createDocument(result.data);
        } else {
          console.log("API [GetTaskDocumentPowerPoint] error status: " + result.status);
        }
      })
      .catch(error => {
        console.log("API [GetTaskDocumentPowerPoint] error ->");
        console.log(error);
        setAppPage("500 error");
      });
  };

  const downloadTaskDocumentWord = async task => {
    await ApiService.getTaskDocumentWord(task.WordDocumentFileId, context).then(async result => {
      if (result.status === 200) {
        const blob = await (
          await fetch(
            `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${result.data}`
          )
        ).blob();
        saveAs(blob, task.WordDocumentFile);
      } else {
        console.log("API [downloadTaskDocumentWord] error status: " + result.status);
      }
    });
  };

  const downloadTaskDocumentPowerpoint = async task => {
    await ApiService.getTaskDocumentPowerpoint(task.WordDocumentFileId, context).then(async result => {
      if (result.status === 200) {
        const blob = await (
          await fetch(
            `data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,${result.data}`
          )
        ).blob();
        saveAs(blob, task.DocumentFile);
      } else {
        console.log("API [DownloadTaskDocumentPowerPoint] error status: " + result.status);
      }
    });
  };

  const onTaskSelect = task => {
    if (task?.Id == context.currentTaskId) {
      context.currentTask = task;
      setAppPage("taskEditor");
    } else {
      context.currentTask = task;
      if (task?.TaskType === "Regular" || context.officeAppType == "Outlook") {
        setAppPage("taskEditor");
      } else if (context.officeAppType === "PowerPoint") {
        if (task?.DocumentType === "PowerPoint") {
          getTaskDocumentPowerPoint(task?.WordDocumentFileId);
        } else if (task?.DocumentType === "Word") {
          downloadTaskDocumentWord(task);
        }
      } else if (context.officeAppType == "Word") {
        if (task?.DocumentType === "Word") {
          getTaskDocumentWord(task?.WordDocumentFileId);
        } else if (task?.DocumentType === "PowerPoint") {
          downloadTaskDocumentPowerpoint(task);
        }
      }
    }
  };

  const getTaskAction = task => {
    let icon;
    switch (task.DocumentType) {
      case "Word":
        icon = Word;
        break;
      case "PowerPoint":
        icon = Powerpoint;
        break;
      case "Excel":
        icon = Excel;
        break;
      default:
        icon = StdTaskIcon;
        break;
    }

    if (task) {
      return (
        <div className="col-1 text-left pl-0 align-self-center d-inline-flex taskListIconsContainer">
          {task.IsActionable && (
            <img src="../../../assets/icon-action-indicator.svg" alt="Actionable Task" className="taskListIconActive" />
          )}
          <img className="taskListIcon text-left" src={icon}></img>
        </div>
      );
    }
  };

  let tasksDisplayed = getTotalTasksDisplayed();
  let searchingTasks = tasks
    .filter(f => f.Name?.toLowerCase().includes(filterTasks.toLowerCase()) || filterTasks === "")
    .slice(0, tasksDisplayed);

  let activeView = null;

  const getTaskTitle = () => {
    let title = "";
    if (context.officeAppType == "Outlook") {
      if (docType == "Regular") {
        title = "Standard Tasks";
      } else {
        title = "Other Tasks";
      }
    } else {
      title = docType;
    }

    return title;
  };

  const isShowingMore = tasksDisplayed <= 3;
  activeView = (
    <React.Fragment key={updatedKey}>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12" style={{ paddingRight: "30px" }}>
              <div className={taskColorCss}>
                <span className="ml-2">
                  {getTaskTitle()}{" "}
                  <span className="taskListItemCount">
                    {" "}
                    {tasks.length} {tasks.length === 1 ? "Task" : "Tasks"}
                  </span>
                </span>
                <span className="text-right float-right">
                  <span
                    className={"tasksMoreLess"}
                    disabled={tasks.length <= 3}
                    style={tasks.length <= 3 ? { pointerEvents: "none" } : {}}
                    onClick={toggleTasksDisplaySize}
                    onKeyDown={() => {}}
                  >
                    {isShowingMore ? "Show More" : "Show Less"}&nbsp;
                    <img src={isShowingMore ? chevronDownBlueIcon : chevronUpBlueIcon} alt={isShowingMore ? "Show more" : "Show less"}/>
                  </span>
                </span>
              </div>
            </div>
          </div>
          { searchingTasks.map(task => (
            <div className="taskListItemPadding"
              type="button"
              style={{ flexWrap: "nowrap" }}
              key={task.Id}
              onClick={() => onTaskSelect(task)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  onTaskSelect(task);
                }
              }}
              role="button"
              tabIndex={0}
            >
              {getTaskAction(task)}
              <div className="taskListDetails">
                <OverlayTrigger
                  placement="auto-start"
                  delay={{ show: 250, hide: 50 }}
                  overlay={
                    <Tooltip id="tooltip-1" className="customTooltip" placement="auto-start">
                      {task.Name}
                    </Tooltip>
                  }
                >
                  <span className="taskListTitle overflowEllipses">{task.Name}</span>
                </OverlayTrigger>
                <span className="appFont taskListDate">
                  Due on <Moment format="MM/DD/YYYY">{task.Due}</Moment>
                </span>

                <div className="taskStatusBadge">
                  <div className="statusBadgeRow">
                    <TaskStatus
                      status={task.StatusAndButtons?.Status ?? task.Status}
                      customStatus={task?.Workflow?.Status}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
  return <div>{activeView}</div>;
};

export default TasksListDetails;
