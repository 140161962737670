import React, { useContext, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import TasksList from "./TasksList";
import TaskAdd from "./TaskAdd";
import TaskAdded from "./TaskAdded";
import AppContext from "../../app/AppContext";

const Tasks = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState("tasksList");
  const [currentTask, setCurrentTask] = useState(null);
  const [updatedKey, setUpdatedKey] = useState(1);

  const setPage = async (page, task) => {
    if (task != null) {
      context.currentTaskId = task.Id;
    } else {
      context.currentTaskId = 0;
    }
    setCurrentPage(page);
    setCurrentTask(task);
    setUpdatedKey(updatedKey + 1);
  };

  useEffect(() => {
    const waitingCurrentPage = () => {
      if (!currentPage.length) {
        setTimeout(waitingCurrentPage, 300);
      }
    };
    waitingCurrentPage();
  }, [updatedKey, currentPage]);

  return (
    <React.Fragment key={updatedKey}>
      {
        (currentPage === "tasksList") &&
        <TasksList
          setPage={setPage}
          setAppPage={props.setPage}
          localizeTaskDates={props.localizeTaskDates}
        />
      }
      {
        (currentPage === "taskEditSuccess") &&
          <TaskAdded
            existingTask={context.existingTask}
            setPage={setPage}
            setAppPage={props.setPage}
            isEditTask = {true}
          />
      }
      {
        (currentPage == "tasksAdd") &&
        <TaskAdd
          setPage={setPage}
          setAppPage={props.setPage}
        />
      }
    </React.Fragment>
  )
}

export default Tasks;
