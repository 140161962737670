import React, { useEffect } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import TaskDynamicFieldsInput from '../Input/TaskDynamicFieldsInput';
import {
    isDynamicFieldValid,
    isMaskValid,
    isRegexValid,
    FIELD_TYPES,
} from '../utils/utils';

const TaskDynamicFieldsForm = (props) => {
    const { field, disabled, handleChange, disableErrors, setDynamicFields, onChange  } = props;

    useEffect(() => {
        if(onChange) {
            onChange(field);
        }
    }, [field, onChange]);

    return (
        <div className='tasks-dynamic-fields-wrapper'>
            <Form.Group
                className={`${field.CssClass ?? ''} ${disabled ? 'disabled' : ''}`}
            >
                {field.FieldType !== "Table" && <DynamicFieldsFormInputLabel field={field} />}
                <TaskDynamicFieldsInput
                    field={field}
                    handleChange={handleChange}
                    disableErrors={disableErrors}
                    disabled={disabled}
                    setDynamicFields={setDynamicFields}
                />
                {
                    !disableErrors ?
                <Form.Control.Feedback type="invalid" className="d-block">
                    <DynamicFieldsFormInputFeedback field={field} />
                </Form.Control.Feedback>: <></>
                }

            </Form.Group>
        </div>
    );
};

const DynamicFieldsFormInputLabel = (props) => {
    const { field } = props;
    const label = (
        field.FieldType === FIELD_TYPES.toggle ? "" :
            <Form.Label className='appFontSubHeading'>
                {field.Label}
                <span className="red">{field.Required ? ' *' : null}</span>
            </Form.Label>
    );

    if (field.FieldHelp) {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{field.FieldHelp}</Tooltip>}
            >
                {label}
            </OverlayTrigger>
        );
    }

    return label;
};

const DynamicFieldsFormInputFeedback = (props) => {
    const { field } = props;

    if (!isMaskValid(field)) {
        return 'Must follow the format: ' + field.FieldMask;
    } else if (!isRegexValid(field)) {
        return field.FieldRegexDescription;
    } else if (!isDynamicFieldValid(field)) {
        return field.FieldType === FIELD_TYPES.table
            ? 'Please add at least one row with values.'
            : 'Field is Required';
    } else {
        return null;
    }
};

export default TaskDynamicFieldsForm;
