import React, { Component } from "react";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import ApiService from "../../services/ApiService";
import Moment from "react-moment";
import AppContext from "../../app/AppContext";
import ValidationMessages from "../ValidationMessages";
import { trackPromise} from "react-promise-tracker";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import attachmentIcon from "../../resources/Icons/Icon\ -\ Attachments.svg";
import attachmentIconWhite from "../../resources/Icons/Icon\ -\ Attachments\ White.svg";
import arrowDownWhite from "../../resources/Icons/Icon\ -\ Arrow\ Down\ White.svg";
import downloadIcon from "../../resources/Icons/Icon\ -\ Download.svg";
import sendIcon from "../../resources/Icons/Icon\ -\ Send.svg";
import removeAttachment from "../../resources/Icons/Icon\ -\ Remove Attachment.svg";
import noCommentsImage from "../../resources/Images/Image\ -\ No\ Comments.svg";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { validFileTypesString  } from "../../util/file-helpers.js";
import "bootstrap/dist/css/bootstrap.min.css";

class TaskEditorComments extends Component {
  state = {
    task: null,
    valMsgs: [],
    selectedFiles: [],
    commentNote: "",
    hide: false,
    currentView: "task",
    noteAction: 11,
  };

  static renderCount = 0;
  static contextType = AppContext;

  downloadZip = (filesList) => {
    let zip = new JSZip();
    let date = new Date();
    let dateStr = "Attachments-" + date.getDate() + "_" + (date.getMonth() + 1) + "_" + date.getFullYear();
    let dateStrZip = dateStr + ".zip";
    let folder = zip.folder(dateStr)
    trackPromise(
        Promise.all(
            filesList.map((file, index) => {
              return ApiService.downloadCommentAttachment(file.FileId, file.FileContentType, this.context).then(
                  (result) => {
                    if(result.status === 200){
                      const blob = new Blob([result.data], {
                        type: file.FileContentType,
                      });
                      return [file, index, blob]
                    } else {
                      console.log("API [DownloadCommentAttachment] error status: " + result.status);
                    }
                  })
                .catch(e => {
                  console.log("API [DownloadCommentAttachment] error ->", e);
                })
            })
        ).then(
            (values) => {
                values.forEach((value) => {
                    folder.file(filesList[value[1]].Name, value[2]);
                });
                zip.generateAsync({ type: "blob" }).then(function (content) {
                    saveAs(content, dateStrZip);
                })
            }
        )
        .catch(e => {
          console.log("API [DownloadCommentAttachment] error ->", e);
        }), "task-editor-comments-area")
  }

  downloadFile = (file) => {
    trackPromise(
      ApiService.downloadCommentAttachment(file.FileId, file.FileContentType, this.context).then(
          (result) => {
            if(result.status === 200){
              const blob = new Blob([result.data], {
                type: file.FileContentType,
              });
              saveAs(blob, file.Name);
            } else {
              console.log("API [DownloadCommentAttachment] error status: " + result.status);
            }
          })
          .catch(e => {
            console.log("API [DownloadCommentAttachment] error ->", e);
          }), "task-editor-comments-area"
    )
  }

  browseFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let files = this.state.selectedFiles;
    let newSelectedFiles = Array.from(e.target.files);
    e.target.value = null;
    if(files.length === 0){
      files = newSelectedFiles;
    }
    else{
      let fileFound = false;
      newSelectedFiles.forEach(file => {
          for(let matchingFile of files){
              if(matchingFile.name === file.name){
                  fileFound = true;
                  break;
              }
          }
          if(!fileFound){
              files.push(file);
          }
      });
    }

    this.setState({
        selectedFiles: files,
    })
  }

  deleteBrowseFile = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    let files = this.state.selectedFiles;
    let idx = files.findIndex(file => file.name === name);
    files.splice(idx, 1);
    this.setState({
        selectedFiles: files,
    })
  }

  getTask = () => {
    trackPromise(
      ApiService.getTask(this.props.taskId, this.context)
        .then(result => {
          if (result.status === 200) {
            this.setState({ task: result.data });
          } else {
            console.log("API [GetTask] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetTask] error ->");
          console.log(e);
          this.props.setPage("500 error");
        }), "task-editor-comments-area"
    );
  };

  addTaskComment = () => {
    this.renderCount = 0;
    const fd = new FormData();
    this.state.selectedFiles.forEach((file) => {
      fd.append("file", file);
    })
    fd.append("note", this.state.commentNote);
    fd.append("taskId", this.context.currentTaskId);
    fd.append("noteAction", "7");
    trackPromise(
      ApiService.addTaskComment(fd, this.context)
        .then(result => {
          if (result.status === 200) {
            this.setState({ task: result.data, commentNote: "", selectedFiles: [] });
          } else {
            console.log("API [AddTaskComment] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [AddTaskComment] error ->");
          console.log(e);
          this.props.setPage("500 error");
        }), "task-editor-comments-area"
    );
  };

  onChangeCommentNote = e => {
    this.setState({
      commentNote: e.target.value
    });
  };

  componentDidMount() {
    this.renderCount = 0;
    this.getTask();
  }

  componentDidUpdate(){
    console.log(this.renderCount);
    if(this.renderCount && this.renderCount == 3){
      window.scrollTo(0,document.body.scrollHeight);
    }
  }

  hideContent = hidden => {
    if(this.state.hide != hidden){
      this.setState({ hide: hidden });
    }
  }

  newlineToBr (str) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }

    return str.split(/[\r\n]+/).map(line => <div>{line}</div>)
  }

  onTaskAction = (action) => {
    this.props.setTaskAction(action);
  };

  render() {
    this.renderCount++;
    let activeView = null;
    let taskModeButtons = null;
    if (this.state.task && this.context.appMode == "task" && this.state.task.UserRoleActive == true) {
      if (this.state.task.UserRole == "Edit"){
        taskModeButtons = (
          <div className="col-12 taskModeButtonContainer">
            <button
              className="loginButton btn-primary blueButtonOutline shadow-none taskModeButton"
              onClick={event => this.onTaskAction("Save")}
            >
              Save
            </button>
            <button
              className="loginButton btn-primary blueButton shadow-none float-right taskModeButton"
              onClick={event => this.onTaskAction("Comment")}
            >
              Request Review
            </button>
          </div>
        );
      }
      else if (this.state.task.UserRole == "Publish") {
        taskModeButtons = (
          <div className="col-12 taskModeButtonContainer">
            <button
              className="loginButton btn-primary blueButtonOutline shadow-none taskModeButton"
              onClick={event => this.onTaskAction("Save")}
            >
              Save
            </button>
            <button
              className="loginButton btn-primary blueButton shadow-none float-right taskModeButton"
              onClick={event => this.onTaskAction("Comment")}
            >
              Publish
            </button>
          </div>
        );
      }
      else if (this.state.task.UserRole == "Approve") {
        taskModeButtons = (
          <div className="col-12 taskModeButtonContainer">
            <button
              className="loginButton btn-primary blueButtonOutline shadow-none taskModeButton"
              onClick={event => this.onTaskAction("Decline")}
            >
              Decline
            </button>
            <button
              className="loginButton btn-primary blueButton shadow-none float-right taskModeButton"
              onClick={event => this.onTaskAction("Approve")}
            >
              Approve
            </button>
          </div>
        );
      }
    }
    else if (this.state.task && this.state.task.UserRoleActive == false) {
      taskModeButtons = (
        <div>
          <div className="ml-3 mr-3 pt-7 pb-7 whiteBackground"></div>
          <div className="pb-3"/>
            <div className="fixed-bottom pl-3 pr-3 pt-3 pb-3 whiteBackground text-center col-12">
            {this.context.officeAppType !== "Outlook" ? (
              <span className="projectCreatedTitle">Please close this Office window.</span>
            ) : (
              <div className="m-5"></div>
            )}
          </div>
        </div>
      );
    }

    activeView = (
      <div>
        <LoadingOverlay area="task-editor-comments-area" inline="loading-overlay-inline show-close-btn" hideContent={this.hideContent}/>
        {!this.state.hide && this.state.task != null ?
          <div className="task-editor-comments">
            <div className="mt-7">
              {this.state.task.Comments.map((i) => (
                <div key={i.Id} className="row mb-3">
                  <div className="col-2 col-xs-2 col-sm-1 mt-4">
                    <span
                      className={`userInitialsCompact mt-1 ml-2 ${
                        i.CreatedBy.Id === this.state.task.CreatedById ? "userInitialsCompactOwner" : ""
                      }`}
                    >
                      {i.CreatedBy.Initials}
                    </span>
                  </div>
                  <div className="col-10 col-xs-10 col-sm-11 mb-3">
                    <div className="commentDate">
                      <Moment format="MM/DD/YYYY">{i.Created}</Moment>&nbsp;&nbsp;
                      <Moment format="hh:mm A">{i.Created}</Moment>
                      <span className="float-right mr-2 taskEditorAction">{i.Action}</span>
                    </div>
                    <OverlayTrigger
                      placement="auto-start"
                      delay={{ show: 250, hide: 50 }}
                      overlay={
                          <Tooltip id="tooltip-1" className="customTooltip" placement="auto-start">
                            {i.CreatedBy.Name}
                          </Tooltip>
                      }
                    >
                      <span className="commentUserName overflowEllipses">
                        {i.CreatedBy.Name}
                      </span>
                    </OverlayTrigger>
                    {i.CreatedBy.Id === this.state.task.CreatedById &&
                      <div className="taskOwner mb-2">
                        Task Owner
                      </div>
                    }
                    <div className={("commentNote ") + (i.Note == "" ? "empty-comment" : "")}>{i.Note == "" ? "<No Comments Entered>" : this.newlineToBr(i.Note)}</div>
                    {i.Files.length > 0 &&
                    <>
                      <OverlayTrigger
                        rootClose={true}
                        placement="bottom-start"
                        trigger="click"
                        overlay={
                            <Popover id="popover-contained">
                              <Popover.Content>
                                {i.Files.map((file, idx) => {
                                  return (
                                    <div key={idx} className="download-file" onClick={(e) => {this.downloadFile(file)}}>
                                      <img src={downloadIcon} className="taskEditorDownloadIcon" alt="Download File" width='12px' height='12px'/>
                                      <span className="download-file-name"> {file.Name} </span>
                                    </div>
                                  )
                                })}
                              </Popover.Content>
                            </Popover>
                        }
                      >
                        <div className="taskEditorAttachmentContainer">
                          <span className="taskEditorAttachment mt-2">
                            <img src={attachmentIconWhite} className="taskEditorAttachmentIcon" alt="Attachment"/>
                            {`${i.Files.length} ${i.Files.length > 1 ? "Attachments" : "Attachment"}`}
                            <img src={arrowDownWhite} className="taskEditorArrowIcon" alt="Attachment"/>
                          </span>
                        </div>
                      </OverlayTrigger>
                      <div className='downloadAll' onClick={(e) => {this.downloadZip(i.Files)}}>
                        <img src={downloadIcon} className="taskEditorDownloadIcon" alt="Download File" width='12px' height='12px'/>
                        <a style={{color: '#1592E6', fontSize: '12px'}}>Download All</a>
                      </div>
                    </>
                    }
                  </div>
                  <div className="col-3 text-right commentDate">
                    <div className="text-right"></div>
                  </div>
                  <div className="col-12 task-comment-bottom-border">
                    <div/>
                  </div>
                </div>
              ))}
            </div>
            <div className="pt-2 pb-3 whiteBackground">
              <div className="row">
                <div className="col-12 mb-1">
                  <span className="pl-2 pr-2 loginInput appFontSubHeading">Comments</span>
                </div>
                <div className="col-12 mb-2 pl-4 pr-4">
                  <textarea
                    placeholder="Enter Comment"
                    className="form-control form-custom-control shadow-none task-comment-box"
                    rows="3"
                    id="commentNote"
                    name="commentNote"
                    value={this.state.commentNote}
                    onChange={event => this.onChangeCommentNote(event)}
                  ></textarea>
                </div>
                <div className="col-12 mb-2  pl-4 pr-4">
                  <div className="task-attachment">
                      <div className="image-upload w-10">
                        <div className="file-input-btn">
                          <label htmlFor="file-input" className="w-10">
                          <img className="pointer" src={attachmentIcon} alt="Attachment"/>
                          </label>
                        </div>

                          {this.state.selectedFiles.map((file) => {
                            return(
                              <div className="file-pill" key={file.name}>
                                <div className="fileAttachment w-10">
                                  <OverlayTrigger
                                    placement="auto-start"
                                    delay={{ show: 250, hide: 50 }}
                                    overlay={
                                        <Tooltip id="tooltip-1" className="customTooltip" placement="auto-start">
                                          {file.name}
                                        </Tooltip>
                                    }
                                  >
                                    <span>
                                      {file.name}
                                    </span>
                                  </OverlayTrigger>
                                  <div className="w-1 float-right">
                                    <img src={removeAttachment} className="pointer float-right" alt="Remove Attachment" onClick={(e) => {this.deleteBrowseFile(e, file.name)}}/>
                                  </div>
                                </div>
                              </div>
                            )
                          })}

                        <div className="w-1 float-right" style={{margin: "5px 0"}}>
                          <img src={sendIcon} alt="Send Comment" className="buttonAddComment" onClick={event => this.addTaskComment()}/>
                        </div>
                        <input
                          type="file"
                          id="file-input"
                          accept={validFileTypesString}
                          multiple
                          onChange={e => {this.browseFile(e)}}
                        />
                      </div>
                  </div>
                </div>
                {taskModeButtons}
              </div>
            </div>
          </div>
          :
          <div className="mt-7">
            <div className="no-comments-img">
              <img src={noCommentsImage} alt="No Active Projects"/>
              <h4>No audit trail yet</h4>
              <span>Open a Triyo task to view the audit trail</span>
            </div>
          </div>
        }
      </div>
    );

    return <div>{activeView}</div>;
  }
}

export default TaskEditorComments;
