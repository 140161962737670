import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import AppContext from "../../app/AppContext";
import ApiService from "../../services/ApiService";
import FileTransferService from "../../services/FileTransferService";
import OfficeWordService from "../../services/OfficeWordService";
import { trackPromise } from "react-promise-tracker";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import ProjectAddForm from "./projectAddForm";
import DynamicFieldsFormWrapper from "../../components/dynamicFields/Form/DynamicFieldsFormWrapper";
import { OBJECT_TYPES, renderTaskDetailDynamicFields, saveDynamicFieldsDetails, isDynamicFieldsValid } from "../dynamicFields/utils/utils.js";


class ProjectsAdd extends Component {
  state = {
    projectAdd: null,
    availableUsers: [],
    availableGroups: [],
    projectNameExists: false,
    projectTemplates : [],
    selectedProjectTemplate : "",
    prioritiesAvailable : [],
    disableDynamicDetailErrors: true
  };
  static contextType = AppContext;
  errorRef = React.createRef();

  // current user (owner) should always be displayed at the top of the member's list in blue, so move to front of member list
  moveOwnertoFront = () => {
    let index = this.state.availableUsers.findIndex(obj => obj.Id === this.context.currUser.Id);
    let owner = this.state.availableUsers.find(availableUser => availableUser.Id === this.context.currUser.Id && !availableUser.isGroup);
    if (owner) {
      this.state.availableUsers.splice(index, 1);
      this.state.availableUsers.unshift(owner);
    }
  }

  setDisableDynamicDetailErrors = (value) =>{
    this.setState({
      disableDynamicDetailErrors: value,
    })
  }

  getProjectTemplates = () =>{
      ApiService.getProjectTemplates(this.context)
        .then(result => {
          if (result.status === 200) {
            this.setState({
              projectTemplates: result.data,
            });
          } else {
            console.log("API [getProjectTemplates] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [getProjectTemplates] error ->");
          console.log(e);
          this.setState({
            projectTemplates: [],
          });
        })
  }

  setSelectedProjectTemplate = (value,setFieldValue) =>{    
    if(value.id === this.state.selectedProjectTemplate.id){
      return;
    }
    this.setState({
      selectedProjectTemplate: value,
    });
    
    let duration = value?.days;
    let today = new Date();
    today.setDate(today.getDate() + duration);

    let pEnd = duration !== 0 ? today : "";
    setFieldValue("projectEnd", pEnd);
    setFieldValue("projectDescription", value?.description);
  }

  //get blank project object
  getProjectAdd = () => {
    trackPromise(
      ApiService.getProjectModel(this.context)
        .then(result => {
          if (result.status === 200) {

            let allAvailable = result.data.AvailableUsers.map(user => {
              return {...user, isGroup: false, groupIndex: -1}
            });
            result.data.AvailableGroups.forEach((group, index) => {
              allAvailable.push({...group, isGroup: true, Id: group.Id + 99999, groupIndex: index}) //99999 added to remove duplicate keys in select member list, will be subtracted before project add
            });

            this.setState({
              projectAdd: result.data,
              prioritiesAvailable: result.data.PrioritiesAvailable,
              availableUsers: this.sortUsers(allAvailable),
              availableGroups: result.data.AvailableGroups
            });
          } else {
            console.log("API [GetProjectModel] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetProjectModel] error ->");
          console.log(e);
          this.props.setAppPage("500 error");
        }),
      "project-creation-area"
    );
  };

  // creates new project and sets application context project details
  addProject = async (values, dynamicFields) => {
    let data = this.state.projectAdd;
    data.Name = values.projectName;
    data.Description = values.projectDescription;
    data.Start = values.projectStart;
    data.End = values.projectEnd;
    data.ParentTemplateId =this.state.selectedProjectTemplate?.id;
    data.ParentTemplateName = this.state.selectedProjectTemplate?.name;
    data.Priority = values.projectPriority;
    let projectSelectedUsers = []
    let projectSelectedGroups = [];
    values.projectSelectedUsers.forEach(id => {
      if(id < 99999){
        projectSelectedUsers.push(id);
      }
      else{
        projectSelectedGroups.push(id);
      }
    })
    let selectedGroupObjects = this.state.availableGroups.filter(group => projectSelectedGroups.includes(group.Id + 99999))

    selectedGroupObjects.forEach(group => {
      group.Members.forEach(member => {
        projectSelectedUsers.push(member.UserId);
      })
    })
    data.SelectedUsers = [ ... new Set(projectSelectedUsers), ... new Set(projectSelectedGroups) ];

    trackPromise(
      (async () => {
        try {
          const addProjectResult = await ApiService.addProject(data, this.context);

          if (addProjectResult.status !== 200) {
            return console.log("API [AddProject] error status: " + addProjectResult.status);
          }

          this.setState({ projectAdd: addProjectResult.data });
          this.context.currentProjectId = addProjectResult.data.Id;
          this.props.setCurrentProject(addProjectResult.data);
          let contextVar = this.context.officeAppType;

          if(dynamicFields?.length>0)
            saveDynamicFieldsDetails(dynamicFields, addProjectResult.data.Id, this.context)

          switch (contextVar) {
            case "PowerPoint":
            case "Word":
              this.uploadProject();
              break;
            case "Outlook":
              this.props.setPage("projectsAdded");
              break;
            default:
            // undefined behaviour
          }
        } catch (addProjectError) {
          console.log("API [AddProject] error ->");
          console.log(addProjectError);
          this.props.setAppPage("500 error");
        }
      })(),
      "project-creation-area"
    );
  };

  uploadProject = () => {
    FileTransferService.uploadDocumentFile(Office.context, this.onProjectFileSlicesReady);
  }

  componentDidMount() {
    this.getProjectAdd();
    this.getProjectTemplates();
  }

  // auto scroll to invalid form fields
  scrollToError = (touched, errors) => {
    if (touched.projectName && errors.projectName) {
      document.querySelector('input[name$="projectName"]').scrollIntoViewIfNeeded();
    } else if (touched.projectStart && errors.projectStart) {
      document.querySelector('input[name$="projectStart"]').scrollIntoViewIfNeeded();
    } else if (touched.projectEnd && errors.projectEnd) {
      document.querySelector('input[name$="projectEnd"]').scrollIntoViewIfNeeded();
    } else if (touched.projectSelectedUsers && errors.projectSelectedUsers) {
      document.getElementById("projectFilter").scrollIntoViewIfNeeded();
    } else if (errors.dynamicFields) {
      document.getElementById("projectDynamicFields").scrollIntoViewIfNeeded();
    } else if (touched.projectPriority && errors.projectPriority) {
      document.getElementById("projectPriority").scrollIntoViewIfNeeded();
    } else {
      document.querySelector('input[name$="projectName"]').scrollIntoViewIfNeeded();
    }

    return null;
  };

  checkProjectNameDuplication = async (data, context) => {
      await ApiService.checkProjectName(data, context)
        .then(result => {
          if (result.status === 200) {
            this.setState({ projectNameExists: result.data});
          } else {
            this.setState({ projectNameExists: false});
          }
        })
        .catch(() => {
          console.log(error);
          this.setState({ projectNameExists: false});
        }
    );
  }

  // Formik validation handler
  validate = (values, dynamicFields, setDynamicFields) => {
    let errors = {};
    if (values.projectName) {
      this.checkProjectNameDuplication(values.projectName, this.context);
    }
    if (!values.projectName) {
      errors.projectName = "This is a required field";
    } else if (this.state.projectNameExists) {
      errors.projectName = "The project name already exists";
    }
    if (!values.projectStart) {
      errors.projectStart = "This is a required field";
    }
    if (!values.projectEnd) {
      errors.projectEnd = "This is a required field";
    }
    if (values.projectStart && values.projectEnd) {
      if (values.projectStart > values.projectEnd) {
        errors.projectStart = "Select a valid date";
        errors.projectEnd = "Select a valid date";
      }
    }
    if (!this.state.prioritiesAvailable.includes(values.projectPriority)) {
      errors.projectPriority = "This is a required field";
    }
    if (values.projectSelectedUsers.length == 0) {
      errors.projectSelectedUsers = "At least one user or group has to be selected";
    }
    if (!isDynamicFieldsValid(dynamicFields)) {
      setDynamicFields(dynamicFields.map(field => ({ ...field, touched: true })));
      errors.dynamicFields = "Dynamic Fields are Invalid";
    }
    return errors;
  };

  // once Office API has converted current doc to object, use Office API to get current file name from embedded file properties
  onProjectFileSlicesReady = slices => {
    let classScope = this;
    if (Office.context.document != null) {
      Office.context.document.getFilePropertiesAsync(function(asyncResultProperties) {
        let fileName = "";
        if (asyncResultProperties.value.url != "") {
          fileName = asyncResultProperties.value.url;
        }
        classScope.uploadProjectDocument(slices, fileName);
      });
    }
  };

  // uses Office API to get the file object, then sends the file and other properties to the backend
  // backend api saves current file to database and associates it to current project
  uploadProjectDocument = (docdataSlices, fileName) => {
    let file = FileTransferService.getFileFromSlices(docdataSlices, this.context.officeAppType);
    const fd = new FormData();
    fd.append("file", file);
    fd.append("projectId", this.state.projectAdd.Id);
    fd.append("fileType", this.context.officeAppTypeDoc);
    fd.append("fileName", fileName);
    fd.append("documentId", this.context.currentProjectDocumentId)
    trackPromise(
      ApiService.addProjectFile(fd, this.context)
        .then(res => {
          if (res.status === 200) {
            this.context.currentProjectDocumentId = res.data.Id;
            this.context.currentProjectId = this.state.projectAdd.Id;
            this.context.currentProjectFileLock = true;
            console.log("addProjectFile result -> ");
            console.log(res.data);
            let contextVar = this.context.officeAppType;
            ApiService.getProjectDocument(res.data.FileNameId, this.context)
              .then(result => {
                if (result.status === 200) {
                  if (contextVar == "PowerPoint") {
                    PowerPoint.createPresentation(result.data);
                    this.context.closeInstance = true;
                    this.props.setPage("projectsAdded");
                  }
                  if (contextVar == "Word") {
                    OfficeWordService.createDocument(result.data);
                    this.context.closeInstance = true;
                    this.props.setPage("projectsAdded");
                  }
                } else {
                  console.log("API [GetProjectDocument] error status: " + result.status);
                }
              })
              .catch(e => {
                console.log("API [GetProjectDocument] error ->");
                console.log(e);
                this.props.setAppPage("500 error");
              });
          } else {
            console.log("API [AddProjectFile] error status: " + res.status);
          }
        })
        .catch(e => {
          console.log("API [AddProjectFile] error ->");
          console.log(e);
          this.props.setAppPage("500 error");
        }),
      "project-creation-area"
    );
  };

  selectUsers = (id, selectedUsers, setFieldValue, setFieldTouched) => {
    if (id == this.context.currUser.Id) {
      return;
    }
    let currentValue = id;
    let currentSelectedUsers = selectedUsers;
    if (currentSelectedUsers.indexOf(currentValue) !== -1) {
      const index = currentSelectedUsers.indexOf(currentValue);
      currentSelectedUsers.splice(index, 1);
    } else {
      currentSelectedUsers.push(currentValue);
    }
    setFieldValue("projectSelectedUsers", currentSelectedUsers);
    setFieldTouched("projectSelectedUsers");

    let updatedAvailableUsers = this.state.availableUsers;

    let updatedSelectedUsers = updatedAvailableUsers.filter(user => currentSelectedUsers.includes(user.Id));
    updatedAvailableUsers = updatedAvailableUsers.filter(user => !currentSelectedUsers.includes(user.Id));

    updatedSelectedUsers = this.sortUsers(updatedSelectedUsers);
    updatedAvailableUsers = this.sortUsers(updatedAvailableUsers);

    this.setState({availableUsers: updatedSelectedUsers.concat(updatedAvailableUsers)});
  }

  getUserDetails = (user) => {
    if(user.isGroup){
      return `${user.Name} - ${this.state.availableGroups[user.groupIndex].Size} users`
    }
    else{
      return `${user.Name} (${user.Role.Name})`
    }
  }

  getGroupHover = (group) => {
    let members = this.state.availableGroups[group.groupIndex].Members;
    let result = "";

    members.forEach((member, index) => {
      if(index === members.length - 1){
        result += `${member.User.Name} (${member.User.Email}) - ${member.User.Role.Name}`
      }
      else{
        result += `${member.User.Name} (${member.User.Email}) - ${member.User.Role.Name} \n`
      }
    })

    return result;
  }

  sortUsers = (users) => {
    let sortedUsers = users;
    return sortedUsers.sort((a,b) => {
      let nameA = a.Name.toLowerCase();
      let nameB = b.Name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };

  render() {
    let activeView = null;
    this.moveOwnertoFront();
    activeView = (
      <div className="mt-7">
        <LoadingOverlay area="project-creation-area" />
        <div className="row mt-4 mb-3">
          <div className="col-12">
            <span className="projectPageTitle appFont ml-2">Create Project</span>
          </div>
        </div>
    <DynamicFieldsFormWrapper
         projectTypeId={this.state.selectedProjectTemplate?.id}
         objectType={OBJECT_TYPES.project}
         objectId={0}
         area={'project-creation-area'}
         context={this.context}
        >
        {({ dynamicFields, setDynamicFields }) => (
        <ProjectAddForm
          validate={(values) => this.validate(values, dynamicFields, setDynamicFields)}
          onSubmit = {this.onSubmit}
          errorRef = {this.errorRef}
          addProject = {this.addProject}
          currUserId = {this.context.currUser.Id}
          availableUsers = {this.state.availableUsers}
          getUserDetails = {this.getUserDetails}
          getGroupHover = {this.getGroupHover}
          selectUsers = {this.selectUsers}
          setPage = {this.props.setPage}
          scrollToError = {this.scrollToError}
          dynamicFields = {dynamicFields}
          setDynamicFields = {setDynamicFields}
          renderTaskDetailDynamicFields = {renderTaskDetailDynamicFields}
          projectTemplates = {this.state.projectTemplates}
          setSelectedProjectTemplate = {this.setSelectedProjectTemplate}
          prioritiesAvailable = {this.state.prioritiesAvailable}
          setDisableDynamicDetailErrors = {this.setDisableDynamicDetailErrors}
          disableDynamicDetailErrors = {this.state.disableDynamicDetailErrors}
        />
        )}
        </DynamicFieldsFormWrapper>
      </div>
    );
    return <div>{activeView}</div>;
  }
}

export default ProjectsAdd;
