import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { Formik } from "formik";
import moment from "moment";
import React, { useState, useEffect, useRef, useContext } from "react";
import Dropdown from "../dropdown/dropdown";
import DatePicker from "react-datepicker";
import ApiService from "../../services/ApiService";
import AppContext from "../../app/AppContext";
import TaskAssignMembers from "../tasks/TaskAssignMembers";
import {
  notificationMessage,
  removeNotificationMessage,
  insightNotificationMessage,
  removeDuplicates,
  arrayContainsString,
  workDays
} from "../../helpers/HelperMethods";
import {
  removeHtmlTagsFromEmail,
  addInitialHeaders,
  addEmailPixelToEmail,
  addEmailParserToEmail,
  setEndDate,
  setCustomInfo,
} from "../../helpers/mailLinkUtils";
import { assignedMembersToolTipInfo, taskNameToolTipInfo } from "../../util/const-helpers";
import "../email-tracking/EmailTracking.scss";
import { validFileTypes, contentTypeMapper } from "../../util/file-helpers";
import momentBusiness from 'moment-business-days';
import DynamicFieldsFormWrapper from "../dynamicFields/Form/DynamicFieldsFormWrapper";
import { isDynamicFieldsValid, OBJECT_TYPES, renderTaskDetailDynamicFields, saveDynamicFieldsDetails } from "../dynamicFields/utils/utils";
import ToolTipIcon from "../../resources/Icons/Icon - Tooltip Info.svg";
import calendarIcon from "../../resources/Icons/Icon - calender.svg";
import EmailTrackingInlineNotification from './EmailTrackingInlineNotification';

const EmailTrackingTaskAdd = ({
  setPage,
  selectedOption,
  isEmailTrackingDisabled,
  isEmailTrackingActivity,
  isAutoSync,
  taskLinkCompleted,
  setTaskLinkCompleted,
  loading,
  setLoading,
}) => {
  const [hide, setHide] = useState(false);
  const [initialAttributesCompleted, setInitialAttributesCompleted] = useState(false);
  const [isExistingTaskDisabled, setIsExistingTaskDisabled] = useState(true);
  const [taskSelected, setTaskSelected] = useState(false);
  const [projectList, setProjectList] = useState(null);
  const [taskAdd, setTaskAdd] = useState(null);
  const [taskDue, setTaskDue] = useState(moment().add(7, "d").toDate());
  const [taskPriority, setTaskPriority] = useState("Medium");
  const [prioritiesAvailable, setPrioritiesAvailable] = useState([]);
  const [allDepMembers, setAllDepMembers] = useState([]);
  const [addedMembersId, setAddedMembersId] = useState([]);
  const [addMembersInFormCounter, setAddMembersInFormCounter] = useState(0);
  const [replacementCodes, setReplacementCodes] = useState(null);
  const [htmlEmailBody, setHtmlEmailBody] = useState("");
  const [taskName, setTaskName] = useState("");
  const [emailId, setEmailId] = useState(null);
  const [emailConversationId, setEmailConversationId] = useState(null);
  const [emailThread, setEmailThread] = useState(null);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [currentProject, setCurrentProject] = useState({});
  const [projectInfo, setProjectInfo] = useState(null);
  const [getCurrProject, setGetCurrProject] = useState(null);
  const [assignedMembers, setAssignedMembers] = useState([]);
  const [assignedOtherMembers, setAssignedOtherMembers] = useState([]);
  const [assignedMembersEmail, setAssignedMembersEmail] = useState([]);
  const [assignedOwner, setAssignedOwner] = useState({});
  const [ownerGroup, setOwnerGroup] = useState(null);
  const [filteredAssignedMembers, setFilteredAssignedMembers] = useState([]);
  const [filteredAssignedOtherMembers, setFilteredAssignedOtherMembers] = useState([]);
  const [selectedMembersNone, setSelectedmembersNone] = useState([]);
  const [selectedMembersView, setSelectedMembersView] = useState([]);
  const [selectedMembersEdit, setSelectedMembersEdit] = useState([]);
  const [selectedMembersApprove, setSelectedMembersApprove] = useState([]);
  const [permissionTaskMembers, setPermissionTaskMembers] = useState([]);
  const [permissionTaskMembersEmail, setPermissionTaskMembersEmail] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [taskTypes, setTaskTypes] = useState([]);
  const [taskTypeId, setTaskTypeId] = useState(0);
  const [currentTo, setcurrentTo] = useState([]);
  const [currentFrom, setCurrentFrom] = useState([]);
  const [currentCC, setcurrentCC] = useState([]);
  const [currentBCC, setcurrentBCC] = useState([]);
  const [BCCObjectString, setBCCObjectString] = useState("");
  const [currentRecipients, setCurrentRecipients] = useState([]);
  const [recipientsCompleted, setRecipientsCompleted] = useState(false);
  const [currentSubject, setcurrentSubject] = useState("");
  const [finalSubject, setFinalSubject] = useState("");
  const [currentBody, setcurrentBody] = useState("");
  const [currentBodyClean, setcurrentBodyClean] = useState("");
  const [emailHeaders, setEmailHeaders] = useState("");
  const [showOtherMembers, setShowOtherMembers] = useState(false);
  const [otherMembers, setOtherMembers] = useState([]);
  const [createdUnassignedProject, setCreatedUnassignedProject] = useState(false);
  const [runningTaskCreation, setRunningTaskCreation] = useState();
  const [saveDraft, setSaveDraft] = useState(false);
  const [initialRenderCompleted, setInitialRenderCompleted] = useState(false);
  const [initialRecipientsCallStart, setInitialRecipientsCallStart] = useState(false);
  const [initialRecipientsCallCompleted, setInitialRecipientsCallCompleted] = useState(false);
  const [getAllUserDepCompleted, setGetAllUserDepCompleted] = useState(false);
  const [getTaskAddCompleted, setGetTaskAddCompleted] = useState(false);
  const [getUserProjectsCompleted, setGetUserProjectsCompleted] = useState(false);
  const [getTaskTypesCompleted, setGetTaskTypesCompleted] = useState(false);
  const [getProjectAddCompleted, setGetProjectAddCompleted] = useState(false);
  const [savingCounter, setSavingCounter] = useState(0);
  const [otherMembersCounter, setOtherMembersCounter] = useState(0);
  const [filesToBeAdded, setFilesToBeAdded] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [currentProjectChanged, setCurrentProjectChanged] = useState(false);
  const [otherMemberAddedToProject, setOtherMemberAddedToProject] = useState(false);
  const [updateMembersList, setUpdateMembersList] = useState(false);
  const [addingAutoSyncAgain, setAddingAutoSyncAgain] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [disableLinkTaskButton, setDisableLinkTaskButton] = useState(false);
  const [globalIsSubmitting, setGlobalIsSubmitting] = useState(false);
  const [startSavingProccess, setStartSavingProccess] = useState(false);
  const [startSavingProccessCompleted, setStartSavingProccessCompleted] = useState(false);
  const [endDateChange, setEndDateChange] = useState(false);
  const [taskTypeDue, setTaskTypeDue] = useState(moment().add(7, "d").toDate());
  const [dFields, setDFields] = useState([]);
  const [submitDfields, setSubmitDfields] = useState(false);
  const [disableDynamicDetailErrors, setDisableDynamicDetailErrors] = useState(true);
  const [dynamicFieldsValid, setDynamicFieldsValid] = useState();
  const [notificationCompleteTask, setNotificationCompleteTask] = useState(false);

  const formRef = useRef();
  const autoSyncRef = useRef();
  const context = useContext(AppContext);
  const isOfficeOnline = context.hostName === "OutlookWebApp" || context.platform === "OfficeOnline";
  
  let intervalEmailSent;

  const hideContent = hidden => {
    if (hide != hidden) setHide(hidden);
  };

  useEffect(() => {
    let token = localStorage.getItem("tmpTriyoToken");
    if (token == null) setPage("login");
  }, [taskName, currentSubject, currentTo, currentBCC, currentCC, taskPriority, taskDue, taskTypeId]);

  //---------------------------------- 1. INITIAL RENDER STARTS -----------------------------------------------------------------

  // This Effect will only be called during initial render BUT before clicking "LINK EMAIL"
  useEffect(() => {
    setLoading(true);
    let token = localStorage?.getItem("tmpTriyoToken");
    if (token == null) setPage("login");
    if (context.currentProjectId > 0) setCurrentProjectId(context.currentProjectId);
    setAssignedOwner(context.currUser);
    getAllUserDep();
    getTaskTypes();
    getUserProjects();
    getGroupDetails(context?.currEmail?.userProfile?.emailAddress);
    momentBusiness.updateLocale('us', workDays);
    insightNotificationMessage("In case TRIYO Add-in is closed. Click =>", "compose");
  }, []);

  useEffect(() => {
    if (currentProjectId > 0 && projectList?.length > 0) {
      let getCurrProjectTemp = projectList?.filter(p => p.Id == currentProjectId);
      if (getCurrProjectTemp?.length > 0) setGetCurrProject(getCurrProjectTemp[0]);
    } else {
      setGetCurrProject(null);
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (currentProjectId > 0) {      
      if (!getTaskAddCompleted) getTaskAdd();
    } else {
      setGetTaskAddCompleted(false);
    }
  }, [currentProjectId, getTaskAddCompleted]);

  useEffect(() => {
    if (currentProjectId > 0) {
      if (!getProjectAddCompleted) getProjectAdd();
    } else {
      setGetProjectAddCompleted(false);
    }
  }, [currentProjectId, getProjectAddCompleted]);
  
  useEffect(() => {
    if (
      getAllUserDepCompleted &&
      getTaskAddCompleted &&
      getProjectAddCompleted &&
      getTaskTypesCompleted &&
      getUserProjectsCompleted &&
      initialAttributesCompleted &&
      initialRenderCompleted      
    ) {
      setInitialRecipientsCallStart(true);
    }
  }, [
    getAllUserDepCompleted,
    getTaskAddCompleted,
    getTaskTypesCompleted,
    getProjectAddCompleted &&
    getUserProjectsCompleted,
    initialAttributesCompleted,
    initialRenderCompleted,
  ]);

  useEffect(() => {
    if (initialRecipientsCallCompleted) {
      setLoading(false);
    }
  }, [initialRecipientsCallCompleted]);

  //---------------------------------- 2. LINK EMAIL LOGIC STARTS -----------------------------------------------------------------
  useEffect(() => {
    if (!isEmailTrackingDisabled)  {
      setInitialAttributesCompleted(false);
      fetchAddInitialHeaders();
    }
  }, [isEmailTrackingDisabled]);

  const fetchAddInitialHeaders = async () => {
    try {
      await addInitialHeaders(context, taskTypeId)
        .then((response) => {
          console.log("addInitialHeaders=> ");
          dynamicValidation(dFields);
          setSavingCounter(savingCounter + 1);
          context.emailRecipientsChanged = false;
          setCurrentFrom(context.currEmail.userProfile.emailAddress);
          setInitialAttributesCompleted(true);
          setSaveDraft(false);
        })
        .catch((e) => {
          console.error(e);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (initialAttributesCompleted)  {
      fetchSaveDraft();
      setInitialRenderCompleted(true);
    }
  }, [initialAttributesCompleted]);

  const fetchSaveDraft = async () => {
    try {
      Office.context.mailbox.item.saveAsync(
        (saveAsyncResult) => {
          if (saveAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
            console.log("Draft saved successfully.");
          }
          if (saveAsyncResult?.value) {
            setEmailConversationId(saveAsyncResult.value);
            setEmailId(saveAsyncResult.value);
          }
          setSavingCounter(savingCounter + 1);
          context.emailRecipientsChanged = false;
          setSaveDraft(false);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  //----------------------- 3. Rest of the logic waiting for changes and task creation---------------------------

  // This Effect it's called every 100mseconds to check if sending email process started
  useEffect(() => {
    if (initialRenderCompleted) {
      if (!isEmailTrackingDisabled) {
        intervalEmailSent = setInterval(() => {
          Office.context.mailbox.item.internetHeaders.getAsync(
            ["x-triyo-draft-saved", "x-triyo-sending-email", "x-triyo-task-created", "x-triyo-dynamicfields-valid"],
            (asyncResult) => {
              // LaunchEvent informs here if the button Send was pressed and Task creation should start
              if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                // ["x-triyo-task-created"] === "false" here prevents accessing this twice
                if (
                  asyncResult.value &&
                  asyncResult.value["x-triyo-sending-email"] === "true" &&
                  asyncResult.value["x-triyo-task-created"] === "false"
                ) {
                  if(!dynamicFieldsValid || asyncResult.value["x-triyo-dynamicfields-valid"] === "false"){
                    Office.context.mailbox.item.internetHeaders.setAsync(
                      { "x-triyo-sending-email" : "false" },
                      (headersAsyncResult) => {
                        setDisableDynamicDetailErrors(false);
                        try {
                          document.getElementsByClassName("invalid-feedback")[0].scrollIntoView({
                            behavior: "auto",
                            block: "center",
                            inline: "center"
                          });
                        } catch (error) {
                          console.log("dynamic validation error");
                          console.log(error);
                        }
                      }
                    );
                    setRunningTaskCreation(false);
                    return;
                  } else if (!runningTaskCreation && asyncResult.value["x-triyo-dynamicfields-valid"] === "true") {
                    setRunningTaskCreation(true); //Once this start, we cannot undone this action on the launchevent.js side.
                    formRef.current.handleSubmit();
                    setEmailThread(currentSubject);
                    clearInterval(intervalEmailSent);
                  }
                // If the button Send was not clicked we can save draft and continue processing
                } else {
                  if (!runningTaskCreation) {
                    if (context.emailRecipientsChanged) setSaveDraft(true);

                    let fetchGetSubject = new Promise((resolve, reject) => {
                      Office.context.mailbox.item.subject.getAsync(asyncResult => {
                        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                          resolve(asyncResult.value);
                        } else {
                          reject(asyncResult.error);
                        }
                      });
                    });

                    Promise.all([fetchGetSubject])
                      .then((subjectObject) => {
                        if (subjectObject[0]) {
                          if (currentSubject !== subjectObject[0]) setcurrentSubject(subjectObject[0]);
                          if (emailThread !== subjectObject[0]) setEmailThread(subjectObject[0]);
                          //using autoSyncRef ref to get the updated value of autosync state inside the interval
                          if (isAutoSync && autoSyncRef.current) {
                            if (taskName !== subjectObject[0]) setTaskName(subjectObject[0]);
                          }
                        } else {
                          //"fetchGetSubject[0] not data => New Email Task by default"
                          if (!currentSubject || currentSubject != "New Email Task") setcurrentSubject("New Email Task");
                          if (!emailThread || emailThread != "New Email Thread") setEmailThread("New Email Thread");
                          //using autoSyncRef ref to get the updated value of autosync state inside the interval
                          if (isAutoSync && autoSyncRef.current && (!taskName || taskName != "New Email Task")) setTaskName("New Email Task");
                        }
                      })
                      .catch((error) => {
                        console.log("Error getting subject =>");
                        console.log(error);
                      });
                  }
                }
              } else {
                console.log("Error getting selected headers: " + JSON.stringify(asyncResult.error));
                setFinalSubject(currentSubject);
              }
            }
          );
        }, 200);
        return () => clearInterval(intervalEmailSent);
      } else {
        clearInterval(intervalEmailSent);
      }
    }
  }, [initialRenderCompleted]);

  // Notification message for PWA
  useEffect(() => {
    if (isOfficeOnline && (currentSubject?.length > 0 || taskName?.length > 0) && (currentTo?.length > 0 || currentCC?.length > 0 || currentBCC?.length > 0) && !notificationCompleteTask) {
      removeNotificationMessage("This email will be tracked as a new Task on TRIYO when you click Send |");
      notificationMessage("While replying an email linked to a task, do it along with the TRIYO Add-in already open so you are to able to see the task details.");
      setNotificationCompleteTask(true);
    }
  }, [taskName, currentSubject, currentTo, currentBCC, currentCC]);

  useEffect(() => {
    if (!runningTaskCreation && initialRecipientsCallStart && !initialRecipientsCallCompleted) {
      let fetchToRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.to.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.cc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchBCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.bcc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      Promise.all([fetchToRecipients, fetchCCRecipients, fetchBCCRecipients]).then((recipients) => {
        console.log("Recipients=>");
        console.log(recipients);
        if (recipients[0].length > 0 || recipients[1].length > 0 || recipients[2].length > 0) {
          console.log("Initial recipients found.");
          context.emailRecipientsChanged = true;
        } else {
          console.log("No initial recipients found.");
        }
        setInitialRecipientsCallCompleted(true);
      });
    }
  }, [initialRecipientsCallStart]);

  // This Effect will save recipients in any field related: to, cc, bcc
  // Promise.all ensure all async callbacks finished. After this states are saved.
  useEffect(() => {
    // savedraft = true, ensures that we access only once
    if (isAutoSync && !runningTaskCreation && (saveDraft || addingAutoSyncAgain)) {
      setRecipientsCompleted(false);
      let fetchToRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.to.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.cc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchBCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.bcc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      // Clean all states if there are no recipients or set recipients states
      Promise.all([fetchToRecipients, fetchCCRecipients, fetchBCCRecipients]).then(recipients => {
        if (recipients[0].length === 0 && recipients[1].length === 0 && recipients[2].length === 0) {
          setcurrentTo([]);
          setcurrentBCC([]);
          setcurrentCC([]);
          setOtherMembers([]);
          setOtherMembersCounter(otherMembersCounter + 1);
          setCurrentRecipients([]);
          setShowOtherMembers(false);
        } else {
          let allRecipients = [];
          if (recipients[0].length > 0) {
            const data = [];
            recipients[0].forEach(email => {
              data.push(email.emailAddress.toLowerCase());
              allRecipients.push(email.emailAddress.toLowerCase());
            });
            // Here we make sure there are no duplicate email addresses
            let uniqueTo = [...new Set(data)];
            setcurrentTo(uniqueTo);
          } else {
            setcurrentTo([]);
          }
          if (recipients[1].length > 0) {
            const data = [];
            recipients[1].forEach(email => {
              data.push(email.emailAddress.toLowerCase());
              allRecipients.push(email.emailAddress.toLowerCase());
            });
            // Here we make sure there are no duplicate email addresses
            let uniqueCC = [...new Set(data)];
            setcurrentCC(uniqueCC);
          } else {
            setcurrentCC([]);
          }
          if (recipients[2].length > 0) {
            const data = [];
            recipients[2].forEach(email => {
              data.push(email.emailAddress.toLowerCase());
              allRecipients.push(email.emailAddress.toLowerCase());
            });
            // Here we make sure there are no duplicate email addresses
            let uniqueBCC = [...new Set(data)];
            setcurrentBCC(uniqueBCC);
            setBCCObjectString(encodeURI(JSON.stringify(recipients[2])));
          } else {
            setcurrentBCC([]);
            setBCCObjectString("");
          }

          // Here we make sure there are no duplicate email addresses
          let uniqueAllRecipients = [...new Set(allRecipients)];
          setCurrentRecipients(uniqueAllRecipients);
          setOtherMembersCounter(otherMembersCounter + 1);
        }
        setRecipientsCompleted(true);
      });
    }
  }, [saveDraft, addingAutoSyncAgain]);

  // This Effect will save draft if saveDraft is true
  useEffect(() => {
    if (context.emailRecipientsChanged && !runningTaskCreation) {
      if (saveDraft) fetchSaveDraft();
    }
  }, [saveDraft]);

  const updateProjectEndDate = async (callback) => {
    let data = {
      Id: currentProjectId,
      End: moment(taskDue).format("YYYY-MM-DDTHH:mm:ss"),
    };
    let message = "There was an error in processing this request. We’re investigating what went wrong.";
  
    await ApiService.updateProjectEndDate(data, context)
      .then(result => {
        if (result.status === 200) {
          getUserProjects();
          setCurrentProject(prevState => ({
            ...prevState,
            end: data.endDate,
            endDateExtended: true,
          }));
          message = "updateProjectEndDate success";
        } else {
          message = result.data?.message || message;
        }
        console.log(message);
        callback({ status: result.status, message: message });
      })
      .catch(error => {
        console.log("updateProjectEndDate [error]:");
        console.log(error);
        callback({ status: 500, message: message });
      });
  };

  // This Effect will only be called when recipients changed and enpoints finished => to update members
  useEffect(() => {
    if (isAutoSync && initialRenderCompleted && !runningTaskCreation && (recipientsCompleted || currentProjectChanged || updateMembersList)) {
      if (currentProjectId > 0) {
        // If a current project is selected, set members
        setRecipientAssignMembers(allDepMembers, currentTo, currentCC, currentBCC);
        // Only allow add other members if is the Unassigned project
        if (currentRecipients?.length > 0 && getCurrProject?.length > 0) {
          allowToAddOtherMembers(getCurrProject[0], currentRecipients);
        } else if (getCurrProject?.length > 0 && getCurrProject[0]?.Name === "Unassigned" && currentRecipients?.length === 0) {
          setOtherMembers([]);
          setShowOtherMembers(false);
        } else {
          setOtherMembers([]);
          setShowOtherMembers(false);
        }
      } else {
        setUnassignedProject();
      }
      if (currentProjectChanged) setCurrentProjectChanged(false);
      if (updateMembersList) setUpdateMembersList(false);
      if (addingAutoSyncAgain) setAddingAutoSyncAgain(false);
    }
  }, [
    savingCounter,
    currentProjectId,
    currentSubject,
    currentBody,
    recipientsCompleted,
    currentProjectChanged,
    updateMembersList
  ]);

  const setUnassignedProject = async () => {
    const getUnassignedProject = projectList?.filter(p => p.Name == "Unassigned");
    if (getUnassignedProject && getUnassignedProject?.length > 0 && getUnassignedProject[0]) {
      setProjectInfo(getUnassignedProject[0]);
      setCurrentProjectId(getUnassignedProject[0]?.Id);
      setCurrentProjectChanged(true);
      if (getUnassignedProject?.length > 0 && getUnassignedProject[0]?.Members?.length > 0) {
        setRecipientAssignMembers(allDepMembers, currentTo, currentCC, currentBCC);
      }
    } else {
      await fetchCreateUnassignedProject();
    }
  }

  const fetchCreateUnassignedProject = async () => {
    if (!createdUnassignedProject) {
      await ApiService.createUnassignedProject(context)
        .then(async (result) => {
          if (result.status == 200) {
            setCreatedUnassignedProject(true);
            console.log("createUnassignedProject ID:",  result.data);            
            await getUserProjects();            
            setCurrentProjectId(result.data);
          }
        })
        .catch((error) => {
          console.log("createUnassignedProject [error]:");
          console.log(error);
        });
    }
  };

  const restartDueDate = () => {
    let today = new Date();
    let tempTaskDueDate = new Date();
    tempTaskDueDate.setDate(today.getDate() + 7);
    let localizedProjectDueDate = moment
      .utc(getCurrProject[0]?.End ? getCurrProject[0].End : tempTaskDueDate)
      .local()
      .toDate();

    if (localizedProjectDueDate < tempTaskDueDate) {
      setTaskDue(localizedProjectDueDate);
    } else {
      setTaskDue(tempTaskDueDate);
    }
  };

  const setDynamicHeaderTrue = () => {
    Office.context.mailbox.item.internetHeaders.setAsync(
      {
        "x-triyo-dynamicfields-valid" : "true"
      },
      (headersAsyncResult) => {
      }
    );
  }

  // This is to edit elements that are not synced in the elements
  useEffect(() => {
    if (!isAutoSync) {
      let uniqueProjectMembers = removeDuplicates(assignedOtherMembers, "Id");
      let uniquePermissions = removeDuplicates(permissionTaskMembers, "Id");
      // Current email project is directly linked to project members, so it can change its values
      let currentEmailProject = allDepMembers;
      let allMembers = uniqueProjectMembers;
      let permissionMembers = uniquePermissions;

      currentEmailProject?.forEach(m => {
        let checkApproverPermission = selectedMembersApprove.includes(m.User.Id);
        let checkEditPermission = selectedMembersEdit.includes(m.User.Id);
        let checkViewerPermission = selectedMembersView.includes(m.User.Id);

        m.User.CanApprove = checkApproverPermission;
        m.User.CanEdit = checkEditPermission;
        m.User.IsAssigned = checkEditPermission;
        m.User.CanView = checkViewerPermission;

        allMembers.push(m.User);
        permissionMembers.push({
          Id: m.User.Id,
          Name: m.User.Name,
          canApprove: checkApproverPermission,
          canEdit: checkEditPermission,
          canReassign: checkEditPermission,
          canView: checkViewerPermission
        });
      });

      // Similar path to change values in email address.
      let initialMemberList = removeDuplicates(allMembers, "Id");
      let initialPermissionList = removeDuplicates(permissionMembers, "Id");
      // If filtered members is empty, assign filter members
      if (filteredAssignedMembers.length === 0 || filteredAssignedOtherMembers.length === 0) {
        let filterMembersNames = [];
        let otherFilterMembersNames = [];
        initialMemberList.forEach(member => {
          filterMembersNames.push(member?.Name);
        });
        allDepMembers.forEach(function (member) {
          otherFilterMembersNames.push(member.User.Name);
        });
        setFilteredAssignedMembers(filterMembersNames);
        setFilteredAssignedOtherMembers(otherFilterMembersNames);
      }

      setAssignedMembersEmail(initialMemberList);
      setPermissionTaskMembersEmail(initialPermissionList);
    }
  }, [selectedMembersApprove, selectedMembersEdit, selectedMembersNone, selectedMembersView]);

  const allowToAddOtherMembers = async (currProj, allRecipients) => {
    let toBeAddedMembersEmails = allRecipients.map(currRecipient => {
      const isInProject = currProj?.Members?.filter(m => m.User.Email === currRecipient);
      if (isInProject.length === 0) return currRecipient;
    });

    if (toBeAddedMembersEmails.length == -0) {
      setOtherMembers([]);
      setShowOtherMembers(false);
    } else {
      await ApiService.checkUserEmailsExists(toBeAddedMembersEmails, context)
        .then((result) => {
          if (result.status === 200) {
            let toBeAddedMembers = result.data;
            setOtherMembers(toBeAddedMembers);
            setShowOtherMembers(true);
          }
        })
        .catch((error) => {
          console.log("checkUserEmailsExists [error]:");
          console.log(error);
          setPage("500 error");
        });
    }
  };

  useEffect(() => {
    if (otherMemberAddedToProject) {
      setOtherMemberAddedToProject(false);

      let fetchGetUserProjects = new Promise((resolve, reject) => {
        const userProjectsFlag = getUserProjects();
        if (userProjectsFlag) {
          resolve(true);
        } else {
          reject(false);
        }
      });

      Promise.all([fetchGetUserProjects]).then(projectsFlags => {
        if (projectsFlags[0]) {
          console.log("projectsFlags =>");
          console.log(projectsFlags);
        }
      });
    }
  }, [otherMemberAddedToProject]);

  useEffect(() => {
    setOtherMembersCounter(otherMembersCounter + 1);
  }, [otherMembers]);

  const setRecipientAssignMembers = async (currentProjectEmailsMembers, membersTo, membersCC, membersBCC) => {
    if (membersTo == null && membersCC == null && membersBCC == null) return null;

    let uniqueProjectMembers = removeDuplicates(assignedMembers, "Id");
    let uniquePermissions = removeDuplicates(permissionTaskMembers, "Id");
    let newMembersId = [];

    let allMembers = uniqueProjectMembers;
    let permissionMembers = uniquePermissions;;

    // To Members
    currentProjectEmailsMembers?.forEach(m => {
      const email = m?.User.Email.toLowerCase();

      if (membersTo?.includes(email)) {
        m.User.CanEdit = true;
        m.User.IsAssigned = true;
        m.User.CanApprove = false;
        m.User.CanView = false;
        allMembers.push(m.User);
        permissionMembers.push({
          Id: m.User.Id,
          Name: m.User.Name,
          canApprove: false,
          canEdit: true,
          canReassign: true,
          canView: false
        });
      } else if (membersCC?.includes(email) || membersBCC?.includes(email)) {
        m.User.CanEdit = false;
        m.User.IsAssigned = false;
        m.User.CanApprove = false;
        m.User.CanView = true;
        allMembers.push(m.User);
        permissionMembers.push({
          Id: m.User.Id,
          Name: m.User.Name,
          canApprove: false,
          canEdit: false,
          canReassign: false,
          canView: true
        });
      }
    });

    let initialMemberList = removeDuplicates(allMembers, "Id");
    let initialPermissionList = removeDuplicates(permissionMembers, "Id");
    initialMemberList.forEach(member=>{
      newMembersId.push(member.Id);
    });
    
    setAssignedMembersEmail(initialMemberList);
    setPermissionTaskMembersEmail(initialPermissionList);
    setAddedMembersId(newMembersId);
    // Set edit elements to default
    setSelectedMembersApprove([]);
    setSelectedMembersEdit([]);
    setSelectedMembersView([]);
    setSelectedmembersNone([]);
  };

  const getAllAssignedMembers = (currMembers, otherDepMembers) =>{
    let projectMembers = [];
    let filterMembersNames = [];
    let otherProjectMembers = [];
    let otherFilterMembersNames = [];

    currMembers.forEach(function (member) {
      filterMembersNames.push(member.User.Name);
      projectMembers.push(member.User);
    });

    otherDepMembers.forEach(function (member) {
      otherFilterMembersNames.push(member.Name);
      otherProjectMembers.push(member);
    });

    let uniqueProjectMembers = removeDuplicates(projectMembers, "Id");
    let uniqueOtherProjectMembers = removeDuplicates(otherProjectMembers, "Id");

    console.log("This is filtered content", otherFilterMembersNames);
    console.log("These are project added to task", uniqueProjectMembers);
    setAssignedMembers(uniqueProjectMembers);
    setFilteredAssignedMembers(filterMembersNames);
    setAssignedOtherMembers(uniqueOtherProjectMembers);
    setFilteredAssignedOtherMembers(otherFilterMembersNames);
  }

  const getTaskTypes = async () => {
    await ApiService.getTaskTypes(context)
      .then((result) => {
        if (result.status === 200) {
          let data = result.data;
          setTaskTypes(data);
          ApiService.getDefaultEmailTaskType(context)
          .then((result) => {
            if (result.status === 200) {
              let defaultTaskTypeId = result.data;
              if (defaultTaskTypeId) {
                let defaultTaskTypeArray = data.filter(t => t.Id == defaultTaskTypeId);
                if (defaultTaskTypeArray && defaultTaskTypeArray?.length > 0) {
                  setTaskTypeId(defaultTaskTypeArray[0]?.Id);
                  let duration = defaultTaskTypeArray[0]?.Days;
                  let isBusinessDays = defaultTaskTypeArray[0]?.IsBusinessDays;
                  let today = new Date();
                  if (isBusinessDays) {
                    today = momentBusiness().businessAdd(duration)._d;
                  } else {
                    today.setDate(today.getDate() + duration);
                  }
                  let pEnd = duration !== 0 ? today : "";
                  setTaskTypeDue(pEnd);
                  setTaskDue(pEnd);
                }
              } else {
                let defaultTaskType = data?.filter(a => a.Type == "Email Task");
                if(defaultTaskType && defaultTaskType?.length>0){
                  setTaskTypeId(defaultTaskType[0]?.Id);
                  let duration = defaultTaskTypeArray[0]?.Days;
                  let isBusinessDays = defaultTaskTypeArray[0]?.IsBusinessDays;
                  let today = new Date();
                  if (isBusinessDays) {
                    today = momentBusiness().businessAdd(duration)._d;
                  } else {
                    today.setDate(today.getDate() + duration);
                  }
                  let pEnd = duration !== 0 ? today : "";
                  setTaskTypeDue(pEnd);
                  setTaskDue(pEnd);
                }
              }
              setGetTaskTypesCompleted(true);
            }
          })
          .catch((e) => {
            console.log(e);
            setGetTaskTypesCompleted(true);
          });
        } else {
          console.log("API [GetTaskTypes] error status: " + result.status);
          setGetTaskTypesCompleted(true);
        }
      })
      .catch((error) => {
        console.log("API [GetTaskTypes] error ->");
        console.log(error);
        setGetTaskTypesCompleted(true);
      });
  };

  const projectFilter = (project, today) => {
    if (new Date(project.End).getTime() > today.getTime()) {
      let isMember = false;

      if (project.CreatedById == context.currUser.Id) isMember = true;
      project.Members.forEach(member => {
        if (member.UserId === context.currUser.Id) isMember = true;
      });

      return isMember;
    }

    return false;
  };

  //get blank project object
  const getProjectAdd = async () => {
    console.log("Task Add Project -> " + currentProjectId);
    if (currentProjectId > 0) {
      await ApiService.getProjectWithDep(currentProjectId, context).then(
        (result) => {
          if (result.status === 200) {
            let noPermissionList = [];

            if (result.data?.Members?.length > 0) {
              result.data.Members.forEach(member => {
                if (member.isGroup) {
                  noPermissionList.push(member.GroupId + 9999999);
                } else {
                  noPermissionList.push(member.UserId);
                }
              });
            }

            setSelectedmembersNone(noPermissionList);
            if (result.data?.Id > 0) {
              setProjectInfo(result.data);
              getAllAssignedMembers(result.data.Members, result.data.DepMembers);
            } else {
              setProjectInfo(null);
            }

            setGetProjectAddCompleted(true);
          } else {
            console.log("API [GetProject] error status: " + result.status);
            setGetProjectAddCompleted(true);
          }
        },
        (error) => {
          console.log("API [GetProject] error ->");
          console.log(error);
          setGetProjectAddCompleted(true);
          setPage("500 error");
        }
      );
    }
  };

  const setProjectId = async (id) => {
    context.currentProjectId = id;
    await getProjectAdd();
  };

  //Get list of all user projects => Initial Render
  const getUserProjects = async (isOtherMembers = false) => {
    await ApiService.getUserProjects(context)
      .then((result) => {
        if (result.status === 200) {
          let today = new Date();
          let allowedProjects = result.data.filter(project => projectFilter(project, today));
          setProjectList(allowedProjects);
          if (isOtherMembers) {
            setGetUserProjectsCompleted(true);
            return allowedProjects;
          }
          setUpdateMembersList(true);
          setGetUserProjectsCompleted(true);
          return true;
        } else {
          console.log("API [GetUserProject] error status: " + result.status);
          setGetUserProjectsCompleted(true);
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        setPage("500 error");
        setGetUserProjectsCompleted(true);
        return false;
      });
  };

  const getAllUserDep = async () => {
    await ApiService.getAllDepUsers(context)
      .then(async (result) => {
        if (result.status === 200) {
          let depMembers = [];
          result.data.forEach(member => {
            let newMember = { UserId: member.Id, User: member };
            depMembers.push(newMember);
          });

          setAllDepMembers(depMembers);
          setAssignedOtherMembers(result.data);
          setGetAllUserDepCompleted(true);
        }
      })
      .catch((error) => {
        console.log("API [GetAllUserDep] error ->", error);
        setGetAllUserDepCompleted(true);
        setPage("500 error");
      });
  };

  const getGroupDetails = async (email) =>{
    await ApiService.getGroupsFromEmail(email,context)
    .then(async (result) => {
      if (result.status === 200) {
        setOwnerGroup(result.data);
      } else {
        console.log("API [GetGroupsFromEmail] error ->");
        console.log(result?.data);
      }
    })
    .catch((error) => {
      console.log("API [GetGroupsFromEmail] error ->");
      console.log(error);
    })
  }

  const getTaskAdd = async () => {
    if (currentProjectId > 0) {
      await ApiService.getTaskModel(currentProjectId, context)
        .then(async (result) => {
          if (result.status === 200) {
            setTaskAdd(result.data);
            setPrioritiesAvailable(result.data.PrioritiesAvailable);
            setReplacementCodes(result.data.WordReplacementCodes);

            if (emailConversationId) {
              await ApiService.getEmailTask(emailConversationId, context).then(async emailtask => {
                if (emailtask.status === 200) {
                  const emailThreadExists = emailtask.data;
                  if (!emailThreadExists) {
                    setSelectedEmail(context.currEmail);
                    setEmailId(context.currEmail?.item?.itemId);
                    //setEmailConversationId(""); //Not needed compose mode. We are going to save draft next to obtain conversationId
                    setEmailThread(taskName);
                    // TO DO: Implement Email Attachments
                    
                    setGetTaskAddCompleted(true);
                  } else {
                    setGetTaskAddCompleted(true);
                  }
                }
              });
            } else {
              setGetTaskAddCompleted(true);
            }
          }
        })
        .catch((error) => {
          console.log("API [GetTaskModel] error ->", error);
          setGetTaskAddCompleted(true);
          //setPage("500 error")
        });
    }
  };

  const isAcceptedFileType = extension => {
    const acceptedAttachments = new Set(validFileTypes);
    return acceptedAttachments.has(extension);
  };

  const onAddTask = (values,dynamicFields) => {
    let valueName = values.taskName;
    let valueDue = values.taskDue;
    let valuePriority = values.taskPriority;
    let valueTaskTypeId = values.taskTypeId;

    // Dealing with Default values for Email Tracking
    if (currentSubject == "" || taskName == "") {
      //valueName = "New Email Task"; // disabled for now because was causing issues outlook mac, also not needed with new logict and validations for taskname
    }
    setDFields(dynamicFields);
    setSubmitDfields(true);
    setTaskName(valueName);
    //setTaskPriority(valuePriority); // this was setting wrong priority value

    setTaskDue(valueDue);
    setTaskTypeId(valueTaskTypeId);

    setStartSavingProccess(true);
  };

  useEffect(() => {
    if (startSavingProccess) {
      // Start fetching promises for email Subject, Body and Headers(to, cc, bcc)
      let fetchSubject = new Promise((resolve, reject) => {
        Office.context.mailbox.item.subject.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchBodyText = new Promise((resolve, reject) => {
        Office.context.mailbox.item.body.getAsync("text", asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchBodyHTML = new Promise((resolve, reject) => {
        Office.context.mailbox.item.body.getAsync("html", asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchGetAttachment = new Promise((resolve, reject) => {
        Office.context.mailbox.item.getAttachmentsAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchFrom = new Promise((resolve, reject) => {
        Office.context.mailbox.item.from.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchToRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.to.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.cc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchBCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.bcc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      Promise.all([fetchSubject, fetchBodyText, fetchBodyHTML, fetchGetAttachment, fetchFrom, fetchToRecipients, fetchCCRecipients, fetchBCCRecipients])
        .then(async (bodySubjectObject) => {
          console.log("bodySubjectObject =>");
          console.log(bodySubjectObject);
          if (bodySubjectObject[0]) {
            setcurrentSubject(bodySubjectObject[0]);
            setEmailThread(bodySubjectObject[0]);
            if (isAutoSync) {
              setTaskName(bodySubjectObject[0]);
            }
          } else {
            setcurrentSubject("New Email Task");
            setEmailThread("New Email Thread");
            if (isAutoSync) {
              setTaskName("New Email Task");
            }
          }

          if (bodySubjectObject[1]) {
            const cleanContent = removeHtmlTagsFromEmail(bodySubjectObject[1]);
            setcurrentBody(bodySubjectObject[1]);
            setcurrentBodyClean(cleanContent);
          } else {
            setcurrentBody("Empty body email...");
            setcurrentBodyClean("Empty body email...");
          }

          if (bodySubjectObject[2]) {
            setHtmlEmailBody(encodeURIComponent(JSON.stringify(bodySubjectObject[2])));
          } else {
            setHtmlEmailBody("Empty body email...");
          }

          if (bodySubjectObject[3]) {
            console.log("Attachments added state => serFilesToBeAdded");
            console.log(bodySubjectObject[3]);
            if (bodySubjectObject[3].length > 0) {
              console.log(bodySubjectObject[3]);
              setFilesToBeAdded(bodySubjectObject[3]);
            } else {
              setFilesToBeAdded([]);
              setSelectedFiles([]);
            }
          } else {
            console.log("Attachments error. Not added to state => setFilesToBeAdded");
            setFilesToBeAdded([]);
            setSelectedFiles([]);
          }

          if (bodySubjectObject[4] || bodySubjectObject[5] || bodySubjectObject[6] || bodySubjectObject[7]) {
            const headersObject = {
              Sent: new Date(),
              From: bodySubjectObject[4],
              To: bodySubjectObject[5],
              Cc: bodySubjectObject[6],
              Bcc: bodySubjectObject[7],
              Subject: currentSubject,
            };
            setEmailHeaders(encodeURI(JSON.stringify(headersObject)));
            //setEmailHeaders(JSON.stringify(headersObject));
            console.log("headersObject =>", headersObject);
            console.log("emailHeaders =>", emailHeaders);
            setStartSavingProccessCompleted(true);
          } else {
            console.log("Headers error. Not added to state => setEmailHeaders");
            setEmailHeaders("");
            setStartSavingProccessCompleted(true);
          }
        })
        .catch((error) => {
          console.log("Error fetching [fetchSubject, fetchBodyText, fetchBodyHTML, fetchGetAttachment,fetchFrom, fetchToRecipients, fetchCCRecipients, fetchBCCRecipients]=>");
          console.log(error);
        });
    }
  }, [startSavingProccess]);

  useEffect(() => {
    if (filesToBeAdded && filesToBeAdded.length > 0) {
      let fetchBCCRecipients = [];
      for (let i = 0; i < filesToBeAdded.length; i++) {
        let splitAttached = filesToBeAdded[i].name.split(".");
        let extension = "." + splitAttached[splitAttached.length - 1];

        fetchBCCRecipients[i] = new Promise((resolve, _reject) => {
          if (isAcceptedFileType(extension)) {
            Office.context.mailbox.item.getAttachmentContentAsync(filesToBeAdded[i].id, asyncResultFile => {
              if (asyncResultFile.value.format === Office.MailboxEnums.AttachmentContentFormat.Base64) {
                resolve({
                  name: filesToBeAdded[i].name,
                  FileName: filesToBeAdded[i].name,
                  FileContentType: contentTypeMapper[extension],
                  Base64String: asyncResultFile.value.content
                });
              } else {
                console.log("asyncResultFile.value.format for =>" + i + " file that didn't pass ===");
                resolve(); //we resolve undefined and remove on promise.all
              }
            });
          } else {
            console.log("filesToBeAdded[i] " + i + " NOT accepted");
            resolve(); //we resolve undefined and remove on promise.all
          }
        });

        // Here we ensure we access promise.all until all the files have been processed
        if (fetchBCCRecipients.length === filesToBeAdded.length) {
          Promise.all(fetchBCCRecipients).then(acceptedFiles => {
            setSelectedFiles(
              acceptedFiles.filter(file => {
                return file !== undefined;
              })
            ); //remove undefined values
          });
        }
      }
    }
  }, [filesToBeAdded]);

  useEffect(() => {
    if (!taskLinkCompleted) {
      if (
        startSavingProccess &&
        startSavingProccessCompleted &&
        taskName &&
        currentSubject &&
        emailThread &&
        currentBodyClean &&
        htmlEmailBody &&
        selectedFiles &&
        submitDfields
      ) {
        apiAddEmailTrackingTask(dFields);
      }
    }
  }, [taskName, currentSubject, emailThread, currentBodyClean, htmlEmailBody, selectedFiles, submitDfields, startSavingProccessCompleted]);

  // Adding custom attributes to the email
  useEffect(() => {
    if (initialRenderCompleted && !isEmailTrackingDisabled) {
      let fetchTaskName = new Promise((resolve, reject) => {
        Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
          let customProps = result.value;
          // Dealing with default data for Email Tracking
          if (isAutoSync) {
            customProps.set("taskName", `${currentSubject == "" ? "New Email Task" : currentSubject ? currentSubject : ''}`);
          } else {
            customProps.set("taskName", `${taskName == "" ? "New Email Task" : taskName? taskName : ''}`);
          }
          customProps.set("emailThread", `${emailThread ? emailThread : currentSubject ? currentSubject : ''}`); // emailThread ? => this validation is for Mac, for some reason it's not saving emailThread value
          customProps.set("description", `${isAutoSync ? currentSubject : taskName ? taskName : ''}`);

          customProps.saveAsync((asyncResult) => {
            if (asyncResult.status == Office.AsyncResultStatus.Failed) {
              console.log("asyncResult.status Error");
              console.log(asyncResult);
              reject(false);
            } else {
              console.log(asyncResult);
              resolve(true);
            }
          })

        })
      });

      Promise.all([fetchTaskName]).then(async (taskNameResult) => {
        console.log(taskNameResult);
        if (taskNameResult[0]) {
          console.log("taskNameResult[0] =>");
          console.log(taskNameResult[0]);
        }
      });
    }
  }, [taskName]);

  useEffect(() => {
    if (initialRenderCompleted && !isEmailTrackingDisabled) {
      let fetchEmailId = new Promise((resolve, reject) => {
        Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
          let customProps = result.value;
          customProps.set("emailId", `${emailId ? emailId : ''}`);
          customProps.set("emailConversationId", `${emailId ? emailId : ''}`);
          customProps.saveAsync((asyncResult) => {
            if (asyncResult.status == Office.AsyncResultStatus.Failed) {
              console.log("asyncResult.status Error");
              console.log(asyncResult);
              reject(false);
            } else {
              console.log(asyncResult);
              resolve(true);
            }
          })

        })
      });

      Promise.all([fetchEmailId]).then(async (emailIdResult) => {
        if (emailIdResult[0]) {
          console.log("emailIdResult[0] =>");
          console.log(emailIdResult[0]);
        }
      });
    }
  }, [emailId]);

  useEffect(() => {
    if (initialRenderCompleted && !isEmailTrackingDisabled) {
      setCustomInfo("isEmailTrackActivity", `${isEmailTrackingActivity}`);
    }
  }, [isEmailTrackingActivity]);

  useEffect(() => {
    if (initialRenderCompleted && !isEmailTrackingDisabled) {
      setCustomInfo("taskDue", `${taskDue.toString()}`);
    }
  }, [taskDue]);

  useEffect(() => {
    if (initialRenderCompleted && taskTypeId && !isEmailTrackingDisabled) {
      setCustomInfo("taskTypeId", `${taskTypeId ? taskTypeId.toString() : ''}`);
    }
  }, [taskTypeId]);

  useEffect(() => {
    console.log("currentProjectId");
    console.log(currentProjectId);
    if (currentProjectId > 0 && !isEmailTrackingDisabled) {
      context.currentProjectId = currentProjectId;
      setCustomInfo("projectId", `${currentProjectId.toString()}`);
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (initialRenderCompleted && !isEmailTrackingDisabled) {
      setCustomInfo("priority", `${taskPriority == "" ? "Medium" : taskPriority}`);
    }
  }, [taskPriority]);

  useEffect(() => {
    if (initialRenderCompleted && getUserProjectsCompleted && !isEmailTrackingDisabled) {
      console.log("assignedMembersEmail =>");
      console.log(assignedMembersEmail);

      let fetchAssignedMembers = new Promise((resolve, reject) => {
        Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
          let customProps = result.value;
          customProps.set(`toUserId`, `${assignedMembersEmail[0]?.Id ? assignedMembersEmail[0]?.Id.toString() : ""}`); // used for endpoint: addEmailPixel
          customProps.set(`toUserEmail`, `${assignedMembersEmail[0]?.Email ? assignedMembersEmail[0]?.Email.toString() : ""}`); // used for endpoint: addEmailPixel
          customProps.saveAsync((asyncResult) => {
            if (asyncResult.status == Office.AsyncResultStatus.Failed) {
              console.log("asyncResult.status Error");
              console.log(asyncResult);
              reject(false);
            } else {
              resolve(true);
            }
          })
        })
      });

      Promise.all([fetchAssignedMembers]).then(async (assignedMembersResult) => {
        if (assignedMembersResult[0]) {
          console.log("assignedMembersResult[0] =>");
          console.log(assignedMembersResult[0]);
        }
      });
    }
  }, [assignedMembersEmail]);

  // assign members attributes: isAutoSync ON
  useEffect(() => {
    if (initialRenderCompleted && projectInfo && isAutoSync) {
      if (projectInfo.Members?.length > 0) {
        // This is for adding members to project endpoint
        const intMemberIdsSyncOn = [...addedMembersId];
        let intMemberIds = [...intMemberIdsSyncOn];
        let currentProject =projectList?.filter(p => p.Id == currentProjectId)

        const addedNewMembers = intMemberIds?.filter(i => i!= currentProject[0]?.Members.find(member => member?.UserId === i));
        let stringMemberIds="";
        if(addedNewMembers?.length > 0) stringMemberIds = addedNewMembers.toString();

        //This is for taskCreation endpoint
        let approve = "";

        selectedMembersApprove.forEach(element => (approve += element + ","));

        let emailNone = [];
        let emailView = [];
        let emailEdit = [];
        let allUserIds = projectInfo?.Members.map(m => m.UserId);

        permissionTaskMembersEmail.forEach(m => {
          if (m.canEdit) {
            emailEdit.push(m.Id);
          } else if (m.canView) {
            emailView.push(m.Id);
          }
        });

        let notNoneMembers = emailEdit.concat(emailView);
        allUserIds.forEach(id => {
          if (!notNoneMembers.includes(id)) {
            emailNone.push(id);
          }
        });

        let fetchtAssignedMembers = new Promise((resolve, reject) => {
          Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
            let customProps = result.value;
            customProps.set(`stringMemberIds`, `${stringMemberIds ? stringMemberIds : ''}`); // used for endpoint: addMembersToProject
            customProps.set(`selectedMembersNone`, `${emailNone ? emailNone : ''}`);
            customProps.set(`selectedMembersView`, `${emailView ? emailView : ''}`);
            customProps.set(`selectedMembersEdit`, `${emailEdit ? emailEdit : ''}`);
            customProps.set(`selectedMembersApprove`, `${selectedMembersApprove ? selectedMembersApprove : ''}`);
            customProps.saveAsync((asyncResult) => {
              if (asyncResult.status == Office.AsyncResultStatus.Failed) {
                console.log("asyncResult.status Error");
                console.log(asyncResult.error);
                reject(false);
              } else {
                console.log("Successfully set assigned members attributes - async ON");
                resolve(true);
              }
            })
          })
        });

        Promise.all([fetchtAssignedMembers]).then(async (membersResult) => {
          // Here we can handle true case
          if (membersResult[0]) {
            console.log("membersResult[0] =>");
            console.log(membersResult[0]);
          }
        });
      }
    }
  }, [addedMembersId]);

  // assign members attributes: isAutoSync OFF
  useEffect(() => {
    if (initialRenderCompleted && projectInfo && !isAutoSync) {
      if (projectInfo.Members?.length > 0) {
        let fetchtStringMemberIds = new Promise((resolve, reject) => {
          const intMemberIdsSyncOff = [
            ...selectedMembersApprove,
            ...selectedMembersEdit,
            ...selectedMembersView
          ];

          let intMemberIds = [...intMemberIdsSyncOff];
          let currentProject = projectList?.filter(p => p.Id == currentProjectId)

          const addedNewMembers = intMemberIds?.filter(i => i!= currentProject[0]?.Members.find(member => member?.UserId === i));
          console.log(addedNewMembers);
          if (addedNewMembers?.length > 0) {
            resolve(addedNewMembers.toString());
          } else {
            reject("");
          }
        });

        let fetchtSelectedMembersApprove = new Promise((resolve, _reject) => {
          resolve(selectedMembersApprove.toString());
        });

        let fetchtSelectedMembersNone = new Promise((resolve, _reject) => {
          resolve(selectedMembersNone.toString());
        });

        let fetchtSelectedMembersView = new Promise((resolve, _reject) => {
          resolve(selectedMembersView.toString());
        });

        let fetchtSelectedMembersEdit = new Promise((resolve, _reject) => {
          resolve(selectedMembersEdit.toString());
        });

        Promise.all([fetchtStringMemberIds,fetchtSelectedMembersApprove,fetchtSelectedMembersNone,fetchtSelectedMembersView,fetchtSelectedMembersEdit]).then(async (membersResult) => {
          if (membersResult[0]) {
            Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
              let customProps = result.value;
              customProps.set(`stringMemberIds`, `${membersResult[0]}`); // used for endpoint: addMembersToProject
              customProps.set(`selectedMembersApprove`, `${membersResult[1]}`);
              customProps.set(`selectedMembersNone`, `${membersResult[2]}`);
              customProps.set(`selectedMembersView`, `${membersResult[3]}`);
              customProps.set(`selectedMembersEdit`, `${membersResult[4]}`);
              customProps.saveAsync((asyncResult) => {
                if (asyncResult.status == Office.AsyncResultStatus.Failed) {
                  console.log("Error");
                  console.log(asyncResult.error);
                } else {
                  console.log("Successfully set assigned members attributes -  async OFF");
                }
              })
            })
          } else {
            console.log("asyncResult.status Error");
          }
        });
      }
    }
  }, [addMembersInFormCounter]);

  // Call this when the email is submitted
  const apiAddEmailTrackingTask = async (dynamicFields) => {
    const fd = await onSubmitFormData();

    //------------Adding Members to Project----------------------
    const intMemberIdsSyncOff = [
      ...selectedMembersApprove,
      ...selectedMembersEdit,
      ...selectedMembersView
    ];
    const intMemberIdsSyncOn = [...addedMembersId];

    let intMemberIds = isAutoSync ? [...intMemberIdsSyncOn] : [...intMemberIdsSyncOff];
    let currentProject = projectList?.filter(p => p.Id == currentProjectId);

    const addedNewMembers = intMemberIds?.filter(i => i!= currentProject[0]?.Members.find(member => member?.UserId === i));
    let stringMemberIds="";
    if(addedNewMembers?.length > 0) stringMemberIds = addedNewMembers.toString();

    //------------Adding Members to Project----------------------
    await ApiService.addMembersToProject(currentProjectId, stringMemberIds, context)
    .then(async (res) => {
      await ApiService.addStandardTask(fd, context)
      .then(async(result) => {
        if (result.status === 200) {
          if (isOfficeOnline) notificationMessage("Task created successfully. If you reply this thread, do it along with the TRIYO Add-in open so you are to able to see the task details.");

          const newTaskId = result.data.Id;
          if(dynamicFields.length > 0) saveDynamicFieldsDetails(dynamicFields, newTaskId, context);

          setDisableLinkTaskButton(true);

          const emailInfo = {
            ToUserId: assignedMembersEmail[0]?.Id,
            ToUserEmail: assignedMembersEmail[0]?.Email,
            TaskType: taskTypeId,
            TaskId: newTaskId,
            ProjectId: currentProjectId,
            Description: isAutoSync ? currentSubject : taskName
          };

          await ApiService.addEmailPixel(emailInfo, context)
            .then(async(pixelData) => {
              if (pixelData.status === 200) {
                console.log("New pixel =>");
                console.log(pixelData.data);
                await addEmailPixelToEmail(newTaskId, pixelData.data, context.hostName, context.platform, BCCObjectString);
                if (!arrayContainsString(currentCC, pixelData.data.Parser)) await addEmailParserToEmail(pixelData.data.Parser);
                setTaskLinkCompleted(true);
              } else {
                console.log("API [EmailPixel] creation error status: " + pixelData.status);
                setTaskLinkCompleted(true);
              }
            })
            .catch((error3) => {
              console.log("API [EmailPixel] creation error ->");
              console.log(error3);
            });
        } else {
          console.log("API [AddTaskStandard] error status: " + result.status);
        }
      })
      .catch((error2) => {
        console.log("API [AddTaskStandard] error ->");
        console.log(error2);
        setPage("500 error");
      });
    })
    .catch((error1) => {
      console.log("Error adding other Member =>");
      console.log(error1);
    });
  };

  const onSubmitFormData = async () => {
    const newFormData = new FormData();
    if (isAutoSync) {
      newFormData.append("taskName", currentSubject == "" ? "New Email Task" : currentSubject);
    } else {
      newFormData.append("taskName", taskName == "" ? "New Email Task" : taskName);
    }
    newFormData.append("taskDue", taskDue);
    newFormData.append("projectId", currentProjectId);
    newFormData.append("priority", taskPriority == "" ? "Medium" : taskPriority);
    newFormData.append("taskTypeId", taskTypeId);
    newFormData.append("senderEmail", currentFrom);
    newFormData.append("emailId", emailId); // emailId is the email unique identifier => itemId will be = emailCoversationID, and later will be updated on the failsafe section App.js
    newFormData.append("emailConversationId", emailConversationId);
    newFormData.append("emailThread", emailThread ? emailThread : currentSubject); // emailThread ? => this validation is for Mac, for some reason it's not saving emailThread value
    newFormData.append("isEmailTrackingStatus", true);
    newFormData.append("taskStatus", 1);
    newFormData.append("emailAttachments", JSON.stringify(selectedFiles));
    newFormData.append("htmlEmailBody", htmlEmailBody);
    newFormData.append("taskDescription", currentBodyClean);
    newFormData.append("isEmailTrackActivity", isEmailTrackingActivity);
    newFormData.append("emailHeaders", emailHeaders);

    console.log("emailHeaders =>", emailHeaders);

    let none = "";
    let view = "";
    let edit = "";
    let approve = "";

    selectedMembersApprove.forEach(element => (approve += element + ","));

    if (isAutoSync) {
      let emailNone = [];
      let emailView = [];
      let emailEdit = [];
      let allUserIds = projectInfo?.Members.map(m => m.UserId);

      permissionTaskMembersEmail.forEach(m => {
        if (m.canEdit) {
          emailEdit.push(m.Id);
        } else if (m.canView) {
          emailView.push(m.Id);
        }
      });

      let notNoneMembers = emailEdit.concat(emailView);
      allUserIds.forEach(id => {
        if (!notNoneMembers.includes(id)) {
          emailNone.push(id);
        }
      });

      newFormData.append("selectedMembersNone", emailNone);
      newFormData.append("selectedMembersView", emailView);
      newFormData.append("selectedMembersEdit", emailEdit);
    } else {
      selectedMembersNone.forEach(element => (none += element + ","));
      selectedMembersView.forEach(element => (view += element + ","));
      selectedMembersEdit.forEach(element => (edit += element + ","));

      newFormData.append("selectedMembersNone", selectedMembersNone);
      newFormData.append("selectedMembersView", selectedMembersView);
      newFormData.append("selectedMembersEdit", selectedMembersEdit);
    }

    newFormData.append("selectedMembersApprove", selectedMembersApprove);
    return newFormData;
  };

  const addMembersInForm = (permission, id) => {
    let addedMembers = [];

    if (permission === "editor") {
      addedMembers = [...selectedMembersEdit];
    } else if (permission === "viewer") {
      addedMembers = [...selectedMembersView];
    } else if (permission === "approver") {
      addedMembers = [...selectedMembersApprove];
    }

    const indexMembers = addedMembers.indexOf(id);
    if (indexMembers > -1) {
      addedMembers.splice(indexMembers, 1);
    }
    addedMembers.push(id);

    if (permission === "editor") {
      setSelectedMembersEdit(addedMembers);
    } else if (permission === "viewer") {
      setSelectedMembersView(addedMembers);
    } else if (permission === "approver") {
      setSelectedMembersApprove(addedMembers);
    }
    setAddMembersInFormCounter(addMembersInFormCounter + 1);
  };

  const removeMembersInForm = (permission, id) => {
    let updatedMembers = [];
    if (permission === "editor") {
      updatedMembers = [...selectedMembersEdit];
    } else if (permission === "viewer") {
      updatedMembers = [...selectedMembersView];
    } else if (permission === "approver") {
      updatedMembers = [...selectedMembersApprove];
    }

    updatedMembers = updatedMembers.filter(member => member !== id);

    if (permission === "editor") {
      setSelectedMembersEdit(updatedMembers);
    } else if (permission === "viewer") {
      setSelectedMembersView(updatedMembers);
    } else if (permission === "approver") {
      setSelectedMembersApprove(updatedMembers);
    }
    setAddMembersInFormCounter(addMembersInFormCounter + 1);
  };

  const onAssignedMembersSend = () => {
    let members = [...assignedMembersEmail];
    assignedMembers.forEach(m => {
      members.push(m);
    });
    return members.concat(otherMembers);
  };

  const onAssignedMembersPermissionSend = () => {
    let members = [...permissionTaskMembersEmail];
    permissionTaskMembers.forEach(m => {
      members.push(m);
    });
    return members;
  };

  const onTaskChangeHandler = (event) => {
    if (!isAutoSync) {
      setcurrentSubject(event.target.value);
      setTaskName(event.target.value);
    }
  };

  useEffect(() => {
    onAutoSyncChangeHandler();
  }, [isAutoSync]);

  const onAutoSyncChangeHandler = () => {
    autoSyncRef.current = isAutoSync;
    if (isAutoSync) {
      setTaskName(currentSubject); // if switch off autosync => set taskname as subject
      let emailView = [];
      let emailEdit = [];
      permissionTaskMembersEmail.forEach(m => {
        if (m.canEdit) {
          emailEdit.push(m.Id);
        } else if (m.canView) {
          emailView.push(m.Id);
        }
      });

      let editMembers = [...selectedMembersEdit];
      let viewMembers = [...selectedMembersView];

      emailEdit.forEach(e => {
        editMembers.push(e);
      });
      emailView.forEach(e => {
        viewMembers.push(e);
      });
      setSelectedMembersEdit(editMembers);
      setSelectedMembersView(viewMembers);
    } else {
      setAddingAutoSyncAgain(true);
      setUpdateMembersList(true);
    }

    setCurrentProjectId(projectInfo?.Id);
  };

  useEffect(() => {
    if (!isEmailTrackingDisabled)  {
      console.log("dynamicValidation => running from useEffect");
      dynamicValidation(dFields);
    }
  }, [dFields]);

  const dynamicValidation = (dynamicFields) => {
    if (isDynamicFieldsValid(dynamicFields)) {
      setDynamicFieldsValid(true);

      try {
        Office.context.mailbox.item.internetHeaders.setAsync(
          {
            "x-triyo-dynamicfields-valid" : "true",
          },
          (headersAsyncResult) => {
            console.log("dynamicValidation => true");
          }
        );
      } catch (error) {
        console.log("dynamic validation error");
        console.log(error);
      }

      return true;
    } else {
      setStartSavingProccess(false);
      setStartSavingProccessCompleted(false);
      setSubmitDfields(false);
      setDynamicFieldsValid(false);

      try {
        Office.context.mailbox.item.internetHeaders.setAsync(
          {
            "x-triyo-dynamicfields-valid" : "false",
            "x-triyo-sending-email" : "false"
          },
          (headersAsyncResult) => {
            console.log("dynamicValidation => false");
            setDisableDynamicDetailErrors(false);
          }
        );
      } catch (error) {
        console.log("dynamic validation error");
        console.log(error);
      }

      return false;
    }
  }

  return (
    <React.Fragment>
      { !taskLinkCompleted && (
        <div>
          <DynamicFieldsFormWrapper
            projectTypeId={0}
            taskTypeId={taskTypeId}
            objectType={OBJECT_TYPES.taskDetail}
            objectId={0}
            context={context}
          >
            {({ dynamicFields, setDynamicFields }) => (
              <Formik
                onSubmit={(values, { setSubmitting }) => {
                  setDFields(dynamicFields);
                  onAddTask(values,dynamicFields);
                  setGlobalIsSubmitting(true);
                  setSubmitting(false);
                }}
                validate={()=>dynamicValidation(dynamicFields)}
                innerRef={formRef}
                initialValues={{
                  projectName: currentProjectId,
                  taskName: taskName,
                  taskDue: taskDue
                  ? taskDue
                  : moment()
                      .add(7, "d")
                      .toDate(),
                  taskTypeId: taskTypeId,
                  taskPriority: 2,
                  taskType: "",
                  taskStart: new Date(),
                  taskFilter: "",
                  selectedMembers: [],
                  taskEmailTrackingActivity: isEmailTrackingActivity,
                }}
                enableReinitialize
              >
                {({
                  setFieldValue,
                  setFieldTouched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  isSubmitting
                }) => (
                  <Form onChange={handleChange} onSubmit={handleSubmit} className="ml-2 mr-2">
                    {/* Project Name */}
                    <Form.Group id="projName">
                      <Form.Label className="loginInput appFontSubHeading"> Project</Form.Label>
                      <Dropdown
                        values={projectList?.map(project => {
                          return { id: project.Id, name: project.Name, end: project.End };
                        })}
                        dropdownButtonText={projectInfo?.Name?.length > 0 ? projectInfo?.Name : "Select a Project"}
                        onSelect={selectedValue => {
                          setCurrentProject(prevState => ({
                            ...prevState,
                            name: selectedValue.name,
                            end: selectedValue.end,
                            endDateExtended: false,
                            selectedEndDate: taskDue
                          }));
                          setCurrentProjectId(selectedValue.id);
                          setProjectId(selectedValue.id);
                          setFieldValue("projectId", selectedValue.id);
                          setFieldValue("projectName", selectedValue.name);
                          setFieldTouched("projectName");
                          setCurrentProjectChanged(true);
                        }}
                        currentSelected={currentProjectId}
                        disabled={disableForm || !projectList}
                        isSearchable={true}
                      />
                    </Form.Group>

                    {/* Task Name */}
                    <Form.Group>
                      <Form.Label className="loginInput appFontSubHeading mr-2">
                        Task Name
                        {isAutoSync ? <OverlayTrigger
                          className="pl-2"
                          placement="auto-start"
                          delay={{ show: 250, hide: 50 }}
                          overlay={
                            <Tooltip id="tooltip-1" className="tooltipIcon" placement="auto-start">
                              {taskNameToolTipInfo}
                            </Tooltip>
                          }
                        >
                          <span className="pull-down">
                            <img src={ToolTipIcon} />
                          </span>
                        </OverlayTrigger>:<span className="pull-down">
                            <img src={ToolTipIcon} />
                          </span>}
                      </Form.Label>
                      <Form.Control
                        name="taskName"
                        id="taskName"
                        type="text"
                        className="form-control form-custom-control shadow-none"
                        placeholder="Enter task name"
                        disabled={isAutoSync || disableForm}
                        onKeyDown={e => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        value={isAutoSync ? (currentSubject == "" ? "New Email Task" : currentSubject) : taskName}
                        onChange={event => onTaskChangeHandler(event)}
                        onBlur={() => {
                          if (!taskName || taskName == "") setTaskName("New Email Task");
                          setFieldTouched("taskName");
                        }}
                      />
                    </Form.Group>

                    {/* Task Type */}
                    <Form.Group id="taskType">
                      <Form.Label className="loginInput appFontSubHeading">Task Type</Form.Label>
                      <Dropdown
                        values={taskTypes?.map(taskType => {
                          return { id: taskType.Id, name: taskType.Type };
                        })}
                        dropdownButtonText={"Select an Option"}
                        onSelect={selectedValue => {
                          setFieldValue("taskType", selectedValue.id);
                          setTaskTypeId(selectedValue.id);
                          setFieldTouched("taskType", selectedValue.id);

                          const newWorkItemType = parseInt(selectedValue.id);
                          const currTaskType = taskTypes.find(type => type.Id === newWorkItemType);
                          if (!endDateChange) {
                            const newEndDate = setEndDate(currTaskType);
                            setFieldValue("taskDue", newEndDate);
                            setTaskTypeDue(newEndDate);
                            setTaskDue(newEndDate);
                          }
                        }}
                        currentSelected={taskTypeId}
                        onChange={(value) => {
                          setTaskTypeId(value);
                        }}
                        onBlur={() => setFieldTouched("taskType")}
                        disabled={disableForm}
                      />
                    </Form.Group>

                    {/* Priority */}
                    <Form.Group className={touched.taskDue && errors.taskDue ? "mt-4" : ""} id="taskPriority">
                      <Form.Label className="loginInput appFontSubHeading">Priority</Form.Label>
                      <Dropdown
                        values={prioritiesAvailable?.map(p => {
                          return { id: p, name: p };
                        })}
                        dropdownButtonText={"Select an Option"}
                        onSelect={selectedValue => {
                          setFieldValue("taskPriority", selectedValue.id);
                          setTaskPriority(selectedValue.id);
                          setFieldTouched("taskPriority", selectedValue.id);
                        }}
                        currentSelected={taskPriority}
                        onChange={(value) => {
                          setTaskPriority(value);
                        }}
                        onBlur={() => setFieldTouched("taskPriority")}
                        disabled={disableForm}
                      />
                    </Form.Group>

                    {/* Start Date */}
                    <Form.Group>
                      <Form.Label className="loginInput appFontSubHeading">Start Date</Form.Label>
                      <DatePicker
                        className={`form-control form-custom-control shadow-none`}
                        id="taskStart"
                        name="taskStart"
                        selected={values?.taskStart}
                        onChange={value => {
                          value.setUTCHours(23, 59, 59, 0);
                          setFieldValue("taskStart", value);
                        }}
                        onBlur={() => setFieldTouched("taskStart")}
                        minDate={new Date()}
                        maxDate={
                          typeof values?.taskDue !== "undefined" ? values?.taskDue : new Date("December 31, 100 03:24:00")
                        }
                        placeholderText="MM/DD/YYYY"
                        autoComplete="off"
                        popperPlacement="top-start"
                        disabled={disableForm}
                      />
                      <img className="calendar-icon" src={calendarIcon} alt="Calendar Icon" />
                    </Form.Group>

                    {/* Due Date */}
                    <Form.Group>
                      <Form.Label className="loginInput appFontSubHeading">Due Date</Form.Label>
                      <DatePicker
                        className={`form-control form-custom-control shadow-none`}
                        id="taskDue"
                        name="taskDue"
                        selected={endDateChange ? values.taskDue : taskTypeDue}
                        onChange={value => {
                          value.setUTCHours(23, 59, 59, 0);
                          setFieldValue("taskDue", value);
                          setTaskDue(value);
                          setEndDateChange(true);
                          setCurrentProject(prevState => ({
                            ...prevState,
                            endDateExtended: false,
                            selectedEndDate: value
                          }));
                        }}
                        onBlur={() => setFieldTouched("taskDue")}
                        minDate={new Date()}
                        placeholderText="MM/DD/YYYY"
                        autoComplete="off"
                        disabled={disableForm}
                      />
                      <img className="calendar-icon" src={calendarIcon} alt="Calendar Icon" />
                    </Form.Group>

                    {/* Inline Notification */}
                    <Form.Group>
                      {currentProject?.name && (
                        <EmailTrackingInlineNotification
                          updateProjectEndDate={updateProjectEndDate}
                          currentProject={currentProject}
                        />
                      )}
                    </Form.Group>
                    {renderTaskDetailDynamicFields({
                        dynamicFields: dynamicFields,
                        setDynamicFields,
                        disableErrors: disableDynamicDetailErrors,
                        onChange: (field) => setDFields(dynamicFields),
                    })}
                    {/* Assign Members Module */}
                    <Form.Group data-testid="selectedMembers">
                      <Form.Label className="loginInput appFontSubHeading">
                        Assign Members
                        {isAutoSync?<OverlayTrigger
                          placement="auto-start"
                          delay={{ show: 250, hide: 50 }}
                          overlay={
                            <Tooltip id="tooltip-1" className="tooltipIcon" placement="auto-start">
                              {assignedMembersToolTipInfo}
                            </Tooltip>
                          }
                        >
                          <span className="pull-down">
                            <img src={ToolTipIcon} />
                          </span>
                        </OverlayTrigger>:<span className="pull-down">
                            <img src={ToolTipIcon} />
                          </span>}
                      </Form.Label>
                      <TaskAssignMembers
                        assignedMembers={onAssignedMembersSend()}
                        assignedOwner={assignedOwner}
                        ownerGroup={ownerGroup}
                        filteredAssignedMembers={filteredAssignedOtherMembers || filteredAssignedMembers}
                        addMembersInForm={addMembersInForm}
                        removeMembersInForm={removeMembersInForm}
                        permissionTaskMembers={onAssignedMembersPermissionSend()}
                        isDisabled={isAutoSync || disableForm}
                      />
                      <div className={`mt-3 pt-3 whiteBackground ${context.enableCreateButton ? "mb-4 pb-4" : "mb-1 pb-1"}`}></div>
                    </Form.Group>
                    {/* This is to enable the create task button*/}
                    {context.enableCreateButton &&
                    <React.Fragment>
                      <div className="mt-3 mb-3 pt-3 pb-3 whiteBackground"></div>
                      <div className="fixed-bottom pl-1 pr-1 pb-1 whiteBackground">
                        <button
                          style={{ width: "96%" }}
                          className="mt-0 mb-0 ml-2 mr-2 loginButton btn-primary blueButton shadow-none taskModeButton taskAddSubmitBtn"
                          type="submit"
                          onClick={(e) => {
                            if(!dynamicValidation(dynamicFields)){
                              setDisableDynamicDetailErrors(false);
                              e.preventDefault();
                            }
                            else{
                              setDynamicHeaderTrue();
                              setDisableForm(true);
                            }
                          }}
                          disabled={isSubmitting || disableLinkTaskButton}
                        >
                          Create Task
                        </button>
                        <p className="loginInput appFontSubHeading pt-2 ml-1 mr-1" style={{ fontSize: "0.67rem" }}>
                          If you are ready to create a task, click this button. Note: Clicking the button will disable the
                          fields above.
                        </p>
                      </div>
                    </React.Fragment>}
                  </Form>
                )}
              </Formik>
            )}
          </DynamicFieldsFormWrapper>
        </div>
      )
      }
    </React.Fragment>
  );
};

export default EmailTrackingTaskAdd;