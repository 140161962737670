import React, { useState, useEffect, useRef, useContext } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import AppContext from "../../app/AppContext";
import ApiService from "../../services/ApiService";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import {
  notificationMessage,
  removeNotificationMessage,
  arrayContainsString,
  workDays,
} from "../../helpers/HelperMethods";
import {
  EmailTrackingTaskManagementSection,
  EmailTrackingTaskCommentSection,
  EmailTrackingTaskEditReassign,
} from "./TaskComponents";
import { validFileTypes, contentTypeMapper } from "../../util/file-helpers";
import {
  OBJECT_TYPES,
  renderTaskDetailDynamicFields,
  saveDynamicFieldsDetails,
  isDynamicFieldsValid
} from "../../components/dynamicFields/utils/utils.js";
import {
  removeHtmlTagsFromEmail,
  addInitialHeaders,
  addEmailPixelToEmail,
  addEmailParserToEmail,
} from "../../helpers/mailLinkUtils";
import DynamicFieldsFormWrapper from "../../components/dynamicFields/Form/DynamicFieldsFormWrapper";
import moment from "moment";
import momentBusiness from 'moment-business-days';
import "bootstrap/dist/css/bootstrap.min.css";

const EmailTrackingTaskEdit = ({
  setPage,
  setTaskNameAndCreatedBy,
  taskIdSelected,
  isEmailTrackingDisabled,
  isEmailTrackingActivity,
  taskLinkCompleted,
  setTaskLinkCompleted,
  loading,
  setLoading,
}) => {
  const [hide, setHide] = useState(false);
  const [getTaskCompleted, setGetTaskCompleted] = useState(false);
  const [getWorkflowButtonsCompleted, setGetWorkflowButtonsCompleted] = useState(false);
  const [getTaskTypesCompleted, setGetTaskTypesCompleted] = useState(false);
  const [getAllUserDepCompleted, setGetAllUserDepCompleted] = useState(false);
  const [getTaskAddCompleted, setGetTaskAddCompleted] = useState(false);
  const [getUserProjectsCompleted, setGetUserProjectsCompleted] = useState(false);
  const [initialAttributesCompleted, setInitialAttributesCompleted] = useState(false);
  const [initialRenderCompleted, setInitialRenderCompleted] = useState(false);
  const [initialRecipientsCallStart, setInitialRecipientsCallStart] = useState(false);
  const [initialRecipientsCallCompleted, setInitialRecipientsCallCompleted] = useState(false);
  const [reassignRefresh, setReassignRefresh] = useState(false);
  const [reassignRefreshCounter, setReassignRefreshCounter] = useState(0);
  const [disableLinkTaskButton, setDisableLinkTaskButton] = useState(false);
  const [isDefaultTaskTypePresent, setIsDefaultTaskTypePresent] = useState(false);

  const [task, setTask] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [taskWithWorkflow, setTaskWithWorkflow] = useState([]);
  const [currentTask, setCurrentTask] = useState(null);
  const [editTask, setEditTask] = useState(null);

  const [addedTaskUsers, setAddedTaskUsers] = useState([]);
  const [editProject, setEditProject] = useState(null);
  const [projectId, setProjectId] = useState(0);
  const [dynamicFields, settingDynamicFields] = useState([]);
  const [taskMembers, setTaskMembers] = useState(null);
  const [taskOwner, setTaskOwner] = useState(null);
  const [ownerAsAGroup, setOwnerAsAGroup] = useState(null);
  const [taskChangeCounter, setTaskChangeCounter] = useState(0);

  const [isExistingTaskDisabled, setIsExistingTaskDisabled] = useState(true);
  const [taskSelected, setTaskSelected] = useState(false);
  const [projectList, setProjectList] = useState(null);
  const [taskAdd, setTaskAdd] = useState(null);
  const [taskStart, setTaskStart] = useState(null);
  const [taskDue, setTaskDue] = useState(null);
  const [taskPriority, setTaskPriority] = useState(null);
  const [prioritiesAvailable, setPrioritiesAvailable] = useState([]);
  const [allDepMembers, setAllDepMembers] = useState([]);
  const [addedMembersId, setAddedMembersId] = useState([]);
  const [addMembersInFormCounter, setAddMembersInFormCounter] = useState(0);
  const [replacementCodes, setReplacementCodes] = useState(null);
  const [htmlEmailBody, setHtmlEmailBody] = useState("");
  const [taskName, setTaskName] = useState("");
  const [emailId, setEmailId] = useState(null);
  const [emailConversationId, setEmailConversationId] = useState(null);
  const [emailThread, setEmailThread] = useState(null);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [projectInfo, setProjectInfo] = useState(null);
  const [getCurrProject, setGetCurrProject] = useState(null);
  const [assignedMembers, setAssignedMembers] = useState([]);
  const [assignedOtherMembers, setAssignedOtherMembers] = useState([]);
  const [assignedMembersEmail, setAssignedMembersEmail] = useState([]);
  const [assignedOwner, setAssignedOwner] = useState({});
  const [ownerGroup, setOwnerGroup] = useState(null);
  const [filteredAssignedMembers, setFilteredAssignedMembers] = useState([]);
  const [filteredAssignedOtherMembers, setFilteredAssignedOtherMembers] = useState([]);
  const [selectedMembersNone, setSelectedmembersNone] = useState([]);
  const [selectedMembersView, setSelectedMembersView] = useState([]);
  const [selectedMembersEdit, setSelectedMembersEdit] = useState([]);
  const [selectedMembersApprove, setSelectedMembersApprove] = useState([]);
  const [permissionTaskMembers, setPermissionTaskMembers] = useState([]);
  const [permissionTaskMembersEmail, setPermissionTaskMembersEmail] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);

  const [taskTypes, setTaskTypes] = useState([]);
  const [taskType, setTaskType] = useState(null);
  const [taskTypeId, setTaskTypeId] = useState(0);
  const [taskTypesName, setTaskTypesName] = useState(null);

  const [currentTo, setcurrentTo] = useState([]);
  const [currentFrom, setCurrentFrom] = useState([]);
  const [currentCC, setcurrentCC] = useState([]);
  const [currentBCC, setcurrentBCC] = useState([]);
  const [BCCObjectString, setBCCObjectString] = useState("");
  const [currentRecipients, setCurrentRecipients] = useState([]);
  const [recipientsCompleted, setRecipientsCompleted] = useState(false);
  const [currentSubject, setcurrentSubject] = useState("");
  const [finalSubject, setFinalSubject] = useState("");
  const [currentBody, setcurrentBody] = useState("");
  const [currentBodyClean, setcurrentBodyClean] = useState("");
  const [emailHeaders, setEmailHeaders] = useState("");
  const [showOtherMembers, setShowOtherMembers] = useState(false);
  const [otherMembers, setOtherMembers] = useState([]);
  const [createdUnassignedProject, setCreatedUnassignedProject] = useState(false);
  const [runningTaskCreation, setRunningTaskCreation] = useState();
  const [saveDraft, setSaveDraft] = useState(false);
  const [savingCounter, setSavingCounter] = useState(0);
  const [otherMembersCounter, setOtherMembersCounter] = useState(0);
  const [filesToBeAdded, setFilesToBeAdded] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [currentProjectChanged, setCurrentProjectChanged] = useState(false);
  const [otherMemberAddedToProject, setOtherMemberAddedToProject] = useState(false);
  const [updateMembersList, setUpdateMembersList] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [disableCreateTaskButton, setDisableCreateTaskButton] = useState(false);
  const [taskCreationCompleted, setTaskCreationCompleted] = useState(false);
  const [globalIsSubmitting, setGlobalIsSubmitting] = useState(false);
  const [startSavingProccess, setStartSavingProccess] = useState(false);
  const [startSavingProccessCompleted, setStartSavingProccessCompleted] = useState(false);
  const [endDateChange, setEndDateChange] = useState(false);
  const [taskTypeDue, setTaskTypeDue] = useState(new Date());
  const [dFields, setDFields] = useState([]);
  const [submitDfields, setSubmitDfields] = useState(false);
  const [disableDynamicDetailErrors, setDisableDynamicDetailErrors] = useState(true);
  const [dynamicFieldsValid, setDynamicFieldsValid] = useState();
  const [notificationCompleteTask, setNotificationCompleteTask] = useState(false);

  const [finalValues, setFinalValues] = useState(null);
  const [finalDynamicFields, setFinalDynamicFields] = useState(null);
  const [finalIsSubmitting, setFinalIsSubmitting] = useState(false);

  const formRef = useRef();
  const context = useContext(AppContext);
  const isOfficeOnline = context.hostName === "OutlookWebApp" || context.platform === "OfficeOnline";
  let intervalEmailLinkSent;

  const restartBaseStates = () => {
    setGetTaskCompleted(false);
    setGetWorkflowButtonsCompleted(false);
    setGetUserProjectsCompleted(false);
    setGetTaskTypesCompleted(false);
    setInitialRecipientsCallStart(false);
    setInitialRecipientsCallCompleted(false);    
  };

  //------------------------------ 1. INITIAL RENDER STARTS WHENEVER WE SELECT A TASK -----------------------------------------------------------------
  useEffect(() => {
    if (taskIdSelected != null) {
      setLoading(true);
      restartBaseStates();
      setReassignRefreshCounter(reassignRefreshCounter + 1);
      getUserProjects();
      getTaskTypes();
      getTask();
      momentBusiness.updateLocale('us', workDays);
    }
  }, [taskIdSelected]);

  useEffect(() => {
    if (currentTask) {
      setEditTask(currentTask);
      setEditProject(currentTask.Project);
      setTaskName(currentTask.Name);
      if (!isDefaultTaskTypePresent) setTaskTypeId(currentTask.workItemType);
      setCurrentProjectId(currentTask.Project.Id);
      setTaskMembers(currentTask.Members);
    }
  }, [currentTask]);

  useEffect(() => {
    if (editTask) {
      setTaskStart(new Date(editTask.Start));
      if (!isDefaultTaskTypePresent) {
        setTaskDue(new Date(editTask.Due));
        setTaskType(editTask.TaskType);
      }
      setTaskPriority(editTask.Priority);
    }
  }, [editTask]);

  useEffect(() => {
    console.log("taskTypes", taskTypes);
    console.log("editTask", editTask);
    if (editTask && taskTypes) {
      setTaskTypesName(taskTypes?.find(taskType => taskType?.Id === editTask?.workItemType)?.Type);
      setTaskTypeId(editTask?.workItemType);
    }
  }, [editTask, taskTypes]);

  useEffect(() => {
    if (currentProjectId && projectList) {
      const newCurrentProject = projectList?.find(project => project.Id === currentProjectId)?.Id;
      console.log(newCurrentProject);
      console.log(projectList?.find(project => project.Id === currentProjectId)?.Id);
      if (newCurrentProject) setGetCurrProject(newCurrentProject);
    }
  }, [currentProjectId, projectList]);

  useEffect(() => {
    if (
      getTaskCompleted &&
      getWorkflowButtonsCompleted &&
      getUserProjectsCompleted &&
      getTaskTypesCompleted &&
      initialAttributesCompleted &&
      initialRenderCompleted
    ) {
      setInitialRecipientsCallStart(true);
    }
  }, [getTaskCompleted, getWorkflowButtonsCompleted, getUserProjectsCompleted, getTaskTypesCompleted, initialAttributesCompleted, initialRenderCompleted]);

  useEffect(() => {
    if (initialRecipientsCallCompleted) {
      setLoading(false);
    }
  }, [initialRecipientsCallCompleted]);

  useEffect(() => {
    setTaskChangeCounter(taskChangeCounter + 1);
  }, [task]);

  //---------------------------------- 2. LINK EMAIL LOGIC STARTS -----------------------------------------------------------------
  useEffect(() => {
    if (!isEmailTrackingDisabled)  {
      setInitialAttributesCompleted(false);
      fetchAddInitialHeaders();
    }
  }, [isEmailTrackingDisabled]);

  const fetchAddInitialHeaders = async () => {
    try {
      await addInitialHeaders(context, taskTypeId)
        .then((response) => {
          console.log("addInitialHeaders=> ");
          console.log(response);
          dynamicValidation(dFields);
          setSavingCounter(savingCounter + 1);
          context.emailRecipientsChanged = false;
          setCurrentFrom(context.currEmail.userProfile.emailAddress);
          setInitialAttributesCompleted(true);
          setSaveDraft(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (initialAttributesCompleted)  {
      fetchSaveDraft();
      setInitialRenderCompleted(true);
    }
  }, [initialAttributesCompleted]);

  const fetchSaveDraft = async () => {
    try {
      Office.context.mailbox.item.saveAsync(
        (saveAsyncResult) => {
          if (saveAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
            console.log("Draft saved successfully.");
          }
          if (saveAsyncResult?.value) {
            setEmailConversationId(saveAsyncResult.value);
            setEmailId(saveAsyncResult.value);
          }
          setSavingCounter(savingCounter + 1);
          context.emailRecipientsChanged = false;
          //if (!initialRecipientsCallCompleted) setInitialRecipientsCallCompleted(true);
          setSaveDraft(false);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  //----------------------- 3. Rest of the logic waiting for changes and task creation---------------------------

  // This Effect it's called every 100mseconds to check if sending email process started
  useEffect(() => {
    if (initialRenderCompleted) {
      if (!isEmailTrackingDisabled) {
        intervalEmailLinkSent = setInterval(() => {
          Office.context.mailbox.item.internetHeaders.getAsync(
            ["x-triyo-draft-saved", "x-triyo-sending-email", "x-triyo-task-created", "x-triyo-dynamicfields-valid"],
            (asyncResult) => {
              // LaunchEvent informs here if the button Send was pressed and Task creation should start
              if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                // ["x-triyo-task-created"] === "false" here prevents accessing this twice
                if (
                  asyncResult.value &&
                  asyncResult.value["x-triyo-sending-email"] === "true" &&
                  asyncResult.value["x-triyo-task-created"] === "false"
                ) {
                  if(!dynamicFieldsValid || asyncResult.value["x-triyo-dynamicfields-valid"] === "false"){
                    Office.context.mailbox.item.internetHeaders.setAsync(
                      { "x-triyo-sending-email" : "false" },
                      (headersAsyncResult) => {
                        setDisableDynamicDetailErrors(false);
                        try {
                          document.getElementsByClassName("invalid-feedback")[0].scrollIntoView({
                            behavior: "auto",
                            block: "center",
                            inline: "center"
                          });
                        } catch (error) {
                          console.log("dynamic validation error");
                          console.log(error);
                        }
                      }
                    );
                    setRunningTaskCreation(false);
                    return;
                  } else if (!runningTaskCreation && asyncResult.value["x-triyo-dynamicfields-valid"] === "true") {
                    setRunningTaskCreation(true); //Once this start, we cannot undone this action on the launchevent.js side.
                    formRef.current.handleSubmit();
                    setEmailThread(currentSubject);
                    clearInterval(intervalEmailLinkSent);
                  }
                // If the button Send was not clicked we can save draft and continue processing
                } else {
                  if (!runningTaskCreation) {
                    if (context.emailRecipientsChanged) setSaveDraft(true);                 

                    let fetchGetSubject = new Promise((resolve, reject) => {
                      Office.context.mailbox.item.subject.getAsync(asyncResult => {
                        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                          resolve(asyncResult.value);
                        } else {
                          reject(asyncResult.error);
                        }
                      });
                    });

                    Promise.all([fetchGetSubject])
                      .then((subjectObject) => {
                        if (subjectObject[0]) {
                          if (currentSubject !== subjectObject[0]) setcurrentSubject(subjectObject[0]);
                          if (emailThread !== subjectObject[0]) setEmailThread(subjectObject[0]);
                        } else {
                          //"fetchGetSubject[0] not data => New Email Task by default"
                          if (!currentSubject || currentSubject != "New Email Task") setcurrentSubject("New Email Task");
                          if (!emailThread || emailThread != "New Email Thread") setEmailThread("New Email Thread");
                        }
                      })
                      .catch((error) => {
                        console.log("Error getting subject =>");
                        console.log(error);
                      });
                  }
                }
              } else {
                console.log("Error getting selected headers: " + JSON.stringify(asyncResult.error));
                setFinalSubject(currentSubject);
              }
            }
          );
        }, 200);
        return () => clearInterval(intervalEmailLinkSent);
      } else {
        clearInterval(intervalEmailLinkSent);
      }
    }
  }, [initialRenderCompleted]);

  // Notification message for PWA
  useEffect(() => {
    if (isOfficeOnline && (currentSubject?.length > 0 || taskName?.length > 0) && (currentTo?.length > 0 || currentCC?.length > 0 || currentBCC?.length > 0) && !notificationCompleteTask) {
      removeNotificationMessage("This email will be tracked on TRIYO when you click Send |");
      notificationMessage("While replying an email linked to a task, do it along with the TRIYO Add-in already open so you are to able to see the task details.");
      setNotificationCompleteTask(true);
    }
  }, [taskName, currentSubject, currentTo, currentBCC, currentCC]);

  useEffect(() => {
    if (!runningTaskCreation && initialRecipientsCallStart && !initialRecipientsCallCompleted) {
      let fetchToRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.to.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.cc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchBCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.bcc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      Promise.all([fetchToRecipients, fetchCCRecipients, fetchBCCRecipients]).then((recipients) => {
        console.log("Recipients=>");
        console.log(recipients);
        if (recipients[0].length > 0 || recipients[1].length > 0 || recipients[2].length > 0) {
          console.log("Initial recipients found.");
          context.emailRecipientsChanged = true;
        } else {
          console.log("No initial recipients found.");
        }
        setInitialRecipientsCallCompleted(true);
      });
    }
  }, [initialRecipientsCallStart]);

  useEffect(() => {
    if (!runningTaskCreation && (saveDraft)) {
      let fetchToRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.to.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.cc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchBCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.bcc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      Promise.all([fetchToRecipients, fetchCCRecipients, fetchBCCRecipients]).then((recipients) => {
        console.log("Recipients=>");
        console.log(recipients);
        if (recipients[0].length === 0 && recipients[1].length === 0 && recipients[2].length === 0) {
          setcurrentTo([]);
          setcurrentBCC([]);
          setcurrentCC([]);
          setOtherMembers([]);
          setOtherMembersCounter(otherMembersCounter + 1);
          setCurrentRecipients([]);
          setShowOtherMembers(false);
        } else {
          let allRecipients = [];
          if (recipients[0].length > 0) {
            const data = [];
            recipients[0].forEach((email) => {
              data.push(email.emailAddress.toLowerCase());
              allRecipients.push(email.emailAddress.toLowerCase());
            });
            let uniqueTo = [...new Set(data)];
            setcurrentTo(uniqueTo);
          } else {
            setcurrentTo([]);
          }
          if (recipients[1].length > 0) {
            const data = [];
            recipients[1].forEach((email) => {
              data.push(email.emailAddress.toLowerCase());
              allRecipients.push(email.emailAddress.toLowerCase());
            });
            let uniqueCC = [...new Set(data)];
            setcurrentCC(uniqueCC);
          } else {
            setcurrentCC([]);
          }
          if (recipients[2].length > 0) {
            const data = [];
            recipients[2].forEach((email) => {
              data.push(email.emailAddress.toLowerCase());
              allRecipients.push(email.emailAddress.toLowerCase());
            });
            let uniqueBCC = [...new Set(data)];
            setcurrentBCC(uniqueBCC);
            setBCCObjectString(encodeURI(JSON.stringify(recipients[2])));
          } else {
            setcurrentBCC([]);
            setBCCObjectString("");
          }

          let uniqueAllRecipients = [...new Set(allRecipients)];
          setCurrentRecipients(uniqueAllRecipients);
          setOtherMembersCounter(otherMembersCounter + 1);
        }
        setRecipientsCompleted(true);
      });
    }
  }, [saveDraft]);

  useEffect(() => {
    if (context.emailRecipientsChanged && !runningTaskCreation) {
      if (saveDraft) fetchSaveDraft();
    }
  }, [saveDraft]);

  const restartDueDate = () => {
    let today = new Date();
    let tempTaskDueDate = new Date();
    tempTaskDueDate.setDate(today.getDate() + 7);
    let localizedProjectDueDate = moment
      .utc(getCurrProject[0]?.End ? getCurrProject[0].End : tempTaskDueDate)
      .local()
      .toDate();

    if (localizedProjectDueDate < tempTaskDueDate) {
      setTaskDue(localizedProjectDueDate);
    } else {
      setTaskDue(tempTaskDueDate);
    }
  };

  const setDynamicHeaderTrue = () => {
    Office.context.mailbox.item.internetHeaders.setAsync(
      {
        "x-triyo-dynamicfields-valid" : "true"
      },
      (headersAsyncResult) => {
      }
    );
  };

  //get blank project object
  const getProjectAdd = async () => {
    console.log("Task Add Project -> " + context.currentProjectId);
    await ApiService.getProjectWithDep(context.currentProjectId, context).then(
      async result => {
        if (result.status === 200) {
          let noPermissionList = [];

          result.data.Members.forEach(member => {
            if (member.isGroup) {
              noPermissionList.push(member.GroupId + 9999999);
            } else {
              noPermissionList.push(member.UserId);
            }
          });

          setSelectedmembersNone(noPermissionList);
          setProjectInfo(result.data);
          getAllAssignedMembers(result.data.Members, result.data.DepMembers);
        } else {
          console.log("API [GetProject] error status: " + result.status);
        }
      },
      (error) => {
        console.log("API [GetProject] error ->");
        console.log(error);
        setPage("500 error");
      }
    );
  };

  const getAllUserDep = async () => {
    await ApiService.getAllDepUsers(context)
      .then(async result => {
        if (result.status === 200) {
          let depMembers = [];
          result.data.forEach(member => {
            let newMember = { UserId: member.Id, User: member };
            depMembers.push(newMember);
          });

          setAllDepMembers(depMembers);
          setAssignedOtherMembers(result.data);
          setGetAllUserDepCompleted(true);
        }
      })
      .catch((e) => {
        console.log("API [GetAllUserDep] error ->", error);
        setGetAllUserDepCompleted(true);
        setPage("500 error");
      });
  };

  const getGroupDetails = async (email) =>{
    await ApiService.getGroupsFromEmail(email,context)
    .then(async result => {
      if (result.status === 200) {
        setOwnerGroup(result.data);
      }
      else{
        console.log("API [GetGroupsFromEmail] error ->");
        console.log(result?.data);
      }
    })
    .catch((e) => {
      console.log("API [GetGroupsFromEmail] error ->");
      console.log(e);
    })
  }

  const getTaskAdd = async () => {
    await ApiService.getTaskModel(context.currentProjectId, context)
      .then(async result => {
        if (result.status === 200) {
          setTaskAdd(result.data);
          setTaskDue(new Date(result.data.Due));
          setPrioritiesAvailable(result.data.PrioritiesAvailable);
          setReplacementCodes(result.data.WordReplacementCodes);

          if (emailConversationId) {
            await ApiService.getEmailTask(emailConversationId, context).then(async emailtask => {
              if (emailtask.status === 200) {
                const emailThreadExists = emailtask.data;
                if (!emailThreadExists) {
                  setSelectedEmail(context.currEmail);
                  setEmailId(context.currEmail?.item?.itemId);
                  //setEmailConversationId(""); //Not needed compose mode. We are going to save draft next to obtain conversationId
                  setEmailThread(taskName);
                  // TO DO: Implement Email Attachments
                }
              }
            });
          }

          setGetTaskAddCompleted(true);
        }
      })
      .catch((e) => {
        console.log("API [GetTaskModel] error ->", e);
        setGetTaskAddCompleted(true);
        //setPage("500 error")
      });
  };

  const isAcceptedFileType = extension => {
    const acceptedAttachments = new Set(validFileTypes);
    return acceptedAttachments.has(extension);
  };

  //---------------------------------------------------------------------------------------------------------------------------------
  const getTask = async () => {
    if (taskIdSelected) {
      await ApiService.getRegularTask(parseInt(taskIdSelected), context)
        .then(async (result) => {
          if (result.status === 200) {
            console.log("API [getRegularTask]:");
            console.log(result.data);
            if (result.data) {
              context.currentTask = result.data;
              setCurrentTask(result.data);

              const { Name, CreatedById } = result.data;
              //setTask(selectedTask);
              //setTaskId(parseInt(taskIdSelected));
              //setTaskNameAndCreatedBy(Name, CreatedById);

              await getWorkflowButtons(parseInt(taskIdSelected), result.data.TaskType);
              //getGroupOwner(result.data);
              setGetTaskCompleted(true);
            }
          } else {
            console.log("API [getRegularTask] error status: " + result.status);
            setGetTaskCompleted(true);
          }
        })
        .catch((error) => {
          console.log("API [getRegularTask] error ->");
          console.log(error);
          setGetTaskCompleted(true);
        });
    };
  };

  const getWorkflowButtons = async (TaskId, TaskType) => {
    await ApiService.getTaskWithWorkflow(TaskId, TaskType, context)
      .then(async(result) => {
        if (result.status === 200) {
          console.log("getTaskWithWorkflow => task");
          console.log(result.data);
          setCurrentTask(result.data);
          setTaskWithWorkflow(result.data);

          const { Name, CreatedById } = result.data;
          setTaskId(result.data.Id);
          setTaskNameAndCreatedBy(Name, CreatedById);

          if (result.data.TaskType == "Regular") {
            context.currentTask = result.data;
            setTask(result.data);
          }

          getGroupOwner(result.data);

          setGetWorkflowButtonsCompleted(true);
        } else {
          console.log("API [getTaskWithWorkflow] error status: " + result.status);
          setGetWorkflowButtonsCompleted(true);
        }
      })
      .catch((error) => {
        console.log("API [getTaskWithWorkflow] error ->");
        console.log(error);
        setGetWorkflowButtonsCompleted(true);
        //setPage("500 error");
      });
  };

  const hideContent = (hidden) => {
    if (hide != hidden) setHide(hidden);
  };

  const isOwner = () => {
    if (editTask) return (context.currUser.Id == editTask.CreatedById);
  }

  const getGroupOwner = (selectedTask) => {
    if (selectedTask?.Groups) {
      let temp = selectedTask?.Groups.filter((group) => {
        if (group.IsOwner) return group;
      })
      if(temp.length > 0) setOwnerAsAGroup(temp[0]);
    }
  };

  const localizeProjectDates = project => {
    if (project === null || typeof project.Start === "undefined" || project.Start === null) return project;

    let localizedStart = moment.utc(project.Start).local().toDate();
    let localizedEnd = moment.utc(project.End).local().toDate();
    project.Start = localizedStart;
    project.End = localizedEnd;

    return project;
  };

  const projectFilter = (project, today) => {
    if (new Date(project.End).getTime() > today.getTime()) {
      let isMember = false;

      if (project.CreatedById == context.currUser.Id) isMember = true;
      project.Members.forEach(member => {
        if (member.UserId === context.currUser.Id) isMember = true;
      });

      return isMember;
    }

    return false;
  };

  const getUserProjects = async (isOtherMembers = false) => {
    await ApiService.getUserProjects(context)
      .then((result) => {
        if (result.status === 200) {
          console.log("API [getUserProjects]:");
          console.log(result.data);

          let projectsList = result.data;
          if (projectsList !== null && projectsList.length !== 0) {
            projectsList.forEach(project => project = localizeProjectDates(project));

            let today = new Date();
            let allowedProjects = projectsList.filter(project => projectFilter(project, today));
            console.log("Allowed Projects =>");
            console.log(allowedProjects);
            setProjectList(allowedProjects);
            if (isOtherMembers) {
              setGetUserProjectsCompleted(true);
              return allowedProjects;
            }
            setUpdateMembersList(true);
            setGetUserProjectsCompleted(true);
            return true;
          }
          setGetUserProjectsCompleted(true);
        } else {
          console.log("API [GetUserProjects] error status: " + result.status);
          setGetUserProjectsCompleted(true);
        }
      })
      .catch((error) => {
        console.log("API [GetUserProjects] error ->");
        console.log(error);
        setGetUserProjectsCompleted(true);
        //setAppPage("500 error");
      })
  };

  const getTaskTypes = async () => {
    await ApiService.getTaskTypes(context)
      .then( async(result) => {
        if (result.status === 200) {
          console.log("API [getTaskTypes]:");
          console.log(result.data);
          let data = result.data;
          setTaskTypes(data);
          await ApiService.getDefaultEmailTaskType(context)
            .then((result) => {
              if (result.status === 200) {
                console.log(result.data);
                let defaultTaskTypeId = result.data;
                if (defaultTaskTypeId) {
                  let defaultTaskTypeArray = data.filter(t => t.Id == defaultTaskTypeId);
                  if (defaultTaskTypeArray && defaultTaskTypeArray?.length > 0) {
                    setTaskTypeId(defaultTaskTypeArray[0]?.Id);
                    let duration = defaultTaskTypeArray[0]?.Days;
                    let isBusinessDays = defaultTaskTypeArray[0]?.IsBusinessDays;
                    let today = new Date();
                    if (isBusinessDays) {
                      today = momentBusiness().businessAdd(duration)._d;
                    } else {
                      today.setDate(today.getDate() + duration);
                    }
                    let pEnd = duration !== 0 ? today : "";
                    setTaskTypeDue(pEnd);
                    setTaskDue(pEnd);
                    setIsDefaultTaskTypePresent(true);
                  } else {
                    setIsDefaultTaskTypePresent(false);
                  }                  
                } else {
                  let defaultTaskType = data?.filter(a => a.Type == "Email Task");
                  if (defaultTaskType && defaultTaskType?.length > 0) {
                    setTaskTypeId(defaultTaskType[0]?.Id);
                    let duration = defaultTaskTypeArray[0]?.Days;
                    let isBusinessDays = defaultTaskTypeArray[0]?.IsBusinessDays;
                    let today = new Date();
                    if (isBusinessDays) {
                      today = momentBusiness().businessAdd(duration)._d;
                    } else {
                      today.setDate(today.getDate() + duration);
                    }
                    let pEnd = duration !== 0 ? today : "";
                    setTaskTypeDue(pEnd);
                    setTaskDue(pEnd);
                    setIsDefaultTaskTypePresent(true);
                  } else {
                    setIsDefaultTaskTypePresent(false);
                  }                  
                }
                setGetTaskTypesCompleted(true);
              }
            })
            .catch((error) => {
              console.log(error);
              setGetTaskTypesCompleted(true);
            });
        } else {
          console.log("API [GetTaskTypes] error status: " + result.status);
          setGetTaskTypesCompleted(true);
        }
      })
      .catch((error) => {
        console.log("API [GetTaskTypes] error ->");
        console.log(error);
        setGetTaskTypesCompleted(true);
        //setPage("500 error");
      })
  };

  const setReassignedMembers = (newMembers, newMembersId) => {
    setTaskMembers(newMembers);
    setAddedTaskUsers(newMembersId);
  }

  const setEditTaskOwner = (receivedTaskOwner) => {
    setTaskOwner(receivedTaskOwner);
  }

  const dynamicValidation = (dynamicFields) => {
    if (isDynamicFieldsValid(dynamicFields)) {
      setDynamicFieldsValid(true);

      try {
        Office.context.mailbox.item.internetHeaders.setAsync(
          {
            "x-triyo-dynamicfields-valid" : "true",
          },
          (headersAsyncResult) => {
            console.log("dynamicValidation => true");
          }
        );
      } catch (error) {
        console.log("dynamic validation error");
        console.log(error);
      }

      return true;
    } else {
      setStartSavingProccess(false);
      setStartSavingProccessCompleted(false);
      setSubmitDfields(false);
      setDynamicFieldsValid(false);

      try {
        Office.context.mailbox.item.internetHeaders.setAsync(
          {
            "x-triyo-dynamicfields-valid" : "false",
            "x-triyo-sending-email" : "false"
          },
          (headersAsyncResult) => {
            console.log("dynamicValidation => false");
            setDisableDynamicDetailErrors(false);
            try {
              document.getElementsByClassName("invalid-feedback")[0].scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center"
              });
            } catch (error) {
              console.log("dynamic validation error");
              console.log(error);
            }
          }
        );
      } catch (error) {
        console.log("dynamic validation error");
        console.log(error);
      }

      return false;
    }
  };

  const validateReassignedMembers = () => {
    let hasEditor = false;
    let ownerHasPermission = false;
    taskMembers.forEach(member => {
      if (member.User.Id === taskOwner?.Id) {
        if (member.CanView || member.CanApprove || member.CanEdit || member.IsAssigned) ownerHasPermission = true;
      }

      if (member.IsAssigned || member.CanEdit) hasEditor = true;
    });

    if (!hasEditor && ownerHasPermission  && ownerAsAGroup == null) {
      return false;
    } else if (!hasEditor && !ownerHasPermission  && ownerAsAGroup == null) {
      let reassignedMembers = taskMembers;
      reassignedMembers.forEach(m => {
        if (m.User.Id === taskOwner.Id) {
          m.CanEdit = true;
          m.IsAssigned = true;
        }
      });

      setReassignedMembers(reassignedMembers);
      return true;
    }

    return true;
  }

  const validateForm = (values, dynamicFields, setDynamicFields) => {
    let errors = {};
    let errorMessage = "This is a required field";

    if (!values.taskName) {
      errors.taskName = errorMessage;
    } else if(values.taskName.trim().length === 0) {
      errors.taskName = "Task Name cannot contain only spaces";
    }

    if (!values.projectName) errors.projectName = errorMessage;
    if (!editTask.PrioritiesAvailable.includes(values.taskPriority)) errors.taskPriority = errorMessage;
    if (!values.taskStart) errors.taskStart = errorMessage;
    if (!values.taskDue) errors.taskDue = errorMessage;

    if (!validateReassignedMembers()) errors.selectedMembers = "Please select at least one edit role";

    if (!isDynamicFieldsValid(dynamicFields)) {
      setDynamicFields(dynamicFields.map(field => ({ ...field, touched: true })));
      errors.dynamicFields = "Dynamic Fields are Invalid";
    }

    return errors;
  };

  const apiEditStandardTask =  async (editObj, dynamicFields, setSubmitting) => {
    await ApiService.editStandardTask(editObj, context)
      .then( async(result) => {
        if (result.status === 200) {
          console.log("Edited Task successfully");
          console.log(result.data);
          context.currentTask = result.data;
          setReassignedMembers([]);
          setEditTaskOwner(null);
          //setSubmitting(false); //this throws => TypeError: setSubmitting is not a function
          if(dynamicFields.length > 0) saveDynamicFieldsDetails(dynamicFields, result.data.Id, context);          

          const emailInfo = {
            ToUserId: assignedMembersEmail[0]?.Id,
            ToUserEmail: assignedMembersEmail[0]?.Email,
            TaskType: taskTypeId,
            TaskId: result.data.Id,
            ProjectId: currentProjectId,
            Description: currentSubject
          };

          console.log("Email Info =>");
          console.log(emailInfo);

          await ApiService.addEmailPixel(emailInfo, context)
            .then(async(pixelData) => {
              if (pixelData.status === 200) {
                console.log("New pixel =>");
                console.log(pixelData.data);
                await addEmailPixelToEmail(result.data.Id, pixelData.data, context.hostName, context.platform, BCCObjectString);
                if (!arrayContainsString(currentCC, pixelData.data.Parser)) await addEmailParserToEmail(pixelData.data.Parser);
                setTaskLinkCompleted(true);
              } else {
                console.log("API [EmailPixel] creation error status: " + pixelData.status);
                setTaskLinkCompleted(true);
              }
            })
            .catch((error) => {
              console.log("API [EmailPixel] creation error ->");              
              console.log(error);
              setTaskLinkCompleted(true);
            });
        }
      })
      .catch((error) => {
        console.log("API [Edit Standard Task] error ->");
        console.log(error);
        //setPage("500 error");
      })
  };

  const onEditSubmit = (values, dynamicFields, setSubmitting) => {
    let reassignedMembers = taskMembers.filter(m => (m.UserId === taskOwner.Id && ownerAsAGroup == null) || m.CanView || m.CanApprove || m.CanEdit || m.IsAssigned);

    let EditObj = {
      Id: currentTask.Id,
      TaskDue: values.taskDue,
      Due: values.taskDue,
      TaskName: values.taskName,
      Name: values.taskName,
      TaskStart: values.taskStart,
      Start: values.taskStart,
      Priority: values.taskPriority,
      WorkItemTypeId: values.taskType,      
      Comments: [{ Note: values.commentNote, Files: values.selectedFiles }],
      Members: reassignedMembers,
      ProjectId: currentProjectId,
      SenderEmail: currentFrom,
      EmailId: emailId,
      EmailConversationId: emailConversationId,
      EmailThread: emailThread,
      IsEmailTracking: true,      
      EmailAttachments: JSON.stringify(selectedFiles),
      HtmlEmailBody: htmlEmailBody,
      Description: currentBodyClean,
      EmailTrackActivity: isEmailTrackingActivity,
      EmailHeaders: emailHeaders,
    };
    apiEditStandardTask(EditObj, dynamicFields, setSubmitting);
  };

  // auto scroll to invalid form fields
  const scrollToError = (touched, errors) => {
    console.log(touched, errors);
    return null;
  };

  const setDynamicFields = (dynamicFields) => {
    settingDynamicFields(dynamicFields);
  }

  const onSubmitAllEditUpdates = async (values, dynamicFields, setSubmitting) => {
    let stringMemberIds = "";
    if (!addedTaskUsers) {
      stringMemberIds = taskOwner?.Id.toString();
    } else {
      stringMemberIds = addedTaskUsers.toString();
    }

    await ApiService.addMembersToProject(currentProjectId, stringMemberIds, context)
      .then((result) => {
        if (result.status == 200) {
          console.log("Project completed");
        }
      })
      .catch((error) => {
        console.log("Error adding other Member =>");
        console.log(error);
      })
      .finally(()=> {
        console.log("Setting start saving proccess to true");
        setFinalValues(values);
        setFinalDynamicFields(dynamicFields);
        setFinalIsSubmitting(setSubmitting);
        setSubmitDfields(true);
        setStartSavingProccess(true);
      });
  };

  useEffect(() => {
    if (startSavingProccess) {
      // Start fetching promises for email Subject, Body and Headers(to, cc, bcc)
      let fetchSubject = new Promise((resolve, reject) => {
        Office.context.mailbox.item.subject.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchBodyText = new Promise((resolve, reject) => {
        Office.context.mailbox.item.body.getAsync("text", asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchBodyHTML = new Promise((resolve, reject) => {
        Office.context.mailbox.item.body.getAsync("html", asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchGetAttachment = new Promise((resolve, reject) => {
        Office.context.mailbox.item.getAttachmentsAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchFrom = new Promise((resolve, reject) => {
        Office.context.mailbox.item.from.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchToRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.to.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.cc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      let fetchBCCRecipients = new Promise((resolve, reject) => {
        Office.context.mailbox.item.bcc.getAsync(asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            reject(asyncResult.error);
          }
        });
      });

      Promise.all([fetchSubject, fetchBodyText, fetchBodyHTML, fetchGetAttachment, fetchFrom, fetchToRecipients, fetchCCRecipients, fetchBCCRecipients])
        .then(async (bodySubjectObject) => {
          console.log(bodySubjectObject);
          if (bodySubjectObject[0]) {
            setcurrentSubject(bodySubjectObject[0]);
            setEmailThread(bodySubjectObject[0]);
          } else {
            setcurrentSubject("New Email Task");
            setEmailThread("New Email Thread");
          }

          if (bodySubjectObject[1]) {
            const cleanContent = removeHtmlTagsFromEmail(bodySubjectObject[1]);
            setcurrentBody(bodySubjectObject[1]);
            setcurrentBodyClean(cleanContent);
          } else {
            setcurrentBody("Empty body email...");
            setcurrentBodyClean("Empty body email...");
          }

          if (bodySubjectObject[2]) {
            setHtmlEmailBody(encodeURIComponent(JSON.stringify(bodySubjectObject[2])));
          } else {
            setHtmlEmailBody("Empty body email...");
          }

          if (bodySubjectObject[3]) {
            console.log("Attachments added state => serFilesToBeAdded");
            console.log(bodySubjectObject[3]);
            if (bodySubjectObject[3].length > 0) {
              console.log(bodySubjectObject[3]);
              setFilesToBeAdded(bodySubjectObject[3]);
            } else {
              setFilesToBeAdded([]);
              setSelectedFiles([]);
            }
          } else {
            console.log("Attachments error. Not added to state => setFilesToBeAdded");
            setFilesToBeAdded([]);
            setSelectedFiles([]);
          }

          if (bodySubjectObject[4] || bodySubjectObject[5] || bodySubjectObject[6] || bodySubjectObject[7]) {
            const headersObject = {
              Sent: new Date(),
              From: bodySubjectObject[4],
              To: bodySubjectObject[5],
              Cc: bodySubjectObject[6],
              Bcc: bodySubjectObject[7],
              Subject: currentSubject,
            };
            setEmailHeaders(encodeURI(JSON.stringify(headersObject)));
            //setEmailHeaders(JSON.stringify(headersObject));
            console.log("headersObject =>", headersObject);
            console.log("emailHeaders =>", emailHeaders);
            setStartSavingProccessCompleted(true);
          } else {
            console.log("Headers error. Not added to state => setEmailHeaders");
            setEmailHeaders("");
            setStartSavingProccessCompleted(true);
          }
        })
        .catch((error) => {
          console.log("Error fetching [fetchSubject, fetchBodyText, fetchBodyHTML, fetchGetAttachment,fetchFrom, fetchToRecipients, fetchCCRecipients, fetchBCCRecipients]=>");
          console.log(error);
        });
    }
  }, [startSavingProccess]);

  useEffect(() => {
    if (filesToBeAdded && filesToBeAdded.length > 0) {
      let fetchBCCRecipients = [];
      for (let i = 0; i < filesToBeAdded.length; i++) {
        let splitAttached = filesToBeAdded[i].name.split(".");
        let extension = "." + splitAttached[splitAttached.length - 1];

        fetchBCCRecipients[i] = new Promise((resolve, _reject) => {
          if (isAcceptedFileType(extension)) {
            Office.context.mailbox.item.getAttachmentContentAsync(filesToBeAdded[i].id, asyncResultFile => {
              if (asyncResultFile.value.format === Office.MailboxEnums.AttachmentContentFormat.Base64) {
                resolve({
                  name: filesToBeAdded[i].name,
                  FileName: filesToBeAdded[i].name,
                  FileContentType: contentTypeMapper[extension],
                  Base64String: asyncResultFile.value.content
                });
              } else {
                console.log("asyncResultFile.value.format for =>" + i + " file that didn't pass ===");
                resolve(); //we resolve undefined and remove on promise.all
              }
            });
          } else {
            console.log("filesToBeAdded[i] " + i + " NOT accepted");
            resolve(); //we resolve undefined and remove on promise.all
          }
        });

        // Here we ensure we access promise.all until all the files have been processed
        if (fetchBCCRecipients.length === filesToBeAdded.length) {
          Promise.all(fetchBCCRecipients).then(acceptedFiles => {
            setSelectedFiles(
              acceptedFiles.filter(file => {
                return file !== undefined;
              })
            ); //remove undefined values
          });
        }
      }
    }
  }, [filesToBeAdded]);

  useEffect(() => {
    if (!taskLinkCompleted) {
      if (
        startSavingProccess &&
        startSavingProccessCompleted &&
        taskName &&
        currentSubject &&
        emailThread &&
        currentBodyClean &&
        htmlEmailBody &&
        selectedFiles &&
        submitDfields
      ) {
        onEditSubmit(finalValues, finalDynamicFields, finalIsSubmitting);
      }
    }
  }, [taskName, currentSubject, emailThread, currentBodyClean, htmlEmailBody, selectedFiles, submitDfields, startSavingProccessCompleted]);

  return (
    !loading &&  (
      <div>
        <LoadingOverlay area="task-reassign-area" inline="loading-overlay-inline" hideContent={hideContent} />
        <DynamicFieldsFormWrapper
          projectTypeId={0}
          taskTypeId={taskTypeId}
          objectType={OBJECT_TYPES.taskDetail}
          objectId={currentTask?.Id}
          context={context}
        >
          {({ dynamicFields, setDynamicFields }) => (
            <Formik
              validate={(values) => { return validateForm(values, dynamicFields, setDynamicFields) }}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitAllEditUpdates(values, dynamicFields, setSubmitting);
              }}
              innerRef={formRef}
              initialValues={{
                projectName: editProject?.Name || "",
                taskName: taskName,
                taskDue: taskDue,
                taskPriority: taskPriority,
                taskType: taskTypeId,
                taskStart: taskStart,
                commentNote: "",
                taskFilter: "",
                selectedMembers: [],
                selectedSlides: "",
                selectedFiles: []
              }}
            >
              {({
                setFieldValue,
                setFieldTouched,
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                isSubmitting,
                isValidating,
                isValid
              }) => (
                <Form onSubmit={handleSubmit} className="ml-2 mr-2">
                  { !hide &&
                    <React.Fragment>
                      <EmailTrackingTaskManagementSection
                        context={context}
                        projectInfo={editProject}
                        isProjectDisabled={true}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        setCurrentProjectId={setCurrentProjectId}
                        setProjectId={setProjectId}
                        taskTypes={taskTypes}
                        taskTypeId={taskTypeId}
                        setTaskTypeId={setTaskTypeId}
                        taskTypesName={taskTypesName}
                        taskTypeDue={taskTypeDue}
                        setTaskTypeDue={setTaskTypeDue}
                        taskPriority={taskPriority}
                        setTaskPriority={setTaskPriority}
                        prioritiesAvailable={editTask.PrioritiesAvailable}
                        taskDue={taskDue}
                        setTaskDue={setTaskDue}
                        endDateChange={endDateChange}
                        setEndDateChange={setEndDateChange}
                        isEditTask={true}
                        values={values}
                        touched={touched}
                        errors={errors}
                        isOwner = {isOwner()}
                      />
                      {renderTaskDetailDynamicFields({
                        dynamicFields: dynamicFields,
                        setDynamicFields,
                        disableErrors: disableDynamicDetailErrors,
                        onChange: (field) => setDFields(dynamicFields),
                      })}
                      <Form.Group
                        data-testid="selectedMembers"
                        className={touched?.selectedMembers && errors?.selectedMembers ? "mt-4" : ""}
                        id="selectedMembers">
                          <EmailTrackingTaskEditReassign
                            context={context}
                            setPage={setPage}
                            createdById={editTask?.CreatedById}
                            errorFromEdit={errors?.selectedMembers}
                            errors={errors}
                            setEditTaskMembers={setReassignedMembers}
                            setEditTaskOwner={setEditTaskOwner}
                            task={currentTask}
                            currUserId={context.currUser.Id}
                            currProjId={currentProjectId}
                            allRecipients={currentRecipients}
                            reassignRefresh={reassignRefresh}
                            setReassignRefresh={setReassignRefresh}
                            reassignRefreshCounter={reassignRefreshCounter}
                          />
                      </Form.Group>
                      <EmailTrackingTaskCommentSection
                        context={context}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        values={values}
                        touched={touched}
                        errors={errors}
                      />
                      <div className={`mt-3 pt-3 whiteBackground ${context.enableCreateButton ? "mb-2 pb-2" : "mb-1 pb-1"}`}></div>
                      { context.enableCreateButton &&
                        <div className="link-email-button-container">
                          <button
                            style={{ width: "96%" }}
                            className="mt-0 mb-0 ml-2 mr-2 loginButton btn-primary blueButton shadow-none taskModeButton taskAddSubmitBtn"
                            type="submit"
                            onClick={(e) => setDisableForm(true)}
                            disabled={isSubmitting || disableLinkTaskButton}
                          >
                            Link Email
                          </button>
                        </div>
                      }
                    </React.Fragment>
                  }
                </Form>
              )}
            </Formik>
          )}
        </DynamicFieldsFormWrapper>
      </div>
    )
  );
};

export default EmailTrackingTaskEdit;
