import React from "react";
import refreshButtom from "../../resources/Icons/ManualRefreshButton-20px-Blue.svg";
import "./manual-refresh-button.scss";

const ManualRefreshButton = ({
  refreshService
}) => (
    <span
        className="manual-refresh-button-container"
        onClick={() => refreshService()}
    >
        <img src={refreshButtom} alt="Refresh" className="refresh-icon" />
    </span>
);

export default ManualRefreshButton;
